import React from "react";
import { LeadCreate } from "./leadcreate";

class LeadCreateParent extends React.Component {
  render() {
    return <LeadCreate />;
  }
}

export default LeadCreateParent;
