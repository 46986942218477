import { Box, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

const useStyles = makeStyles((theme) => ({
    root: {
        cursor: "pointer",
        marginTop: "8px",
    },
    periodStyle: {
        height: "6px",
        width: "6px",
        borderRadius: "50%",
        backgroundColor: "#CED3DD",
        margin: "6px",
    },
    name: {
        fontSize: "14px",
        fontFamily: "tenant_bold",
        textTransform: "capitalize",
    },
    call: {
        fontSize: "12px",
        color: theme.typography.color.secondary,
    },
    mail: {
        fontSize: "12px",
        color: theme.typography.color.secondary,
    },
    img: {
        objectFit: "cover",
        height: "60px",
        width: "60px",
        borderRadius: "12px",
    },
    tagStyle: {
        backgroundColor: "#5078E11E",
        borderRadius: "5px",
        padding: "3px 10px 3px 10px",
        fontSize: "10px",
        fontFamily: "tenant_bold",
        color: theme.typography.color.primary,
    },
}));

export const CustomerCard = (props) => {
    const classes = useStyles();

    return (
        <div
            className={classes.root}
            onClick={() => {
                props.onClick(props.data.id);
            }}
        >
            <Grid container>
                <Grid item xs={12}>
                    <Box display="flex">
                        <Box>
                            <img
                                src={
                                    (props?.data?.assets !== null && props?.data?.assets?.length > 0 && props?.data?.assets?.[0]?.url !== null && props?.data?.assets?.[0]?.url !== "")
                                        ? props?.data?.assets?.[0]?.url
                                        : "/images/blank-profile-picture.png"
                                }
                                alt=""
                                className={classes.img}
                            />
                        </Box>

                        <Box flexGrow={1} marginTop="10px" marginLeft="8px">
                            <Typography className={classes.name}>
                                {props?.data?.customer_name}
                            </Typography>
                            <Box display="flex">
                                <Box>
                                    <Typography className={classes.mail}>
                                        {props?.data?.agreement !== null ?
                                            (props?.data?.agreement?.length + (props?.data?.agreement?.length === 1 ? " Agreement" : " Agreements")) :
                                            ""}
                                    </Typography>
                                </Box>
                                <Box className={classes.periodStyle} />
                                <Box>
                                    <Typography className={classes.call}>
                                        {"+91 " + props?.data?.mobile_no}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </div>
    );
};
