import { Container, Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { Track } from "..";
const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "5%",
  },
}));

export const Gokul = (props) => {
  const classes = useStyles();


  const quotationHistory = [
    {
      id: 0,
      quotationId: "Activity",
      time: "Bala Ganesh created a phone call activity for 10 Dec",
      date: "09 Dec 21, 14:30"
    },
    {
      id: 1,
      quotationId: "Check list",
      time: "Bala Ganesh created a phone call activity for 10 Dec",
      date: "09 Dec 21, 14:30"

    },
    {
      id: 2,
      quotationId: "Check list",
      time: "Bala Ganesh created a phone call activity for 10 Dec",
      date: "09 Dec 21, 14:30"
    },
    {
      id: 3,
      quotationId: "Lead Details",
      time: "Bala Ganesh created a phone call activity for 10 Dec",
      date: "09 Dec 21, 14:30"
    },
    {
      id: 4,
      quotationId: "Activity",
      time: "Bala Ganesh created a phone call activity for 10 Dec",
      date: "09 Dec 21, 14:30"
    }

  ]





  return (
    <div className={classes.root}>
      <Container maxWidth="sm">
        <Grid container spacing={1}>
          {quotationHistory.map((item, index, array) => {
            return (
              <Grid item xs={12}>
                <Track data={item} index={index} length={array.length} />

              </Grid>

            )
          })}

        </Grid>

      </Container>
    </div>
  );
};