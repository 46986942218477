import React from "react";
import { LeadShortList } from "./leadShortlist";


class LeadShortlistingParent extends React.Component {
    render() {
        return <LeadShortList />;
    }
}

export default (LeadShortlistingParent);
