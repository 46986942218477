import { Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Box } from "@mui/system";
import React from "react";
import { config } from "../../../config";
import { ADDTODOLIST, UPDATETODO } from "../../../graphql/mutations";
import { GETLEADDATA, GETLEADURL, TODOLIST } from "../../../graphql/queries";
import { NetworkCall } from "../../../networkcall/index";
import { NetWorkCallMethods } from "../../../utils";
import useWindowDimensions from "../../../utils/useWindowDimensions";
import { ActivitySection } from "./activitySection";
import { BottomActionButton } from "./bottomActionButton";
import { PrimaryDetailsSection } from "./primaryDetailsSection";
import { ShortlistedUnitsSection } from "./shortlistedUnitsSection";
import { ToDoListSection } from "./toDoListSection";
import { TransferLeadSection } from "./transferLeadSection";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.secondary,
  },
  screenScrollEffect: {
    overflow: "overlay",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
}));

const initialState = {
  id: "",
  name: "",
  description: "",
  is_active: true,
  id_done: "",
  created_by: "",
  error: {
    name: "",
    description: "",
  },
};
export const LeadInfo = (props) => {
  const classes = useStyles();

  const size = useWindowDimensions();
  const [todoListdata, setTodoListData] = React.useState([]);
  const [addto, setTodo] = React.useState({ ...initialState });
  const [leadDetails, setleadDetails] = React.useState({});
  const [leadImg, setleadImg] = React.useState("");
  const [isCreateToDo, setIsCreateToDoOpen] = React.useState(false);
  //lead details function
  //get URL
  const getleadUrl = (id) => {
    const variables = {
      query: GETLEADURL,
      variables: {
        id: id,
      },
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      variables,
      null,
      true,
      false
    )
      .then((response) => {
        setleadImg(response.data.data.assets[0].url);
      })

      .catch((error) => {
        console.log(error);
      });
  };
  //get Lead details
  const getleaddetails = () => {
    const variables = {
      query: GETLEADDATA,
      variables: {
        id: props.leadId,
      },
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      variables,
      null,
      true,
      false
    )
      .then((response) => {
        getleadUrl(response.data.data.lead[0].contact.id);

        setleadDetails(response.data.data.lead[0]);
      })

      .catch((error) => {
        console.log(error);
      });
  };
  //set completed in todo
  const checked = (val) => {
    const variables = {
      query: UPDATETODO,
      variables: {
        leadChecklistID: val.id,
        leadChecklistUpdateData: {
          updated_by: localStorage.getItem("userProfileId"),
          description: val.description,
          name: val.name,
          is_done: val.is_done ? false : true,
        },
      },
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      variables,
      null,
      true,
      false
    )
      .then((response) => {
        todo();
      })

      .catch((error) => {
        console.log(error);
      });
  };
  //get todo
  const todo = () => {
    const variables = {
      query: TODOLIST,
      variables: {
        id: props.leadId,
      },
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      variables,
      null,
      true,
      false
    )
      .then((response) => {
        setTodoListData(response.data.data.lead_checklist);
      })

      .catch((error) => {
        console.log(error);
      });
  };
  React.useEffect(() => {
    getleaddetails();
    todo();
    // eslint-disable-next-line
  }, []);
  //state management for todo
  const updateState = (key, value) => {
    let error = addto.error;
    error[key] = "";
    setTodo({ ...addto, [key]: value, error });
  };
  const isIamValide = () => {
    let isValid = true;
    let error = addto.error;
    //Checking name
    if (addto.name.length === 0) {
      isValid = false;
      error.name = "Name is Required";
    }
    //Checking description
    if (addto.description.length === 0) {
      isValid = false;
      error.description = "Description is Required";
      setTodo({ ...addto, error });
    }
    return isValid;
  };
  const closeCreateToDo = () => {
    setIsCreateToDoOpen(false);
  };
  const openCreateToDo = () => {
    setIsCreateToDoOpen(true);
  };
  const createTodo = () => {
    if (isIamValide()) {
      const variables = {
        query: ADDTODOLIST,
        variables: {
          insert: [
            {
              name: addto.name,
              description: addto.description,
              is_active: true,
              created_by: localStorage.getItem("userProfileId"),
              lead_id: props?.leadId,
            },
          ],
        },
      };
      NetworkCall(
        `${config.graphql_url}`,
        NetWorkCallMethods.post,
        variables,
        null,
        true,
        false
      )
        .then((response) => {
          let clonedata = {
            id: "",
            name: "",
            description: "",
            is_active: "",
            is_done: "",
            created_by: "",

            error: {
              name: "",
              description: "",
            },
          };

          setTodo(clonedata);
          todo();
          setIsCreateToDoOpen(false);
        })

        .catch((error) => {
          console.log(error);
        });
    } else {
    }
  };
  const clickes = () => {};

  return (
    <Grid className={classes.root} container direction={"column"}>
      <div
        className={classes.screenScrollEffect}
        style={{ height: size?.height - (55 + 59.5 + 63), width: "inherit" }}
      >
        <PrimaryDetailsSection
          leadDetails={leadDetails}
          leadImg={leadImg}
          leadId={props.leadId}
          active={props.active}
        />
        <Box height={8} />
        <ShortlistedUnitsSection />
        <Box height={8} />

        <ToDoListSection
          todoListdata={todoListdata}
          checked={checked}
          addto={addto}
          updateStateadd={updateState}
          submit={createTodo}
          isCreateToDo={isCreateToDo}
          closeCreateToDo={closeCreateToDo}
          openCreateToDo={openCreateToDo}
          onClick={clickes}
          active={props.active}
        />
        <Box height={8} />
        <ActivitySection leadId={props.leadId} active={props.active} />
        <Box height={8} />
        {props?.active === "true" && (
          <>
            <TransferLeadSection leadId={props.leadId} />
            <Box height={8} />
          </>
        )}
      </div>
      <BottomActionButton leadId={props.leadId} active={props.active} />
    </Grid>
  );
};
