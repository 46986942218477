import React from "react";
import { withBottombar } from "../../HOCs";
import { Activities } from "./activities";

class ActivitiesParent extends React.Component {
  render() {
    return <Activities />;
  }
}

export default withBottombar(ActivitiesParent);
