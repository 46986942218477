import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import jwt_decode from "jwt-decode";
import moment from "moment-timezone";
import React from "react";
import { CreateQuotationListContext } from "./createquotationcontaxt";
const useStyles = makeStyles((theme) => ({
  root: {},
  stepindicate: {
    color: theme.typography.color.Tertiary,
    fontSize: "12px",
    fontFamily: "tenant_semiBold",
  },

  stepindicateavatar: {
    backgroundColor: "#5078E1",

    padding: "10px",
  },
  stepindicateavatarborder: {
    border: "1px solid #00000029",
    borderRadius: "50%",
    padding: "4px",
  },
  steps: {
    borderBottom: "4px solid #F5F7FA",
  },
  search: {
    borderBottom: "4px solid #F5F7FA",

    padding: "18px 0px",
  },
  textbox: {
    [`& fieldset`]: {
      borderRadius: "8px",
      height: (props) => (props.multiline ? "unset" : 50),
      border: "1px solid #CED3DD",
      "& :hover": {
        border: "1px solid #5078E1",
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: "11px 14px",
    },
  },
  btnroot: {
    backgroundColor: "#F5F7FA",
    padding: "10px",
  },
  previousbtn: {
    backgroundColor: "white",
    color: "#091B29",
    border: "1px solid #CED3DD",
    borderRadius: "10px",
    padding: "12px 8px",
    boxShadow: "none",
  },
  submitbtn: {
    borderRadius: "10px",
    padding: "12px 8px",
  },
  name: {
    fontSize: "16px",
    fontFamily: "tenant_extraBold",
  },
  email: {
    fontSize: "10px",
    fontFamily: "tenant_bold",
    color: theme.typography.color.secondary,
    backgroundColor: "#F2F4F7",
    padding: "4px 6px",
    borderRadius: "8px",
    marginTop: "4px",
    display: "inline-block",
  },
  created: {
    fontSize: "10px",
    fontFamily: "tenant_regular",
    color: theme.typography.color.Tertiary,
    marginBottom: "10px",
    marginTop: "10px",
  },
  leadtype: {
    fontSize: "10px",
    fontFamily: "tenant_bold",

    backgroundColor: "#E1E8F8",
    padding: "1px 6px",
    borderRadius: "4px",
    textTransform: "capitalize",

    display: "inline-block",
  },
  imgdiv: {
    position: "relative",
  },
  prority: {
    position: "absolute",
    bottom: "4px",
    left: "0px",
    right: "0px",
  },
  proritydata: {
    fontFamily: "tenant_extraBold",
    fontSize: "10px",
    backgroundColor: "#071741",
    borderRadius: "4px",
    color: "white",
    padding: "2px 12px",
  },
  requirmentdata: {
    fontSize: "8px",
  },
  sqftdata: {
    fontSize: "12px",
    fontFamily: "tenant_bold",
  },
  secdule: {
    fontSize: "12px",
    fontFamily: "tenant_bold",
    marginLeft: "4px",
  },
  note: {
    fontSize: "12px",
    color: "#4E5A6B",
  },
  more: {
    color: "#5078E1",
    fontSize: "12px",
    cursor: "pointer",
  },
  screenPadding: {
    overflow: "overlay",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
}));
export const LeadPreview = () => {
  const classes = useStyles();
  const { leaddata, url, data } = React.useContext(CreateQuotationListContext);

  moment.defaultFormat = "DD MMM YY";

  const [more, setMoremore] = React.useState(false);
  const tempDecoded = jwt_decode(localStorage.getItem("authToken"));
  return (
    <Grid container>
      <Grid item style={{ padding: "14px" }}>
        <Grid container spacing={2} sx={{ padding: "14px" }}>
          <Grid item xs={4} md={3}>
            <div className={classes.imgdiv}>
              <img
                src={url}
                style={{
                  height: "150px",
                  width: "100%",
                  objectFit: "cover",
                  borderRadius: "10px",
                }}
                alt=""
              />
              <div className={classes.prority}>
                <center>
                  <span className={classes.proritydata}>
                    {leaddata.priority}
                  </span>
                </center>
              </div>
            </div>
          </Grid>
          <Grid item xs={8} md={9}>
            <Box display="flex">
              <Box>
                {" "}
                <Typography className={classes.name}>
                  {data.newleadfirst_name}&nbsp;{data.newleadlast_name}
                </Typography>
              </Box>
              <Box marginLeft="10px">
                {" "}
                <Typography className={classes.leadtype}>
                  {leaddata.leadtype === 1 && "Commercial"}
                  {leaddata.leadtype === 2 && "Residential"}
                </Typography>
              </Box>
            </Box>

            <Box display="flex">
              <Box>
                {" "}
                <Typography className={classes.email}>
                  {data.newleadmobile?.mobile}
                </Typography>
              </Box>
              <Box marginLeft="10px">
                {" "}
                <Typography className={classes.email}>
                  {data.newleademail}
                </Typography>
              </Box>
            </Box>

            {data.newleadstreet && (
              <Typography className={classes.email}>
                {data.newleadstreet}-{data.newleadaddress2}
              </Typography>
            )}
            <Box>
              {" "}
              <Typography className={classes.created}>
                Created by {tempDecoded?.user_name}&nbsp;
                {moment(leaddata.startdate).tz(moment.tz.guess()).format()}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ padding: "14px" }}>
          <Grid item xs={12}>
            <Stack
              direction="row"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={2}
            >
              <Box p={2}>
                <img src="/images/Sq ft icvon.svg" alt="sqft" />
                <Typography className={classes.requirmentdata}>
                  Requirement
                </Typography>
                <Typography className={classes.sqftdata}>
                  {leaddata.requirment}&nbsp;{leaddata.requirmentType}
                </Typography>
              </Box>
              <Box p={2}>
                <img src="/images/money.svg" alt="sqft" />

                <Typography className={classes.requirmentdata}>
                  Budget
                </Typography>
                <Typography className={classes.sqftdata}>
                  $ {leaddata.budget[0]}K - {leaddata.budget[1]}K
                </Typography>
              </Box>
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <Divider />
            <Box display="flex" alignItems="center" p={2}>
              <Box>
                <img src="/images/icons8-schedule (1).svg" alt="schdule" />
              </Box>
              <Box>
                <Typography className={classes.secdule}>
                  {moment(leaddata.startdate).tz(moment.tz.guess()).format()}
                  &nbsp;-&nbsp;
                  {moment(leaddata.endDate).tz(moment.tz.guess()).format()}
                </Typography>
              </Box>
            </Box>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" alignItems="center">
              <Box>
                <Typography className={classes.note}>
                  {more ? (
                    leaddata.notes
                  ) : (
                    <>
                      <b>Note:</b> {leaddata.notes}
                    </>
                  )}
                </Typography>
              </Box>
              <Box>
                <Typography
                  onClick={() => {
                    setMoremore(!more);
                  }}
                  className={classes.more}
                >
                  &nbsp;
                  {more ? " see less" : "see more"}
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>

        <br />
      </Grid>
    </Grid>
  );
};
