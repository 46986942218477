import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Container,
  Dialog,
  Divider,
  Drawer,
  Grid,
  Hidden,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { DocumentViewer, UnitList } from "../../components";
import { CreateQuotationListContext } from "./createquotationcontaxt";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: "8px",
  },
  drawer: {
    "& .MuiDialog-paper": {
      borderRadius: "10px",
    },
  },
  periodStyle: {
    height: "6px",
    width: "6px",
    borderRadius: "50%",
    backgroundColor: "#CED3DD",
    margin: "6px",
  },
  title: {
    fontSize: "14px",
    fontFamily: "tenant_bold",
    marginBottom: "4px",
  },
  leadname: {
    fontSize: "14px",
    fontFamily: "tenant_bold",
  },
  phone: {
    fontSize: "12px",
    color: theme.typography.color.secondary,
  },
  mail: {
    fontSize: "12px",
    color: theme.typography.color.secondary,
  },
  bottombtn: {
    background: "white",
    borderRadius: "8px",
    border: "2px solid #E4E8EE",
    padding: "12px 16px",
  },
  totalcost: {
    fontSize: "16px",
    fontFamily: "tenant_bold",
  },
  totalcostpreview: {
    fontSize: "14px",
    fontFamily: "tenant_bold",
    marginTop: "10px",
  },
  totalunits: {
    fontSize: "12px",
    color: theme.typography.color.secondary,
    fontFamily: "tenant_semiBold",
  },
  previewbtn: {
    padding: "8px 16px",
    background: "white",
    border: "2px solid #5078E1",
    borderRadius: "8px",
    boxShadow: "none",
    color: "#5078E1",
    "&:hover": {
      background: "white",
      boxShadow: "none",
    },
  },
  previewtitle: {
    fontSize: "14px",
    fontFamily: "tenant_bold",
  },
  previewaddress: {
    fontSize: "12px",
    fontFamily: "tenant_semiBold",
    color: theme.typography.color.Tertiary,
    marginTop: "6px",
  },
  previewsqft: {
    fontSize: "12px",
    fontFamily: "tenant_semiBold",
    color: theme.typography.color.secondary,
    display: "flex",
    alignItems: "center",
    marginTop: "6px",
  },

  data: {
    fontSize: "12px",
    fontFamily: "tenant_semiBold",
    color: theme.typography.color.Tertiary,
    marginTop: "6px",
  },
  datavalue: {
    fontSize: "12px",
    fontFamily: "tenant_semiBold",
    color: theme.typography.color.secondary,
    marginTop: "6px",
  },
  img: {
    height: "70px",
    width: "70px",
    objectFit: "cover",
    borderRadius: "10px",
  },
}));
export const Preview = (props) => {
  const classes = useStyles();

  const { data } = React.useContext(CreateQuotationListContext);
  const [openbreak, setOpenbreak] = React.useState(false);
  const [openpreview, setOpenpreview] = React.useState(false);

  const [previewdata, setPreview] = React.useState([]);
  const unitlist = [
    {
      id: 1,
      image: "/images/image1.png",
      title: "K-F01-394",
      address: "K Tower, 1 Floor",
      sqft: 1250,
      bed: 3,
      amount: "1,200.00",
      location: "T.Nager",
    },
    {
      id: 2,
      image: "/images/image1.png",
      title: "K-F01-394",
      address: "K Tower, 1 Floor",
      sqft: 1230,
      bed: 4,
      amount: "1,100.00",
      location: "T.Nager",
    },
  ];
  const handlebreak = (data) => {
    setOpenbreak(true);

    setPreview(data);
  };
  return (
    <div>
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <Typography className={classes.title}>Lead Details</Typography>
          {data.type === "existing lead" ? (
            <Box display="flex" alignItems="center">
              <Box>
                <img
                  src={data?.leadDetails?.contact?.get_assets_url_id}
                  className={classes.img}
                  alt=""
                />
              </Box>
              <Box flexGrow={1} marginLeft="12px">
                <Typography className={classes.leadname}>
                  {data?.leadDetails?.contact?.first_name}&nbsp;
                  {data?.leadDetails?.contact?.last_name}
                </Typography>
                <Box display="flex" alignItems="center">
                  <Box>
                    <Typography className={classes.phone}>
                      {data?.leadDetails?.contact?.mobile_no}
                    </Typography>
                  </Box>
                  <Box className={classes.periodStyle} />
                  <Box>
                    <Typography className={classes.mail}>
                      {data?.leadDetails?.contact?.email_id}(Account ID)
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          ) : (
            <Box display="flex" alignItems="center">
              <Box>
                <img
                  src={URL.createObjectURL(data.newleadprofile)}
                  className={classes.img}
                  alt=""
                />
              </Box>
              <Box flexGrow={1} marginLeft="12px">
                <Typography className={classes.leadname}>
                  {data?.newleadfirst_name}&nbsp; {data?.newleadlast_name}
                </Typography>
                <Box display="flex" alignItems="center">
                  <Box>
                    <Typography className={classes.phone}>
                      {data?.newleadmobile.mobile}
                    </Typography>
                  </Box>
                  <Box className={classes.periodStyle} />
                  <Box>
                    <Typography className={classes.mail}>
                      {data?.newleademail}(Account ID)
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}

          <Typography className={classes.title}>Quotation</Typography>
          {unitlist.map((val) => {
            return <UnitList data={val} breakup={true} onbreak={handlebreak} />;
          })}
          <br />
          <br />
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          position: "fixed",
          bottom: "80px",
          width: "100%",
          right: "0px",
          left: "0px",
        }}
      >
        <Container maxWidth="sm">
          <Box display="flex" alignItems="center" className={classes.bottombtn}>
            <Box flexGrow={1}>
              <Typography className={classes.totalcost}>$ 2,400</Typography>
              <Typography className={classes.totalunits}>2 units</Typography>
            </Box>
            <Box>
              <Button
                variant="contained"
                className={classes.previewbtn}
                onClick={() => {
                  setOpenpreview(true);
                }}
              >
                Preview Quotation
              </Button>
            </Box>
          </Box>
        </Container>
      </Grid>
      <Hidden smDown>
        <Dialog
          className={classes.drawer}
          open={openbreak}
          maxWidth="sm"
          fullWidth="true"
          onClose={() => {
            setOpenbreak(false);
          }}
        >
          <div className={classes.breakroot}>
            <Box display="flex" alignItems="center" p={1}>
              <Box flexGrow={1}>
                <Typography className={classes.title}>
                  Quotation Breakup
                </Typography>
              </Box>
              <Box>
                <IconButton
                  onClick={() => {
                    setOpenbreak(false);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
            <Divider />
            <Box
              display="flex"
              alignItems="center"
              p={2}
              borderBottom={2}
              borderColor="#F2F4F7"
              marginTop="6px"
            >
              <Box>
                <img src={previewdata.image} alt="" />
              </Box>
              <Box p={1} marginLeft="10px">
                <Typography className={classes.previewtitle}>
                  {previewdata.title}
                </Typography>
                <Typography className={classes.previewaddress}>
                  {previewdata.address}
                </Typography>
                <Stack
                  direction="row"
                  divider={<Divider orientation="vertical" flexItem />}
                  spacing={2}
                >
                  <Box>
                    <Typography className={classes.previewsqft}>
                      <img src="/images/SQ FT.svg" alt="" />
                      &nbsp;&nbsp;{previewdata.sqft}&nbsp;Sqft
                    </Typography>
                  </Box>
                  <Box>
                    <Typography className={classes.previewsqft}>
                      <img src="/images/Bedroom.svg" alt="" />
                      &nbsp;&nbsp;{previewdata.bed}&nbsp;Bedroom
                    </Typography>
                  </Box>
                  <Box>
                    <Typography className={classes.previewsqft}>
                      <img src="/images/location.svg" alt="" />
                      &nbsp;&nbsp;{previewdata.location}&nbsp;
                    </Typography>
                  </Box>
                </Stack>
              </Box>
            </Box>
            <div style={{ padding: "8px 14px" }}>
              <Box>
                <Typography className={classes.previewtitle}>
                  Breakup
                </Typography>
              </Box>
              <Box display="flex" alignItems="center">
                <Box flexGrow={1}>
                  {" "}
                  <Typography className={classes.data}>
                    Base Rent (Monthly)
                  </Typography>
                </Box>
                <Box>
                  {" "}
                  <Typography className={classes.datavalue}>$1,000</Typography>
                </Box>
              </Box>
              <Box display="flex" alignItems="center">
                <Box flexGrow={1}>
                  {" "}
                  <Typography className={classes.data}>
                    Maintanence (Monthly - 10%)
                  </Typography>
                </Box>
                <Box>
                  {" "}
                  <Typography className={classes.datavalue}>$100</Typography>
                </Box>
              </Box>
              <Box display="flex" alignItems="center">
                <Box flexGrow={1}>
                  {" "}
                  <Typography className={classes.data}>
                    Deposit (Onetime - Refundable)
                  </Typography>
                </Box>
                <Box>
                  {" "}
                  <Typography className={classes.datavalue}>$1000</Typography>
                </Box>
              </Box>
              <Box display="flex" alignItems="center">
                <Box flexGrow={1}>
                  {" "}
                  <Typography className={classes.data}></Typography>
                </Box>
                <Box>
                  {" "}
                  <Typography className={classes.totalcostpreview}>
                    Total&nbsp;$1200
                  </Typography>
                </Box>
              </Box>
            </div>
            <br />
          </div>
        </Dialog>
      </Hidden>
      <Hidden smUp>
        <Drawer
          open={openbreak}
          anchor="bottom"
          onClose={() => {
            setOpenbreak(false);
          }}
        >
          <Container maxWidth="sm">
            <div className={classes.breakroot}>
              <Box display="flex" alignItems="center" p={1}>
                <Box flexGrow={1}>
                  <Typography className={classes.title}>
                    Quotation Breakup
                  </Typography>
                </Box>
                <Box>
                  <IconButton
                    onClick={() => {
                      setOpenbreak(false);
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Box>
              <Divider />
              <Box
                display="flex"
                alignItems="center"
                p={2}
                borderBottom={2}
                borderColor="#F2F4F7"
                marginTop="6px"
              >
                <Box>
                  <img src={previewdata.image} alt="" />
                </Box>
                <Box p={1} marginLeft="10px">
                  <Typography className={classes.previewtitle}>
                    {previewdata.title}
                  </Typography>
                  <Typography className={classes.previewaddress}>
                    {previewdata.address}
                  </Typography>
                  <Stack
                    direction="row"
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={2}
                  >
                    <Box>
                      <Typography className={classes.previewsqft}>
                        <img src="/images/SQ FT.svg" alt="" />
                        &nbsp;&nbsp;{previewdata.sqft}&nbsp;Sqft
                      </Typography>
                    </Box>
                    <Box>
                      <Typography className={classes.previewsqft}>
                        <img src="/images/Bedroom.svg" alt="" />
                        &nbsp;&nbsp;{previewdata.bed}&nbsp;Bedroom
                      </Typography>
                    </Box>
                    <Box>
                      <Typography className={classes.previewsqft}>
                        <img src="/images/location.svg" alt="" />
                        &nbsp;&nbsp;{previewdata.location}&nbsp;
                      </Typography>
                    </Box>
                  </Stack>
                </Box>
              </Box>
              <div style={{ padding: "8px 14px" }}>
                <Box>
                  <Typography className={classes.previewtitle}>
                    Breakup
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center">
                  <Box flexGrow={1}>
                    {" "}
                    <Typography className={classes.data}>
                      Base Rent (Monthly)
                    </Typography>
                  </Box>
                  <Box>
                    {" "}
                    <Typography className={classes.datavalue}>
                      $1,000
                    </Typography>
                  </Box>
                </Box>
                <Box display="flex" alignItems="center">
                  <Box flexGrow={1}>
                    {" "}
                    <Typography className={classes.data}>
                      Maintanence (Monthly - 10%)
                    </Typography>
                  </Box>
                  <Box>
                    {" "}
                    <Typography className={classes.datavalue}>$100</Typography>
                  </Box>
                </Box>
                <Box display="flex" alignItems="center">
                  <Box flexGrow={1}>
                    {" "}
                    <Typography className={classes.data}>
                      Deposit (Onetime - Refundable)
                    </Typography>
                  </Box>
                  <Box>
                    {" "}
                    <Typography className={classes.datavalue}>$1000</Typography>
                  </Box>
                </Box>
                <Box display="flex" alignItems="center">
                  <Box flexGrow={1}>
                    {" "}
                    <Typography className={classes.data}></Typography>
                  </Box>
                  <Box>
                    {" "}
                    <Typography className={classes.totalcostpreview}>
                      Total&nbsp;$1200
                    </Typography>
                  </Box>
                </Box>
              </div>
              <br />
            </div>
          </Container>
        </Drawer>
      </Hidden>
      {/* preview */}
      <Hidden smDown>
        <Dialog
          className={classes.drawer}
          open={openpreview}
          maxWidth="sm"
          fullWidth="true"
          onClose={() => {
            setOpenpreview(false);
          }}
        >
          <div className={classes.breakroot}>
            <Box display="flex" alignItems="center" p={1}>
              <Box flexGrow={1}>
                <Typography className={classes.title}>
                  Preview Quotation
                </Typography>
              </Box>
              <Box>
                <IconButton
                  onClick={() => {
                    setOpenbreak(false);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
            <Divider />
            <div>
              <DocumentViewer url="https://storage.healthcircles.care/docs/v1/file/8803bdf0-a94e-4433-ad51-376ef2a2d4f5.pdf" />
            </div>
            <br />
          </div>
        </Dialog>
      </Hidden>
      <Hidden smUp>
        <Drawer
          open={openpreview}
          anchor="bottom"
          onClose={() => {
            setOpenpreview(false);
          }}
        >
          <Container maxWidth="sm">
            <div className={classes.breakroot}>
              <Box display="flex" alignItems="center" p={1}>
                <Box flexGrow={1}>
                  <Typography className={classes.title}>
                    Preview Quotation
                  </Typography>
                </Box>
                <Box>
                  <IconButton
                    onClick={() => {
                      setOpenbreak(false);
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Box>
              <Divider />
              <div>
                <DocumentViewer url="https://storage.healthcircles.care/docs/v1/file/8803bdf0-a94e-4433-ad51-376ef2a2d4f5.pdf" />
              </div>
              <br />
            </div>
          </Container>
        </Drawer>
      </Hidden>
    </div>
  );
};
