import {
  Box,
  Container,
  Dialog,
  Divider,
  Drawer,
  Grid,
  Hidden,
  IconButton,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { TitleBar } from "../../components";
import useWindowDimensions from "../../utils/useWindowDimensions";
import CloseIcon from "@mui/icons-material/Close";
import { config } from "../../config";
import { NetWorkCallMethods } from "../../utils";
import { NetworkCall } from "../../networkcall";
import { CustomerCard } from "./customerCard/customerCard";
import { AgreementCard } from "./agreementCard/agreementCard";


const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiContainer-root": {
      paddingLeft: "0px",
      paddingRight: "0px",
    },
  },
  total: {
    fontSize: "16px",
    fontFamily: "tenant_bold",
  },
  active: {
    fontSize: "12px",
    padding: "2px 6px",
    color: "#5078E1",
    backgroundColor: "#E4E8EE",
    borderRadius: "50%",
    width: "100%",
  },
  bottomTitle: {
    fontSize: "18px",
    fontFamily: "tenant_extraBold",
  },
  titleroot: {
    borderBottom: "1px solid #c1c1c1",
    // marginBottom: "12px",
  },
  contentBottom: {
    padding: "12px",
  },

  titleroot2: {
    borderBottom: "1px solid #c1c1c1",
    marginBottom: "8px",
    fontSize: "12px",
    color: theme.typography.color.secondary,
  },
  phone: {
    borderRadius: "10px",
    border: "1px solid #c1c1c1",
  },
  bottomDetails: {
    fontSize: "14px",
    color: theme.typography.color.primary,
    fontFamily: "tenant_Semibold",
  },
  viewHandbook: {
    color: theme.palette.primary.main,
    fontSize: "14px",
    fontFamily: "tenant_Semibold",
    cursor: "pointer",
  },
  screenPadding: {
    backgroundColor: "white",
    padding: "16px",
    overflow: "overlay",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  labelchecked: {
    fontSize: "16px",
    fontFamily: "tenant_semiBold",
    color: "#071741"
  },
  call: {
    fontSize: "12px",
    color: theme.typography.color.secondary,
  },
  mail: {
    fontSize: "12px",
    color: theme.typography.color.secondary,
  },
  periodStyle: {
    height: "6px",
    width: "6px",
    borderRadius: "50%",
    backgroundColor: "#CED3DD",
    margin: "6px",
  },
  drawer: {
    "& .css-9emuhu-MuiPaper-root-MuiDrawer-paper": {
      "box-shadow": "none",
      borderTopLeftRadius: "14px",
      borderTopRightRadius: "14px",
    },
  },
  dialog: {
    "& .css-tlc64q-MuiPaper-root-MuiDialog-paper": {
      "background-color": "transparent",
      "box-shadow": "none",
    },
  },
  bottomSheetStyle: {
    background: "white",
    borderRadius: "16px 16px 0px 0px",
    border: "1px solid #E4E8EE",
    justifyContent: "center",
  },
  dialogStyle: {
    background: "white",
    borderRadius: "16px",
    border: "1px solid #E4E8EE",
    justifyContent: "center",
  },
  drawerHeaderStyle: {
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px",
  },
  drawerHeaderTextStyle: {
    fontSize: "16px",
    fontWeight: "bolder",
    color: theme.typography.color.primary,
  },
  drawerBodyStyle: {
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px 16px 16px 16px",
  },

}));


export const ActiveCustomers = (props) => {
  const classes = useStyles();
  const size = useWindowDimensions();
  const [initialActiveCustomersData, setInitialActiveCustomerData] = React.useState([]);
  const [agreementData, setAgreementData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isAgreementListOpen, setIsAgreementListOpen] = React.useState(false);
  const roles = JSON.parse(localStorage.getItem("selectedroles"));
  const [selectedAgreement, setSelectedAgreement] = React.useState([]);

  const closeAgreementList = () => {
    setIsAgreementListOpen(false);
  };

  const openAgreementList = () => {
    setIsAgreementListOpen(true);
  };

  const goBack = () => {
    window.history.go(-1);
  };
  const Clear = () => {
    alert("clear");
  };

  React.useEffect(() => {
    const payload = {
      company_id: roles.company_id,
      tenantId: `${config.tenantid}`,
      sales_agent: localStorage.getItem("userProfileId")
    };
    NetworkCall(
      `${config.api_url}/active-customer`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    ).then((response) => {
      const data = response?.data?.data ? response?.data?.data : null;
      if (data !== null) {
        setInitialActiveCustomerData(data);
        setIsLoading(false);
      }
    }).catch((error) => { console.log(error); });
    // eslint-disable-next-line
  }, []);

  const getAgreementsByContactID = (contactID) => {
    const payload = {
      company_id: roles.company_id,
      tenantId: `${config.tenantid}`,
      contact_id: contactID
    };
    NetworkCall(
      `${config.api_url}/active-customer/agreements`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    ).then((response) => {
      let tempAgreementSelectionData = (response?.data?.data !== null && response?.data?.data.length > 0) ? response?.data?.data : null;
      if (tempAgreementSelectionData !== null) {
        setAgreementData(tempAgreementSelectionData);
        openAgreementList();
      }
    }).catch((error) => { console.log(error); });
  }

  const handleSelectedAgreementItem = (agreement) => {
    setSelectedAgreement(agreement)
  }

  return (
    <div className={classes.root}>
      <Container maxWidth="sm">
        {isLoading ? <></> : <>
          <Grid container sx={{ backgroundColor: "#F5F7FA" }}>
            <Grid
              className={classes.screen}
              style={{ height: size?.height }}
              item
              xs={12}
            >
              <Grid className={classes.topNavBarStyle}>
                <Grid item xs={12}>
                  <TitleBar text="Active Customers" goBack={goBack} onClear={Clear} />
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{ padding: "0px 12px" }}
                >
                  {/* filter btn and units length */}
                  <Box display="flex" alignItems="center" marginBottom="10px">
                    <Box flexGrow={1}>
                      <Typography className={classes.total}>
                        {(initialActiveCustomersData?.length === 1 ? "Active Customer " : "Active Customers (") + initialActiveCustomersData?.length + ")"}
                      </Typography>
                    </Box>
                    <Box>
                      <IconButton className={classes.filter} >
                        <img src="/images/search.svg" alt="filter" />
                      </IconButton>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              <Grid
                className={classes.screenPadding}
                style={{ height: size?.height - 115 }}
              >
                <Grid item xs={12} style={{ padding: "0px", backgroundColor: "white" }}>
                  {initialActiveCustomersData.map((val) => {
                    return <CustomerCard data={val} onClick={getAgreementsByContactID} />;
                  })}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* drawer */}

          <Hidden smDown>
            <Dialog
              className={classes.dialog}
              fullWidth={true}
              maxWidth="sm"
              open={isAgreementListOpen}
              onClose={closeAgreementList}
            >
              <Grid
                container
                direction="column"
                className={classes.dialogStyle}
              >
                <Grid
                  container
                  direction="row"
                  className={classes.drawerHeaderStyle}
                >
                  <Grid>
                    <Typography className={classes.drawerHeaderTextStyle}>
                      Select Agreement
                    </Typography>
                  </Grid>
                  <Grid>
                    <IconButton
                      style={{
                        padding: "0px",
                      }}
                      onClick={closeAgreementList}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                </Grid>
                <Divider />
                <Grid container direction={"column"} className={classes.drawerBodyStyle}>
                  {agreementData?.map((item, index, array) => {
                    return <AgreementCard data={item} selectedAgreement={selectedAgreement} onClick={handleSelectedAgreementItem} index={index} array={array} />
                  })}
                </Grid>
              </Grid>
            </Dialog>
          </Hidden>
          <Hidden smUp>
            <Drawer
              className={classes.drawer}
              anchor="bottom"
              open={isAgreementListOpen}
              onClose={closeAgreementList}
            >
              <Grid
                container
                direction="column"
                className={classes.bottomSheetStyle}
              >
                <Grid
                  container
                  direction="row"
                  className={classes.drawerHeaderStyle}
                >
                  <Grid>
                    <Typography className={classes.drawerHeaderTextStyle}>
                      Select Agreement
                    </Typography>
                  </Grid>
                  <Grid>
                    <IconButton
                      style={{
                        padding: "0px",
                      }}
                      onClick={closeAgreementList}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                </Grid>
                <Divider />
                <Grid container direction={"column"} className={classes.drawerBodyStyle}>
                  {agreementData?.map((item, index, array) => {
                    return <AgreementCard data={item} selectedAgreement={selectedAgreement} onClick={handleSelectedAgreementItem} index={index} array={array} />
                  })}
                </Grid>
              </Grid>
            </Drawer>
          </Hidden>

        </>}
      </Container>
    </div >
  );
};