import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { TodayActionCard } from "../../components";

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: "overlay",
    display: "-webkit-box",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
}));

export const Kishore = (props) => {
  const classes = useStyles();

  const actionCardData = [
    {
      id: 1
    },
    {
      id: 2
    },
  ];

  return (
    <div className={classes.root}>
      {actionCardData.map((actionCard) => {
        return <TodayActionCard />
      })}
    </div>
  );
};