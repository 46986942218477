import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Container,
  Dialog,
  Drawer,
  Grid,
  Hidden,
  IconButton,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import jwt_decode from "jwt-decode";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Password, TextBox } from "../../components";
import { config } from "../../config";
import { AlertContext,CommonApiContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";
import { AlertProps, Message, ValidateEmail } from "../../utils";
import { LocalStorageKeys, NetWorkCallMethods } from "../../utils/constants";
import { ResetPassword } from "./resetPassword";

const useStyles = makeStyles((theme) => ({
  backgroundImage:props=>({
    // backgroundColor: theme.palette.primary.main,
    height: "22vh",
    // backgroundImage: `url("images/setpassword.svg")`,
    backgroundImage: `url(${props?.bg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 22vh",
    backgroundPosition: "center",
  }),
  content: {
    height: "78vh",
    width: "100%",
    padding: 20,
    display:'flex',
    flexDirection:'column'
  },
  text: {
    fontSize: "24px",
    fontFamily: "tenant_extraBold",
    color: theme.typography.color.primary,
  },
  btn: {
    borderRadius: "12px",
    boxShadow: "opx 6px 10px #00000014",
    marginBlock:theme.spacing(2),
    padding: "16px",
    "&:hover": {
      color: "#FFFFFF",
      backgroundColor: "#5078E1",
    },
  },
  img: {
    height: "8%",
    width: "28%",
  },
  forgetPassword: {
    float: "right",
    fontFamily: "tenant_semiBold",
    fontSize: "12px",
    color: theme.palette.primary.main,
    marginTop: 7,
    cursor: "pointer",
  },
  bottomTitle: {
    fontSize: "18px",
    fontFamily: "tenant_extraBold",
  },
  titleroot: {
    borderBottom: "1px solid #c1c1c1",
    marginBottom: "12px",
  },
  contentBottom: {
    padding: "12px",
  },
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopLeftRadius: "16px",
      borderTopRightRadius: "16px",
    },
    "& .MuiContainer-root": {
      padding: "0px",
    },
  },
  poweredby: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "12px",
    color: theme.typography.color.Tertiary,
    marginTop: "10px",
  },
  pa: {
    color: theme.typography.color.secondary,
    fontFamily: "tenant_semiBold",
  },
}));
const InitialState = {
  email: "",
  password: "",
  error: {
    email: "",
    password: "",
  },
};

export const Login = () => {
  const history = useHistory();
  const alert = React.useContext(AlertContext);
  const commanApi = React.useContext(CommonApiContext);
  const classes = useStyles({bg:commanApi?.logo?.login_banner_url});
  const [login, setLogin] = React.useState({ ...InitialState });
  const authToken = localStorage.getItem(LocalStorageKeys.authToken);
  const search = useLocation().search;
  const welcomePageAuthToken = new URLSearchParams(search).get(
    "welcomeAuthToken"
  );
  const [decoded, setDecoded] = React.useState(null);
  const [showDrawer, setShowDrawer] = React.useState(false);
  React.useEffect(() => {
    if (welcomePageAuthToken !== null && welcomePageAuthToken !== "") {
      setDecoded(jwt_decode(welcomePageAuthToken));
    } else if (authToken !== null && authToken !== "") {
      history.push(Routes.dashboard);
    } else {
      history.push(Routes.login);
    }
    // eslint-disable-next-line
  }, [history]);
  const updateState = (key, value) => {
    if (key === "email") {
      ValidateEmail(value);
    }
    let error = login.error;
    error[key] = "";
    setLogin({ ...login, [key]: value, error });
  };
  const isIamValideToLogin = () => {
    let isValid = true;
    let error = login.error;

    if (decoded != null && decoded.email_id.length === 0) {
      isValid = false;
      error.email = Message.English.requiredMessage("Email");
    }
    if (login.email.length === 0) {
      isValid = false;
      error.email = Message.English.requiredMessage("Email");
    }
    if (login.password.length === 0) {
      isValid = false;
      error.password = Message.English.requiredMessage("Password");
    }
    if (
      ((decoded != null && decoded.email_id !== "") || login.email !== "") &&
      login.password !== ""
    ) {
      isValid = true;
    }
    setLogin({ ...login, error });
    return isValid;
  };

  const onSendLoginBtnClicked = () => {
    if (isIamValideToLogin()) {
      const data = {
        tenantId: `${config.tenantid}`,
        email_id: decoded && decoded.email_id ? decoded.email_id : login.email,
        password: login.password,
      };
      NetworkCall(
        `${config.api_url}/users/verify`,
        NetWorkCallMethods.post,
        data,
        null,
        false,
        false
      )
        .then((response) => {
          if (response.status === 200) {
            if (welcomePageAuthToken !== null && welcomePageAuthToken !== "") {
              history.push(
                Routes.otpPage + "?welcomeAuthToken=" + welcomePageAuthToken
              );
            } else {
              history.push(
                Routes.otpPage +
                "?emailId=" +
                (decoded && decoded.email_id ? decoded.email_id : login.email)
              );
            }
          }
        })
        .catch((err) => {
          console.log(err);
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: "Invalid Credientials",
          });
        });
    } else {
      return false;
    }
  };

  const onDrawerClose = () => {
    setShowDrawer(false);
  };

  return (
    <div>
      <Container maxWidth="sm" style={{ padding: 0 }}>
        <Grid container>
          <Grid item xs={12} className={classes.backgroundImage} />
          <div className={classes.content}>
            <Grid item style={{flex:1}}>
            <Grid
              container
              xs={12}
              direction="row"
              justifyContent={"space-between"}
              alignContent={"center"}
            >
              <img
                // src="/images/logoOne.svg"
                src={commanApi?.logo?.logo_url}
                alt="logo"
                className={classes.img}
              />
              <img
                // src="/images/Propgoto logo-01.svg"
                src="/images/PA Logo.svg"
                alt="logo"
                className={classes.img}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.text}>Log in</Typography>
              <br />
            </Grid>

            <Grid item xs={12}>
              <TextBox
                value={
                  decoded && decoded.email_id ? decoded.email_id : login.email
                }
                onChange={(e) => updateState("email", e.target.value)}
                label={Message.English.login.email.label}
                placeholder={Message.English.login.email.placeholder}
                isError={login.error.email.length > 0}
                errorMessage={login.error.email}
                isReadonly={decoded && decoded.email_id ? true : false}
                isRequired
              />
              <br />
            </Grid>

            <Grid item xs={12}>
              <Password
                value={login.password}
                onChange={(e) => updateState("password", e.target.value)}
                label={Message.English.login.password.label}
                placeholder={Message.English.login.password.placeholder}
                isError={login.error.password.length > 0}
                errorMessage={login.error.password}
                isRequired
              />
              <span
                className={classes.forgetPassword}
                onClick={() => setShowDrawer(true)}
              >
                Forget password ?
              </span>
              {/* <br />
              <br />
              <br />
              <br /> */}
            </Grid>
            </Grid>
            {/* <Grid item xs={12}> */}
              {/* <br /> */}
              {/* <Typography className={classes.poweredby}>
                Powered by&nbsp;
                <img src="/images/logo.png" alt="logo" />
                &nbsp;<span className={classes.pa}>Property Automate</span>
              </Typography> */}
              <div>
              <Button
                fullWidth
                size="large"
                variant="contained"
                className={classes.btn}
                onClick={onSendLoginBtnClicked}
              >
                Log in
              </Button>
              </div>
              {/* <br />
              <br /> */}
            {/* </Grid> */}
          </div>
        </Grid>
        {/* Drawer */}
        <Hidden smUp>
          <Drawer
            className={classes.drawer}
            open={showDrawer}
            anchor="bottom"
            onClose={() => setShowDrawer(false)}
          >
            <Container maxWidth="sm" style={{ overflow: "hidden" }}>
              <div className={classes.contentBottom}>
                <Box
                  display="flex"
                  p={2}
                  alignItems="center"
                  className={classes.titleroot}
                >
                  <Box flexGrow={1}>
                    <Typography className={classes.bottomTitle}>
                      Reset Password
                    </Typography>
                  </Box>
                  <Box>
                    <IconButton
                      size="small"
                      onClick={() => setShowDrawer(false)}
                    >
                      <CloseIcon color="secondary" />
                    </IconButton>
                  </Box>
                </Box>

                <ResetPassword onDrawerClose={onDrawerClose} />
              </div>
            </Container>
          </Drawer>
        </Hidden>
        <Hidden smDown>
          <Dialog fullWidth={true} maxWidth="sm" open={showDrawer}>
            <Container maxWidth="sm" style={{ overflow: "hidden" }}>
              <div className={classes.contentBottom}>
                <Box
                  display="flex"
                  p={2}
                  alignItems="center"
                  className={classes.titleroot}
                >
                  <Box flexGrow={1}>
                    <Typography className={classes.bottomTitle}>
                      Reset Password
                    </Typography>
                  </Box>
                  <Box>
                    <IconButton
                      size="small"
                      onClick={() => setShowDrawer(false)}
                    >
                      <CloseIcon color="secondary" />
                    </IconButton>
                  </Box>
                </Box>

                <ResetPassword onDrawerClose={onDrawerClose} />
              </div>
            </Container>
          </Dialog>
        </Hidden>
      </Container>
    </div>
  );
};
