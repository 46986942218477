import React from "react";
import { MoveIn } from "./moveIn";

class MoveInParent extends React.Component {
    render() {
        return <MoveIn />;
    }
}

export default (MoveInParent);
