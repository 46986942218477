import { ChevronRight } from "@mui/icons-material";
import { Box, Typography, IconButton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useHistory } from "react-router-dom";
import { Routes } from "../../router/routes";

const useStyles = makeStyles((theme) => ({
    root: {
        boxShadow: "0px 3px 6px #53668523",
        borderRadius: "10px",
        backgroundColor: "#EEF9EE",
        cursor: "pointer",
    },
    title: {
        fontSize: "16px",
        fontFamily: "tenant_bold",
    },

}));

export const MoveCard = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const moveOut = () => {
        history.push(Routes.moveout);
    };
    return (
        <div className={classes.root} onClick={props.movein ? props.onClick : moveOut}>
            <Box display="flex">
                <Box flexGrow={1}>
                    <Box display={"flex"} sx={{ padding: "4px", alignItems: "center" }}>
                        &nbsp;<img src={props.movein ? "images/movein.svg" : "images/moveout.svg"} alt={"pro"} />
                        <Box>
                            <Typography variant="body1" className={classes.title}>
                                &nbsp; {props.movein ? "Tenant Move in" : "Tenant Move Out"}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <Box>
                    <IconButton onClick={() => false}>
                        <ChevronRight />
                    </IconButton>
                </Box>
            </Box>
        </div>
    );
};
