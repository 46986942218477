import React from "react";
import { MoveOut } from "./moveOut";

class MoveOutParent extends React.Component {
    render() {
        return <MoveOut />;
    }
}

export default (MoveOutParent);
