import React from "react";
import { QuotationDetails } from "./quotationDetails";

class QuotationDetailsParent extends React.Component {
  render() {
    return <QuotationDetails />;
  }
}

export default QuotationDetailsParent;
