import React from "react";
import { withBottombar } from "../../HOCs";
import { Profile } from "./profile";
class ProfileParent extends React.Component {
  render() {
    return <Profile />;
  }
}

export default withBottombar(ProfileParent);
