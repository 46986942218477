// import gql from "graphql-tag";

// export const yourQuery = gql``;
export const GETLEADTYPE = `
query{
  lead_type_master{

    id
    name
  }
}`;
export const SEARCHLEAD = `
query($search: String) {
  contact(
    where: {
      or: {
        first_name: { ilike: $search }
        email_id: { ilike: $search }
        mobile_no: { ilike: $search }
      }
    }
  ) {
    id
     lead{
      id
    }
    first_name
    last_name
    email_id
    mobile_no
    get_assets_url_id
  }
}`;
export const GETPROFILEID = `

query($id){
  user_profiles(where:{user_id:{eq:$id}}){
   id
  }
}
`;
export const GETLEADDATA = `
query{
 lead(where:{id:{eq:$id}}){
  id
  is_active
  name
  budget_range
  lease_start_date
  lease_end_date
  lead_type
  urgent
  created_by
  value
  notes
  value_type
  created_at
  contact{
    id
    email_id
    mobile_no
     first_name
    last_name
    mobile_no_country_code
   
  }
} 
}
`;
export const GETLEADURL = `
query($id:String){
  assets(where:{reference_id:$id priority:1 assets_master_type:2}){
    url
    id
  }
}
`;
export const TODOLIST = `
query($id:String){
  lead_checklist(where:{lead_id:$id ,is_active:true}){
    id
    name
    description
    is_done
    created_by
  }
}`;
export const GETCATEGORY = `
 query($activity_id:String){
  activity_category_master(where:{lead_activity_master_id:$activity_id}){
    id
    activity_category
  }
}`;
export const GETSUBCATEGORY = `
query($master_id:String){
  activity_sub_category_master(where:{activity_category_master_id:$master_id}){
    id
    sub_category
  }
}`;
export const GETACTIVITYBYID = `
query($leadId:String){
  lead_activities(where:{lead_id:{eq:$leadId} is_active:{eq:true}}){
    id
            lead{
      lead_no
    }
    activity_sub_category{
    id
    sub_category
  }
    activity_category{
      id
      activity_category
    }
    created_at
    created_by
    notes
    remarks
    email_id
    priority
    type
    responsible
    lead_activitiy_master
    status
     date_time
      phone_number
      location
  }
}`;
export const GETACTIVITYBYOWNER = `
query($id: String) {
  lead_activities(
    order_by:{created_at:desc}
    where: { responsible: { id: { eq: $id } }, status: "open", is_active:true }) {
       id
        lead{
      lead_no
    }
    activity_sub_category {
      id
      sub_category:name
    }
    activity_category {
      id
      activity_category:name
    }
    created_at
    created_by
    notes
    remarks
    email_id
    priority
    type
    responsible
    lead_activitiy_master
    status
    date_time
    phone_number
    location
  }
}

`;
export const SEARCHLEADBYOWNER = `
query($search: String,$ownerId:String) {
  lead(
    where: {
      lead_owner: $ownerId
      contact:{
        or: {
        first_name: { ilike: $search }
        email_id: { ilike: $search }
        mobile_no: { ilike: $search }
      }
      }
    }
  ) {
    lead_no
    created_by
    id
    created_at
    contact {
      id
      first_name
      last_name
      email_id
     mobile_no
      get_assets_url_id
      
    }
  }
}

`;
export const GETLEADBYOWNER = `
query($search: String, $ownerId: String) {
  lead(where: { lead_owner: $ownerId }) {
    lead_no
    created_by
    id
    created_at
    contact {
      id
      first_name
      last_name
      email_id
      mobile_no
      get_assets_url_id
    }
  }
}
`;

export const GETPROFILEDETAILS = `
query Profile($userId: String) {
  user_profiles(where: { user_id: { eq: $userId } }) {
    id
    first_name
    last_name
    email_id
    mobile_no
    alternative_mobile_no
    alternative_email_id
    contact_id
    contact {
      id
      street_1
      street_2
      street_3
      district
      city
      country
      state
      zipcode
      latitude
      longitude
    }
  }
}
`;
export const GETALL = `
query($quotation_no: Int) {
  quotation(where: { quotation_no: { eq: $quotation_no } }) {
    id
    lead_id
    quotation_no
    quote_start_date
    lease_period
    lease_duration
    lease_end_date
    lease_start_date
    lease_payment_period
    grace_period
    description
    total_tax
    created_by
    total_amount
    quotation_status {
      id
      remarks
      request_date_time
      quotation_status_id
      quotation_status_master {
        id
        quotation_status
      }
      created_at
    }
  }
}
`;
export const GETALLSTATUS = `
query{
 quotation_status_master{
  quotation_status
  id
}
}`;
export const INVOICEQ = `
query($quotation_id:string){
  invoice(where:{quotation_id:{eq:$quotation_id}}){
   id
    invoice_no
    invoice_due_amount
    description
    payment_status
    payment_due_date
  }
}
`;
export const GETPROFILEIMAGE = `
query($referenceId: String) {
  assets(
    where: {
      reference_id: { eq: $referenceId }
      priority: { eq: 1 }
      assets_master: { id: { eq: 2 } }
      is_active: true
    }
  ) {
    id
    reference_id
    url
  }
}
`;

export const GETCLIENTUSERROLE = `
query($userProfileId: String) {
  client_user_roles(where: { user_profile_id: { eq: $userProfileId } }) {
    id
    user_profile_id
    client_role_no
  }
}
`;
export const USER = `
query($id: String){
  user_profiles(where:{id:{eq:$id}}){
    first_name
    last_name
  }
}`;

export const GET_LEAD_ACTIVITY_TYPE_ID_BY_TYPE = `
query GetLeadActivityMaster($leadActivityType: String) {
  lead_activity_master(
    where: { type: $leadActivityType, is_active: { eq: true } }
  ) {
    id
    type
  }
}
`;
export const GETROLES = `
query{
  roles(where:{is_view:true}){
    name
    id
  }
}`;
export const GETTRANSFERNAME = `
query($id:String){
  client_user_roles(where:{roles_id:$id}){
     user_profile{
      id
      first_name
      last_name
    }    
  }
}`;

export const GET_PROFILE_DETAILS = `
query Profile($userID: String) {
  user_profiles(where: { user_id: { eq: $userID } }) {
    id
    first_name
    last_name
    email_id
    mobile_no
    alternative_mobile_no
    alternative_email_id
    contact_id
    contact {
      id
      street_1
      street_2
      street_3
      district
      city
      country
      state
      zipcode
      latitude
      longitude
    }
  }
}
`;

export const GET_DETAILS_FOR_PROFILE_SCREEN = `
query getDetailsForProfileScreen(
  $referenceID: String
  $userProfileID: String
  $contactID: ID
) {
  assets(
    where: {
      reference_id: { eq: $referenceID }
      
      assets_master: { id: { eq: 2 } }
      is_active: true
    }
  ) {
    id
    reference_id
    url
    priority
    assets_master_type
    is_active
  }
  client_user_roles(
    where: { user_profile_id: { eq: $userProfileID }, is_active: { eq: true } }
  ) {
    id
    user_profile_id
    client_role_no
    is_active
  }
  contact(where: { id: { eq: $contactID }, is_active: { eq: true } }) {
    id
    street_1
    street_2
    street_3
    district
    city
    country
    state
    zipcode
    latitude
    longitude
    is_active
  }
}
`;
