import { Divider, Grid, IconButton, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  card: {
    boxShadow: "0px 3px 18px #0000000F",
    borderRadius: "16px",
    border: `1px solid ${theme.palette.border.secondary}`,
    backgroundColor: theme.palette.background.tertiary1,
  },
  Cardcontent: {
    padding: "8px 16px",
  },
  title: {
    fontSize: "16px",
    fontFamily: "tenant_bold",
    color: theme.typography.color.primary,
  },
  download: {},
  pdfContent: {
    borderRadius: "8px",
    border: `1px solid ${theme.palette.border.secondary}`,
    backgroundColor: theme.palette.background.tertiary1,
    display: "flex",
    objectFit: "cover",
  },
  pdfImg: { width: "100%", height: "155px" },
  body: {
    fontSize: "12px",
    fontFamily: "tenant_semiBold",
    color: theme.typography.color.secondary,
  },
  subBody: {
    textAlign:"right",
    fontSize: "14px",
    fontFamily: "tenant_bold",
    color: theme.typography.color.primary,
  },
  divider: {
    margin: "12px 0px",
  },
}));

export const Quotation = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.card}>
      <div className={classes.cardContent}>
        <Grid container p={2}>
          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography className={classes.title}>
              {props.data.title}
            </Typography>
            <div className={classes.download}>
              <IconButton onClick={props.onClickDownload}>
                <img src="/images/download.svg" alt="download" />
              </IconButton>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.pdfContent}>
              <img
                className={classes.pdfImg}
                src="/images/quotation_1.png"
                alt="quotationImage"
              />
            </div>
          </Grid>

          <Grid xs={12} className={classes.divider}>
            <Divider />
          </Grid>
          <Grid container xs={12}>
              <Grid item xs={6}>
                <Typography className={classes.body}>
                  {"Total amount : "}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography className={classes.subBody}>
                  {"INR "+props.data.totalAmount}
                </Typography>
              </Grid>
            </Grid>
        </Grid>
      </div>
    </div>
  );
};
