import { Avatar, Container } from "@mui/material";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import makeStyles from "@mui/styles/makeStyles";
import * as React from "react";
const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.primary.main,
    // borderRadius: "10px",
    color: theme.palette.primary.main,
    // "&.css-14ugdsl-MuiButtonBase-root-MuiBottomNavigationAction-root.Mui-selected":
    //   {
    //     color: "white",
    //   },
    // margin: "0px 8px",
  },
  root1:{
    '@media (max-width:376px)': {   
      flex:'auto',
      minWidth:'auto',
      maxWidth:'100%',
      padding:5
    }
  },
  containers: {
    "&.MuiContainer-root": {
      paddingLeft: "0px",
      paddingRight: "0px",
    },
    overflow: "hidden",
  },
}));
export const BottomNavbar = (props) => {
  const classes = useStyles();

  return (
    <Container className={classes.containers}>
      <BottomNavigation
        showLabels
        style={{
          borderTopRightRadius: "25px",
          borderTopLeftRadius: "25px",
          boxShadow: "0px -1px 6px #00000014",
        }}
      >
        <BottomNavigationAction
          className={
            `${window.location.pathname === "/dashboard" ? classes.root : ''} ${classes.root1}`
          }
          label="Dashboard"
          icon={
            <img
              src={
                window.location.pathname === "/dashboard"
                  ? "/images/dashboard_selected.svg"
                  : "/images/dashboard_unselected.svg"
              }
              style={{ paddingBottom: "6px" }}
              alt="dashboard"
            />
          }
          onClick={props.onTab1Clicked}
        />
        <BottomNavigationAction
          className={
            `${window.location.pathname === "/lead" ? classes.root : ''} ${classes.root1}`
          }
          label="Lead"
          icon={
            <img
              src={
                window.location.pathname === "/lead"
                  ? "/images/lead_selected.svg"
                  : "/images/lead_unselected.svg"
              }
              style={{ paddingBottom: "6px" }}
              alt="lead"
            />
          }
          onClick={props.onTab2Clicked}
        />

        <BottomNavigationAction
        className={classes.root1}
          icon={
            <Avatar
              variant="circular"
              style={{
                height: "46px",
                width: "46px",
                border: "2px solid #CCD3E4",
                backgroundColor: "#5078E1",
              }}
            >
              <img
                src={
                  window.location.pathname === "/search"
                    ? "/images/search_selected.svg"
                    : "/images/search_unselected.svg"
                }
                alt="search"
              />
            </Avatar>
          }
          onClick={props.onTab3Clicked}
        />
        <BottomNavigationAction
          className={
            `${window.location.pathname === "/activities" ? classes.root : ''} ${classes.root1}`
          }
          label="Activities"
          icon={
            <img
              src={
                window.location.pathname === "/activities"
                  ? "/images/activities_selected.svg"
                  : "/images/activities_unselected.svg"
              }
              style={{ paddingBottom: "6px" }}
              alt="activites"
            />
          }
          onClick={props.onTab4Clicked}
        />
        <BottomNavigationAction
          className={
            `${window.location.pathname === "/profile" ? classes.root : ''} ${classes.root1}`
          }
          label="Profile"
          icon={
            <img
              src={
                window.location.pathname === "/profile"
                  ? "/images/profile_selected.svg"
                  : "/images/profile_unselected.svg"
              }
              style={{ paddingBottom: "6px" }}
              alt="profile"
            />
          }
          onClick={props.onTab5Clicked}
        />
      </BottomNavigation>
    </Container>
  );
};
