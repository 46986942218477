import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Box,
  Button,
  Checkbox,
  Container,
  Dialog,
  Drawer,
  FormControlLabel,
  Grid,
  Hidden,
  IconButton,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useHistory } from "react-router-dom";
import { AgreementCard, TitleBar, UnitList } from "../../components";
import { Routes } from "../../router/routes";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { MoveCard } from "./moveCard";
import { Payment } from "./payment";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiContainer-root": {
      paddingLeft: "0px",
      paddingRight: "0px",
    },
  },
  total: {
    fontSize: "16px",
    fontFamily: "tenant_extraBold",
    color: theme.typography.color.primary,
  },
  active: {
    fontSize: "12px",
    padding: "2px 6px",
    color: "#5078E1",
    backgroundColor: "#E4E8EE",
    borderRadius: "50%",
    width: "100%",
  },
  bottomTitle: {
    fontSize: "16px",
    fontFamily: "tenant_bold",
    color: theme.typography.color.primary,
  },
  titleroot: {
    borderBottom: "1px solid #c1c1c1",
    // marginBottom: "12px",
  },
  contentBottom: {
    padding: "12px",
  },

  titleroot2: {
    borderBottom: "2px solid #CED3DD",
    marginBottom: "8px",
    fontSize: "12px",
    padding: "8px 0px",
    color: theme.typography.color.secondary,
  },
  phone: {
    borderRadius: "10px",
    border: "1px solid #c1c1c1",
  },
  bottomDetails: {
    fontSize: "14px",
    color: theme.typography.color.primary,
    fontFamily: "tenant_semiBold",
  },
  bottomDetails2: {
    fontSize: "16px",
    color: theme.typography.color.primary,
    fontFamily: "tenant_bold",
  },
  viewHandbook: {
    color: theme.palette.primary.main,
    fontSize: "14px",
    fontFamily: "tenant_Semibold",
    cursor: "pointer",
  },
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopRightRadius: "16px",
      borderTopLeftRadius: "16px",
    },
    "& .MuiContainer-root": {
      padding: "0px 8px",
    },
  },
  screenPadding: {
    overflow: "overlay",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  labelchecked: {
    fontSize: "16px",
    fontFamily: "tenant_semiBold",
    color: "#071741",
  },
  call: {
    fontSize: "12px",
    color: theme.typography.color.secondary,
  },
  mail: {
    fontSize: "12px",
    color: theme.typography.color.secondary,
  },
  periodStyle: {
    height: "6px",
    width: "6px",
    borderRadius: "50%",
    backgroundColor: "#CED3DD",
    margin: "6px",
  },
  tagStyle: {
    backgroundColor: "#5078E11E",
    borderRadius: "5px",
    padding: "3px 10px 3px 10px",
    fontSize: "10px",
    fontFamily: "tenant_bold",
    color: theme.typography.color.primary,
  },
  id: {
    color: "#071741",
    fontFamily: "tenant_extraBold",
    fontSize: "14px",
  },
  agg: {
    color: "#98A0AC",
    fontSize: "14px",
    fontFamily: "tenant_semiBold",
  },
  units: {
    fontSize: "16px",
    fontFamily: "tenant_bold",
    color: theme.typography.color.primary,
  },
  unitImg: {
    height: "250px",
    width: "100%",
    borderRadius: "20px",
    objectFit: "cotain",
  },
  bottomsub: {
    fontSize: "12px",
    color: theme.typography.color.secondary,
  },
}));
const aminitties = [
  { id: "1", value: false, label: "AG-123-21", properties: "1", units: "3" },
  { id: "2", value: false, label: "AG-123-21", properties: "1", units: "3" },
  { id: "3", value: false, label: "AG-123-21", properties: "1", units: "3" },
];

export const AgreementDetails = (props) => {
  const classes = useStyles();
  const size = useWindowDimensions();
  const history = useHistory();
  const [aminittiesdata, setAminitidata] = React.useState([...aminitties]);
  const [unitDrawer, setunitDrawer] = React.useState(false);
  const [handbook, sethandbook] = React.useState(false);
  const [unitDetail, setunitDetail] = React.useState(null);
  const [openDrawer, setopenDrawer] = React.useState(false);
  const [moveinDetails, setmoveinDetails] = React.useState(false);
  const [moveoutDetails, setmoveoutDetails] = React.useState(false);
  const openAgreement = () => {
    setopenDrawer(true);
  };
  const closeAgreement = () => {
    setopenDrawer(false);
  };
  React.useEffect(() => {
    setmoveinDetails(false);
    setmoveoutDetails(false);
  }, []);
  const goBack = () => {
    //history.push(Routes.agreement);
    window.history.go(-1);
  };
  const Clear = () => {
    alert("clear");
  };
  const propertData = [
    {
      id: 1,
      title: "Rubix Apartment",
      img: "https://media.istockphoto.com/photos/classic-italian-appartement-picture-id1290254343?b=1&k=20&m=1290254343&s=170667a&w=0&h=fjFhURiEyDW8au7_iwhjkpxTvFzGM9bFY4SZxzlyMzU=",
      units: 5,
      sqft: 1200,
      type: "Monthly Rent",
      till: "Till 31 Jan 22",
    },
    {
      id: 2,
      title: "Crayond Apartment",
      img: "https://images.unsplash.com/photo-1559396306-52630b6e0261?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTV8fGFwcGFydGVtZW50fGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60",
      units: 10,
      sqft: 2200,
      type: "Lease",
      till: "Till 31 Jun 22",
    },
    {
      id: 3,
      title: "Crayond Apartment",
      img: "https://images.unsplash.com/photo-1559396306-52630b6e0261?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTV8fGFwcGFydGVtZW50fGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60",
      units: 10,
      sqft: 2200,
      type: "Lease",
      till: "Till 31 Jun 22",
    },
  ];
  const unitlisted = [
    {
      id: 1,
      image: "/images/image2.png",
      title: "K-F01-394",
      address: "K Tower, 1 Floor",
      sqft: 1250,
      bed: 3,
    },
    {
      id: 1,
      image: "/images/image2.png",
      title: "K-F01-394",
      address: "K Tower, 1 Floor",
      sqft: 1250,
      bed: 3,
    },
    {
      id: 1,
      image: "/images/image2.png",
      title: "K-F01-394",
      address: "K Tower, 1 Floor",
      sqft: 1250,
      bed: 3,
    },
    {
      id: 1,
      image: "/images/image2.png",
      title: "K-F01-394",
      address: "K Tower, 1 Floor",
      sqft: 1250,
      bed: 3,
    },
    {
      id: 1,
      image: "/images/image2.png",
      title: "K-F01-394",
      address: "K Tower, 1 Floor",
      sqft: 1250,
      bed: 3,
    },
  ];
  const checkedfun = (data, e) => {
    const edited = aminittiesdata.map((task) =>
      task.id === data.id
        ? {
            id: data.id,
            value: e.target.checked,
            label: data.label,
            properties: data.properties,
            units: data.units,
          }
        : task
    );
    setAminitidata(edited);
    // setOpenSearch(false);
  };
  const closeunitDrawer = () => {
    setunitDrawer(false);
    setunitDetail(false);
  };
  const handleHandbook = () => {
    sethandbook(true);
    setunitDetail(false);
  };
  const closeHandbook = () => {
    setunitDrawer(false);
    sethandbook(false);
  };
  const handleOnClick = () => {
    setunitDrawer(!unitDrawer);
    setunitDetail(true);
  };

  return (
    <div className={classes.root}>
      <Container maxWidth="sm">
        <Grid container sx={{ backgroundColor: "#F5F7FA" }}>
          <Grid
            className={classes.screen}
            style={{ height: size?.height }}
            item
            xs={12}
          >
            <Grid className={classes.topNavBarStyle}>
              <Grid item xs={12} marginBottom={"4px"}>
                <TitleBar text="Tom Curse" goBack={goBack} onClear={Clear} />
              </Grid>
            </Grid>
            <Grid
              className={classes.screenPadding}
              style={{ height: size?.height - 65 }}
            >
              <Grid
                item
                xs={12}
                marginBottom={"4px"}
                style={{ padding: "0px 12px", backgroundColor: "white" }}
              >
                {/* filter btn and units length */}
                <Box display="flex" alignItems="center" padding={"4px 0px"}>
                  <Box>
                    <img src="/images/contact1.png" alt="tom" />
                  </Box>
                  <Box>
                    <Typography className={classes.total}>
                      Tom Cursie
                    </Typography>
                    <Typography gutterBottom>
                      <span className={classes.tagStyle}>+91 9090808012</span>
                      &nbsp;&nbsp;
                      <span className={classes.tagStyle}>Tomcruz@mail.com</span>
                    </Typography>
                    <Typography className={classes.tagStyle} gutterBottom>
                      Tom Cruise House - Polichalur
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                style={{ padding: "0px 12px", backgroundColor: "white" }}
              >
                <Typography className={classes.agg}>
                  Agreement: <span className={classes.id}>AG-123-21</span>
                  <IconButton
                    size="small"
                    color="secondary"
                    onClick={openAgreement}
                  >
                    <KeyboardArrowDownIcon />
                  </IconButton>
                </Typography>
                {/* Property Details with slider */}
                <div style={{ overflow: "scroll" }}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Box display="flex">
                        {propertData.map((datas) => {
                          return (
                            <Box>
                              <AgreementCard data={datas} />
                            </Box>
                          );
                        })}
                      </Box>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              <Grid item xs={12} style={{ padding: "6px 12px" }}>
                <Typography className={classes.units}>Units (09)</Typography>
              </Grid>
              <Grid
                item
                xs={12}
                style={{ padding: "0px 12px", backgroundColor: "white" }}
              >
                {unitlisted.map((val, index) => {
                  return (
                    <UnitList
                      data={val}
                      Pending={true}
                      index={index}
                      onClick={handleOnClick}
                    />
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* unit detalis */}
        <Grid item xs={12}>
          <Hidden smDown>
            <Dialog fullWidth={true} maxWidth="sm" open={unitDrawer}>
              <Container maxWidth="sm">
                <Grid
                  className={classes.screen}
                  style={{ height: size?.height }}
                  item
                  xs={12}
                >
                  <Grid className={classes.topNavBarStyle}>
                    {/* TopNavbar */}
                    <Box
                      display="flex"
                      p={2}
                      alignItems="center"
                      className={classes.titleroot}
                    >
                      <Box flexGrow={1}>
                        <Typography className={classes.bottomTitle}>
                          {handbook ? "Handbook" : "Unit"}
                        </Typography>
                      </Box>
                      <Box>
                        {unitDetail && (
                          <IconButton size="small" onClick={closeunitDrawer}>
                            <CloseIcon color="secondary" />
                          </IconButton>
                        )}

                        {handbook && (
                          <IconButton size="small" onClick={closeHandbook}>
                            <CloseIcon color="secondary" />
                          </IconButton>
                        )}
                      </Box>
                    </Box>
                  </Grid>
                  <Grid
                    className={classes.screenPadding}
                    style={{ height: size?.height - 115 }}
                  >
                    {unitDetail && (
                      <div>
                        <img
                          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSJNYQMDoCojFPZiNi9uMXofvo67EyeAPzVYA&usqp=CAU"
                          className={classes.unitImg}
                          alt="unitimg"
                        />

                        <Box
                          display="flex"
                          // alignItems="center"
                          className={classes.titleroot2}
                        >
                          <Box flexGrow={1}>
                            <Typography className={classes.bottomTitle}>
                              K-F1-U25
                            </Typography>
                            <Typography className={classes.bottomsub}>
                              K Tower,1 Floor
                            </Typography>
                          </Box>
                          <Box>
                            <Typography className={classes.bottomTitle}>
                              $ 12344
                            </Typography>
                          </Box>
                        </Box>
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                          className={classes.titleroot2}
                        >
                          <Box display="flex" alignItems="center">
                            <img src="/images/Bedroom.svg" alt="bedroom" />
                            <span className={classes.bottomDetails}>
                              &nbsp; &nbsp;3 Bedrooms
                            </span>
                          </Box>
                          <Box display="flex" alignItems="center">
                            <img src="/images/SQ_FT.svg" alt="sqft" />{" "}
                            <span className={classes.bottomDetails}>
                              &nbsp;1050&nbsp;sqft
                            </span>
                          </Box>
                          <Box display="flex" alignItems="center">
                            <img src="/images/Furnished.svg" alt="furnieshed" />{" "}
                            <span className={classes.bottomDetails}>
                              &nbsp;Furnished
                            </span>
                          </Box>
                        </Box>
                        <Box>
                          <Box marginBottom={"8px"}>
                            <MoveCard
                              onClick={() => history.push(Routes.movein)}
                            />
                          </Box>
                          <Box>
                            <Payment />
                          </Box>
                        </Box>
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="space-evenly"
                          className={classes.titleroot2}
                        >
                          <Box flexGrow={1} display="flex" alignItems="center">
                            <img src="/images/Handbook.svg" alt="handbook" />
                            <Typography className={classes.bottomDetails}>
                              &nbsp; &nbsp;Handbook
                            </Typography>
                          </Box>

                          <Box>
                            <Typography
                              className={classes.viewHandbook}
                              onClick={handleHandbook}
                            >
                              View &nbsp;/ &nbsp;Download
                            </Typography>
                          </Box>
                        </Box>
                        <Box display="flex" alignItems="center">
                          <Box flexGrow={1} display="flex" alignItems="center">
                            <img src="/images/Location.svg" alt="address" />
                            <Typography className={classes.bottomDetails}>
                              &nbsp; &nbsp;2nd Floor, K Tower, D Block
                            </Typography>
                          </Box>
                        </Box>
                        <img
                          src="/images/map.png"
                          alt="map"
                          className={classes.unitImg}
                        />
                      </div>
                    )}
                    {handbook && (
                      <>
                        <img
                          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSaw3Qs1JdsoLnmIKsK_GytpZkOYgiyT-s4vg&usqp=CAU"
                          width="100%"
                          alt="handbook"
                        />

                        <Grid
                          container
                          className={classes.btncontainer}
                          spacing={1}
                        >
                          <Grid item xs={12}>
                            <Button
                              variant="contained"
                              fullWidth={true}
                              className={classes.applybtn}
                              onClick={closeHandbook}
                            >
                              Download
                            </Button>
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Grid>
              </Container>
            </Dialog>
          </Hidden>
          <Hidden smUp>
            <Drawer
              open={unitDrawer}
              anchor="bottom"
              className={classes.drawer}
            >
              <Container
                maxWidth="sm"
                style={{ height: "70vh", overflow: "auto" }}
              >
                <Grid
                  className={classes.screen}
                  style={{ height: size?.height }}
                  item
                  xs={12}
                >
                  <Grid className={classes.topNavBarStyle}>
                    {/* TopNavbar */}
                    <Box
                      display="flex"
                      alignItems="center"
                      className={classes.titleroot}
                    >
                      <Box flexGrow={1}>
                        <Typography className={classes.bottomTitle}>
                          {handbook ? "Handbook" : "Unit"}
                        </Typography>
                      </Box>
                      <Box>
                        {unitDetail && (
                          <IconButton size="small" onClick={closeunitDrawer}>
                            <CloseIcon color="secondary" />
                          </IconButton>
                        )}

                        {handbook && (
                          <IconButton size="small" onClick={closeHandbook}>
                            <CloseIcon color="secondary" />
                          </IconButton>
                        )}
                      </Box>
                    </Box>
                  </Grid>
                  <Grid
                    className={classes.screenPadding}
                    style={{ height: size?.height - 115 }}
                  >
                    {unitDetail && (
                      <div>
                        <img
                          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSJNYQMDoCojFPZiNi9uMXofvo67EyeAPzVYA&usqp=CAU"
                          className={classes.unitImg}
                          alt="unitimg"
                        />

                        <Box
                          display="flex"
                          // alignItems="center"
                          className={classes.titleroot2}
                        >
                          <Box flexGrow={1}>
                            <Typography className={classes.bottomTitle}>
                              K-F1-U25
                            </Typography>
                            <Typography className={classes.bottomsub}>
                              K Tower,1 Floor
                            </Typography>
                          </Box>
                          <Box>
                            <Typography className={classes.bottomTitle}>
                              $ 12344
                            </Typography>
                          </Box>
                        </Box>
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                          className={classes.titleroot2}
                        >
                          <Box display="flex" alignItems="center">
                            <img src="/images/Bedroom.svg" alt="bedroom" />
                            <span className={classes.bottomDetails}>
                              &nbsp; &nbsp;3 Bedrooms
                            </span>
                          </Box>
                          <Box display="flex" alignItems="center">
                            <img src="/images/SQ_FT.svg" alt="sqft" />{" "}
                            <span className={classes.bottomDetails}>
                              &nbsp;1050&nbsp;sqft
                            </span>
                          </Box>
                          <Box display="flex" alignItems="center">
                            <img src="/images/Furnished.svg" alt="furnieshed" />{" "}
                            <span className={classes.bottomDetails}>
                              &nbsp;Furnished
                            </span>
                          </Box>
                        </Box>
                        <Box>
                          <Box marginBottom={"8px"}>
                            <MoveCard
                              movein
                              onClick={() => history.push(Routes.movein)}
                            />
                          </Box>
                          {/* move in details */}
                          {moveinDetails && (
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="space-evenly"
                              className={classes.titleroot2}
                            >
                              <Box
                                flexGrow={1}
                                display="flex"
                                alignItems="center"
                              >
                                <img src="/images/movein2.svg" alt="handbook" />
                                <Typography className={classes.bottomDetails2}>
                                  &nbsp; &nbsp;Move in Details
                                </Typography>
                              </Box>

                              <Box>
                                <Typography
                                  className={classes.viewHandbook}
                                  onClick={handleHandbook}
                                >
                                  View &nbsp;
                                </Typography>
                              </Box>
                            </Box>
                          )}
                          {/* move out details */}
                          {moveoutDetails && (
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="space-evenly"
                              className={classes.titleroot2}
                            >
                              <Box
                                flexGrow={1}
                                display="flex"
                                alignItems="center"
                              >
                                <img
                                  src="/images/moveout2.svg"
                                  alt="handbook"
                                />
                                <Typography className={classes.bottomDetails2}>
                                  &nbsp; &nbsp;Move out Details
                                </Typography>
                              </Box>

                              <Box>
                                <Typography
                                  className={classes.viewHandbook}
                                  onClick={handleHandbook}
                                >
                                  View &nbsp;
                                </Typography>
                              </Box>
                            </Box>
                          )}
                          <Box>
                            <Payment />
                          </Box>
                        </Box>

                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="space-evenly"
                          className={classes.titleroot2}
                        >
                          <Box flexGrow={1} display="flex" alignItems="center">
                            <img src="/images/Handbook.svg" alt="handbook" />
                            <Typography className={classes.bottomDetails}>
                              &nbsp; &nbsp;Handbook
                            </Typography>
                          </Box>

                          <Box>
                            <Typography
                              className={classes.viewHandbook}
                              onClick={handleHandbook}
                            >
                              View &nbsp;/ &nbsp;Download
                            </Typography>
                          </Box>
                        </Box>
                        <Box display="flex" alignItems="center">
                          <Box flexGrow={1} display="flex" alignItems="center">
                            <img src="/images/Location.svg" alt="address" />
                            <Typography className={classes.bottomDetails}>
                              &nbsp; &nbsp;2nd Floor, K Tower, D Block
                            </Typography>
                          </Box>
                        </Box>
                        <img
                          src="/images/map.png"
                          alt="map"
                          className={classes.unitImg}
                        />
                      </div>
                    )}
                    {handbook && (
                      <>
                        <img
                          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSaw3Qs1JdsoLnmIKsK_GytpZkOYgiyT-s4vg&usqp=CAU"
                          width="100%"
                          alt="handbook"
                        />

                        <Grid
                          container
                          className={classes.btncontainer}
                          spacing={1}
                        >
                          <Grid item xs={12}>
                            <Button
                              variant="contained"
                              fullWidth={true}
                              className={classes.applybtn}
                              onClick={closeHandbook}
                            >
                              Download
                            </Button>
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Grid>
              </Container>
            </Drawer>
          </Hidden>
        </Grid>
      </Container>
      {/* drawer */}

      <Hidden smUp>
        <Drawer className={classes.drawer} anchor="bottom" open={openDrawer}>
          <Container maxWidth="sm" style={{ overflow: "hidden" }}>
            <div className={classes.contentBottom}>
              <Box
                display="flex"
                alignItems="center"
                className={classes.titleroot}
              >
                <Box flexGrow={1}>
                  <Typography className={classes.bottomTitle}>
                    {"Agreements"}
                  </Typography>
                </Box>
                <Box>
                  <IconButton size="small" onClick={closeAgreement}>
                    <CloseIcon color="secondary" />
                  </IconButton>
                </Box>
              </Box>
              <>
                <Grid container>
                  <Grid item xs={12} className={classes.field}>
                    {aminittiesdata.map((val) => {
                      return (
                        <Box
                          display="flex"
                          alignItems="center"
                          sx={{
                            borderBottom: "1px solid #0717411F",
                            padding: "4px 0px 12px",
                          }}
                        >
                          <Box>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={val.value}
                                  checkedIcon={<CheckCircleIcon />}
                                  icon={
                                    <CircleOutlinedIcon
                                      style={{ color: "#c1c1c1" }}
                                    />
                                  }
                                />
                              }
                              label={
                                <>
                                  <span className={classes.labelchecked}>
                                    {val.label}
                                  </span>
                                  <Box display="flex">
                                    <Box>
                                      <Typography className={classes.call}>
                                        {val.properties}&nbsp;Properties
                                      </Typography>
                                    </Box>
                                    <Box className={classes.periodStyle} />
                                    <Box>
                                      <Typography className={classes.mail}>
                                        {val.units}&nbsp;Units
                                      </Typography>
                                    </Box>
                                  </Box>
                                </>
                              }
                              onChange={(e) => checkedfun(val, e)}
                            />
                          </Box>
                        </Box>
                      );
                    })}
                  </Grid>
                </Grid>
              </>
            </div>
          </Container>
        </Drawer>
      </Hidden>
      <Hidden smDown>
        <Dialog fullWidth={true} maxWidth="sm" open={openDrawer}>
          <Grid item xs={12}>
            <div className={classes.contentBottom}>
              <Box
                display="flex"
                alignItems="center"
                className={classes.titleroot}
              >
                <Box flexGrow={1}>
                  <Typography className={classes.bottomTitle}>
                    {"Agreements"}
                  </Typography>
                </Box>
                <Box>
                  <IconButton size="small" onClick={closeAgreement}>
                    <CloseIcon color="secondary" />
                  </IconButton>
                </Box>
              </Box>
              <>
                <Grid container>
                  <Grid item xs={12} className={classes.field}>
                    {aminittiesdata.map((val) => {
                      return (
                        <Box
                          display="flex"
                          alignItems="center"
                          sx={{
                            borderBottom: "1px solid #0717411F",
                            padding: "4px 0px 12px",
                          }}
                        >
                          <Box>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={val.value}
                                  checkedIcon={<CheckCircleIcon />}
                                  icon={
                                    <CircleOutlinedIcon
                                      style={{ color: "#c1c1c1" }}
                                    />
                                  }
                                />
                              }
                              label={
                                <>
                                  <span className={classes.labelchecked}>
                                    {val.label}
                                  </span>
                                  <Box display="flex">
                                    <Box>
                                      <Typography className={classes.call}>
                                        {val.properties}&nbsp;Properties
                                      </Typography>
                                    </Box>
                                    <Box className={classes.periodStyle} />
                                    <Box>
                                      <Typography className={classes.mail}>
                                        {val.units}&nbsp;Units
                                      </Typography>
                                    </Box>
                                  </Box>
                                </>
                              }
                              onChange={(e) => checkedfun(val, e)}
                            />
                          </Box>
                        </Box>
                      );
                    })}
                  </Grid>
                </Grid>
              </>
            </div>
          </Grid>
        </Dialog>
      </Hidden>
    </div>
  );
};
