import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Checkbox,
  Container,
  Dialog,
  Divider,
  Drawer,
  Fab,
  FormControlLabel,
  Grid,
  Hidden,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment-timezone";
import React from "react";
import { useHistory } from "react-router-dom";
import ShowMoreText from "react-show-more-text";
import {
  ActivityListCard,
  DateTime,
  LoderSimmer,
  TextBox,
  TitleBar,
  TopNavBars,
} from "../../components";
import { config } from "../../config";
import { UPDATEACTIVITIES } from "../../graphql/mutations";
import { GETACTIVITYBYOWNER } from "../../graphql/queries";
import { NetworkCall } from "../../networkcall/index";
import { Routes } from "../../router/routes";
import { NetWorkCallMethods } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.secondary,
  },
  screenPadding: {
    overflow: "overlay",

    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  dividerStyle: {
    backgroundColor: theme.palette.border.secondary,
    height: "1px",
    width: "100%",
    margin: "14px 0px 14px 0px",
  },
  total: {
    fontSize: "16px",
    fontFamily: "tenant_bold",
  },
  filter: {
    borderRadius: "6px",
    border: "1px solid #E4E8EE",
    color: "black",
    backgroundColor: "white",
  },
  filterdata: {
    padding: "8px 12px",
    fontSize: "16px",
    fontFamily: "tenant_bold",
    cursor: "pointer",
    backgroundColor: theme.palette.background.paper,
    width: "100%",
  },
  filterdataChild: {
    paddingLeft: "12px",
    fontSize: "16px",
    fontFamily: "tenant_bold",
    width: "100%",
  },

  previewCardphone: {
    backgroundColor: "#55B0CF",
    color: "#EFFCFF",
    borderRadius: "10px",
  },
  icon: {
    backgroundColor: "white",
  },
  name: {
    fontSize: "14px",
    fontFamily: "tenant_semiBold",

    textTransform: "capitalize",
  },
  title: {
    fontSize: "10px",
    fontFamily: "tenant_semiBold",
    color: theme.typography.color.Tertiary,
    textTransform: "capitalize",
  },
  sub: {
    fontSize: "13px",
    fontFamily: "tenant_semiBold",
    color: theme.typography.color.secondary,
    textTransform: "capitalize",
  },
  data: {
    padding: "6px",
    textAlign: "center",
  },
  divider: {
    border: "2px solid #F5F7FA",
  },
  note: {
    fontSize: "14px",
    fontFamily: "tenant_semiBold",
    textTransform: "capitalize",
    color: theme.typography.color.secondary,
  },
  notetitle: {
    fontSize: "10px",
    fontFamily: "tenant_semiBold",
    color: theme.typography.color.Tertiary,
    textTransform: "capitalize",
    marginTop: "10px",
  },
  viewdata: {
    padding: "18px",
  },
  head: {
    fontSize: "16px",
    fontFamily: "tenant_extraBold",
    marginLeft: "10px",
  },
  completedbtn: {
    boxShadow: "none",
    padding: "10px",
    borderRadius: "10px",
    marginTop: "14px",
  },
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopLeftRadius: "10px",
      borderTopRightRadius: "10px",
    },
  },

  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: "16px",
      padding: "0px",
    },
  },
  filterleft: {
    borderRight: "1px solid #c1c1c1",
    [theme.breakpoints.up("sm")]: {
      minHeight: "68vh",
    },
    [theme.breakpoints.down("sm")]: {
      minHeight: "75vh",
    },
  },
  active: {
    fontSize: "12px",
    padding: "2px 6px",
    color: "#5078E1",
    backgroundColor: "#E4E8EE",
    borderRadius: "50%",
    width: "100%",
  },
  btncontainer: {
    padding: "14px",
    backgroundColor: "#F5F7FA",
  },
  applybtn: {
    padding: "12px",
    borderRadius: "10px",
  },
  cancelbtn: {
    padding: "12px",
    border: "1px solid #E4E8EE",
    backgroundColor: "white",
    borderRadius: "10px",
  },
  filterdataActive: {
    padding: "8px 12px",
    fontSize: "16px",
    fontFamily: "tenant_bold",
    cursor: "pointer",
    backgroundColor: theme.palette.background.secondary,
    width: "100%",
  },
  listpad: {
    padding: "0px",
  },
  fab: {
    textTransform: "capitalize",
    "& .MuiButton-root": {
      color: theme.palette.background.paper,
    },

    [theme.breakpoints.down("sm")]: {
      position: "fixed",
      bottom: "60px",
      right: "10%",
    },
    [theme.breakpoints.up("sm")]: {
      position: "fixed",
      bottom: "60px",
      left: "65%",
    },
  },
  activitycard: {
    backgroundColor: "white",
    padding: "12px",
    marginTop: "4px",
  },
  payCard: {
    backgroundColor: "white",
    padding: "8px",
    borderRadius: "10px",
    margin: "0px 12px",
    boxShadow: "0px 8px 24px #1C1C1C40",
  },
  iconButtonStyle: {
    borderRadius: "8px",
    padding: "8px 12px",
    backgroundColor: "#F34276",
    fontFamily: "tenant_bold",
    boxShadow: "0px 2px 6px #00000021",
  },
  selected: {
    fontSize: "16px",
    fontFamily: "tenant_bold",
    marginLeft: "4px",
  },
  topNavBarStyle: {},
  previewCardfollow: {
    backgroundColor: "#35A27D",
    color: "#EFFCFF",
    borderRadius: "10px",
  },
  previewCardmeeting: {
    backgroundColor: "#8887D4",
    color: "#F8F8FF",
    borderRadius: "10px",
  },
  previewCardappoinment: {
    backgroundColor: "#E53468 ",
    color: "#FFF3F7",
    borderRadius: "10px",
  },
  previewCardsite: {
    backgroundColor: "#E29336",
    color: "#FFF8EF",
    borderRadius: "10px",
  },
  previewCardqutation: {
    backgroundColor: "#5078E1",
    color: "#F2F6FA",
    borderRadius: "10px",
  },
  previewCardmail: {
    backgroundColor: "#78B1FE",
    color: "#F2F6FA",
    borderRadius: "10px",
  },
  noteTextStyle2: {
    fontSize: "12px",
    fontFamily: "tenant_regular",
    color: theme.typography.color.secondary,
    textOverflow: "clip",
  },
  seeMoreLessTextStyle: {
    fontSize: "12px",
    fontFamily: "tenant_semiBold",
    color: theme.palette.primary.main,
    textDecoration: "none",
  },
}));
const reducer = (state, action) => {
  let invoicesCartCopy = {
    ...state,
  };
  switch (action.type) {
    case "ADD":
      //to add that invoice to the invoices key
      invoicesCartCopy.invoices = [
        ...invoicesCartCopy.invoices,
        action.payload,
      ];

      return invoicesCartCopy;
    case "REMOVE":
      //to remove that invoice to the invoices key
      let invoices = [...invoicesCartCopy.invoices];
      const result = invoices.filter(
        (invoice) => invoice.id !== action.payload.id
      );
      invoicesCartCopy.invoices = result;

      return invoicesCartCopy;
    case "CLEAR":
      invoicesCartCopy.invoices = [];
      return invoicesCartCopy;
    default:
      return state;
  }
};
export const Activities = (props) => {
  const classes = useStyles();
  moment.defaultFormat = "DD MMM YY";
  const size = useWindowDimensions();
  const [filterChild, setfilterChild] = React.useState([]);
  const [filterActive, setfilterActive] = React.useState(null);
  const [drawer, setDrawer] = React.useState(null);
  const [leadActivities, setLeadActivities] = React.useState([]);
  const [search, setSearch] = React.useState(false);
  const [mark, setMark] = React.useState(false);
  const [preview, setPreview] = React.useState();
  const [completed, setCompleted] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [invoiceCart, setInvoiceCart] = React.useReducer(reducer, {
    invoices: [],
  });
  const [loading, setLoading] = React.useState(true);
  const getActivityByOwner = () => {
    const variables = {
      query: GETACTIVITYBYOWNER,
      variables: {
        id: localStorage.getItem("userProfileId"),
      },
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      variables,
      null,
      true,
      false
    )
      .then((response) => {
        setLeadActivities(response.data.data.lead_activities);
        setLoading(false);
      })

      .catch((error) => {
        console.log(error);
      });
  };
  React.useEffect(() => {
    getActivityByOwner();
  }, []);
  const history = useHistory();

  const filterData = [
    {
      id: "1",
      title: "Lead Name",
      values: [
        {
          label: "Tom Cruise",
          value: true,
        },
        {
          label: "Jonathan Valdez",
          value: false,
        },
        {
          label: "Doris Young",
          value: false,
        },
        {
          label: "Andreea Foster",
          value: false,
        },
      ],
    },
    {
      id: "2",

      title: "Lead ID",
      values: [
        {
          label: "AGR-125",
          value: false,
        },
        {
          label: "AGR-126",
          value: true,
        },
        {
          label: "AGR-127",
          value: false,
        },
        {
          label: "AGR-128",
          value: true,
        },
      ],
    },
    {
      id: "3",

      title: "Date",
      values: [
        {
          label: "22-02-2022",
          value: false,
        },
        {
          label: "19-02-2022",
          value: true,
        },
        {
          label: "10-02-2022",
          value: false,
        },
        {
          label: "10-03-2022",
          value: true,
        },
      ],
    },
    {
      id: "4",

      title: "Status",
      values: [
        {
          label: "New",
          value: false,
        },
        {
          label: "In-Progress",
          value: true,
        },
        {
          label: "Resolved",
          value: false,
        },
      ],
    },
  ];
  //functions
  const headerChild = (id) => {
    let filerdatavalue = filterData.filter((e) => {
      return e.id === id;
    });
    setfilterChild(filerdatavalue[0].values);
    setfilterActive(id);
  };
  const draweropen = () => {
    setDrawer(true);
  };
  const drawerclose = () => {
    setDrawer(false);
  };
  const handleClicksearch = () => {
    setSearch(!search);
  };

  const handleToggle = () => {
    history.push(Routes.createactivity);
  };
  const markCompleted = () => {
    setMark(true);
    setOpen(false);
  };
  const onDelete = () => {
    for (let i = 0; i < invoiceCart.invoices.length; i++) {
      const variables = {
        query: UPDATEACTIVITIES,
        variables: {
          leadActivityId: invoiceCart?.invoices[i]?.id,
          updatePayload: {
            is_active: false,
          },
        },
      };
      NetworkCall(
        `${config.graphql_url}`,
        NetWorkCallMethods.post,
        variables,
        null,
        true,
        false
      )
        .then((response) => {
          getActivityByOwner();

          setInvoiceCart({
            type: "CLEAR",
          });
        })

        .catch((error) => {
          console.log(error);
        });
    }
  };
  const handleData = (data) => {
    setPreview(data);
    setOpen(true);
  };
  const closeCompleted = () => {
    const variables = {
      query: UPDATEACTIVITIES,
      variables: {
        leadActivityId: preview.id,
        updatePayload: {
          updated_by: localStorage.getItem("userProfileId"),
          updated_at: new Date().toISOString(),
          is_active: true,
          status: "closed",
          closed_by: localStorage.getItem("userProfileId"),
          closed_on: new Date().toISOString(),
          remarks: completed,
        },
      },
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      variables,
      null,
      true,
      false
    )
      .then((response) => {
        setMark(false);
        getActivityByOwner();
      })

      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      {loading ? (
        <>
          <TopNavBars />
          <LoderSimmer count={10} />
        </>
      ) : (
        <>
          <div className={classes.root}>
            <Grid container sx={{ padding: "0px" }}>
              <Grid
                className={classes.screen}
                style={{ height: size?.height }}
                item
                xs={12}
              >
                <Grid className={classes.topNavBarStyle}>
                  <Grid item xs={12}>
                    {/* topnavbar */}
                    {search ? (
                      <>
                        <TitleBar
                          text="search"
                          goBack={() => setSearch(false)}
                        />
                        <div style={{ padding: "12px" }}>
                          <TextField
                            size="small"
                            variant="outlined"
                            color="secondary"
                            style={{ backgroundColor: "white" }}
                            fullWidth
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="end">
                                  <IconButton aria-label="Toggle password visibility">
                                    <img
                                      src="/images/icons8-search.svg"
                                      alt="note"
                                    />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </div>
                      </>
                    ) : (
                      <TopNavBars
                        search={true}
                        handleClicksearch={handleClicksearch}
                      />
                    )}

                    <Grid
                      item
                      xs={12}
                      style={{ padding: "0px 12px", marginTop: "8px" }}
                    >
                      {/* filter btn and units length */}
                      <Box
                        display="flex"
                        alignItems="center"
                        marginBottom="10px"
                      >
                        <Box flexGrow={1}>
                          <Typography className={classes.total}>
                            Activities(
                            {leadActivities.length < 10
                              ? `0${leadActivities.length}`
                              : leadActivities.length}
                            )
                          </Typography>
                        </Box>
                        <Box>
                          <IconButton
                            className={classes.filter}
                            onClick={draweropen}
                          >
                            <Badge variant="dot" color="primary">
                              <img
                                src="/images/icons8-funnel.svg"
                                alt="filter"
                              />
                            </Badge>
                          </IconButton>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  className={classes.screenPadding}
                  style={{ height: size?.height - 115 }}
                >
                  <Grid item xs={12} className={classes.activitycard}>
                    {leadActivities.map((val) => {
                      return (
                        <React.Fragment key={val.id}>
                          <ActivityListCard
                            //showCheckBox
                            selectable={true}
                            onselect={handleData}
                            data={val}
                            type="selectable"
                            setInvoiceCart={setInvoiceCart}
                            invoiceData={val}
                          />

                          <Box className={classes.dividerStyle} />
                        </React.Fragment>
                      );
                    })}
                    <br />
                  </Grid>
                  <Fab
                    color="primary"
                    className={classes.fab}
                    aria-label="add"
                    onClick={handleToggle}
                  >
                    <AddIcon />
                  </Fab>
                </Grid>
              </Grid>
            </Grid>

            {invoiceCart.invoices.length > 0 ? (
              <Grid container>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  className={classes.containers}
                >
                  <Paper
                    sx={{
                      position: "fixed",
                      bottom: "65px",
                      left: 0,
                      right: 0,
                      boxShadow: "none",
                      backgroundColor: "transparent",
                      padding: "12px",
                    }}
                    elevation={3}
                  >
                    <Container maxWidth="sm" sx={{ padding: 0 }}>
                      <div className={classes.bottomScreen}>
                        <Box
                          display="flex"
                          alignItems="center"
                          p={1}
                          className={classes.payCard}
                        >
                          <Box flexGrow={1}>
                            <Typography className={classes.selected}>
                              {invoiceCart.invoices.length < 10
                                ? `0${invoiceCart.invoices.length} Selected`
                                : `${invoiceCart.invoices.length} Selected`}
                            </Typography>
                          </Box>
                          <Box>
                            <Button
                              onClick={onDelete}
                              variant="contained"
                              className={classes.iconButtonStyle}
                            >
                              <Typography
                                className={classes.payButtonTextStyle}
                              >
                                &nbsp;Delete Activity&nbsp;
                              </Typography>
                            </Button>
                          </Box>
                        </Box>
                      </div>
                    </Container>
                  </Paper>
                </Grid>
              </Grid>
            ) : (
              ""
            )}
            {/* filter drawer */}
            <Hidden smDown>
              <Dialog
                fullWidth={true}
                maxWidth="sm"
                open={drawer}
                onClose={drawerclose}
              >
                <Grid container>
                  <Grid item xs={12}>
                    <TitleBar
                      text="Filter By"
                      goBack={drawerclose}
                      clearFilter
                    />
                  </Grid>
                  <Grid item xs={6} className={classes.filterleft}>
                    <div>
                      {filterData.map((e) => {
                        let filerdatavalue = e.values.filter((e) => {
                          return e.value === true;
                        });

                        return (
                          <>
                            <Typography
                              className={
                                e.id === filterActive
                                  ? classes.filterdataActive
                                  : classes.filterdata
                              }
                              key={e.id}
                              whiteSpace="nowrap"
                              onClick={() => {
                                headerChild(e.id);
                              }}
                            >
                              {e.title} &nbsp;
                              <span className={classes.active}>
                                {filerdatavalue.length}
                              </span>
                            </Typography>
                          </>
                        );
                      })}
                    </div>
                  </Grid>
                  <Grid item xs={6} className={classes.filterRight}>
                    {filterChild.map((val) => {
                      return (
                        <>
                          <FormControlLabel
                            key={val.id}
                            style={{ display: "block" }}
                            className={classes.filterdataChild}
                            control={<Checkbox checked={val.value} />}
                            label={val.label}
                          />
                        </>
                      );
                    })}
                  </Grid>
                </Grid>
                <Grid container className={classes.btncontainer} spacing={1}>
                  <Grid item xs={6}>
                    <Button
                      variant="contain"
                      fullWidth={true}
                      className={classes.cancelbtn}
                      onClick={drawerclose}
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      fullWidth={true}
                      className={classes.applybtn}
                    >
                      Apply
                    </Button>
                  </Grid>
                </Grid>
              </Dialog>
            </Hidden>
            <Hidden smUp>
              <Drawer open={drawer} fullWidth={true} onClose={drawerclose}>
                <Grid container>
                  <Grid item xs={12}>
                    <TitleBar
                      text="Filter By"
                      goBack={drawerclose}
                      clearFilter
                    />
                  </Grid>
                  <Grid item xs={6} className={classes.filterleft}>
                    <div>
                      {filterData.map((e) => {
                        let filerdatavalue = e.values.filter((e) => {
                          return e.value === true;
                        });

                        return (
                          <>
                            <Typography
                              className={
                                e.id === filterActive
                                  ? classes.filterdataActive
                                  : classes.filterdata
                              }
                              key={e.id}
                              whiteSpace="nowrap"
                              onClick={() => {
                                headerChild(e.id);
                              }}
                            >
                              {e.title} &nbsp;
                              <span className={classes.active}>
                                {filerdatavalue.length}
                              </span>
                            </Typography>
                          </>
                        );
                      })}
                    </div>
                  </Grid>
                  <Grid item xs={6} className={classes.filterRight}>
                    {filterChild.length > 0 ? (
                      filterChild.map((val) => {
                        return (
                          <>
                            <FormControlLabel
                              key={val.id}
                              style={{ display: "block" }}
                              className={classes.filterdataChild}
                              control={<Checkbox checked={val.value} />}
                              label={val.label}
                            />
                          </>
                        );
                      })
                    ) : (
                      <p style={{ width: "100%" }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp; &nbsp;
                      </p>
                    )}
                  </Grid>
                </Grid>
                <Grid container className={classes.btncontainer} spacing={1}>
                  <Grid item xs={6}>
                    <Button
                      variant="contain"
                      fullWidth={true}
                      className={classes.cancelbtn}
                      onClick={drawerclose}
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      fullWidth={true}
                      className={classes.applybtn}
                    >
                      Apply
                    </Button>
                  </Grid>
                </Grid>
              </Drawer>
            </Hidden>
            <Hidden smDown>
              <Dialog
                className={classes.dialog}
                fullWidth
                maxWidth="sm"
                open={open}
                onClose={() => {
                  setOpen(false);
                }}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  p={1}
                  border={2}
                  borderColor="#F5F7FA"
                >
                  <Box flexGrow={1}>
                    <Typography className={classes.head}>Activity</Typography>
                  </Box>
                  <Box>
                    <IconButton
                      onClick={() => {
                        setOpen(false);
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Box>
                </Box>
                <div className={classes.viewdata}>
                  <div>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        {/* phonecall */}

                        {preview?.lead_activitiy_master ===
                          "67f34ccd-3f23-4f10-8334-80ed1d425781" && (
                          <>
                            <Box
                              p={1}
                              display="flex"
                              alignItems="center"
                              className={classes.previewCardphone}
                            >
                              <Box>
                                <Avatar className={classes.icon}>
                                  <img
                                    src="/images/callreview.svg"
                                    alt=" Phone Call"
                                  />
                                </Avatar>
                              </Box>
                              <Box flexGrow={1} marginLeft="10px">
                                <Typography className={classes.name}>
                                  Phone Call
                                </Typography>
                              </Box>
                            </Box>
                          </>
                        )}
                        {/* follow up */}
                        {preview?.lead_activitiy_master ===
                          "fff87381-de52-455d-9f69-8400b9a2a0ff" && (
                          <>
                            <Box
                              p={1}
                              display="flex"
                              alignItems="center"
                              className={classes.previewCardfollow}
                            >
                              <Box>
                                <Avatar className={classes.icon}>
                                  <img
                                    src="/images/followreview.svg"
                                    alt="Follow up"
                                  />
                                </Avatar>
                              </Box>
                              <Box flexGrow={1} marginLeft="10px">
                                <Typography className={classes.name}>
                                  Follow up
                                </Typography>
                              </Box>
                            </Box>
                          </>
                        )}
                        {/* meeting up */}
                        {preview?.lead_activitiy_master ===
                          "cbc1480a-a7e5-4bab-9a07-9f08d949f179" && (
                          <>
                            <Box
                              p={1}
                              display="flex"
                              alignItems="center"
                              className={classes.previewCardmeeting}
                            >
                              <Box>
                                <Avatar className={classes.icon}>
                                  <img
                                    src="images/meetreview.svg"
                                    alt="Meeting"
                                  />
                                </Avatar>
                              </Box>
                              <Box flexGrow={1} marginLeft="10px">
                                <Typography className={classes.name}>
                                  Meeting
                                </Typography>
                              </Box>
                            </Box>
                          </>
                        )}
                        {/* appoinment up */}
                        {preview?.lead_activitiy_master ===
                          "50fae8f5-17d0-417a-ba01-568b35acfbc1" && (
                          <>
                            <Box
                              p={1}
                              display="flex"
                              alignItems="center"
                              className={classes.previewCardappoinment}
                            >
                              <Box>
                                <Avatar className={classes.icon}>
                                  <img
                                    src="/images/Group 4957.svg"
                                    alt="Appointment"
                                  />
                                </Avatar>
                              </Box>
                              <Box flexGrow={1} marginLeft="10px">
                                <Typography className={classes.name}>
                                  Appointment
                                </Typography>
                              </Box>
                            </Box>
                          </>
                        )}
                        {/* site visit */}
                        {preview?.lead_activitiy_master ===
                          "60f6e6f0-1328-47b4-8dc6-34035ee49c6e" && (
                          <>
                            <Box
                              p={1}
                              display="flex"
                              alignItems="center"
                              className={classes.previewCardsite}
                            >
                              <Box>
                                <Avatar className={classes.icon}>
                                  <img
                                    src="/images/sitereview.svg"
                                    alt="Arrange site visit"
                                  />
                                </Avatar>
                              </Box>
                              <Box flexGrow={1} marginLeft="10px">
                                <Typography className={classes.name}>
                                  Arrange site visit
                                </Typography>
                              </Box>
                            </Box>
                          </>
                        )}
                        {/* site qutation */}
                        {preview?.lead_activitiy_master ===
                          "ac53c561-9ab2-437b-b80c-e7a738bab8f2" && (
                          <>
                            <Box
                              p={1}
                              display="flex"
                              alignItems="center"
                              className={classes.previewCardqutation}
                            >
                              <Box>
                                <Avatar className={classes.icon}>
                                  <img
                                    src="/images/qutationreview.svg"
                                    alt="Send email"
                                  />
                                </Avatar>
                              </Box>
                              <Box flexGrow={1} marginLeft="10px">
                                <Typography className={classes.name}>
                                  Send email
                                </Typography>
                              </Box>
                            </Box>
                          </>
                        )}
                        {/* site mail */}
                        {preview?.lead_activitiy_master ===
                          "1931346b-a32e-4834-b399-708f07448278" && (
                          <>
                            <Box
                              p={1}
                              display="flex"
                              alignItems="center"
                              className={classes.previewCardmail}
                            >
                              <Box>
                                <Avatar className={classes.icon}>
                                  <img
                                    src="/images/mail.svg"
                                    alt="Send quotation"
                                  />
                                </Avatar>
                              </Box>
                              <Box flexGrow={1} marginLeft="10px">
                                <Typography className={classes.name}>
                                  Send quotation
                                </Typography>
                              </Box>
                            </Box>
                          </>
                        )}
                      </Grid>

                      <Grid item xs={3} className={classes.data}>
                        <Typography className={classes.title}>
                          CATEGORY
                        </Typography>
                        <Typography className={classes.sub}>
                          {preview?.activity_category?.activity_category}
                        </Typography>
                      </Grid>

                      <Grid item xs={3} className={classes.data}>
                        <Typography className={classes.title}>
                          PURPOSE
                        </Typography>
                        <Typography className={classes.sub}>
                          {preview?.activity_sub_category?.sub_category}
                        </Typography>
                      </Grid>

                      <Grid item xs={3} className={classes.data}>
                        <Typography className={classes.title}>
                          PRIORITY
                        </Typography>
                        <Typography className={classes.sub}>
                          {preview?.priority}
                        </Typography>
                      </Grid>

                      <Grid item xs={3} className={classes.data}>
                        <Typography className={classes.title}>
                          START DATE
                        </Typography>
                        <Typography className={classes.sub}>
                          {moment(preview?.date_time)
                            .tz(moment.tz.guess())
                            .format()}
                        </Typography>
                      </Grid>
                      {preview?.phone_number && (
                        <Grid item xs={3} className={classes.data}>
                          <Typography className={classes.title}>
                            PHONE NUMBER
                          </Typography>
                          <Typography className={classes.sub}>
                            {preview?.phone_number}
                          </Typography>
                        </Grid>
                      )}
                      {preview?.email_id && (
                        <Grid item xs={3} className={classes.data}>
                          <Typography className={classes.title}>
                            START DATE
                          </Typography>
                          <Typography className={classes.sub}>
                            {preview?.email_id}
                          </Typography>
                        </Grid>
                      )}
                      {preview?.location && (
                        <Grid item xs={3} className={classes.data}>
                          <Typography className={classes.title}>
                            START DATE
                          </Typography>
                          <Typography className={classes.sub}>
                            {preview?.location}
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                  </div>
                  <Divider className={classes.divider} />

                  <div style={{ padding: "8px" }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography className={classes.notetitle}>
                          NOTE:
                        </Typography>
                        <ShowMoreText
                          lines={1}
                          more="Show More"
                          less="Show Less"
                          className={classes.noteTextStyle2}
                          anchorClass={classes.seeMoreLessTextStyle}
                          expanded={false}
                          truncatedEndingComponent={"... "}
                        >
                          <Typography className={classes.note}>
                            {preview?.notes}
                          </Typography>
                        </ShowMoreText>
                      </Grid>
                    </Grid>
                  </div>
                  <Divider className={classes.divider} />
                  <Button
                    variant="contained"
                    fullWidth
                    className={classes.completedbtn}
                    onClick={markCompleted}
                  >
                    Mark As Completed
                  </Button>
                </div>
              </Dialog>
            </Hidden>
            <Hidden smUp>
              <Drawer
                anchor="bottom"
                className={classes.drawer}
                open={open}
                onClose={() => {
                  setOpen(false);
                }}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  p={1}
                  border={2}
                  borderColor="#F5F7FA"
                >
                  <Box flexGrow={1}>
                    <Typography className={classes.head}>Activity</Typography>
                  </Box>
                  <Box>
                    <IconButton
                      onClick={() => {
                        setOpen(false);
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Box>
                </Box>
                <div className={classes.viewdata}>
                  <div>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        {/* phonecall */}

                        {preview?.lead_activitiy_master ===
                          "67f34ccd-3f23-4f10-8334-80ed1d425781" && (
                          <>
                            <Box
                              p={1}
                              display="flex"
                              alignItems="center"
                              className={classes.previewCardphone}
                            >
                              <Box>
                                <Avatar className={classes.icon}>
                                  <img
                                    src="/images/callreview.svg"
                                    alt=" Phone Call"
                                  />
                                </Avatar>
                              </Box>
                              <Box flexGrow={1} marginLeft="10px">
                                <Typography className={classes.name}>
                                  Phone Call
                                </Typography>
                              </Box>
                            </Box>
                          </>
                        )}
                        {/* follow up */}
                        {preview?.lead_activitiy_master ===
                          "fff87381-de52-455d-9f69-8400b9a2a0ff" && (
                          <>
                            <Box
                              p={1}
                              display="flex"
                              alignItems="center"
                              className={classes.previewCardfollow}
                            >
                              <Box>
                                <Avatar className={classes.icon}>
                                  <img
                                    src="/images/followreview.svg"
                                    alt="Follow up"
                                  />
                                </Avatar>
                              </Box>
                              <Box flexGrow={1} marginLeft="10px">
                                <Typography className={classes.name}>
                                  Follow up
                                </Typography>
                              </Box>
                            </Box>
                          </>
                        )}
                        {/* meeting up */}
                        {preview?.lead_activitiy_master ===
                          "cbc1480a-a7e5-4bab-9a07-9f08d949f179" && (
                          <>
                            <Box
                              p={1}
                              display="flex"
                              alignItems="center"
                              className={classes.previewCardmeeting}
                            >
                              <Box>
                                <Avatar className={classes.icon}>
                                  <img
                                    src="images/meetreview.svg"
                                    alt="Meeting"
                                  />
                                </Avatar>
                              </Box>
                              <Box flexGrow={1} marginLeft="10px">
                                <Typography className={classes.name}>
                                  Meeting
                                </Typography>
                              </Box>
                            </Box>
                          </>
                        )}
                        {/* appoinment up */}
                        {preview?.lead_activitiy_master ===
                          "50fae8f5-17d0-417a-ba01-568b35acfbc1" && (
                          <>
                            <Box
                              p={1}
                              display="flex"
                              alignItems="center"
                              className={classes.previewCardappoinment}
                            >
                              <Box>
                                <Avatar className={classes.icon}>
                                  <img
                                    src="/images/Group 4957.svg"
                                    alt="Appointment"
                                  />
                                </Avatar>
                              </Box>
                              <Box flexGrow={1} marginLeft="10px">
                                <Typography className={classes.name}>
                                  Appointment
                                </Typography>
                              </Box>
                            </Box>
                          </>
                        )}
                        {/* site visit */}
                        {preview?.lead_activitiy_master ===
                          "60f6e6f0-1328-47b4-8dc6-34035ee49c6e" && (
                          <>
                            <Box
                              p={1}
                              display="flex"
                              alignItems="center"
                              className={classes.previewCardsite}
                            >
                              <Box>
                                <Avatar className={classes.icon}>
                                  <img
                                    src="/images/sitereview.svg"
                                    alt="Arrange site visit"
                                  />
                                </Avatar>
                              </Box>
                              <Box flexGrow={1} marginLeft="10px">
                                <Typography className={classes.name}>
                                  Arrange site visit
                                </Typography>
                              </Box>
                            </Box>
                          </>
                        )}
                        {/* site qutation */}
                        {preview?.lead_activitiy_master ===
                          "ac53c561-9ab2-437b-b80c-e7a738bab8f2" && (
                          <>
                            <Box
                              p={1}
                              display="flex"
                              alignItems="center"
                              className={classes.previewCardqutation}
                            >
                              <Box>
                                <Avatar className={classes.icon}>
                                  <img
                                    src="/images/qutationreview.svg"
                                    alt="Send email"
                                  />
                                </Avatar>
                              </Box>
                              <Box flexGrow={1} marginLeft="10px">
                                <Typography className={classes.name}>
                                  Send email
                                </Typography>
                              </Box>
                            </Box>
                          </>
                        )}
                        {/* site mail */}
                        {preview?.lead_activitiy_master ===
                          "1931346b-a32e-4834-b399-708f07448278" && (
                          <>
                            <Box
                              p={1}
                              display="flex"
                              alignItems="center"
                              className={classes.previewCardmail}
                            >
                              <Box>
                                <Avatar className={classes.icon}>
                                  <img
                                    src="/images/mail.svg"
                                    alt="Send quotation"
                                  />
                                </Avatar>
                              </Box>
                              <Box flexGrow={1} marginLeft="10px">
                                <Typography className={classes.name}>
                                  Send quotation
                                </Typography>
                              </Box>
                            </Box>
                          </>
                        )}
                      </Grid>

                      <Grid item xs={3} className={classes.data}>
                        <Typography className={classes.title}>
                          CATEGORY
                        </Typography>
                        <Typography className={classes.sub}>
                          {preview?.activity_category?.activity_category}
                        </Typography>
                      </Grid>

                      <Grid item xs={3} className={classes.data}>
                        <Typography className={classes.title}>
                          PURPOSE
                        </Typography>
                        <Typography className={classes.sub}>
                          {preview?.activity_sub_category?.sub_category}
                        </Typography>
                      </Grid>

                      <Grid item xs={3} className={classes.data}>
                        <Typography className={classes.title}>
                          PRIORITY
                        </Typography>
                        <Typography className={classes.sub}>
                          {preview?.priority}
                        </Typography>
                      </Grid>

                      <Grid item xs={3} className={classes.data}>
                        <Typography className={classes.title}>
                          START DATE
                        </Typography>
                        <Typography className={classes.sub}>
                          {moment(preview?.date_time)
                            .tz(moment.tz.guess())
                            .format()}
                        </Typography>
                      </Grid>
                      {preview?.phone_number && (
                        <Grid item xs={3} className={classes.data}>
                          <Typography className={classes.title}>
                            PHONE NUMBER
                          </Typography>
                          <Typography className={classes.sub}>
                            {preview?.phone_number}
                          </Typography>
                        </Grid>
                      )}
                      {preview?.email_id && (
                        <Grid item xs={3} className={classes.data}>
                          <Typography className={classes.title}>
                            START DATE
                          </Typography>
                          <Typography className={classes.sub}>
                            {preview?.email_id}
                          </Typography>
                        </Grid>
                      )}
                      {preview?.location && (
                        <Grid item xs={3} className={classes.data}>
                          <Typography className={classes.title}>
                            START DATE
                          </Typography>
                          <Typography className={classes.sub}>
                            {preview?.location}
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                  </div>
                  <Divider className={classes.divider} />

                  <div style={{ padding: "8px" }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography className={classes.notetitle}>
                          NOTE:
                        </Typography>
                        <ShowMoreText
                          lines={1}
                          more="Show More"
                          less="Show Less"
                          className={classes.noteTextStyle2}
                          anchorClass={classes.seeMoreLessTextStyle}
                          expanded={false}
                          truncatedEndingComponent={"... "}
                        >
                          <Typography className={classes.note}>
                            {preview?.notes}
                          </Typography>
                        </ShowMoreText>
                      </Grid>
                    </Grid>
                  </div>
                  <Divider className={classes.divider} />
                  <Button
                    variant="contained"
                    fullWidth
                    className={classes.completedbtn}
                    onClick={markCompleted}
                  >
                    Mark As Completed
                  </Button>
                </div>
              </Drawer>
            </Hidden>
            <Hidden smDown>
              <Dialog
                fullWidth
                maxWidth="sm"
                open={mark}
                className={classes.dialog}
                onClose={() => {
                  setMark(false);
                }}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  p={1}
                  border={2}
                  borderColor="#F5F7FA"
                >
                  <Box flexGrow={1}>
                    <Typography className={classes.head}>
                      Close Activity
                    </Typography>
                  </Box>
                  <Box>
                    <IconButton
                      onClick={() => {
                        setMark(false);
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Box>
                </Box>
                <div className={classes.viewdata}>
                  <TextBox
                    multiline
                    value={completed}
                    onChange={(e) => setCompleted(e.target.value)}
                    label="Reason/ Note,if any"
                    placeholder=""
                  />
                  <Button
                    variant="contained"
                    fullWidth
                    className={classes.completedbtn}
                    onClick={closeCompleted}
                  >
                    Submit
                  </Button>
                </div>
              </Dialog>
            </Hidden>
            <Hidden smUp>
              <Drawer
                open={mark}
                anchor="bottom"
                className={classes.drawer}
                onClose={() => {
                  setMark(false);
                }}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  p={1}
                  border={2}
                  borderColor="#F5F7FA"
                >
                  <Box flexGrow={1}>
                    <Typography className={classes.head}>
                      Close Activity
                    </Typography>
                  </Box>
                  <Box>
                    <IconButton
                      onClick={() => {
                        setMark(false);
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Box>
                </Box>
                <div className={classes.viewdata}>
                  <DateTime title="End Date & Time" />
                  <br />
                  <TextBox
                    multiline
                    label="Reason/ Note,if any"
                    value={completed}
                    onChange={(e) => setCompleted(e.target.value)}
                    placeholder=""
                  />
                  <Button
                    variant="contained"
                    fullWidth
                    className={classes.completedbtn}
                    onClick={closeCompleted}
                  >
                    Submit
                  </Button>
                </div>
              </Drawer>
            </Hidden>
          </div>
        </>
      )}
    </>
  );
};
