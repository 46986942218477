import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItemButton,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { DateTime, TitleBar } from "../";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { CheckboxesGroup } from "./checkBoxFilter";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },

  active: {
    fontSize: "12px",
    padding: "2px 6px",
    color: "#5078E1",
    backgroundColor: "#E4E8EE",
    borderRadius: "50%",
    width: "100%",
    display: "inline",
    fontFamily: "tenant_bold",
  },
  filterdata: {
    fontSize: "16px",
    fontFamily: "tenant_bold",
  },
  roots: {
    [theme.breakpoints.up("sm")]: {
      height: (props) => props.height - 200,
    },

    [theme.breakpoints.down("sm")]: {
      height: (props) => props.height - 150,
    },

    overflow: "scroll",
  },
  btncontainer: {
    padding: "14px",
    backgroundColor: "#F5F7FA",
  },
  applybtn: {
    padding: "12px",
    borderRadius: "10px",
  },
  cancelbtn: {
    padding: "12px",
    border: "1px solid #E4E8EE",
    backgroundColor: "white",
    borderRadius: "10px",
  },
}));

const filterTypes = {
  CHECKBOX: "CHECKBOX",
  DATE: "DATE",
};

export const Filter = ({
  filterData = [],
  onGoBack = () => false,
  selectedList = {},
  filters = {},
  onApply = () => false,
  updateState = () => false,
  filterStartDate = {},
  onClose = () => false,
}) => {
  const size = useWindowDimensions();
  const classes = useStyles(size);

  const [state, setState] = React.useState({ selectedList, filters });
  const [search, setSearch] = React.useState("");
  const onListSelected = (event, filter) => {
    setState({ ...state, selectedList: filter });
  };

  const onApplyBtnClicked = () => {
    onApply(state.filters);
  };

  const onFilterChange = (key, values, type) => {
    let filters = state.filters;
    if (type === filterTypes.CHECKBOX) {
      if (values.isChecked) {
        filters[key].push(values.value);
      } else {
        filters[key].splice(filters[key].indexOf(values.value), 1);
      }
    }
    setState({ ...state, filters });
  };

  const resetFilter = () => {
    if (filterData.length) {
      let filters = state.filters;
      filterData.forEach((filter) => {
        if (!filters?.[filter.key]?.length) {
          filters[filter.key] = [];
        }
      });
      setState({ ...state, filters });
    }
  };

  React.useEffect(() => {
    resetFilter();
    // eslint-disable-next-line
  }, []);

  return (
    <div className={classes.root}>
      <Grid container sx={{ padding: "0px" }}>
        {/* Header */}
        <Grid item xs={12}>
          <TitleBar text="Filter By" goBack={onGoBack} />
        </Grid>

        {/* Body */}
        <Grid item xs={12} container className={classes.roots}>
          <Grid
            item
            xs={6}
            sx={{
              borderRight: "1px solid #c1c1c1",
            }}
          >
            <List component="nav">
              {filterData.map((filter, key) => {
                return (
                  <ListItemButton
                    key={key}
                    selected={state.selectedList.key === filter.key}
                    onClick={(event) => onListSelected(event, filter)}
                  >
                    <ListItemText className={classes.filterdata}>
                      {filter.title}&nbsp;
                      <span className={classes.active}>
                        {state.filters?.[filter.key]?.length ?? 0}
                      </span>
                    </ListItemText>
                  </ListItemButton>
                );
              })}
            </List>
          </Grid>

          {state.selectedList.key && (
            <Grid item xs={6}>
              {state.selectedList.showSearch && (
                <div style={{ padding: "12px" }}>
                  <TextField
                    size="small"
                    variant="outlined"
                    color="secondary"
                    fullWidth
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                    placeholder={"Search " + state.selectedList.title}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton>
                            <img src="/images/icons8-search.svg" alt="note" />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              )}

              {state.selectedList.values.length ? (
                <>
                  {state.selectedList.filterType === filterTypes.CHECKBOX && (
                    <CheckboxesGroup
                      search={search}
                      selectedValues={state.filters[state.selectedList.key]}
                      onSelect={(values) =>
                        onFilterChange(
                          state.selectedList.key,
                          values,
                          filterTypes.CHECKBOX
                        )
                      }
                      filters={state.selectedList.values}
                    />
                  )}
                  {state.selectedList.filterType === filterTypes.DATE && (
                    <div style={{ padding: "12px" }}>
                      <DateTime
                        time={true}
                        title="Start Date"
                        value={filterStartDate.startDate}
                        onChange={(val) => updateState("startDate", val)}
                      />
                      <Box height="8px" />
                      <DateTime
                        title="End Date"
                        time={true}
                        value={filterStartDate.endDate}
                        onChange={(val) => updateState("endDate", val)}
                      />
                    </div>
                  )}
                </>
              ) : (
                <Typography variant={"caption"}>
                  No Filters Available
                </Typography>
              )}
            </Grid>
          )}
        </Grid>
        <Grid container className={classes.btncontainer} spacing={1}>
          <Grid item xs={6}>
            <Button
              variant="contain"
              fullWidth={true}
              className={classes.cancelbtn}
              onClick={onClose}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="contained"
              fullWidth={true}
              className={classes.applybtn}
              onClick={() => {
                onApplyBtnClicked();
              }}
            >
              Apply
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
