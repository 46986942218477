import { Container, Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useLocation } from "react-router-dom";
import { TabsComponent, TitleBar } from "../../components";
import ActivityLead from "../activitylead";
import { Todo } from "../todo";
import { LeadInfo } from "./leadInfo/leadInfo";
const useStyles = makeStyles((theme) => ({
  root: {},
  screenScrollEffect: {
    overflow: "overlay",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
}));
export const LeadView = (props) => {
  const search = useLocation().search;
  const leadId = new URLSearchParams(search).get("leadId");
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = React.useState(0);

  const onTab1Clicked = () => {
    setSelectedTab(0);
  };
  const onTab2Clicked = () => {
    setSelectedTab(1);
  };
  const onTab3Clicked = () => {
    setSelectedTab(2);
  };
  const onTab4Clicked = () => {
    setSelectedTab(3);
  };
  const onTab5Clicked = () => {
    setSelectedTab(4);
  };
  const goBack = () => {
    window.history.go(-1);
  };

  return (
    <Container
      className={classes.root}
      maxWidth="sm"
      style={{ padding: "0px" }}
    >
      <TitleBar text="Lead Detail" goBack={goBack} />
      <TabsComponent
        selectedTab={selectedTab}
        onTab1Clicked={onTab1Clicked}
        onTab2Clicked={onTab2Clicked}
        onTab3Clicked={onTab3Clicked}
        onTab4Clicked={onTab4Clicked}
        onTab5Clicked={onTab5Clicked}
      />
      <Grid className={classes.screenScrollEffect}>
        {selectedTab === 0 ? (
          <LeadInfo
            leadId={leadId}
            active={new URLSearchParams(search).get("open")}
          />
        ) : selectedTab === 1 ? (
          "Quotation"
        ) : selectedTab === 2 ? (
          <ActivityLead
            leadId={leadId}
            active={new URLSearchParams(search).get("open")}
          />
        ) : selectedTab === 3 ? (
          <Todo
            leadId={leadId}
            active={new URLSearchParams(search).get("open")}
          />
        ) : selectedTab === 4 ? (
          "Track"
        ) : (
          "Not Found"
        )}
      </Grid>
    </Container>
  );
};
