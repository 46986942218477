import React from "react";
import { withNavBars } from "../../HOCs";
import { Gokul } from "./gokul";

class GokulParent extends React.Component {
  render() {
    return <Gokul />;
  }
}

export default withNavBars(GokulParent);
