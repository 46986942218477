//How To Use

// <SimpleMapWithMarker
//   lat={13.120910}
//   lng={80.258786}
//   center={{ lat: 13.120910, lng: 80.258786 }}
//   zoom={11}
// />


import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';

const AnyReactComponent = () => <img height={60} width={60} src='/images/Group_4268.svg' alt='marker' />;
const getMapOptions = () => {
  return {
    fullscreenControl: false,
    zoomControl: false
  };
};
class SimpleMapWithMarker extends Component {
  render() {
    return (
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyAOb6SGSupR3aquQkI8TEO_vPewSG8IaFM" }}
        defaultCenter={this.props.center}
        defaultZoom={this.props.zoom}
        draggable={false}
        options={getMapOptions}
      >
        <AnyReactComponent
          lat={this.props.lat}
          lng={this.props.lng}
        />
      </GoogleMapReact>
    );
  }
}

export default SimpleMapWithMarker;