import { Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { DateTime, TextBox } from "../../components";
import { CreateQuotationListContext } from "./createquotationcontaxt";
const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "14px",
  },

  profileimg: {
    backgroundColor: theme.palette.background.tertiary,
    color: "#E4E8EE",
    position: "relative",
  },
  profileicon: {
    fontSize: "80px",
    padding: "14px",
  },
  profileimgs: {
    backgroundColor: theme.palette.background.tertiary,
    color: "#E4E8EE",
    position: "absolute",
    bottom: "0px",
    right: "0px",
    border: "2px solid white",
    borderRadius: "50%",
  },

  profilesection: {
    marginTop: "14px",
  },
  field: {
    marginTop: "10px",
  },
  fieldparent: {
    marginTop: "10px",
  },
  address: {
    marginTop: "10px",
    borderBottom: "4px solid #F5F7FA",
    borderTop: "4px solid #F5F7FA",
    marginBottom: "10px",
    padding: "8px 0px",
  },
  addAddress: {
    fontSize: "14px",
    fontFamily: "tenant_bold",
    color: "#5078E1",
    cursor: "pointer",
  },
  addresstitle: {
    fontSize: "16px",
    fontFamily: "tenant_bold",
  },
  UnitStatusactive: {
    padding: "12px 16px",

    textAlign: "center",
    borderRadius: "10px",
    color: "white",
    fontSize: "14px",
    backgroundColor: "#5078E1",
    cursor: "pointer",
  },
  UnitStatus: {
    padding: "12px 16px",
    textAlign: "center",
    borderRadius: "10px",
    fontSize: "14px",
    cursor: "pointer",

    backgroundColor: "white",
    border: "1px solid #E4E8EE",
  },
  Label: {
    color: theme.typography.color.Tertiary,
    fontSize: "12px",
    marginTop: "6px",
  },
}));
export const QutationDetails = (props) => {
  const classes = useStyles();
  const { data, updateState } = React.useContext(CreateQuotationListContext);
  return (
    <>
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <Typography variant="body1" className={classes.Label} gutterBottom>
            Lease period
          </Typography>
        </Grid>

        <Grid item>
          <Typography
            onClick={() => {
              updateState("leaseperiod", "Monthly");
            }}
            className={
              data.leaseperiod === "Monthly"
                ? classes.UnitStatusactive
                : classes.UnitStatus
            }
          >
            Monthly
          </Typography>
        </Grid>
        <Grid item sx={{ marginLeft: "8px" }}>
          <Typography
            onClick={() => {
              updateState("leaseperiod", "Quarterly");
            }}
            className={
              data.leaseperiod === "Quarterly"
                ? classes.UnitStatusactive
                : classes.UnitStatus
            }
          >
            Quarterly
          </Typography>
        </Grid>
        <Grid item sx={{ marginLeft: "8px" }}>
          <Typography
            onClick={() => {
              updateState("leaseperiod", "Yearly");
            }}
            className={
              data.leaseperiod === "Yearly"
                ? classes.UnitStatusactive
                : classes.UnitStatus
            }
          >
            Yearly
          </Typography>
        </Grid>
        {data?.error?.leaseperiod.length > 0 && (
          <Grid item xs={12}>
            <Typography style={{ fontSize: "12px", color: "red" }}>
              {data.error.leaseperiod}
            </Typography>
          </Grid>
        )}
      </Grid>
      <Grid container spacing={1} className={classes.fieldparent}>
        <Grid item xs={6}>
          <DateTime
            value={data.leasestartdate}
            title="Lease Start date"
            onChange={(value) => {
              updateState("leasestartdate", value);
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <DateTime
            value={data.leaseenddate}
            title="Lease End date"
            onChange={(value) => {
              updateState("leaseenddate", value);
            }}
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={1}
        alignItems="center"
        className={classes.fieldparent}
      >
        <Grid item xs={6} sm={8}>
          <TextBox
            label="Duration"
            type="number"
            placeholder=""
            onChange={(value) => updateState("duration", value.target.value)}
            isError={data.error.duration.length > 0}
            errorMessage={data.error.duration}
            value={data.duration}
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <Grid container spacing={1}>
            <Grid item xs={6} sm={6}>
              <br />
              <Typography
                onClick={() => {
                  updateState("durationtype", "days");
                }}
                className={
                  data.durationtype === "days"
                    ? classes.UnitStatusactive
                    : classes.UnitStatus
                }
              >
                Days
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6}>
              <br />
              <Typography
                onClick={() => {
                  updateState("durationtype", "weeks");
                }}
                className={
                  data.durationtype === "weeks"
                    ? classes.UnitStatusactive
                    : classes.UnitStatus
                }
              >
                Weeks
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {data?.error?.durationtype.length > 0 && (
                <Grid item xs={12}>
                  <Typography style={{ fontSize: "12px", color: "red" }}>
                    {data.error.durationtype}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.field}>
        <TextBox
          label="Description"
          placeholder=""
          multiline
          onChange={(value) => updateState("desp", value.target.value)}
          value={data.desp}
        />
      </Grid>
      <Grid
        container
        spacing={1}
        alignItems="center"
        className={classes.fieldparent}
      >
        <Grid item xs={9} sm={10}>
          <TextBox
            type="number"
            label="Grace period"
            placeholder=""
            onChange={(value) => updateState("gradeperiod", value.target.value)}
            isError={data.error.gradeperiod.length > 0}
            errorMessage={data.error.gradeperiod}
            value={data.gradeperiod}
          />
        </Grid>
        <Grid item xs={3} sm={2}>
          <br />
          <Typography
            className={
              data.durationtype === "days"
                ? classes.UnitStatusactive
                : classes.UnitStatus
            }
          >
            Days
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.field}>
        <DateTime
          title="Valid till"
          onChange={(value) => {
            updateState("valid", value);
          }}
          value={data.valid}
        />
        {data?.error?.valid.length > 0 && (
          <Grid item xs={12}>
            <Typography style={{ fontSize: "12px", color: "red" }}>
              {data.error.valid}
            </Typography>
          </Grid>
        )}
      </Grid>
      <br />
      <br />
    </>
  );
};
