import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Propertyview } from "../screens/search/propertyview";
import { SearchResult } from "../screens/search/searchresult";
import { ShortListunits } from "../screens/search/shortlistedunits";
import {
  ActiveCustomers,
  Activities,
  AgreementDetails,
  CreateActivity,
  CreateQuotation,
  Dashboard,
  Gokul,
  Kishore,
  Lead,
  LeadCreate,
  LeadInfoEdit,
  LeadShortList,
  LeadView,
  Login,
  MoveIn,
  MoveOut,
  NotFound,
  OtpPage,
  Prem,
  Profile,
  ProfileAddressAdd,
  ProfileAddressEdit,
  ProfileEdit,
  QuotationDetails,
  Search,
  SetPassword,
  SetupAccount,
  WelcomePage,
} from "./../screens";
import PrivateRouter from "./privateRouter";
import { Routes } from "./routes";
const RouterApp = (props) => {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Login />
        </Route>
        {/* ShortListunits Route */}
        <PrivateRouter exact path={Routes.shortlistedunits}>
          <ShortListunits />
        </PrivateRouter>
        {/* Login Route */}
        <Route exact path={Routes.login}>
          <Login />
        </Route>
        {/* Dashboard Route */}
        <PrivateRouter exact path={Routes.dashboard}>
          <Dashboard />
        </PrivateRouter>
        {/* Lead Route */}
        <PrivateRouter exact path={Routes.lead}>
          <Lead />
        </PrivateRouter>
        {/* Lead create Route */}
        <PrivateRouter exact path={Routes.createlead}>
          <LeadCreate />
        </PrivateRouter>
        {/* Search Route */}
        <PrivateRouter exact path={Routes.search}>
          <Search />
        </PrivateRouter>
        {/* Search Result */}
        <PrivateRouter exact path={Routes.searchresult}>
          <SearchResult />
        </PrivateRouter>
        {/* Search Result */}
        <PrivateRouter exact path={Routes.propertyview}>
          <Propertyview />
        </PrivateRouter>
        {/* Activities Route */}
        <PrivateRouter exact path={Routes.activities}>
          <Activities />
        </PrivateRouter>
        {/* Quotation Details Route */}
        <PrivateRouter exact path={Routes.quotationDetails}>
          <QuotationDetails />
        </PrivateRouter>
        {/* create Activities Route */}
        <PrivateRouter exact path={Routes.createactivity}>
          <CreateActivity />
        </PrivateRouter>
        {/* create CreateQuotation Route */}
        <PrivateRouter exact path={Routes.createquotation}>
          <CreateQuotation />
        </PrivateRouter>
        {/* Profile Route */}
        <PrivateRouter exact path={Routes.profile}>
          <Profile />
        </PrivateRouter>
        {/* Profile Edit Route */}
        <PrivateRouter exact path={Routes.profileEdit}>
          <ProfileEdit />
        </PrivateRouter>
        {/* Profile Address Add Route */}
        <PrivateRouter exact path={Routes.profileAddressAdd}>
          <ProfileAddressAdd />
        </PrivateRouter>
        {/* Profile Address Edit Route */}
        <PrivateRouter exact path={Routes.profileAddressEdit}>
          <ProfileAddressEdit />
        </PrivateRouter>
        {/* View Lead Route */}
        <PrivateRouter exact path={Routes.leadView}>
          <LeadView />
        </PrivateRouter>
        {/* View Info Edit Route */}
        <PrivateRouter exact path={Routes.leadInfoEdit}>
          <LeadInfoEdit />
        </PrivateRouter>

        {/* WelcomePage Route */}
        <Route exact path={Routes.welcomePage}>
          <WelcomePage />
        </Route>
        {/* setupAccount Route */}
        <Route exact path={Routes.setupAccount}>
          <SetupAccount />
        </Route>
        {/* otp Route */}
        <Route exact path={Routes.otpPage}>
          <OtpPage />
        </Route>

        {/* setpassword Route */}
        <Route exact path={Routes.setpassword}>
          <SetPassword />
        </Route>

        {/* Active customer Route */}
        <PrivateRouter exact path={Routes.activecustomers}>
          <ActiveCustomers />
        </PrivateRouter>
        {/* Agreement details Route */}
        <PrivateRouter exact path={Routes.agreementdetails}>
          <AgreementDetails />
        </PrivateRouter>
        {/* Move in Route */}
        <PrivateRouter exact path={Routes.movein}>
          <MoveIn />
        </PrivateRouter>
        {/* Move out Route */}
        <PrivateRouter exact path={Routes.moveout}>
          <MoveOut />
        </PrivateRouter>

        {/* leadshortlist Route */}
        <PrivateRouter exact path={Routes.leadshortlist}>
          <LeadShortList />
        </PrivateRouter>
        {/* Home Route */}
        {/* <PrivateRouter exact path={Routes.home}>
          <Home />
        </PrivateRouter> */}
        {/* Gokul Route */}
        <PrivateRouter exact path={Routes.gokul}>
          <Gokul />
        </PrivateRouter>
        {/* prem Route */}
        <PrivateRouter exact path={Routes.prem}>
          <Prem />
        </PrivateRouter>
        {/* kishore Route */}
        <PrivateRouter exact path={Routes.kishore}>
          <Kishore />
        </PrivateRouter>

        {/* For unknow/non-defined path */}
        <Route exact path="*" component={NotFound} />
      </Switch>
    </Router>
  );
};

export default RouterApp;
