export const Routes = {
  // home: "/",
  login: "/login",
  prem: "/prem",
  gokul: "/gokul",
  kishore: "/kishore",
  componentScreen: "/componentScreen",
  dashboard: "/dashboard",
  lead: "/lead",
  search: "/search",
  activities: "/activities",
  welcomePage: "/welcomepage",
  setupAccount: "/setupaccount",
  otpPage: "/otppage",
  setpassword: "/setpassword",
  activecustomers: "/activecustomers",
  agreementdetails: "/agreementdetails",
  movein: "/movein",
  moveout: "/moveout",
  createactivity: "/create_activity",
  searchresult: "/search_result",
  propertyview: "/property_view",
  shortlistedunits: "/shortlisted_units",
  createquotation: "/create_quotation",
  createlead: "/create_lead",
  leadshortlist: "/leadshortlist",
  leadView: "/lead_view",
  leadInfoEdit: "/lead_info_edit",
  profile: "/profile",
  profileEdit: "/profile_edit",
  profileAddressAdd: "/profile_address_add",
  profileAddressEdit: "/profile_address_edit",
  quotationDetails: "/quotation/:id",
};
