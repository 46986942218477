import React from "react";
import { AgreementDetails } from "./agreementDetails";

class AgreementDetailsParent extends React.Component {
  render() {
    return <AgreementDetails />;
  }
}

export default (AgreementDetailsParent);
