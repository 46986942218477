import { Avatar, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

const useStyles = makeStyles((theme) => ({
    root: {

    },
    child: {
        backgroundColor: (props) => props.data.backgroundColor,
        borderRadius: "12px",
        padding: "12px 12px 20px 12px",
        height: "147px",
        margin: "5px"
    },
    content: {
        marginTop: "8px"
    },
    img: {
        backgroundColor: (props) => props.data.imgColor,
    },
    count: {
        fontFamily: "tenant_bold",
        fontSize: "20px",
        color: theme.typography.color.primary
    },
    title: {
        fontFamily: "tenant_semiBold",
        fontSize: "12px",
        color: theme.typography.color.secondary
    }

}));

export const LeadsCard = (props) => {
    const classes = useStyles(props);

    return (
        <div className={classes.root}>
            <div className={classes.child}>
                <Avatar className={classes.img}>
                    <img src={props.data.src} alt={props.data.src} />
                </Avatar>
                <div className={classes.content} >
                    <Typography className={classes.count} >{props.data.count}</Typography>
                    <Typography className={classes.title}>{props.data.title}</Typography>
                </div>
            </div>
        </div>
    );
};