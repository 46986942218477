import { Container, Grid, Typography, } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { UnitList } from '../../components';

const useStyles = makeStyles((theme) => ({
    root: {

    },
    title: {
        fontSize: "16px",
        fontFamily: "tenant_bold",
        color: theme.typography.color.primary

    },
    totalAmount: {
        fontSize: "14px",
        fontFamily: "tenant_bold",
        color: theme.typography.color.primary

    },

    subTitle: {
        fontSize: "12px",
        fontFamily: "tenant_semiBold",
        color: theme.typography.color.secondary

    },
    btn: {
        borderRadius: "12px"
    }
}))



export const PaymentScreen = (props) => {
    const classes = useStyles();




    return <div className={classes.root}>
        <Container maxWidth="sm">
            <Grid Container padding={1} >
                <Grid item xs={12}>

                    <UnitList data={props.invoiceCart} location={true} object={true} />



                </Grid>
                <Grid item xs={12}>
                    <Grid container justifyContent={"center"} alignItems={"center"} spacing={1}>
                        <Grid item xs={12}>
                            <Typography className={classes.title}>
                                Breakup
                            </Typography>

                        </Grid>

                        <Grid item xs={12} style={{ display: "flex" }}>
                            <Grid item xs={6}>
                                <Typography className={classes.subTitle}>
                                    Base Rent (Monthly)
                                </Typography>

                            </Grid>
                            <Grid item xs={6}>
                                <div style={{ float: "right" }}>
                                    <Typography className={classes.subTitle}>
                                        {/* {"$ " + props?.invoicePay?.total} */}
                                        66
                                    </Typography>

                                </div>

                            </Grid>
                        </Grid>
                        <Grid item xs={12} style={{ display: "flex" }}>
                            <Grid item xs={6}>
                                <Typography className={classes.subTitle}>
                                    Maintanence (Monthly - 10%)
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <div style={{ float: "right" }}>
                                    <Typography className={classes.subTitle}>
                                        $60.00
                                    </Typography>
                                </div>
                                <br />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} style={{ display: "flex" }}>
                            <Grid item xs={6}>
                                <Typography className={classes.subTitle}>
                                    Deposit (Onetime - Refundable)
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <div style={{ float: "right" }}>
                                    <Typography className={classes.subTitle}>
                                        $60.00
                                    </Typography>
                                </div>
                                <br />
                            </Grid>
                        </Grid>

                        <Grid item xs={12}
                            style={{
                                display: "flex",
                                borderBottom: "2px dashed #CED3DD",
                                borderTop: "2px dashed #CED3DD"
                            }}>
                            <Grid item xs={6}>
                                <Typography className={classes.totalAmount}>

                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <div style={{ float: "right" }}>
                                    <Typography className={classes.totalAmount}>
                                        Total Amount To Pay:   $60.00
                                    </Typography>
                                </div>
                                <br />
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>
        </Container>

    </div>
}
