import React from 'react';
import { Divider, Grid, Typography, Checkbox } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CheckCircleIcon from "@mui/icons-material/CheckCircle";


const useStyles = makeStyles((theme) => ({
    InvoiceListGrid: {
        padding: "8px 0px"

    },
    checkBox: {
        color: theme.palette.primary.main,
        borderRadius: "5px",

    },
    title: {
        fontSize: "14px",
        fontFamily: "tenant_bold",
        color: theme.typography.color.primary

    },
    subTitle: {
        fontSize: "12px",
        fontFamily: "tenant_semiBold",
        color: theme.typography.color.secondary

    },
    roundShape: {
        width: "6px",
        height: "6px",
        backgroundColor: theme.palette.border.primary,
        borderRadius: "100%",
        display: "inline-block",
        marginBottom: "2px"

    },
    amount: {
        fontSize: "16px",
        fontFamily: "tenant_semiBold",
        color: theme.typography.color.secondary
    },
    status: {
        fontSize: "10px",
        fontFamily: "tenant_bold",
        backgroundColor: theme.palette.warning.light,
        borderRadius: "4px",
        color: theme.palette.warning.main,
        padding: " 1px 7px",
        float: "right"
    },
    statusPaid: {
        fontSize: "10px",
        fontFamily: "tenant_bold",
        backgroundColor: theme.palette.success.light,
        borderRadius: "4px",
        color: theme.palette.success.main,
        padding: " 1px 7px",
        float: "right"
    }

}))

export const QuotationList = ({
    invoiceData = {},
    setInvoiceCart
}) => {
    const classes = useStyles();
    const [showCheckBox, setShowCheckBox] = React.useState(false);

    const checked = (checked) => {
        setShowCheckBox(checked);
        setInvoiceCart({
            type: checked ? "ADD" : "REMOVE",
            payload: invoiceData
        })
    }

    return <div className={classes.root}>
        <Grid container onClick={() => checked(!showCheckBox)} alignItems="center" className={classes.InvoiceListGrid} >
            <Grid item xs={8} display="flex" alignItems="center"  >

                {showCheckBox &&
                    <Grid item xs={1} marginRight={1}>
                        <Checkbox className={classes.checkBox}
                            checkedIcon={<CheckCircleIcon />}
                            icon={<CheckCircleIcon />}
                        />

                    </Grid>

                }
                <Grid item xs={11}>
                    <Typography className={classes.title}>
                        {invoiceData.title}
                    </Typography>

                    <span className={classes.subTitle}>
                        {invoiceData.requestId}&nbsp; <span className={classes.roundShape}></span>&nbsp;&nbsp;{invoiceData.dueDate}&nbsp;&nbsp;
                        <span className={classes.roundShape}></span>&nbsp;&nbsp;{invoiceData.dueDate}
                    </span>
                </Grid>
            </Grid>

            <Grid item xs={4} >
                <div style={{ float: "right" }}>
                    <Typography className={classes.amount}>
                        {"$ " + invoiceData.amount}
                    </Typography>
                    {
                        invoiceData.status === "Quote Accepted" ?
                            <span className={classes.statusPaid}>
                                {invoiceData.status}
                            </span> :
                            <span className={classes.status}>
                                {invoiceData.status}
                            </span>
                    }

                </div>
            </Grid>


        </Grid>
        <Divider />


    </div>
}