import {
  Avatar,
  Box,
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import jwt_decode from "jwt-decode";
import moment from "moment-timezone";
import React from "react";
import { useHistory } from "react-router-dom";
import ShowMoreText from "react-show-more-text";
import { ContactList, TitleBar } from "../../components";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import {
  POSTLEADDETAILS,
  POSTNEWCONTACT,
  UPLOADLEADIMAGE,
} from "../../graphql/mutations";
import { SEARCHLEAD } from "../../graphql/queries";
import { NetworkCall } from "../../networkcall/index";
import { Routes } from "../../router/routes";
import { AlertProps, NetWorkCallMethods, ValidateEmail } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { Step1 } from "./step1";
import { Step2 } from "./step2";
const useStyles = makeStyles((theme) => ({
  root: {},
  stepindicate: {
    color: theme.typography.color.Tertiary,
    fontSize: "12px",
    fontFamily: "tenant_semiBold",
  },

  stepindicateavatar: {
    backgroundColor: "#5078E1",

    padding: "10px",
  },
  stepindicateavatarborder: {
    border: "1px solid #00000029",
    borderRadius: "50%",
    padding: "4px",
  },
  steps: {
    borderBottom: "4px solid #F5F7FA",
  },
  search: {
    borderBottom: "4px solid #F5F7FA",
    padding: "18px 0px",
  },
  searchmain: {
    padding: "18px 0px",
  },
  searchtext: {
    color: "#98A0AC",
    fontSize: "14px",
  },
  searchbox: {
    border: "1px solid #E4E8EE",
    borderRadius: "8px",
    backgroundColor: "#F5F7FA",
    padding: "8px 12px",
    cursor: "pointer",
  },
  textbox: {
    [`& fieldset`]: {
      borderRadius: "8px",
      height: (props) => (props.multiline ? "unset" : 50),
      border: "1px solid #CED3DD",
      "& :hover": {
        border: "1px solid #5078E1",
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: "11px 14px",
    },
  },
  btnroot: {
    backgroundColor: "#F5F7FA",
    padding: "10px",
  },
  previousbtn: {
    backgroundColor: "white",
    color: "#091B29",
    border: "1px solid #CED3DD",
    borderRadius: "10px",
    padding: "12px 8px",
    boxShadow: "none",
  },
  submitbtn: {
    borderRadius: "10px",
    padding: "12px 8px",
  },
  name: {
    fontSize: "16px",
    fontFamily: "tenant_extraBold",
    textTransform: "capitalize",
  },
  email: {
    fontSize: "10px",
    fontFamily: "tenant_bold",
    color: theme.typography.color.secondary,
    backgroundColor: "#F2F4F7",
    padding: "4px 6px",
    borderRadius: "4px",
    marginTop: "4px",
    display: "inline-block",
  },
  created: {
    fontSize: "10px",
    fontFamily: "tenant_regular",
    color: theme.typography.color.Tertiary,
    marginBottom: "10px",
    marginTop: "10px",
  },
  leadtype: {
    fontSize: "10px",
    fontFamily: "tenant_bold",

    backgroundColor: "#E1E8F8",
    padding: "1px 6px",
    borderRadius: "4px",
    textTransform: "capitalize",

    display: "inline-block",
  },
  imgdiv: {
    position: "relative",
  },
  prority: {
    position: "absolute",
    bottom: "4px",
    left: "0px",
    right: "0px",
  },
  proritydata: {
    fontFamily: "tenant_extraBold",
    fontSize: "10px",
    backgroundColor: "#071741",
    borderRadius: "2px",
    color: "white",
    padding: "4px 12px",
  },
  requirmentdata: {
    fontSize: "8px",
  },
  sqftdata: {
    fontSize: "12px",
    fontFamily: "tenant_bold",
  },
  secdule: {
    fontSize: "12px",
    fontFamily: "tenant_bold",
    marginLeft: "4px",
  },
  note: {
    fontSize: "12px",
    color: "#4E5A6B",
  },
  more: {
    color: "#5078E1",
    fontSize: "12px",
    cursor: "pointer",
  },
  screenPadding: {
    overflow: "overlay",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    padding: "8px",
  },
  noteTextStyle2: {
    fontSize: "12px",
    fontFamily: "tenant_regular",
    color: theme.typography.color.secondary,
    textOverflow: "clip",
  },
  seeMoreLessTextStyle: {
    fontSize: "12px",
    fontFamily: "tenant_semiBold",
    color: theme.palette.primary.main,
  },
  result: {
    fontSize: "12px",
    color: "#A4B2C1",
  },
  listpad: {
    marginTop: "4px",
    marginBottom: "4px",
  },
  divider: {
    "& .css-cppmpw-MuiDivider-root": {
      borderWidth: "2px",
      borderColor: "#F5F7FA",
    },
  },
}));
const InitialStatecontect = {
  email: "",
  mobile: "",
  first_name: "",
  last_name: "",
  altmobile: "",
  telephone: "",
  fax: "",
  facebook: "",
  twitter: "",
  linkedin: "",
  image: "",
  street: "",
  address2: "",
  address3: "",
  state: {},
  city: {},
  country: {},
  pincode: "",
  name1: "",
  error: {
    email: "",
    mobile: "",
    first_name: "",
    last_name: "",
  },
};
const InitialStatelead = {
  name: "",
  requirment: "",
  requirmentType: "",
  budget: [0, 2000],
  leaseperiod: "",
  startdate: "",
  endDate: "",
  leadtype: 0,
  priority: "",
  notes: "",

  error: {
    requirment: "",
    requirmentType: "",
    budget: "",
    leaseperiod: "",
    startdate: "",
    endDate: "",
    leadtype: "",
    priority: "",
    notes: "",
    owner: "",
  },
};
moment.defaultFormat = "DD MMM YY";
export const LeadCreate = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [already, setAlready] = React.useState(false);
  const size = useWindowDimensions();
  const [data, setData] = React.useState({ ...InitialStatecontect });
  const [leaddata, setLeadData] = React.useState({ ...InitialStatelead });
  const [step, setStep] = React.useState(1);
  const alert = React.useContext(AlertContext);
  const [contactList, setContactList] = React.useState([]);
  const [url, setUrl] = React.useState("");
  const [contactid, setContactId] = React.useState("");
  const [search, setSearch] = React.useState(false);
  const tempDecoded = jwt_decode(localStorage.getItem("authToken"));
  const user_profile_id = localStorage.getItem("userProfileId");
  const roles = JSON.parse(localStorage.getItem("selectedroles"));
  const [disable, setDisable] = React.useState(false);
  const goBack = () => {
    window.history.go(-1);
  };
  const updateState = (key, value) => {
    let error = data.error;
    error[key] = "";
    setData({ ...data, [key]: value, error });
  };
  const updateStatelead = (key, value) => {
    if (
      key === "requirmentType" ||
      key === "leaseperiod" ||
      key === "leadtype" ||
      key === "priority" ||
      key === "startdate" ||
      key === "endDate" ||
      key === "owner" ||
      key === "budget"
    ) {
      let error = leaddata.error;
      error[key] = "";
      setLeadData({ ...leaddata, [key]: value, error });
    } else {
      let error = leaddata.error;
      error[key] = "";
      setLeadData({ ...leaddata, [key]: value.target.value, error });
    }
  };
  const isIamValide = () => {
    let isValid = true;
    let error = data.error;
    if (!already) {
      //Checking name
      if (data.first_name.length === 0) {
        isValid = false;
        error.first_name = "First Name is Required";
      }
      //Checking name
      if (data.last_name.length === 0) {
        isValid = false;
        error.last_name = "Last Name is Required";
      }
      //Checking Mobile
      if (data.mobile.length === 0) {
        isValid = false;
        error.mobile = "Mobile is Required";
      }
      //Checking mail
      if (data.email.length === 0) {
        isValid = false;
        error.email = "Email is Required";
      }
      if (ValidateEmail(data.email) === false) {
        isValid = false;
        error.email = "Enter Correct Email";
      }
      setData({ ...data, error });
    }
    return isValid;
  };
  const isIamValidelead = () => {
    let isValid = true;
    let error = leaddata.error;

    //Checking name
    if (leaddata.name.length === 0) {
      isValid = false;
      error.name = "Subject is Required";
    }
    //Checking Requirement
    if (leaddata.requirment.length === 0) {
      isValid = false;
      error.requirment = "Requirement is Required";
    }

    //Checking notes
    if (leaddata.requirmentType.length === 0) {
      isValid = false;
      error.requirmentType = " Requirment Type is Required";
    }

    //Checking priority
    if (leaddata.priority.length === 0) {
      isValid = false;
      error.priority = "Priority is Required";
    }

    //Checking startdata
    if (leaddata.startdate.length === 0) {
      isValid = false;
      error.startdate = "Start Date is Required";
    }
    //Checking endDate
    if (leaddata.endDate.length === 0) {
      isValid = false;
      error.endDate = "End Date is Required";
    }
    //Checking endDate
    if (leaddata.leaseperiod.length === 0) {
      isValid = false;
      error.leaseperiod = "Lease Period is Required";
    }

    if (
      moment(moment(leaddata.endDate).tz(moment.tz.guess()).format()).isAfter(
        moment(leaddata.startdate).tz(moment.tz.guess()).format()
      ) === false
    ) {
      isValid = false;
      error.endDate = "End Date is More then Start Date";
    }
    if (
      moment(moment(leaddata.startdate).tz(moment.tz.guess()).format()).isAfter(
        moment(leaddata.endDate).tz(moment.tz.guess()).format()
      )
    ) {
      isValid = false;
      error.startdate = "Start Date is Less then End Date";
    }

    setLeadData({ ...leaddata, error });
    return isValid;
  };
  const uploadleadimage = (id, url) => {
    const variables = {
      query: UPLOADLEADIMAGE,
      variables: {
        insert: [
          {
            is_active: true,
            reference_id: id,
            url: url,
            created_by: user_profile_id,
            assets_master_type: 2,
            priority: 1,
          },
        ],
      },
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      variables,
      null,
      true,
      false
    )
      .then((response) => {
        if ((step) => 1 || step <= 3) {
          setStep(step + 1);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const uploadImageS3 = (contactId) => {
    if (data.image) {
      const formData = new FormData();
      formData.append("files", data.image);
      formData.append("tenantId", `${config.tenantid}`);

      NetworkCall(
        `${config.api_url}/file-upload`,
        NetWorkCallMethods.post,
        formData,
        null,
        true,
        false
      )
        .then((response) => {
          setUrl(response.data.fileUrls[0].url);
          uploadleadimage(contactId, response.data.fileUrls[0].url);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const Submit = () => {
    if (step === 1) {
      if (isIamValide()) {
        if (contactid.length === 0) {
          const variables = {
            query: POSTNEWCONTACT,
            variables: {
              insert: [
                {
                  first_name: data.first_name,
                  last_name: data.last_name,
                  email_id: data.email,
                  mobile_no: data.mobile.mobile,
                  mobile_no_country_code: "+91",
                  alternative_mobile: data.altmobile.mobile_no,
                  street_1: data.street,
                  street_2: data.address2,
                  street_3: data.address3,
                  city: data.city.value,
                  state: data.state.value,
                  country: data.country.value,
                  facebook: data.facebook,
                  linkedin: data.linkedin,
                  twitter: data.twitter,
                  is_active: true,
                  zipcode: data.pincode,
                },
              ],
            },
          };
          NetworkCall(
            `${config.graphql_url}`,
            NetWorkCallMethods.post,
            variables,
            null,
            true,
            false
          )
            .then((response) => {
              setContactId(response.data.data.contact[0].id);
              uploadImageS3(response.data.data.contact[0].id);
            })

            .catch((error) => {
              console.log(error);
              alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: "The contact already exists please search to add",
              });
            });
        } else {
          if ((step) => 1 || step <= 3) {
            setStep(step + 1);
          }
        }
      } else {
        return false;
      }
    } else if (step === 2) {
      if (isIamValidelead()) {
        if ((step) => 1 || step <= 3) {
          setStep(step + 1);
        }
      } else {
        return false;
      }
    } else if (step === 3) {
      const variables = {
        query: POSTLEADDETAILS,
        variables: {
          insert: [
            {
              subject: leaddata.name,
              value: parseInt(leaddata.requirment),
              type: leaddata.requirmentType,
              lease_start_date: leaddata.startdate,
              lease_end_date: leaddata.endDate,
              lead_type: leaddata.leadtype,
              notes: leaddata.notes,
              lead_owner: user_profile_id,
              lease_period: leaddata.leaseperiod,
              budget_range: leaddata.budget,
              urgent: leaddata.priority,
              created_by: user_profile_id,
              contact_id: contactid,
              is_active: true,
              company_id: roles.company_id,
            },
          ],
        },
      };
      NetworkCall(
        `${config.graphql_url}`,
        NetWorkCallMethods.post,
        variables,
        null,
        true,
        false
      )
        .then((response) => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: "Lead Successfully Created",
          });
          setDisable(true);
          history.push(Routes.lead);
        })
        .catch((err) => {
          setDisable(true);
        });
    } else {
    }
  };

  const previous = () => {
    if ((step) => 1 || step <= 3) {
      setStep(step - 1);
    }
  };
  const searchHandle = (search) => {
    const variables = {
      query: SEARCHLEAD,
      variables: {
        search: `%${search}%`,
      },
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      variables,
      null,
      true,
      false
    )
      .then((response) => {
        setContactList(response.data.data.contact);
      })

      .catch((error) => {
        console.log(error);
      });
  };
  const goBacksearch = () => {
    setSearch(!search);
  };
  const getSearchdata = (val) => {
    setData({
      email: val.email_id,
      mobile: {
        mobile: val.mobile_no,
        mobile_code: "+91",
      },
      first_name: val.first_name,
      last_name: val.last_name,
      altmobile: "",
      telephone: "",
      fax: "",
      facebook: "",
      twitter: "",
      linkedin: "",
      image: val.get_assets_url_id,
      street: "",
      address2: "",
      address3: "",
      state: {},
      city: {},
      country: {},
      pincode: "",
      name1: "",
      error: {
        email: "",
        mobile: "",
        first_name: "",
        last_name: "",
      },
    });
    setContactId(val.id);
    setAlready(true);
    setSearch(!search);
  };
  const MyDivider = styled(Divider)(({ thiccness, orientation }) => ({
    ...(thiccness !== undefined &&
      (orientation === "vertical"
        ? { borderRightWidth: thiccness, borderColor: "#F5F7FA" }
        : { borderBottomWidth: thiccness, borderColor: "#F5F7FA" })),
  }));
  return (
    <div className={classes.root}>
      <Container maxWidth="sm" sx={{ padding: "0px" }}>
        {search ? (
          <>
            <TitleBar text="Search" goBack={goBacksearch} />
            <div style={{ padding: "8px" }}>
              <Grid item xs={12} className={classes.searchmain}>
                <TextField
                  variant="outlined"
                  color="secondary"
                  style={{ backgroundColor: "#F5F7FA" }}
                  fullWidth
                  onChange={(e) => {
                    searchHandle(e.target.value);
                  }}
                  placeholder="Search Contact Here"
                  className={classes.textbox}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="end">
                        <IconButton aria-label="Toggle password visibility">
                          <img src="/images/icons8-search.svg" alt="note" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              {contactList.length > 0 && (
                <>
                  <Typography
                    className={classes.result}
                    style={{ marginBottom: "-12px" }}
                  >
                    Results
                  </Typography>
                  <MyDivider thiccness={4} />
                  <div className={classes.listpad}>
                    {contactList?.map((val) => {
                      return (
                        <>
                          <ContactList
                            data={val}
                            type="search"
                            onClick={getSearchdata}
                          />
                          <MyDivider thiccness={4} />
                        </>
                      );
                    })}
                  </div>
                </>
              )}
            </div>
          </>
        ) : (
          <Grid
            container
            className={classes.screen}
            style={{ height: size?.height }}
          >
            <Grid item xs={12} sx={{ position: "sticky", top: 0 }}>
              <TitleBar text="New Lead Creation" goBack={goBack} />
              <Grid container>
                <Grid item xs={12} className={classes.steps}>
                  <Box display="flex" alignItems="center" p={1}>
                    <Box className={classes.stepindicateavatarborder}>
                      <Avatar className={classes.stepindicateavatar}>
                        {step}
                      </Avatar>
                    </Box>
                    <Box flexGrow={1} marginLeft="10px">
                      <Typography className={classes.stepindicate}>
                        Step {step}/3{" "}
                      </Typography>

                      <Typography className={classes.name}>
                        {step === 1 && "Add Contact"}
                        {step === 2 && "Lead Details"}
                        {step === 3 && "Preview and Submit"}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              className={classes.screenPadding}
              style={{ height: size?.height - 200 }}
              spacing={1}
            >
              {step === 1 && (
                <>
                  <Grid item style={{ padding: "14px" }}>
                    <Grid item xs={12} className={classes.search}>
                      <Box
                        display="flex"
                        alignItems="center"
                        className={classes.searchbox}
                        p={1}
                        onClick={() => {
                          setSearch(!search);
                        }}
                      >
                        <Box>
                          {" "}
                          <img src="/images/icons8-search.svg" alt="note" />
                        </Box>
                        <Box flexGrow={1} marginLeft="14px">
                          <Typography className={classes.searchtext}>
                            Search Contact Here
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Step1
                      already={already}
                      data={data}
                      updateState={updateState}
                    />
                  </Grid>
                </>
              )}
              {step === 2 && (
                <Grid item style={{ padding: "14px" }}>
                  <Step2 updateState={updateStatelead} data={leaddata} />
                </Grid>
              )}

              {step === 3 && (
                <>
                  <Grid item style={{ padding: "14px" }}>
                    <Grid container spacing={2} sx={{ padding: "14px" }}>
                      <Grid item xs={4} md={3}>
                        <div className={classes.imgdiv}>
                          <img
                            src={already ? data.image : url}
                            style={{
                              height: "150px",
                              width: "100%",
                              objectFit: "cover",
                              borderRadius: "10px",
                            }}
                            alt=""
                          />
                          <div className={classes.prority}>
                            <center>
                              <span className={classes.proritydata}>
                                {leaddata.priority}
                              </span>
                            </center>
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={8} md={9}>
                        <Box display="flex">
                          <Box>
                            {" "}
                            <Typography className={classes.name}>
                              {data.first_name}&nbsp;{data.last_name}
                            </Typography>
                          </Box>
                          <Box marginLeft="10px">
                            {" "}
                            <Typography className={classes.leadtype}>
                              {leaddata.leadtype === 1 && "Commercial"}
                              {leaddata.leadtype === 2 && "Residential"}
                            </Typography>
                          </Box>
                        </Box>

                        <Box display="flex">
                          <Box>
                            {" "}
                            <Typography className={classes.email}>
                              +91 {data.mobile?.mobile}
                            </Typography>
                          </Box>
                          <Box marginLeft="10px">
                            {" "}
                            <Typography className={classes.email}>
                              {data.email}
                            </Typography>
                          </Box>
                        </Box>
                        {data.address1 && (
                          <Typography className={classes.email}>
                            {data.address1}-{data.address2}
                          </Typography>
                        )}

                        <Box>
                          {" "}
                          <Typography className={classes.created}>
                            Created by {tempDecoded?.user_name}&nbsp;
                            {moment(leaddata.startdate)
                              .tz(moment.tz.guess())
                              .format()}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ padding: "14px" }}>
                      <Grid item xs={12}>
                        <Stack
                          direction="row"
                          divider={<Divider orientation="vertical" flexItem />}
                          spacing={2}
                        >
                          <Box p={2}>
                            <img src="/images/Sq ft icvon.svg" alt="sqft" />
                            <Typography className={classes.requirmentdata}>
                              Requirement
                            </Typography>
                            <Typography className={classes.sqftdata}>
                              {leaddata.requirment}&nbsp;
                              {leaddata.requirmentType}
                            </Typography>
                          </Box>
                          <Box p={2}>
                            <img src="/images/money.svg" alt="sqft" />

                            <Typography className={classes.requirmentdata}>
                              Budget
                            </Typography>
                            <Typography className={classes.sqftdata}>
                              {new Intl.NumberFormat("en-IN", {
                                style: "currency",
                                currency: "INR",
                                minimumFractionDigits: 0,
                              }).format(Math.round(leaddata.budget[0]))}
                              K -{" "}
                              {new Intl.NumberFormat("en-IN", {
                                style: "currency",
                                currency: "INR",
                                minimumFractionDigits: 0,
                              }).format(Math.round(leaddata.budget[1]))}
                              K
                            </Typography>
                          </Box>
                        </Stack>
                      </Grid>

                      <Grid item xs={12}>
                        <Divider />
                        <Box display="flex" alignItems="center" p={2}>
                          <Box>
                            <img
                              src="/images/icons8-schedule (1).svg"
                              alt="schdule"
                            />
                          </Box>
                          <Box>
                            <Typography className={classes.secdule}>
                              &nbsp;{" "}
                              {moment(leaddata.startdate)
                                .tz(moment.tz.guess())
                                .format()}{" "}
                              &nbsp;-&nbsp;
                              {moment(leaddata.endDate)
                                .tz(moment.tz.guess())
                                .format()}
                            </Typography>
                          </Box>
                        </Box>
                        <Divider />
                      </Grid>
                      <Grid item xs={12}>
                        <ShowMoreText
                          lines={1}
                          more="Show More"
                          less="Show Less"
                          className={classes.noteTextStyle2}
                          anchorClass={classes.seeMoreLessTextStyle}
                          expanded={false}
                          truncatedEndingComponent={"... "}
                        >
                          <Typography className={classes.note}>
                            <b>Notes:</b> {leaddata.notes}
                          </Typography>
                        </ShowMoreText>
                      </Grid>
                    </Grid>

                    <br />
                  </Grid>
                </>
              )}
            </Grid>
            <Grid
              container
              className={classes.btnroot}
              justifyContent="space-around"
              spacing={1}
            >
              <Grid item xs={6}>
                <Button
                  fullWidth
                  disabled={step === 1 && true}
                  className={classes.previousbtn}
                  variant="contained"
                  onClick={previous}
                >
                  Previous
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  disabled={disable}
                  fullWidth
                  className={classes.submitbtn}
                  variant="contained"
                  onClick={Submit}
                >
                  Next
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Container>
    </div>
  );
};
