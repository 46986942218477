import React from "react";
import { withBottombar } from "../../HOCs";
import { Lead } from "./lead";

class LeadParent extends React.Component {
  render() {
    return <Lead />;
  }
}

export default withBottombar(LeadParent);
