import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  Divider,
  Drawer,
  Grid,
  Hidden,
  IconButton,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { DateTime, RangeSlider, TextBox } from "../../components";
import { CreateQuotationListContext } from "./createquotationcontaxt";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "14px",
  },

  profileimg: {
    backgroundColor: theme.palette.background.tertiary,
    color: "#E4E8EE",
    position: "relative",
  },
  profileicon: {
    fontSize: "80px",
    padding: "14px",
  },
  profileimgs: {
    backgroundColor: theme.palette.background.tertiary,
    color: "#E4E8EE",
    position: "absolute",
    bottom: "0px",
    right: "0px",
    border: "2px solid white",
    borderRadius: "50%",
  },

  profilesection: {
    marginTop: "14px",
  },
  field: {
    marginTop: "10px",
  },
  address: {
    marginTop: "10px",
    borderBottom: "4px solid #F5F7FA",
    borderTop: "4px solid #F5F7FA",
    marginBottom: "10px",
    padding: "8px 0px",
  },
  addAddress: {
    fontSize: "14px",
    fontFamily: "tenant_bold",
    color: "#5078E1",
    cursor: "pointer",
  },
  addresstitle: {
    fontSize: "16px",
    fontFamily: "tenant_bold",
  },
  budget: {
    height: "47px",
    borderRadius: "10px",
    border: "1px solid #CED3DD",
  },
  Label: {
    color: theme.typography.color.Tertiary,
    fontSize: "12px",
  },
  budgettitle: {
    fontSize: "16px",
    fontFamily: "tenant_extraBold",
  },
  budgetroot: {
    padding: "12px 4px",
    borderBottom: "1px solid black",
  },
  budgetrange: {
    fontSize: "12px",
    fontFamily: "tenant_bold",
    color: theme.typography.color.secondary,
  },
  budgetvalue: {
    fontSize: "16px",
    fontFamily: "tenant_semiBold",
  },
  rangeroot: {
    padding: "8px 14px",
  },
  slidervalue: {
    fontSize: "12px",
    color: theme.typography.color.secondary,
    marginBottom: "10px",
  },
  Requirement: {
    padding: "10px",
    marginTop: "24px",
    textAlign: "center",
    borderRadius: "10px",
    color: "white",
    fontSize: "14px",
    backgroundColor: "#5078E1",
    cursor: "pointer",
  },
  Requirementsqft: {
    padding: "10px",
    marginTop: "24px",
    textAlign: "center",
    borderRadius: "10px",

    fontSize: "14px",

    cursor: "pointer",
    border: "1px solid #E4E8EE",
  },
  Requirements: {
    padding: "10px",

    textAlign: "center",
    borderRadius: "10px",
    color: "white",
    fontSize: "14px",
    backgroundColor: "#5078E1",
    cursor: "pointer",
  },
  Requirementsqfts: {
    padding: "10px",

    textAlign: "center",
    borderRadius: "10px",

    fontSize: "14px",

    cursor: "pointer",
    border: "1px solid #E4E8EE",
  },
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopLeftRadius: "16px",
      borderTopRightRadius: "16px",
      width: "100vw",
    },
    "& .MuiContainer-root": {
      padding: "0px",
      width: "100vw",
    },
  },
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: "16px",
      padding: "0px",
    },
  },
}));

export const Step5 = (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const { leaddata, updateStatelead3 } = React.useContext(
    CreateQuotationListContext
  );

  return (
    <>
      <Grid container className={classes.root} spacing={1} alignItems="center">
        {/* name */}
        <Grid item xs={12}>
          <TextBox
            label="Subject"
            placeholder=""
            onChange={(value) => updateStatelead3("name", value.target.value)}
            value={leaddata.name}
            isError={leaddata?.error?.name?.length > 0}
            errorMessage={leaddata?.error?.name}
          />
        </Grid>
        {/* requirments */}
        <Grid item xs={4} sm={6} className={classes.field}>
          <TextBox
            label="Requirement"
            placeholder=""
            onChange={(value) =>
              updateStatelead3("requirment", value.target.value)
            }
            value={leaddata.requirment}
            isError={leaddata?.error?.requirment?.length > 0}
            errorMessage={leaddata?.error?.requirment}
          />
        </Grid>
        <Grid item xs={8} sm={6}>
          <Grid
            container
            spacing={1}
            style={{ padding: "0px", marginTop: "8px" }}
          >
            <Grid item xs={6}>
              <Typography
                onClick={() => {
                  updateStatelead3("requirmentType", "sqft");
                }}
                className={
                  leaddata.requirmentType === "sqft"
                    ? classes.Requirement
                    : classes.Requirementsqft
                }
              >
                Sqaure Ft
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                onClick={() => {
                  updateStatelead3("requirmentType", "units");
                }}
                className={
                  leaddata.requirmentType === "units"
                    ? classes.Requirement
                    : classes.Requirementsqft
                }
              >
                Units
              </Typography>
            </Grid>
          </Grid>
          {leaddata?.error?.requirmentType?.length > 0 && (
            <Typography style={{ fontSize: "14px", color: "red" }}>
              {leaddata?.error?.requirmentType}
            </Typography>
          )}
        </Grid>
        {/* budget */}
        <Grid item xs={12} className={classes.field}>
          <Typography variant="body1" className={classes.Label} gutterBottom>
            Budget
          </Typography>
          <Box
            onClick={() => setOpen(!open)}
            className={classes.budget}
            display="flex"
            style={{ cursor: "pointer" }}
            alignItems="center"
          >
            <Box flexGrow={1} marginLeft="12px">
              {leaddata?.budget.length > 0 && (
                <>
                  <Typography className={classes.budgetvalue}>
                    {new Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 0,
                    }).format(Math.round(leaddata?.budget[0]))}{" "}
                    -{" "}
                    {new Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 0,
                    }).format(Math.round(leaddata?.budget[1]))}
                  </Typography>
                </>
              )}
            </Box>
          </Box>
        </Grid>
        {/* lease period */}
        <Grid item xs={12} className={classes.field}>
          <Typography variant="body1" className={classes.Label} gutterBottom>
            Lease period
          </Typography>
        </Grid>
        <Grid item xs={4} sm={3}>
          <Typography
            onClick={() => {
              updateStatelead3("leaseperiod", "Monthly");
            }}
            className={
              leaddata.leaseperiod === "Monthly"
                ? classes.Requirements
                : classes.Requirementsqfts
            }
          >
            Monthly
          </Typography>
        </Grid>
        <Grid item xs={4} sm={3}>
          <Typography
            onClick={() => {
              updateStatelead3("leaseperiod", "Quarterly");
            }}
            className={
              leaddata.leaseperiod === "Quarterly"
                ? classes.Requirements
                : classes.Requirementsqfts
            }
          >
            Quarterly
          </Typography>
        </Grid>
        <Grid item xs={4} sm={3}>
          <Typography
            onClick={() => {
              updateStatelead3("leaseperiod", "Yearly");
            }}
            className={
              leaddata.leaseperiod === "Yearly"
                ? classes.Requirements
                : classes.Requirementsqfts
            }
          >
            Yearly
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography style={{ fontSize: "14px", color: "red" }}>
            {leaddata?.error?.leaseperiod}
          </Typography>
        </Grid>
        {/* date */}
        <Grid
          item
          xs={6}
          className={classes.field}
          style={{ marginTop: "-1px" }}
        >
          <DateTime
            onChange={(value) => updateStatelead3("startdate", value)}
            title="Lease Start Date"
            value={leaddata.startdate}
          />
          {leaddata?.error?.startdate?.length > 0 && (
            <Typography style={{ fontSize: "14px", color: "red" }}>
              {leaddata?.error?.startdate}
            </Typography>
          )}
        </Grid>
        <Grid item xs={6} className={classes.field}>
          <DateTime
            onChange={(value) => updateStatelead3("endDate", value)}
            title="Lease End Date"
            value={leaddata.endDate}
          />
          {leaddata?.error?.endDate?.length > 0 && (
            <Typography style={{ fontSize: "14px", color: "red" }}>
              {leaddata?.error?.endDate}
            </Typography>
          )}
          <Grid item xs={12}>
            <Typography
              style={{
                fontSize: "12px",
                color: "red",
                whiteSpace: "nowrap",
                marginTop: "8px",
              }}
            >
              {props?.data?.error?.endDate}
            </Typography>
          </Grid>
        </Grid>
        {/* Lead Type */}
        <Grid item xs={12} className={classes.field}>
          <Typography variant="body1" className={classes.Label} gutterBottom>
            Lead Type
          </Typography>
        </Grid>
        <Grid item xs={4} sm={3}>
          <Typography
            onClick={() => {
              updateStatelead3("leadtype", 1);
            }}
            className={
              leaddata.leadtype === 1
                ? classes.Requirements
                : classes.Requirementsqfts
            }
          >
            Commercial
          </Typography>
        </Grid>
        <Grid item xs={4} sm={3}>
          <Typography
            onClick={() => {
              updateStatelead3("leadtype", 2);
            }}
            className={
              leaddata.leadtype === 2
                ? classes.Requirements
                : classes.Requirementsqfts
            }
          >
            Residential
          </Typography>
        </Grid>

        {/* Priority Type */}
        <Grid item xs={12} className={classes.field}>
          <Typography variant="body1" className={classes.Label} gutterBottom>
            Priority Type
          </Typography>
        </Grid>

        <Grid item xs={3}>
          <Typography
            onClick={() => {
              updateStatelead3("priority", "high");
            }}
            className={
              leaddata.priority === "high"
                ? classes.Requirements
                : classes.Requirementsqfts
            }
          >
            High
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography
            onClick={() => {
              updateStatelead3("priority", "medium");
            }}
            className={
              leaddata.priority === "medium"
                ? classes.Requirements
                : classes.Requirementsqfts
            }
          >
            Medium
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography
            onClick={() => {
              updateStatelead3("priority", "low");
            }}
            className={
              leaddata.priority === "low"
                ? classes.Requirements
                : classes.Requirementsqfts
            }
          >
            Low
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography style={{ fontSize: "14px", color: "red" }}>
            {leaddata?.error?.priority}
          </Typography>
        </Grid>
        {/* Notes */}
        <Grid item xs={12} className={classes.field}>
          <TextBox
            label="Notes"
            placeholder=" "
            multiline
            onChange={(value) => updateStatelead3("notes", value.target.value)}
            value={leaddata.notes}
            isError={leaddata?.error?.notes?.length > 0}
            errorMessage={leaddata?.error?.notes}
          />
        </Grid>
        {/* Lead owner */}
        <br />
        <br />
      </Grid>
      <br />
      <Hidden smDown>
        <Dialog
          onClose={() => {
            setOpen(!open);
          }}
          className={classes.dialog}
          open={open}
          maxWidth="sm"
          fullWidth="true"
        >
          <Box
            display="flex"
            className={classes.budgeroot}
            p={1}
            alignItems="center"
          >
            <Box flexGrow={1}>
              <Typography className={classes.budgettitle}>Budget</Typography>
            </Box>
            <Box>
              <IconButton
                size="small"
                className={classes.close}
                onClick={() => {
                  setOpen(!open);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          <Divider />

          <div className={classes.rangeroot}>
            <Typography className={classes.budgetrange}>
              Budget Range
            </Typography>
            {leaddata?.budget.length > 0 && (
              <>
                <Typography className={classes.budgetvalue}>
                  {new Intl.NumberFormat("en-IN", {
                    style: "currency",
                    currency: "INR",
                    minimumFractionDigits: 0,
                  }).format(Math.round(leaddata?.budget[0]))}{" "}
                  -{" "}
                  {new Intl.NumberFormat("en-IN", {
                    style: "currency",
                    currency: "INR",
                    minimumFractionDigits: 0,
                  }).format(Math.round(leaddata?.budget[1]))}
                </Typography>
              </>
            )}

            <br />
            <RangeSlider
              min={0}
              max={50000}
              value={leaddata?.budget}
              onChange={(value) => updateStatelead3("budget", value)}
            />
            <Box display="flex" alignItems="center">
              <Box flexGrow={1}>
                <Typography className={classes.slidervalue}>$ 0</Typography>
              </Box>
              <Box>
                <Typography className={classes.slidervalue}>
                  $ 50,000
                </Typography>
              </Box>
            </Box>
            <br />
            <Button
              fullWidth
              variant="contained"
              onClick={() => {
                setOpen(!open);
              }}
              style={{ padding: "12px", borderRadius: "16px" }}
            >
              Save
            </Button>
          </div>
        </Dialog>
      </Hidden>
      <Hidden smUp>
        <Drawer
          onClose={() => {
            setOpen(!open);
          }}
          className={classes.drawer}
          anchor="bottom"
          open={open}
        >
          <Box
            display="flex"
            className={classes.budgeroot}
            p={1}
            alignItems="center"
          >
            <Box flexGrow={1}>
              <Typography className={classes.budgettitle}>Budget</Typography>
            </Box>
            <Box>
              <IconButton
                size="small"
                className={classes.close}
                onClick={() => {
                  setOpen(!open);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          <Divider />

          <div className={classes.rangeroot}>
            <Typography className={classes.budgetrange}>
              Budget Range
            </Typography>
            {leaddata?.budget.length > 0 && (
              <>
                <Typography className={classes.budgetvalue}>
                  {new Intl.NumberFormat("en-IN", {
                    style: "currency",
                    currency: "INR",
                    minimumFractionDigits: 0,
                  }).format(Math.round(leaddata?.budget[0]))}{" "}
                  -{" "}
                  {new Intl.NumberFormat("en-IN", {
                    style: "currency",
                    currency: "INR",
                    minimumFractionDigits: 0,
                  }).format(Math.round(leaddata?.budget[1]))}
                </Typography>
              </>
            )}

            <br />
            <RangeSlider
              min={0}
              max={50000}
              value={leaddata?.budget}
              onChange={(value) => updateStatelead3("budget", value)}
            />
            <Box display="flex" alignItems="center">
              <Box flexGrow={1}>
                <Typography className={classes.slidervalue}>$ 0</Typography>
              </Box>
              <Box>
                <Typography className={classes.slidervalue}>
                  $ 50,000
                </Typography>
              </Box>
            </Box>
            <br />
            <Button
              fullWidth
              variant="contained"
              onClick={() => {
                setOpen(!open);
              }}
              style={{ padding: "12px", borderRadius: "16px" }}
            >
              Save
            </Button>
          </div>
        </Drawer>
      </Hidden>
    </>
  );
};
