import { Box, Hidden, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

const useStyles = makeStyles((theme) => ({
  propertyName: {
    color: "white",
    fontFamily: "tenant_bold",
    fontSize: "18px",

    marginLeft: "10px",
    [theme.breakpoints.up("sm")]: {
      marginTop: "10px",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "0px",
    },
  },
  propertyDetail: {
    fontSize: "12px",
    color: "white",
    textTransform: "capitalize",
    marginTop: "10px",
    fontFamily: "tenant_bold",
  },
  productDetails: {
    backgroundColor: "#08080862",

    borderBottomLeftRadius: "20px",
    borderBottomRightRadius: "20px",
    width: "100%",
    padding: "12px",
  },
  propertyimage: {
    objectFit: "contain",
    borderRadius: "20px",
  },
  propertyDetailsroot: {
    backgroundImage: (props) => `url(${props.data.img})`,
    [theme.breakpoints.down("sm")]: {
      width: "250px",
      marginLeft: "10px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "400px",
      marginLeft: "12px",
    },
    borderRadius: "20px",
  },
  till: {
    position: "absolute",
    top: "0px",
    right: "0px",
    color: "white",
    padding: "12px",
    fontSize: "12px",
    borderTopRightRadius: "10px",
    borderBottomLeftRadius: "10px",
    backgroundColor: "#080808ad",
    bottom: "20px",
  },
}));

export const AgreementCard = (props) => {
  const classes = useStyles(props);

  return (
    <div className={classes.propertyDetailsroot}>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <Hidden smDown>
        <br />
      </Hidden>
      <div className={classes.productDetails}>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>
            <Typography className={classes.propertyName}>
              {props.data.title}
            </Typography>
          </Box>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          marginTop="18px"
          p={1}
        >
          <Box>
            <center>
              <img src="/images/units.svg" alt="units" />
              <Typography className={classes.propertyDetail}>
                {props.data.units}&nbsp;units
              </Typography>
            </center>
          </Box>
          <Box>
            <center>
              <img src="/images/sqft.svg" alt="sqft" />
              <Typography className={classes.propertyDetail}>
                {props.data.sqft}&nbsp;sqft
              </Typography>
            </center>
          </Box>
          <Box>
            <center>
              <img src="/images/rent.svg" alt={props.data.type} />
              <Typography className={classes.propertyDetail}>
                {props.data.type}
              </Typography>
            </center>
          </Box>
        </Box>
      </div>
    </div>
  );
};
