import React from "react";
import { SetPassword } from "./setPassword";

class SetPasswordParent extends React.Component {
    render() {
        return <SetPassword />;
    }
}

export default (SetPasswordParent);
