import CloseIcon from "@mui/icons-material/Close";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import {
  Avatar,
  Box,
  Button,
  Dialog,
  Drawer,
  Grid,
  Hidden,
  IconButton,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import {
  MobileNumberInputComponent,
  SelectBox,
  TextBox,
} from "../../components";
import { city, country, state } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "14px",
  },

  profileimg: {
    backgroundColor: theme.palette.background.tertiary,
    color: "#E4E8EE",
    position: "relative",
  },
  profileicon: {
    fontSize: "80px",
    padding: "14px",
  },
  profileimgs: {
    backgroundColor: theme.palette.background.tertiary,
    color: "#E4E8EE",
    position: "absolute",
    bottom: "0px",
    right: "0px",
    border: "2px solid white",
    borderRadius: "50%",
  },

  profilesection: {
    marginTop: "14px",
  },
  field: {
    marginTop: "10px",
  },
  address: {
    marginTop: "10px",
    borderBottom: "4px solid #F5F7FA",
    borderTop: "4px solid #F5F7FA",
    marginBottom: "10px",
    padding: "8px 0px",
  },
  addAddress: {
    fontSize: "14px",
    fontFamily: "tenant_bold",
    color: "#5078E1",
    cursor: "pointer",
  },
  addAddresserr: {
    fontSize: "14px",
    fontFamily: "tenant_bold",
    color: "red",
    cursor: "pointer",
  },
  addresstitle: {
    fontSize: "16px",
    fontFamily: "tenant_bold",
  },
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopLeftRadius: "16px",
      borderTopRightRadius: "16px",
      width: "100vw",
    },
    "& .MuiContainer-root": {
      padding: "0px",
      width: "100vw",
    },
  },
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: "16px",
      padding: "0px",
    },
  },
  titleroot: {
    borderBottom: "1px solid #c1c1c1",
    paddingBottom: "8px",
    marginBottom: "8px",
  },
  addressFieldsContainerStyle: {
    padding: "8px",
  },
  addAddressButtonContainerStyle: {
    padding: "16px",
    background: "white",
    border: "1px solid #E4E8EE",
    boxShadow: "0px -7px 20px #0717410D",
    // position: "fixed",
    width: "-webkit-fill-available",
  },
  iconButtonStyle: {
    borderRadius: "10px",
    padding: "12px 8px",
  },
  screenScrollEffect: {
    overflow: "overlay",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  imgdiv: {
    position: "relative",
    display: "inline-block",
    borderRadius: "50%",
  },
  close: {
    position: "absolute",
    right: "0px",
    top: "0px",
    backgroundColor: "red",
    borderRadius: "50%",
    color: "white",
    fontSize: "20px",
  },
  addressList: {
    fontSize: "12px",
    color: "#98A0AC",
    fontFamily: "tenant_semiBold",
    marginTop: "26px",
  },
  addresstitles: {
    fontSize: "14px",
    color: "#091B29",
    fontFamily: "tenant_bold",
  },
  primary: {
    fontSize: "8px",
    padding: "2px 4px 1px 4px",
    backgroundColor: "#071741",
    borderRadius: "4px",
    color: "white",
    fontFamily: "tenant_extraBold",
  },
  addressbox: {
    padding: "8px",
    border: "1px solid #071741",
    borderRadius: "8px",
    position: "relative",
  },
  addressChid: {
    position: "absolute",
    top: "10px",
    left: "0px",
  },
}));
export const Step1 = (props) => {
  const classes = useStyles();
  const size = useWindowDimensions();
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <form autoComplete="off">
        <Grid container className={classes.root}>
          <Grid item xs={12} className={classes.profilesection}>
            <center>
              {props.data.image ? (
                <div className={classes.imgdiv}>
                  <img
                    style={{
                      height: "100px",
                      width: "100px",
                      objectFit: "cover",
                      borderRadius: "50%",
                    }}
                    alt="-"
                    src={
                      props.already
                        ? props.data.image
                        : URL.createObjectURL(props.data.image)
                    }
                    className={classes.profileicons}
                  />
                  {props.already ? (
                    ""
                  ) : (
                    <CloseIcon
                      className={classes.close}
                      onClick={() => {
                        props.updateState("image", "");
                      }}
                    />
                  )}
                </div>
              ) : (
                <IconButton className={classes.profileimg}>
                  <PersonOutlineIcon className={classes.profileicon} />
                  <label htmlFor="uplodebtn">
                    <Avatar
                      sx={{ width: 24, height: 24 }}
                      className={classes.profileimgs}
                      type="file"
                    >
                      <img
                        src="/images/icons8-camera (1).svg"
                        alt="-"
                        className={classes.profileicons}
                      />
                    </Avatar>
                  </label>
                  <input
                    type="file"
                    name="img"
                    accept="image/*"
                    style={{ display: "none" }}
                    id="uplodebtn"
                    onChange={(e) => {
                      props.updateState("image", e.target.files[0]);
                    }}
                  />
                </IconButton>
              )}
            </center>
          </Grid>

          <Grid item xs={12}>
            <TextBox
              label="First Name"
              placeholder=""
              disabled={props.already}
              onChange={(value) =>
                props.updateState("first_name", value.target.value)
              }
              isError={props?.data?.error?.first_name?.length > 0}
              errorMessage={props?.data?.error?.first_name}
              value={props?.data?.first_name}
            />
          </Grid>
          <Grid item xs={12}>
            <TextBox
              disabled={props.already}
              label="Last Name"
              placeholder=""
              onChange={(value) =>
                props.updateState("last_name", value.target.value)
              }
              isError={props?.data?.error?.last_name?.length > 0}
              errorMessage={props?.data?.error?.last_name}
              value={props?.data?.last_name}
            />
          </Grid>
          <Grid item xs={12} className={classes.field}>
            <MobileNumberInputComponent
              label="Mobile"
              disabled={props.already}
              placeholder=""
              handleChange={(value) => props.updateState("mobile", value)}
              isError={props?.data?.error?.mobile?.length > 0}
              errorMessage={props?.data?.error?.mobile}
              value={props?.data?.mobile}
            />
          </Grid>
          <Grid item xs={12} className={classes.field}>
            <MobileNumberInputComponent
              label="Alternative Mobile"
              placeholder=""
              handleChange={(value) => props.updateState("altmobile", value)}
              value={props.data.altmobile}
            />
          </Grid>
          <Grid item xs={12} className={classes.field}>
            <TextBox
              label="Email Address"
              placeholder=""
              disabled={props.already}
              onChange={(value) =>
                props.updateState("email", value.target.value)
              }
              isError={props?.data?.error?.email?.length > 0}
              errorMessage={props?.data?.error?.email}
              value={props?.data?.email}
            />
          </Grid>
          {!props.already && (
            <Grid item xs={12} className={classes.address}>
              <Box display="flex" alignItems="center" p={1}>
                <Box flexGrow={1}>
                  <Typography className={classes.addresstitle}>
                    Address
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    className={classes.addAddress}
                    onClick={() => {
                      setOpen(!open);
                    }}
                  >
                    Add Address
                  </Typography>
                </Box>
              </Box>

              {props?.data?.street && (
                <div className={classes.addressbox}>
                  <Box
                    className={classes.addressChid}
                    display="flex"
                    alignItems="center"
                  >
                    <Box>
                      <Typography className={classes.primary}>
                        PRIMARY
                      </Typography>
                    </Box>
                    <Box flexGrow={1} marginLeft="14px">
                      <Typography className={classes.addresstitles}>
                        {props?.data?.first_name}&nbsp;{props?.data?.city.value}
                      </Typography>
                    </Box>
                  </Box>
                  <Typography className={classes.addressList}>
                    {props?.data?.street + ","}
                    {props?.data?.address2 + ","}
                    {props?.data?.address3 + ","}
                    {props?.data?.city?.value + ","}
                    {props?.data?.state?.value + ","}
                    {props?.data?.country?.value + "-"}
                    {props?.data?.pincode + "."}
                  </Typography>
                </div>
              )}
            </Grid>
          )}

          <Grid item xs={12} className={classes.field}>
            <Typography
              className={classes.addresstitle}
              style={{ marginTop: props.already ? "10px" : "auto" }}
            >
              Social Profile
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.field}>
            <TextBox
              label="Facebook"
              placeholder="Enter facebook id"
              onChange={(value) =>
                props.updateState("facebook", value.target.value)
              }
              value={props.data.facebook}
            />
          </Grid>
          <Grid item xs={12} className={classes.field}>
            <TextBox
              label="Twitter"
              placeholder="Enter Twitter id"
              onChange={(value) =>
                props.updateState("twitter", value.target.value)
              }
              value={props.data.twitter}
            />
          </Grid>
          <Grid item xs={12} className={classes.field}>
            <TextBox
              label="Linkdedin"
              placeholder="Enter Linkdedin id"
              onChange={(value) =>
                props.updateState("linkedin", value.target.value)
              }
              value={props.data.linkedin}
            />
          </Grid>
        </Grid>
      </form>
      <br />
      <br />
      <Hidden smDown>
        <Dialog
          maxWidth="sm"
          fullWidth
          open={open}
          onClose={() => {
            setOpen(!open);
          }}
          className={classes.dialog}
        >
          <div>
            <Box
              display="flex"
              className={classes.titleroot}
              p={1}
              alignItems="center"
            >
              <Box flexGrow={1}>
                <Typography
                  style={{
                    fontFamily: "tenant_bold",
                    fontSize: "16px",
                    color: "#091B29",
                  }}
                >
                  Add Address
                </Typography>
              </Box>
              <Box>
                <IconButton size="small" onClick={() => setOpen(!open)}>
                  <CloseIcon color="secondary" />
                </IconButton>
              </Box>
            </Box>

            <Grid
              className={classes.screenScrollEffect}
              style={{ height: size?.height - 250 }}
            >
              <Grid className={classes.addressFieldsContainerStyle}>
                <Grid>
                  <TextBox
                    label="Address Line 1"
                    placeholder="Enter Address Line 1"
                    onChange={(e) =>
                      props.updateState("street", e.target.value)
                    }
                    value={props.data.street}
                  />
                  <Box height="16px" />
                  <TextBox
                    label="Address Line 2"
                    placeholder="Enter Address Line 2"
                    onChange={(e) =>
                      props.updateState("address2", e.target.value)
                    }
                    value={props.data.address2}
                  />
                  <Box height="16px" />
                  <TextBox
                    label="Address Line 3"
                    placeholder="Enter Address Line 3"
                    onChange={(e) =>
                      props.updateState("address3", e.target.value)
                    }
                    value={props.data.address3}
                  />
                  <Box height="16px" />
                  <Grid container direction={"row"}>
                    <Grid xs={6} style={{ paddingLeft: "8px" }}>
                      <SelectBox
                        label="City"
                        placeholder="Select City"
                        onChange={(value) => props.updateState("city", value)}
                        value={props.data.city}
                        options={city}
                        createSelect={true}
                      />
                    </Grid>
                    <Grid xs={6} style={{ paddingLeft: "8px" }}>
                      <SelectBox
                        label="State"
                        onChange={(value) => props.updateState("state", value)}
                        value={props.data.state}
                        placeholder="Select State"
                        options={state}
                        createSelect={true}
                      />
                    </Grid>
                  </Grid>
                  <Box height="16px" />
                  <Grid container direction={"row"}>
                    <Grid xs={6} style={{ paddingLeft: "8px" }}>
                      <SelectBox
                        label="Country"
                        onChange={(value) =>
                          props.updateState("country", value)
                        }
                        value={props.data.country}
                        placeholder="Select Country"
                        options={country}
                        createSelect={true}
                      />
                    </Grid>
                    <Grid xs={6} style={{ paddingLeft: "8px" }}>
                      <TextBox
                        label="Pincode"
                        fullWidth
                        placeholder="Enter Pincode"
                        onChange={(e) =>
                          props.updateState("pincode", e.target.value)
                        }
                        value={props.data.pincode}
                      />
                    </Grid>
                  </Grid>
                  <Box height="16px" />
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={12} className={classes.addAddressButtonContainerStyle}>
              <Button
                variant="contained"
                fullWidth
                className={classes.iconButtonStyle}
                onClick={() => {
                  setOpen(!open);
                }}
              >
                <Typography className={classes.addAddressButtonTextStyle}>
                  {"Add Address"}
                </Typography>
              </Button>
            </Grid>
          </div>
        </Dialog>
      </Hidden>
      <Hidden smUp>
        <Drawer
          anchor="bottom"
          open={open}
          onClose={() => {
            setOpen(!open);
          }}
          className={classes.drawer}
        >
          <div>
            <Box
              display="flex"
              className={classes.titleroot}
              p={1}
              alignItems="center"
            >
              <Box flexGrow={1}>
                <Typography
                  style={{
                    fontFamily: "tenant_bold",
                    fontSize: "16px",
                    color: "#091B29",
                  }}
                >
                  Add Address
                </Typography>
              </Box>
              <Box>
                <IconButton size="small" onClick={() => setOpen(!open)}>
                  <CloseIcon color="secondary" />
                </IconButton>
              </Box>
            </Box>

            <Grid
              className={classes.screenScrollEffect}
              style={{ height: size?.height - 250 }}
            >
              <Grid className={classes.addressFieldsContainerStyle}>
                <Grid>
                  <TextBox
                    label="Address Line 1"
                    placeholder="Enter Address Line 1"
                    onChange={(e) =>
                      props.updateState("street", e.target.value)
                    }
                    value={props.data.street}
                  />
                  <Box height="16px" />
                  <TextBox
                    label="Address Line 2"
                    placeholder="Enter Address Line 2"
                    onChange={(e) =>
                      props.updateState("address2", e.target.value)
                    }
                    value={props.data.address2}
                  />
                  <Box height="16px" />
                  <TextBox
                    label="Address Line 3"
                    placeholder="Enter Address Line 3"
                    onChange={(e) =>
                      props.updateState("address3", e.target.value)
                    }
                    value={props.data.address3}
                  />
                  <Box height="16px" />
                  <Grid container direction={"row"}>
                    <Grid xs={6} style={{ paddingLeft: "8px" }}>
                      <SelectBox
                        label="City"
                        placeholder="Select City"
                        onChange={(value) => props.updateState("city", value)}
                        value={props.data.city}
                        options={city}
                        createSelect={true}
                      />
                    </Grid>
                    <Grid xs={6} style={{ paddingLeft: "8px" }}>
                      <SelectBox
                        label="State"
                        onChange={(value) => props.updateState("state", value)}
                        value={props.data.state}
                        placeholder="Select State"
                        options={state}
                        createSelect={true}
                      />
                    </Grid>
                  </Grid>
                  <Box height="16px" />
                  <Grid container direction={"row"}>
                    <Grid xs={6} style={{ paddingLeft: "8px" }}>
                      <SelectBox
                        label="Country"
                        onChange={(value) =>
                          props.updateState("country", value)
                        }
                        value={props.data.country}
                        placeholder="Select Country"
                        options={country}
                        createSelect={true}
                      />
                    </Grid>
                    <Grid xs={6} style={{ paddingLeft: "8px" }}>
                      <TextBox
                        label="Pincode"
                        fullWidth
                        placeholder="Enter Pincode"
                        onChange={(e) =>
                          props.updateState("pincode", e.target.value)
                        }
                        value={props.data.pincode}
                      />
                    </Grid>
                  </Grid>
                  <Box height="16px" />
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={12} className={classes.addAddressButtonContainerStyle}>
              <Button
                variant="contained"
                fullWidth
                className={classes.iconButtonStyle}
                onClick={() => {
                  setOpen(!open);
                }}
              >
                <Typography className={classes.addAddressButtonTextStyle}>
                  {"Add Address"}
                </Typography>
              </Button>
            </Grid>
          </div>
        </Drawer>
      </Hidden>
    </>
  );
};
