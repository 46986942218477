import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Dialog,
  Divider,
  Drawer,
  Grid,
  Hidden,
  IconButton,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Box } from "@mui/system";
import React from "react";
import { useHistory } from "react-router-dom";
import { DateTime, QuotationList, TextBox } from "../../../components";
import { config } from "../../../config";
import { CLOSELEAD } from "../../../graphql/mutations";
import { NetworkCall } from "../../../networkcall/index";
import { Routes } from "../../../router/routes";
import { NetWorkCallMethods } from "../../../utils";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: "8px 16px 8px 16px",
    backgroundColor: "white",
  },
  closeTheLeadButtonStyle: {
    padding: "12px 0px 12px 0px",
    border: "1px solid",
    borderColor: theme.palette.primary.main,
    borderRadius: "8px",
    backgroundColor: "#FFFFFF",
    height: "47px",
    "&:hover": {
      backgroundColor: "#e5ecff",
    },
  },
  closeTheLeadButtonTextStyle: {
    fontSize: "14px",
    fontFamily: "tenant_bold",
    color: theme.palette.primary.main,
  },
  requestForReserveButtonStyle: {
    padding: "12px 0px 12px 0px",
    border: "1px solid",
    borderColor: theme.palette.primary.main,
    borderRadius: "8px",
    backgroundColor: theme.palette.primary.main,
    height: "47px",
    "&:hover": {
      backgroundColor: "#5685ff",
    },
  },
  requestForReserveButtonTextStyle: {
    fontSize: "14px",
    fontFamily: "tenant_bold",
    color: "#FFFFFF",
  },
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopRightRadius: "16px",
      borderTopLeftRadius: "16px",
    },
    "& .MuiContainer-root": {
      padding: "0px 8px",
    },
  },
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: "16px",
      padding: "0px",
    },
  },
  bottomSheetStyle: {
    background: "white",
    borderRadius: "16px 16px 0px 0px",
    border: "1px solid #E4E8EE",
    justifyContent: "center",
  },
  dialogStyle: {
    background: "white",
    borderRadius: "16px",
    border: "1px solid #E4E8EE",
    justifyContent: "center",
  },
  drawerHeaderStyle: {
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px",
  },
  drawerHeaderTextStyle: {
    fontSize: "16px",
    fontWeight: "bolder",
    color: theme.typography.color.primary,
  },
  drawerBodyStyle: {
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px",
  },
  drawerFooterStyle: {
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px",
  },
  drawerButtonStyle: {
    "&:hover": "none",
    borderRadius: "8px",
    padding: "14px",
    background: theme.palette.primary.main,
    fontSize: "14px",
    fontWeight: "normal",
    color: "#FFF",
  },
  labelStyle: {
    fontSize: "12px",
    fontFamily: "tenant_semiBold",
    color: theme.typography.color.Tertiary,
  },
  unselectedButtonStyle: {
    backgroundColor: "white",
    border: "1px solid",
    borderColor: theme.palette.border.secondary,
    padding: "12px 16px",
    margin: "0px 8px 0px 0px",
    borderRadius: "10px",
  },
  unselectedButtonTextStyle: {
    fontSize: "14px",
    fontFamily: "tenant_semiBold",
    color: theme.typography.color.primary,
    cursor: "pointer",
  },
  selectedButtonStyle: {
    backgroundColor: theme.palette.primary.main,
    border: "1px solid",
    borderColor: theme.palette.primary.main,
    padding: "12px 16px",
    margin: "0px 8px 0px 0px",
    borderRadius: "10px",
    cursor: "pointer",
  },
  selectedButtonTextStyle: {
    fontSize: "14px",
    fontFamily: "tenant_semiBold",
    color: "white",
    cursor: "pointer",
  },
}));

const reducer = (state, action) => {
  let invoicesCartCopy = {
    ...state,
  };
  switch (action.type) {
    case "ADD":
      //to add that invoice to the invoices key
      invoicesCartCopy.invoices = [
        ...invoicesCartCopy.invoices,
        action.payload,
      ];
      //to increment the total value
      invoicesCartCopy.total =
        invoicesCartCopy.total + parseFloat(action.payload.amount);

      return invoicesCartCopy;
    case "REMOVE":
      //to remove that invoice to the invoices key
      let invoices = [...invoicesCartCopy.invoices];
      const result = invoices.filter(
        (invoice) => invoice.id !== action.payload.id
      );
      invoicesCartCopy.invoices = result;
      //to decrement the total value
      invoicesCartCopy.total =
        invoicesCartCopy.total - parseFloat(action.payload.amount);

      return invoicesCartCopy;
    default:
      return state;
  }
};
const Initialstate = {
  closestatus: "",
  closedate: "",
  remarks: "",

  error: {
    closestatus: "",
    closedate: "",
    remarks: "",
  },
};
export const BottomActionButton = (props) => {
  const classes = useStyles();
  const [isCloseLead, setIsCloseLeadOpen] = React.useState(false);
  const [data, setData] = React.useState({ ...Initialstate });
  const history = useHistory();

  const updateState = (key, value) => {
    let error = data.error;
    error[key] = "";
    setData({ ...data, [key]: value, error });
  };
  const isIamValide = () => {
    let isValid = true;
    let error = data.error;

    //Checking Role
    if (data.closestatus.length === 0) {
      isValid = false;
      error.closestatus = "Close Status  is Required";
    }
    //Checking name
    if (data.closedate.length === 0) {
      isValid = false;
      error.closedate = "Close Date is Required";
    }
    if (data.remarks.length === 0) {
      isValid = false;
      error.remarks = "Reson is Required";
    }
    setData({ ...data, error });

    return isValid;
  };
  const closeCloseLead = () => {
    if (isIamValide()) {
      const variables = {
        query: CLOSELEAD,
        variables: {
          leadID: props?.leadId,
          updatePayload: {
            lead_closure_status: data.closestatus,
            reason: data.remarks,
            status: 2,
            closed_by: localStorage.getItem("userProfileId"),
            closed_on: data.closedate,
            is_active: false,
          },
        },
      };
      NetworkCall(
        `${config.graphql_url}`,
        NetWorkCallMethods.post,
        variables,
        null,
        true,
        false
      )
        .then((response) => {
          setIsCloseLeadOpen(false);
          history.push(Routes.lead);
        })

        .catch((error) => {
          console.log(error);
        });
    } else {
      return false;
    }
  };
  const openCloseLead = () => {
    setIsCloseLeadOpen(true);
  };
  const [isDisqualify, setIsDisqualify] = React.useState(false);
  const handleDisqualifyLeadClosingStatus = () => {
    setIsDisqualify(!isDisqualify);
    setIsPostpone(false);
  };
  const [isPostpone, setIsPostpone] = React.useState(false);
  const handlePostponeLeadClosingStatus = () => {
    setIsDisqualify(false);
    setIsPostpone(!isPostpone);
  };

  const [isRequestReserve, setIsRequestReserveOpen] = React.useState(false);
  const closeRequestReserve = () => {
    setIsRequestReserveOpen(false);
  };
  const openRequestReserve = () => {
    setIsRequestReserveOpen(true);
  };
  const [invoiceCart, setInvoiceCart] = React.useReducer(reducer, {
    invoices: [],
    total: 0,
  });

  const InvoiceData = [
    {
      id: 0,
      type: "invoice",
      title: "2344",
      requestId: "K-F01-U25",
      dueDate: "Due 22 Nov",
      amount: "32.00",
      status: "Requested for visit",
    },
    {
      id: 1,
      type: "invoice",
      title: "2344",
      requestId: "K-F01-U25",
      dueDate: "Due 22 Nov",
      amount: "32.0000",
      status: "Requested for re-quote",
    },
    {
      id: 2,
      type: "invoice",
      title: "2344",
      requestId: "K-F01-U25",
      dueDate: "Due 22 Nov",
      amount: "32.00",
      status: "Quote Accepted",
    },
  ];

  return (
    <>
      {props?.active === "true" && (
        <Grid
          className={classes.root}
          container
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Grid xs={6}>
            <Grid style={{ padding: "0px 8px 0px 0px" }}>
              <Button
                onClick={openCloseLead}
                className={classes.closeTheLeadButtonStyle}
                fullWidth
              >
                <Typography className={classes.closeTheLeadButtonTextStyle}>
                  Close The Lead
                </Typography>
              </Button>
            </Grid>
          </Grid>
          <Grid xs={6}>
            <Grid style={{ padding: "0px 0px 0px 8px" }}>
              <Button
                onClick={openRequestReserve}
                className={classes.requestForReserveButtonStyle}
                fullWidth
              >
                <Typography
                  className={classes.requestForReserveButtonTextStyle}
                >
                  Request For Reserve
                </Typography>
              </Button>
            </Grid>
          </Grid>
          <Hidden smDown>
            <Dialog
              className={classes.dialog}
              open={isCloseLead}
              fullWidth={true}
              maxWidth="sm"
              onClose={() => setIsCloseLeadOpen(false)}
            >
              <Grid
                container
                direction="column"
                className={classes.dialogStyle}
              >
                <Grid
                  container
                  direction="row"
                  className={classes.drawerHeaderStyle}
                >
                  <Grid>
                    <Typography className={classes.drawerHeaderTextStyle}>
                      Close The Lead
                    </Typography>
                  </Grid>
                  <Grid>
                    <IconButton
                      style={{
                        padding: "0px",
                      }}
                      onClick={() => setIsCloseLeadOpen(false)}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                </Grid>
                <Divider />
                <Grid className={classes.drawerBodyStyle}>
                  <Typography className={classes.labelStyle}>
                    Lead Closing Status
                  </Typography>
                  <Box height={"8px"} />
                  <Grid container direction={"row"}>
                    <Box
                      onClick={() => {
                        updateState("closestatus", "Disqualify");
                        handleDisqualifyLeadClosingStatus();
                      }}
                      className={
                        isDisqualify
                          ? classes.selectedButtonStyle
                          : classes.unselectedButtonStyle
                      }
                    >
                      <Typography
                        className={
                          isDisqualify
                            ? classes.selectedButtonTextStyle
                            : classes.unselectedButtonTextStyle
                        }
                      >
                        Disqualify
                      </Typography>
                    </Box>
                    <Box
                      onClick={() => {
                        updateState("closestatus", "Postpone");
                        handlePostponeLeadClosingStatus();
                      }}
                      className={
                        isPostpone
                          ? classes.selectedButtonStyle
                          : classes.unselectedButtonStyle
                      }
                    >
                      <Typography
                        className={
                          isPostpone
                            ? classes.selectedButtonTextStyle
                            : classes.unselectedButtonTextStyle
                        }
                      >
                        Postpone
                      </Typography>
                    </Box>
                  </Grid>
                  {data?.error?.closestatus?.length > 0 && (
                    <Typography
                      style={{
                        color: "red",
                        fontSize: "10px",
                        marginTop: "8px",
                      }}
                    >
                      {data?.error?.closestatus}
                    </Typography>
                  )}
                  <Box height={"16px"} />
                  <DateTime
                    title="Closing Date"
                    placeholder=""
                    onChange={(value) => {
                      updateState("closedate", value);
                    }}
                    value={data.closedate}
                  />
                  {data?.error?.closedate?.length > 0 && (
                    <Typography
                      style={{
                        color: "red",
                        fontSize: "10px",
                        marginTop: "8px",
                      }}
                    >
                      {data?.error?.closedate}
                    </Typography>
                  )}
                  <Box height={"16px"} />
                  <TextBox
                    label="Reason"
                    multiline
                    placeholder="Enter Your Reson"
                    onChange={(e) => {
                      updateState("remarks", e.target.value);
                    }}
                    value={data.remarks}
                    isError={data?.error?.remarks?.length > 0}
                    errorMessage={data?.error?.remarks}
                  />
                </Grid>
                <Divider />
                <Grid className={classes.drawerFooterStyle}>
                  <Button
                    fullWidth
                    className={classes.drawerButtonStyle}
                    variant="contained"
                    onClick={closeCloseLead}
                  >
                    Close Lead
                  </Button>
                </Grid>
              </Grid>
            </Dialog>
          </Hidden>
          <Hidden smUp>
            <Drawer
              className={classes.drawer}
              anchor="bottom"
              open={isCloseLead}
              onClose={() => setIsCloseLeadOpen(false)}
            >
              <Grid
                container
                direction="column"
                className={classes.bottomSheetStyle}
              >
                <Grid
                  container
                  direction="row"
                  className={classes.drawerHeaderStyle}
                >
                  <Grid>
                    <Typography className={classes.drawerHeaderTextStyle}>
                      Close The Lead
                    </Typography>
                  </Grid>
                  <Grid>
                    <IconButton
                      style={{
                        padding: "0px",
                      }}
                      onClick={() => setIsCloseLeadOpen(false)}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                </Grid>
                <Divider />
                <Grid className={classes.drawerBodyStyle}>
                  <Typography className={classes.labelStyle}>
                    Lead Closing Status
                  </Typography>
                  <Box height={"8px"} />
                  <Grid container direction={"row"}>
                    <Box
                      onClick={() => {
                        updateState("closestatus", "Disqualify");
                        handleDisqualifyLeadClosingStatus();
                      }}
                      className={
                        isDisqualify
                          ? classes.selectedButtonStyle
                          : classes.unselectedButtonStyle
                      }
                    >
                      <Typography
                        className={
                          isDisqualify
                            ? classes.selectedButtonTextStyle
                            : classes.unselectedButtonTextStyle
                        }
                      >
                        Disqualify
                      </Typography>
                    </Box>
                    <Box
                      onClick={() => {
                        updateState("closestatus", "Postpone");
                        handlePostponeLeadClosingStatus();
                      }}
                      className={
                        isPostpone
                          ? classes.selectedButtonStyle
                          : classes.unselectedButtonStyle
                      }
                    >
                      <Typography
                        className={
                          isPostpone
                            ? classes.selectedButtonTextStyle
                            : classes.unselectedButtonTextStyle
                        }
                      >
                        Postpone
                      </Typography>
                    </Box>
                  </Grid>
                  {data?.error?.closestatus?.length > 0 && (
                    <Typography
                      style={{
                        color: "red",
                        fontSize: "10px",
                        marginTop: "8px",
                      }}
                    >
                      {data?.error?.closestatus}
                    </Typography>
                  )}
                  <Box height={"16px"} />
                  <DateTime
                    title="Closing Date"
                    placeholder=""
                    onChange={(value) => {
                      updateState("closedate", value);
                    }}
                    value={data.closedate}
                  />
                  {data?.error?.closedate?.length > 0 && (
                    <Typography
                      style={{
                        color: "red",
                        fontSize: "10px",
                        marginTop: "8px",
                      }}
                    >
                      {data?.error?.closedate}
                    </Typography>
                  )}
                  <Box height={"16px"} />
                  <TextBox
                    label="Reason"
                    multiline
                    placeholder="Enter Your Reson"
                    onChange={(e) => {
                      updateState("remarks", e.target.value);
                    }}
                    value={data.remarks}
                    isError={data?.error?.remarks?.length > 0}
                    errorMessage={data?.error?.remarks}
                  />
                </Grid>
                <Divider />
                <Grid className={classes.drawerFooterStyle}>
                  <Button
                    fullWidth
                    className={classes.drawerButtonStyle}
                    variant="contained"
                    onClick={closeCloseLead}
                  >
                    Close Lead
                  </Button>
                </Grid>
              </Grid>
            </Drawer>
          </Hidden>

          <Hidden smDown>
            <Dialog
              className={classes.dialog}
              open={isRequestReserve}
              fullWidth={true}
              maxWidth="sm"
              onClose={closeRequestReserve}
            >
              <Grid
                container
                direction="column"
                className={classes.dialogStyle}
              >
                <Grid
                  container
                  direction="row"
                  className={classes.drawerHeaderStyle}
                >
                  <Grid>
                    <Typography className={classes.drawerHeaderTextStyle}>
                      Request for reserve
                    </Typography>
                  </Grid>
                  <Grid>
                    <IconButton
                      style={{
                        padding: "0px",
                      }}
                      onClick={closeRequestReserve}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                </Grid>
                <Divider />
                <Grid className={classes.drawerBodyStyle}>
                  {InvoiceData.map((item) => {
                    return (
                      <React.Fragment key={item.id}>
                        <Grid item xs={12}>
                          <QuotationList
                            setInvoiceCart={setInvoiceCart}
                            invoiceCart={invoiceCart}
                            invoiceData={item}
                          />
                        </Grid>
                      </React.Fragment>
                    );
                  })}
                </Grid>
                <Divider />
                <Grid className={classes.drawerFooterStyle}>
                  <Button
                    fullWidth
                    className={classes.drawerButtonStyle}
                    variant="contained"
                    onClick={closeRequestReserve}
                  >
                    Request For Reserve
                  </Button>
                </Grid>
              </Grid>
            </Dialog>
          </Hidden>
          <Hidden smUp>
            <Drawer
              className={classes.drawer}
              anchor="bottom"
              open={isRequestReserve}
              onClose={closeRequestReserve}
            >
              <Grid
                container
                direction="column"
                className={classes.bottomSheetStyle}
              >
                <Grid
                  container
                  direction="row"
                  className={classes.drawerHeaderStyle}
                >
                  <Grid>
                    <Typography className={classes.drawerHeaderTextStyle}>
                      Request for reserve
                    </Typography>
                  </Grid>
                  <Grid>
                    <IconButton
                      style={{
                        padding: "0px",
                      }}
                      onClick={closeRequestReserve}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                </Grid>
                <Divider />
                <Grid className={classes.drawerBodyStyle}>
                  {InvoiceData.map((item) => {
                    return (
                      <React.Fragment key={item.id}>
                        <Grid item xs={12}>
                          <QuotationList
                            setInvoiceCart={setInvoiceCart}
                            invoiceCart={invoiceCart}
                            invoiceData={item}
                          />
                        </Grid>
                      </React.Fragment>
                    );
                  })}
                </Grid>
                <Divider />
                <Grid className={classes.drawerFooterStyle}>
                  <Button
                    fullWidth
                    className={classes.drawerButtonStyle}
                    variant="contained"
                    onClick={closeRequestReserve}
                  >
                    Request For Reserve
                  </Button>
                </Grid>
              </Grid>
            </Drawer>
          </Hidden>
        </Grid>
      )}
      {props?.active === "false" && (
        <Grid
          className={classes.root}
          container
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Grid item xs={12}>
            <Button className={classes.closeTheLeadButtonStyle} fullWidth>
              <Typography className={classes.closeTheLeadButtonTextStyle}>
                This Lead is Close
              </Typography>
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  );
};
