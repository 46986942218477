import { Button, Container, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Box } from "@mui/system";
import * as React from "react";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: "10px 0px",
    backgroundColor: "white",
  },
  totalInvoiceAmountTextStyle: {
    color: "#091B29",
    fontSize: "16px",
    textAlign: "left",
    fontWeight: "bold",
  },
  totalNoOfInvoiceTextStyle: {
    color: "#98A0AC",
    fontSize: "12px",
    textAlign: "left",
  },
  totalQuotationAmountTextStyle: {
    color: "#091B29",
    fontSize: "18px",
    textAlign: "left",
    fontWeight: "bolder",
  },
  totalQuotationLableTextStyle: {
    color: "#4E5A6B",
    fontSize: "14px",
    textAlign: "left",
    fontWeight: "bold",
  },
  iconButtonStyle: {
    // height: "44px",
    // width: "144px",
    // padding: "12px 16px 12px 16px",
    borderRadius: "8px",
    border: "2px solid #5078E1",
    // color: "#5078E1",
    // background: "#5078E1",
    // backgroundColor: "#5078E1",
    boxShadow: "0px 2px 6px #00000021",
  },
  payButtonTextStyle: {
    fontSize: "14px",
    color: "#5078E1",
    fontWeight: "bold",
  },
}));
export const PayCard2 = (props) => {
  const classes = useStyles();
  // const handleOnClick = () => {
  //     alert("Clicked")
  // };

  return (
    <Container maxWidth="md">
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        direction="row"
        className={classes.root}
      >
        <Grid alignItems="center" direction="column">
          <Grid alignItems="center" direction="column">
            <Grid item>
              <Typography
                className={
                  props?.type === "invoice"
                    ? classes.totalInvoiceAmountTextStyle
                    : classes.totalQuotationLableTextStyle
                }
              >
                {props?.type === "invoice"
                  ? "$ " + props?.data.total
                  : "Total Amount"}
              </Typography>
            </Grid>
            <Box height="2px" />
            <Grid item>
              <Typography
                className={
                  props?.type === "invoice"
                    ? classes.totalNoOfInvoiceTextStyle
                    : classes.totalQuotationAmountTextStyle
                }
              >
                {props?.type === "invoice"
                  ? props?.data.invoices.length + "Units"
                  : "$ " + props?.data.amount}
              </Typography>
            </Grid>
          </Grid>
          <Box height="2px" />
        </Grid>
        <Box height="2px" />
        <Grid alignItems="center">
          <Button
            variant="outlined"
            onClick={props.handleOnClick}
            size="large"
            className={classes.iconButtonStyle}
          >
            <Typography className={classes.payButtonTextStyle}>
              {props?.type === "invoice" ? "Preview Quotation" : "Pay Now"}
            </Typography>
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};
