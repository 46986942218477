import React from "react";
import { OtpPage } from "./otpPage";

class OtpPagetParent extends React.Component {
    render() {
        return <OtpPage />;
    }
}

export default (OtpPagetParent);
