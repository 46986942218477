import React from "react";
import { withBottombar } from "../../HOCs";
import { ComponentScreen } from "./componentScreen";

class ComponentScreenParent extends React.Component {
  render() {
    return <ComponentScreen />;
  }
}

export default withBottombar(ComponentScreenParent);
