import React, { createContext, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { ACTIVITYCREATE } from "../../graphql/mutations";
import { NetworkCall } from "../../networkcall/index";
import { Routes } from "../../router/routes";
import { AlertProps, NetWorkCallMethods, ValidateEmail } from "../../utils";
const InitialState = {
  activitytypeId: "",
  activitytype: "",
  category: "",
  purpose: "",
  phone: "",
  date: "",
  notes: "",
  priority: "",
  followtype: "",
  location: "",
  type: "",
  mail: "",
  error: {
    activitytype: "",
    category: "",
    purpose: "",
    phone: "",
    date: "",
    notes: "",
    priority: "",
    followtype: "",
    location: "",
    mail: "",
    type: "",
  },
};
export const ActivityListContext = createContext();

const ActivityContextProvider = (props) => {
  const [data, setdata] = useState({ ...InitialState });
  const [step, setStep] = useState(1);
  const search = useLocation().search;
  const [type, setType] = React.useState("");
  const leadIds = new URLSearchParams(search).get("leadId");
  const alert = React.useContext(AlertContext);
  const history = useHistory();
  const [searchdata, setSearch] = React.useState(false);
  const [id, setId] = React.useState("");

  const updateState = (key, value) => {
    if (key === "category" || key === "purpose" || key === "followtype") {
      let error = data.error;
      error[key] = "";
      setdata({ ...data, [key]: value, error });
    } else {
      let error = data.error;
      error[key] = "";
      setdata({ ...data, [key]: value, error });
    }
  };
  const isIamValideToCreateTicket = () => {
    let isValid = true;
    let error = data.error;

    //Checking priority
    if (data.priority.length === 0) {
      isValid = false;
      error.priority = "Priority is Required";
    }
    //Checking date
    if (data.date.length === 0) {
      isValid = false;
      error.date = "Date is Required";
    }
    //Checking purpose
    if (data.purpose.length === 0) {
      isValid = false;
      error.purpose = "Purpose is Required";
    }
    //Checking category
    if (data.category.length === 0) {
      isValid = false;
      error.category = "Category is Required";
    }
    //Checking notes
    if (data.notes.length === 0) {
      isValid = false;
      error.notes = "Notes is Required";
    }

    if (type === "phone call") {
      //Checking phone
      if (data.phone.length === 0) {
        isValid = false;
        error.phone = "Phone is Required";
      }
    }

    //Checking Location
    if (type === "follow up" || type === "meeting" || type === "appoinment") {
      if (data.followtype.value === "In-Person") {
        if (data.location.length === 0) {
          isValid = false;
          error.location = "Location is Required";
        }
      }
    }
    //Checking follow type
    if (type === "follow up" || type === "meeting" || type === "appoinment") {
      if (data.followtype.value === "In-Person") {
        if (data.followtype.length === 0) {
          isValid = false;
          error.followtype = "Followtype  is Required";
        }
      }
    }
    //Checking Email
    if (type === "send quotation" || type === "send mail") {
      ValidateEmail(data.mail);
      if (data.mail.length === 0) {
        isValid = false;

        error.mail = "Email is Required";
      }
    }

    setdata({ ...data, error });
    return isValid;
  };

  const previous = () => {
    setStep(step - 1);
  };
  const next = () => {
    if (step < 3) {
      setStep(step + 1);
    }
  };
  const createAcivity = (leadId) => {
    if (step === 2) {
      if (isIamValideToCreateTicket()) {
        next();
      } else {
        console.log("step not");
        return false;
      }
    }
    if (step === 3) {
      const variables = {
        query: ACTIVITYCREATE,
        variables: {
          insertPayload: [
            {
              lead_id: leadIds ? leadIds : id,
              activity_category: data.category.value,
              activity_sub_category: data.purpose.value,
              created_at: new Date().toISOString(),
              created_by: localStorage.getItem("userProfileId"),
              date_time: data.date,
              is_active: true,
              location: data.location,
              notes: data.notes,
              phone_number: data.phone.mobile,
              priority: data.priority,
              responsible: localStorage.getItem("userProfileId"),
              type: data.followtype.value,
              status: "open",
              email_id: data.mail,
              lead_activitiy_master: data.activitytypeId,
              updated_by: localStorage.getItem("userProfileId"),
            },
          ],
        },
      };
      NetworkCall(
        `${config.graphql_url}`,
        NetWorkCallMethods.post,
        variables,
        null,
        true,
        false
      )
        .then((response) => {
          const InitialStates = {
            activitytypeId: "",
            activitytype: "",
            category: "",
            purpose: "",
            phone: "",
            date: "",
            notes: "",
            priority: "",
            followtype: "",
            location: "",
            type: "",
            mail: "",
            error: {
              activitytype: "",
              category: "",
              purpose: "",
              phone: "",
              date: "",
              notes: "",
              priority: "",
              followtype: "",
              location: "",
              mail: "",
              type: "",
            },
          };
          setdata(InitialStates);
          history.push();
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: "Activity Successfully Created",
          });
          if (leadIds) {
            history.push(Routes.lead);
          } else {
            history.push(Routes.activities);
          }
        })

        .catch((error) => {
          console.log(error);
        });
    }
  };
  return (
    <ActivityListContext.Provider
      value={{
        data,
        step,
        previous,
        next,
        updateState,
        createAcivity,
        type,
        searchdata,
        setSearch,
        setType,
        setId,
        leadIds,
      }}
    >
      {props.children}
    </ActivityListContext.Provider>
  );
};

export default ActivityContextProvider;
