import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import ActivityLead from "../activitylead";
const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "5%",
  },
}));
export const Prem = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ActivityLead />
    </div>
  );
};
