import { Avatar, Grid, IconButton, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  root: {},
  quotationId: {
    fontSize: "16px",
    fontFamily: "tenant_bold",
    color: theme.typography.color.primary,
  },
  time: {
    fontSize: "12px",
    fontFamily: "tenant_semiBold",
    color: "#7C8594",
  },
  img: {
    // backgroundImage: "url(/images/avatarBlur.svg)",
    backgroundColor: theme.palette.primary.main,
    border: `0.5px solid ${theme.palette.primary.main}`,
    borderRadius: "50%",
    width: "32px",
    height: "32px",
    padding: "4px",
  },
  img2: {
    backgroundColor: theme.palette.background.tertiary,
    borderRadius: "50%",
    width: "32px",
    height: "32px",
    padding: "4px",
  },
  line: {
    color: theme.palette.border.secondary,
  },
  lineColor: {
    color: theme.palette.primary.main,
  },
}));

export const QuotationHistory = ({
  data = [],
  index = "",
  length,
  close = () => false,
  id = "",
}) => {
  const classes = useStyles();

  const handleOnClick = () => {
    close();
  };

  return (
    <Link
      to={{
        pathname: `/quotation/${data.quotationNo}`,
      }}
      style={{ textDecoration: "none" }}
    >
      <div className={classes.root} onClick={handleOnClick}>
        <Grid container alignItems="center">
          <Grid item xs={2}>
            {index !== 0 && (
              <Typography
                className={data.color ? classes.lineColor : classes.line}
              >
                &nbsp;&nbsp;&nbsp;|
              </Typography>
            )}
            {data.color && (
              <Avatar className={classes.img}>
                <img
                  src={
                    data.quotationId === "New"
                      ? "/images/tick.svg"
                      : "/images/clockWhite.svg"
                  }
                  alt="document"
                />
              </Avatar>
            )}
            {parseInt(id) === data.quotationNo ? (
              <Avatar className={classes.img}>
                <img src={"/images/regularDocument.svg"} alt="document" />
              </Avatar>
            ) : window.location.pathname === "/requestdetails" ? (
              ""
            ) : (
              <Avatar className={classes.img2}>
                <img src={"/images/clock.svg"} alt="document" />
              </Avatar>
            )}

            {index + 1 !== length && (
              <Typography
                className={data.color ? classes.lineColor : classes.line}
              >
                &nbsp;&nbsp;&nbsp;|
              </Typography>
            )}
          </Grid>
          <Grid item xs={9}>
            <div
              style={{
                marginBottom: index === 0 ? "16px" : "0px",
                marginTop: index + 1 === length ? "22px" : "0px",
              }}
            >
              <Typography className={classes.quotationId}>
                Quotation #{data.quotationNo}
              </Typography>
              <Typography className={classes.time}>
                {" "}
                Received on&nbsp;
                {moment(data.createdOn)
                  .tz(moment.tz.guess())
                  .format("YYYY-MM-DD hh:mm:ss A")}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={1} textAlign="center" alignSelf="auto">
            {index !== 0 && (
              <IconButton>
                <img src="/images/arrowPath.svg" alt="arrow" />
              </IconButton>
            )}
          </Grid>
        </Grid>
      </div>
    </Link>
  );
};
