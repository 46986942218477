import {
    Box,
    Button,
    Container,
    Dialog,
    Drawer,
    Grid,
    Hidden,
    IconButton,
    Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { TextBox, UploadReports } from "../../components";
import CloseIcon from "@mui/icons-material/Close";


const useStyles = makeStyles((theme) => ({
    btnroot: {
        padding: "10px",
    },
    previousbtn: {
        backgroundColor: "white",
        color: "#091B29",
        border: "1px solid #CED3DD",
        borderRadius: "10px",
        padding: "12px 8px",
        boxShadow: "none",
    },
    submitbtn: {
        backgroundColor: "white",
        color: "#091B29",
        border: "1px solid #CED3DD",
        borderRadius: "10px",
        padding: "12px 8px",
        boxShadow: "none",

    },
    drawer: {
        "& .MuiDrawer-paper": {
            borderTopRightRadius: "16px",
            borderTopLeftRadius: "16px",
        },
        "& .MuiContainer-root": {
            padding: "0px 8px",
        },
    },
    bottomTitle: {
        fontSize: "16px",
        fontFamily: "tenant_bold",
        color: theme.typography.color.primary,

    },
    titleroot: {
        borderBottom: "1px solid #c1c1c1",
        // marginBottom: "12px",
    },
    contentBottom: {
        padding: "12px",
    },



}));
export const MoveStep1 = (props) => {
    const classes = useStyles();
    const [openDrawer, setopenDrawer] = React.useState(false);
    const [drawer, setDrawer] = React.useState(false);
    const openLights = () => {
        setopenDrawer(true);
    };
    const closeLights = () => {
        setopenDrawer(false);
    };
    const OpenElectricity = () => {
        setDrawer(true);
    };
    const closeElectricity = () => {
        setDrawer(false);
    };
    return (
        <>
            <Grid
                container
                className={classes.btnroot}
                justifyContent="space-around"
                spacing={1}
            >
                <Grid item xs={6}>
                    <Button
                        fullWidth
                        className={classes.previousbtn}
                        variant="contained"
                        onClick={openLights}
                    >
                        Lights
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <Button
                        fullWidth
                        className={classes.submitbtn}
                        variant="contained"
                        onClick={OpenElectricity}
                    >
                        Electricity
                    </Button>
                </Grid>
            </Grid>
            {/*lights drawer */}

            <Hidden smUp>
                <Drawer className={classes.drawer} anchor="bottom" open={openDrawer}>
                    <Container maxWidth="sm" style={{ overflow: "hidden" }}>
                        <div className={classes.contentBottom}>
                            <Box
                                display="flex"
                                alignItems="center"
                                className={classes.titleroot}
                            >
                                <Box flexGrow={1}>
                                    <Typography className={classes.bottomTitle}>
                                        {"Lights"}
                                    </Typography>
                                </Box>
                                <Box>
                                    <IconButton size="small" onClick={closeLights}>
                                        <CloseIcon color="secondary" />
                                    </IconButton>
                                </Box>
                            </Box>
                            <>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} >
                                        <TextBox multiline />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <UploadReports />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button fullWidth variant="contained" >
                                            Update
                                        </Button>
                                    </Grid>
                                </Grid>

                            </>
                        </div>
                    </Container>
                </Drawer>
            </Hidden>
            <Hidden smDown>
                <Dialog fullWidth={true} maxWidth="sm" open={openDrawer}>
                    <Grid item xs={12}>
                        <div className={classes.contentBottom}>
                            <Box
                                display="flex"
                                alignItems="center"
                                className={classes.titleroot}
                            >
                                <Box flexGrow={1}>
                                    <Typography className={classes.bottomTitle}>
                                        {"Lights"}
                                    </Typography>
                                </Box>
                                <Box>
                                    <IconButton size="small" onClick={closeLights}>
                                        <CloseIcon color="secondary" />
                                    </IconButton>
                                </Box>
                            </Box>
                            <>
                                <Grid container spacing={2} >
                                    <Grid item xs={12} >
                                        <TextBox multiline />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <UploadReports />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button fullWidth variant="contained" >
                                            Update
                                        </Button>
                                    </Grid>
                                </Grid>
                            </>
                        </div>
                    </Grid>
                </Dialog>
            </Hidden>
            {/*lights drawer */}

            <Hidden smUp>
                <Drawer className={classes.drawer} anchor="bottom" open={drawer}>
                    <Container maxWidth="sm" style={{ overflow: "hidden" }}>
                        <div className={classes.contentBottom}>
                            <Box
                                display="flex"
                                alignItems="center"
                                className={classes.titleroot}
                            >
                                <Box flexGrow={1}>
                                    <Typography className={classes.bottomTitle}>
                                        {"Electricity"}
                                    </Typography>
                                </Box>
                                <Box>
                                    <IconButton size="small" onClick={closeElectricity}>
                                        <CloseIcon color="secondary" />
                                    </IconButton>
                                </Box>
                            </Box>
                            <>
                                <Grid container spacing={2} >
                                    <Grid item xs={12}>
                                        <TextBox />
                                    </Grid>
                                    <Grid item xs={12} >
                                        <TextBox multiline />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <UploadReports />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button fullWidth variant="contained" >
                                            Update
                                        </Button>
                                    </Grid>
                                </Grid>

                            </>
                        </div>
                    </Container>
                </Drawer>
            </Hidden>
            <Hidden smDown>
                <Dialog fullWidth={true} maxWidth="sm" open={drawer}>
                    <Grid item xs={12}>
                        <div className={classes.contentBottom}>
                            <Box
                                display="flex"
                                alignItems="center"
                                className={classes.titleroot}
                            >
                                <Box flexGrow={1}>
                                    <Typography className={classes.bottomTitle}>
                                        {"Electricity"}
                                    </Typography>
                                </Box>
                                <Box>
                                    <IconButton size="small" onClick={closeElectricity}>
                                        <CloseIcon color="secondary" />
                                    </IconButton>
                                </Box>
                            </Box>
                            <>
                                <Grid container spacing={2} >
                                    <Grid item xs={12}>
                                        <TextBox />
                                    </Grid>
                                    <Grid item xs={12} >
                                        <TextBox multiline />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <UploadReports />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button fullWidth variant="contained" >
                                            Update
                                        </Button>
                                    </Grid>
                                </Grid>
                            </>
                        </div>
                    </Grid>
                </Dialog>
            </Hidden>
        </>
    );
};
