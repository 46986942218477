import React from "react";
import { Redirect, Route, useHistory } from "react-router-dom";
import { Routes } from "./routes";
import { config } from "../config";
import { LocalStorageKeys, NetWorkCallMethods } from "../utils";
import { NetworkCall } from "../networkcall";

const PrivateRoute = ({ children, ...rest }) => {
  const history = useHistory();
  const isAuthenticated = async (router) => {
    if (localStorage.getItem(LocalStorageKeys.authToken)) {

      const payload = { tenantId: `${config.tenantid}` };

      const response = await NetworkCall(
        `${config.api_url}/users/refresh`,
        NetWorkCallMethods.post,
        payload, null, true,
        false).catch((error) => {
          console.log(error);
          localStorage.clear();
          history.push(Routes.login)
        });

      if (response?.status === 200) {
        return true;
      } else {
        localStorage.clear();
        history.push(Routes.login)
      }

    } else {
      localStorage.clear();
      history.push(Routes.login)
    }
  };

  return (
    <Route
      {...rest}
      render={(_) =>
        isAuthenticated(_) ? children : (
          <Redirect
            to={{
              pathname: Routes.login,
              state: { from: _?.location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
