import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";
import * as React from "react";

const useStyles = makeStyles((theme) => ({
  text: {
    color: theme.typography.color.primary,
    fontFamily: "tenant_semiBold",
  },
  checkbox: {
    color: theme.palette.border.primary,
    "&.Mui-checked": {
      color: theme.palette.primary,
    },
  },
}));
const CheckBox = (props) => {
  const classes = useStyles();
  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">{props.heading}</FormLabel>
      <FormGroup aria-label="position" row>
        <FormControlLabel
          value={props.value}
          control={<Checkbox className={classes.checkbox} />}
          label={props.label}
          labelPlacement={props.labelPlacement}
          className={classes.text}
          onChange={(e, checked) => props.onChange(checked)}
        />
      </FormGroup>
    </FormControl>
  );
};
CheckBox.propTypes = {
  heading: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  labelPlacement: "end" | "start",
};
CheckBox.defaultProps = {
  heading: "",
  label: "Schedule a visit",
  value: "Schedule a visit",
  labelPlacement: "end",
  onChange: () => {},
};

export default CheckBox;
