import ChevronRight from "@mui/icons-material/ChevronRight";
import CloseIcon from "@mui/icons-material/Close";
import {
  Avatar,
  Button,
  Dialog,
  Divider,
  Drawer,
  Grid,
  Hidden,
  IconButton,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Box } from "@mui/system";
import React from "react";
import { useHistory } from "react-router-dom";
import { SelectBox, TextBox } from "../../../components";
import { config } from "../../../config";
import { TRANSFERLEAD } from "../../../graphql/mutations";
import { GETROLES, GETTRANSFERNAME } from "../../../graphql/queries";
import { NetworkCall } from "../../../networkcall/index";
import { Routes } from "../../../router/routes";
import { NetWorkCallMethods } from "../../../utils";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: "12px 20px 12px 20px",
    backgroundColor: "white",
    cursor: "pointer",
  },
  mainTextStyle: {
    fontSize: "16px",
    fontFamily: "tenant_bold",
    color: theme.typography.color.primary,
  },
  avatarCheveronRightStyle: {
    backgroundColor: theme.palette.background.tertiary,
    color: theme.palette.secondary.main,
    height: "32px",
    width: "32px",
    padding: "10px",
  },
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopRightRadius: "16px",
      borderTopLeftRadius: "16px",
    },
    "& .MuiContainer-root": {
      padding: "0px 8px",
    },
  },
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: "16px",
      padding: "0px",
    },
  },
  bottomSheetStyle: {
    background: "white",
    borderRadius: "16px 16px 0px 0px",
    border: "1px solid #E4E8EE",
    justifyContent: "center",
  },
  dialogStyle: {
    background: "white",
    borderRadius: "16px",
    border: "1px solid #E4E8EE",
    justifyContent: "center",
  },
  drawerHeaderStyle: {
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px",
  },
  drawerHeaderTextStyle: {
    fontSize: "16px",
    fontWeight: "bolder",
    color: theme.typography.color.primary,
  },
  drawerBodyStyle: {
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px",
  },
  drawerFooterStyle: {
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px",
  },
  drawerButtonStyle: {
    "&:hover": "none",
    borderRadius: "8px",
    padding: "14px",
    background: theme.palette.primary.main,
    fontSize: "14px",
    fontWeight: "normal",
    color: "#FFF",
  },
}));
const Initialstate = {
  name: "",
  role: "",
  remarks: "",

  error: {
    name: "",
    role: "",
    remarks: "",
  },
};
export const TransferLeadSection = (props) => {
  const classes = useStyles();
  const [isTransferLead, setIsTransferLeadOpen] = React.useState(false);
  const [data, setData] = React.useState({ ...Initialstate });
  const [roles, setRoles] = React.useState([]);
  const [names, setNames] = React.useState([]);

  const history = useHistory();
  React.useEffect(() => {
    const variables = {
      query: GETROLES,
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      variables,
      null,
      true,
      false
    )
      .then((response) => {
        const roledata = response.data.data.roles?.map((val, index) => {
          let _d;
          try {
            _d = {
              value: val.id,
              label: val.name,
            };
          } catch (err) {
            console.log(err);
          }
          return _d;
        });
        setRoles(roledata);
      })

      .catch((error) => {
        console.log(error);
      });
  }, []);
  const isIamValide = () => {
    let isValid = true;
    let error = data.error;

    //Checking Role
    if (data.role.length === 0) {
      isValid = false;
      error.role = "Role is Required";
    }
    //Checking name
    if (data.name.length === 0) {
      isValid = false;
      error.name = "Name is Required";
    }
    if (data.remarks.length === 0) {
      isValid = false;
      error.remarks = "Remarks is Required";
    }
    setData({ ...data, error });

    return isValid;
  };
  const closeTransferLead = () => {
    if (isIamValide()) {
      const variables = {
        query: TRANSFERLEAD,
        variables: {
          leadID: props?.leadId,
          updatePayload: {
            transfer_remarks: data.remarks,
            lead_owner: data.name.value,
          },
        },
      };
      NetworkCall(
        `${config.graphql_url}`,
        NetWorkCallMethods.post,
        variables,
        null,
        true,
        false
      )
        .then((response) => {
          setIsTransferLeadOpen(false);
          history.push(Routes.lead);
        })

        .catch((error) => {
          console.log(error);
        });
    } else {
      return false;
    }
  };
  const openTransferLead = () => {
    setIsTransferLeadOpen(true);
  };
  const updateState = (key, value) => {
    let error = data.error;
    error[key] = "";
    setData({ ...data, [key]: value, error });
  };
  const onSChange = (value) => {
    updateState("role", value);
    const variables = {
      query: GETTRANSFERNAME,
      variables: {
        id: value.value,
      },
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      variables,
      null,
      true,
      false
    )
      .then((response) => {
        const rolename = response.data.data.client_user_roles?.map(
          (val, index) => {
            let _d;
            try {
              _d = {
                value: val.user_profile.id,
                label: `${val.user_profile.first_name} ${val.user_profile.last_name}`,
              };
            } catch (err) {
              console.log(err);
            }
            return _d;
          }
        );
        setNames(rolename);
      })

      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Grid
      className={classes.root}
      container
      direction={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <Grid
        onClick={openTransferLead}
        container
        xs={10}
        direction={"row"}
        alignItems={"center"}
      >
        <img
          src="/images/lead_info_transfer_lead_icon.svg"
          alt="lead_info_transfer_lead_icon"
        />
        <Box width={12} />
        <Typography className={classes.mainTextStyle}>
          Transfer this Lead
        </Typography>
        <Box width={12} />
      </Grid>
      <Avatar
        onClick={openTransferLead}
        className={classes.avatarCheveronRightStyle}
        variant="circular"
      >
        <ChevronRight />
      </Avatar>
      <Hidden smDown>
        <Dialog
          className={classes.dialog}
          open={isTransferLead}
          fullWidth={true}
          maxWidth="sm"
          onClose={() => setIsTransferLeadOpen(false)}
        >
          <Grid container direction="column" className={classes.dialogStyle}>
            <Grid
              container
              direction="row"
              className={classes.drawerHeaderStyle}
            >
              <Grid>
                <Typography className={classes.drawerHeaderTextStyle}>
                  Transfer Lead
                </Typography>
              </Grid>
              <Grid>
                <IconButton
                  style={{
                    padding: "0px",
                  }}
                  onClick={() => setIsTransferLeadOpen(false)}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Divider />
            <Grid className={classes.drawerBodyStyle}>
              <SelectBox
                label="Select Role"
                value={data.role}
                placeholder="Select Role"
                onChange={(value) => onSChange(value)}
                options={roles}
                createSelect={true}
                isError={data?.error?.role?.length > 0}
                errorMessage={data?.error?.role}
              />
              <Box height={"16px"} />
              <SelectBox
                label="Select Name"
                value={data.name}
                placeholder="Select Name"
                onChange={(value) => updateState("name", value)}
                options={names}
                isError={data?.error?.name?.length > 0}
                errorMessage={data?.error?.name}
              />
              <Box height={"16px"} />
              <TextBox
                label="Remarks"
                multiline
                placeholder="Enter Your Remarks"
                value={data.remarks}
                onChange={(value) => updateState("remarks", value.target.value)}
                isError={data?.error?.remarks?.length > 0}
                errorMessage={data?.error?.remarks}
              />
            </Grid>
            <Divider />
            <Grid className={classes.drawerFooterStyle}>
              <Button
                fullWidth
                className={classes.drawerButtonStyle}
                variant="contained"
                onClick={closeTransferLead}
              >
                Transfer
              </Button>
            </Grid>
          </Grid>
        </Dialog>
      </Hidden>
      <Hidden smUp>
        <Drawer
          className={classes.drawer}
          anchor="bottom"
          open={isTransferLead}
          onClose={() => setIsTransferLeadOpen(false)}
        >
          <Grid
            container
            direction="column"
            className={classes.bottomSheetStyle}
          >
            <Grid
              container
              direction="row"
              className={classes.drawerHeaderStyle}
            >
              <Grid>
                <Typography className={classes.drawerHeaderTextStyle}>
                  Transfer Lead
                </Typography>
              </Grid>
              <Grid>
                <IconButton
                  style={{
                    padding: "0px",
                  }}
                  onClick={() => setIsTransferLeadOpen(false)}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Divider />
            <Grid className={classes.drawerBodyStyle}>
              <SelectBox
                label="Select Role"
                value={data.role}
                placeholder="Select Role"
                onChange={(value) => onSChange(value)}
                options={roles}
                createSelect={true}
                isError={data?.error?.role?.length > 0}
                errorMessage={data?.error?.role}
              />
              <Box height={"16px"} />
              <SelectBox
                label="Select Name"
                value={data.name}
                placeholder="Select Name"
                onChange={(value) => updateState("name", value)}
                options={names}
                isError={data?.error?.name?.length > 0}
                errorMessage={data?.error?.name}
              />
              <Box height={"16px"} />
              <TextBox
                label="Remarks"
                multiline
                placeholder="Enter Your Remarks"
                value={data.remarks}
                onChange={(value) => updateState("remarks", value.target.value)}
                isError={data?.error?.remarks?.length > 0}
                errorMessage={data?.error?.remarks}
              />
            </Grid>
            <Divider />
            <Grid className={classes.drawerFooterStyle}>
              <Button
                fullWidth
                className={classes.drawerButtonStyle}
                variant="contained"
                onClick={closeTransferLead}
              >
                Transfer
              </Button>
            </Grid>
          </Grid>
        </Drawer>
      </Hidden>
    </Grid>
  );
};
