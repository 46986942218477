import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "12px",
  },
  title: {
    fontSize: "14px",
    fontFamily: "tenant_bold",
  },
  address: {
    fontSize: "12px",
    color: theme.typography.color.primary,
    fontFamily: "tenant_semiBold",
    marginTop: "4px",
  },
  sqft: {
    fontSize: "12px",
    color: theme.typography.color.secondary,
  },
  amount: {
    fontSize: "14px",
    fontFamily: "tenant_bold",
  },
  img: {
    height: "100%",
    width: "100%",
    objectFit: "contain",
  },
  arrow: {
    border: "1px solid #E4E8EE",
    backgroundColor: "#F2F4F7",
    fontSize: "16px",
    color: "black",
  },
  breakup: {
    color: "#5078E1",
    textDecoration: "underline",
    fontSize: "12px",
    fontFamily: "tenant_SemiBold",
    cursor: "pointer",
  },
  shortlist: {
    fontSize: "10px",
    fontFamily: "tenant_bold",
    marginLeft: "6px",
    padding: "4px 4px",
    backgroundColor: "#FFE6C9",
    color: "#E29336",
    borderRadius: "4px",
  },
}));

export const PropertyList = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={3} md={2}>
          <img
            src={props.data.image}
            alt={props.data.title}
            className={classes.img}
          />
        </Grid>
        <Grid item xs={7} md={8}>
          <Typography className={classes.title}>{props.data.title}</Typography>
          <Typography className={classes.address}>
            {props.data.Reqid}
          </Typography>
          <Box display="flex" alignItems="center" marginTop="4px">
            <Box flexGrow={1}>
              <Stack
                direction="row"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={1}
              >
                <Box display="flex" alignItems="center">
                  <img src="/images/location.svg" alt="sqft" />
                  <Typography className={classes.sqft} noWrap>
                    &nbsp;&nbsp;{props.data.loc}
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center">
                  <img src="/images/build.svg" alt="bed" />
                  <Typography className={classes.sqft} noWrap>
                    &nbsp;&nbsp;{props.data.units} &nbsp;Units
                  </Typography>
                </Box>
              </Stack>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={2} md={2}>
          {props.link ? (
            <Link
              to={{
                pathname: "/property_view",
                state: {
                  data: props.data,
                },
              }}
            >
              <IconButton size="small" className={classes.arrow}>
                <ArrowForwardIosIcon
                  size="small"
                  style={{ fontSize: "16px" }}
                />
              </IconButton>
            </Link>
          ) : (
            <IconButton
              size="small"
              className={classes.arrow}
              onClick={props.onClick}
            >
              <ArrowForwardIosIcon size="small" style={{ fontSize: "16px" }} />
            </IconButton>
          )}
        </Grid>
        <Grid item xs={12}>
          <Divider
            style={{
              border: "1.2px solid #F1F3F7",
              backgroundColor: "#F1F3F7",
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};
