// import gql from "graphql-tag";

// export const yourMutation = gql``;

export const POSTNEWCONTACT = `
mutation($insert: [contactInput!]) {
  contact(insert: $insert) {
    id
    first_name
    last_name
    email_id
    mobile_no
    mobile_no_country_code
    alternative_mobile
    street_1
    street_2
    street_3
    city
    state
    country
    facebook
    linkedin
    twitter
  }
} `;
export const POSTLEADDETAILS = `
mutation($insert: [leadInput!]) {
  lead(insert: $insert) {
    id
  }
}`;
export const UPLOADLEADIMAGE = `
mutation($insert: [assetsInput!]) {
  assets(insert: $insert,assets_master_type:4) {
    reference_id
    id
    url
    created_by
    assets_master_type
    priority
  }
}
`;
export const ADDTODOLIST = `
mutation($insert: [lead_checklistOutput!]) {
  lead_checklist(insert: $insert) {
   name
    description
    is_active
    created_by
  }
}`;
export const UPDATETODO = `
mutation($leadChecklistID: ID, $leadChecklistUpdateData: lead_checklistInput) {
  lead_checklist(
    where: { id: { eq: $leadChecklistID } }
    update: $leadChecklistUpdateData
  ) {
    id
  }
}`;
export const ACTIVITYCREATE = `
mutation($insertPayload: [lead_activitiesInput!]) {
  lead_activities(insert: $insertPayload) {
    id
  }
}`;

export const UPDATEACTIVITIES = `
mutation($leadActivityId: ID, $updatePayload: lead_activitiesInput) {
  lead_activities(
    where: { id: { eq: $leadActivityId } }
    update: $updatePayload
  ) {
    id
  }
}
`;
export const UPDATE = `mutation($userId: String, $update: user_profilesInput) {
  user_profiles(
      update: $update
  where: { user_id: { eq: $userId } }
  ) {
      id
      user_id
      alternative_mobile_no
      email_id
      first_name
      last_name
      mobile_no
  }
}
`;

export const UPDATEADDRESS = `
mutation($contactID: ID, $updatedAddress: contactInput) {
  contact(
    where: { id: { eq: $contactID } }
    update: $updatedAddress
  ) {
    id
    street_1
    street_2
    street_3
    district
    city
    state
    zipcode
    country
    latitude
    longitude
  }
}
`;

export const INSERT_PROFILE_IMAGE_BY_CONTACT_ID = `
mutation($insertPayload: [assetsInput!]) {
  assets(insert: $insertPayload) {
    id
  }
}
`;

export const UPDATE_PROFILE_IMAGE_BY_CONTACT_ID = `
mutation($assetsID: ID, $updatePayload: assetsInput) {
  assets(
    where: {
      id: { eq: $assetsID }
      
      assets_master_type: { id: { eq: 2 } }
      is_active: { eq: true }
    }
    update: $updatePayload
  ) {
    id
  }
}
`;
export const DELETEACTIVITIES = `
mutation($id:ID){
 lead_activities(where:{id:{eq:$id}} delete:true){
  id
}
}`;
export const CREATEQUOTATION = `
mutation($insert: [quotationOutput!]) {
  quotation(
    insert: $insert
  ) {
    id
  }
}`;
export const QUOTATIONSTATUS = `
mutation($insert: [quotation_statusInput!]) {
  quotation_status(
    insert: $insert
  ) {
    id
    quotation_id
    quotation_status_id
    remarks
    request_date_time
    is_active
  }
} 
`;
export const TRANSFERLEAD = `
mutation UpdateLeadByLeadID($leadID: ID, $updatePayload: leadInput) {
  lead(
    where: { id: { eq: $leadID }, is_active: { eq: true } }
    update: $updatePayload
  ) {
    id
    transfer_remarks
  }
}`;
export const CLOSELEAD = `
 mutation UpdateLeadByLeadID($leadID: ID, $updatePayload: leadInput) {
  lead(
    where: { id: { eq: $leadID }, is_active: { eq: true } }
    update: $updatePayload
  ) {
    id
    
  }
}
`;
