import { Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
const useStyles = makeStyles((theme) => ({
    root: {},
    quotationId: {
        fontSize: "12px",
        fontFamily: "tenant_semiBold",
        color: theme.typography.color.Tertiary,
    },
    time: {
        fontSize: "14px",
        fontFamily: "tenant_semiBold",
        color: theme.typography.color.secondary,
    },
    img: {
        backgroundColor: "#78B1FE",
        borderRadius: "50%",
        width: "10px",
        height: "10px",
        padding: "4px"
    },
    img2: {
        border: "1.5px dashed #78B1FE",
        borderRadius: "50%",
        width: "18px",
        height: "18px",
        padding: "2.5px",
        margin: "0px 6px",
    },
    line: {
        color: theme.palette.border.secondary,
    },
    lineColor: {
        color: theme.palette.primary.main,
    },
}));

export const Track = ({ data = [], index = "", length }) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Grid container alignItems="center">
                <Grid item xs={2}>
                    {index !== 0 && (
                        <Typography
                            className={data.color ? classes.lineColor : classes.line}
                        >
                            &nbsp;&nbsp;&nbsp;|
                        </Typography>
                    )}

                    <div className={classes.img2}>
                        <div className={classes.img}>
                        </div>
                    </div>



                    {index + 1 !== length && (
                        <Typography
                            className={data.color ? classes.lineColor : classes.line}
                        >
                            &nbsp;&nbsp;&nbsp;|
                        </Typography>

                    )}
                </Grid>
                <Grid item xs={10}>
                    <div
                        style={{
                            marginBottom: index === 0 ? "16px" : "0px",
                            marginTop: index + 1 === length ? "22px" : "0px",
                        }}
                    >
                        <Grid item xs={12} style={{ display: "flex" }}>
                            <Grid item xs={6}>
                                <Typography className={classes.quotationId}>
                                    {data.quotationId}
                                </Typography>

                            </Grid>
                            <Grid item xs={6}>
                                <div style={{ float: "right" }}>
                                    <Typography className={classes.quotationId}>
                                        {data.date}
                                    </Typography>

                                </div>

                            </Grid>
                        </Grid>

                        <Typography className={classes.time}>{data.time}</Typography>
                    </div>
                </Grid>

            </Grid>
        </div>
    );
};
