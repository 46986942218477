import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "0px 3px 6px #53668523",
    borderRadius: "10px",
    height: "90px",
    backgroundColor: "white",
    cursor: "pointer",
    [theme.breakpoints.up("md")]: {
      padding: "10px 14px",
    },

    [theme.breakpoints.down("md")]: {
      padding: "10px",
    },
  },
  title: {
    fontSize: "20px",
    fontFamily: "tenant_bold",
  },
  subtitle: {
    fontSize: "12px",
    fontFamily: "tenant_SemiBold",
  },
}));

export const DashboardCard = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root} onClick={props?.data?.onClick}>
      <Box display="flex" >
        <Box flexGrow={1}>
          <Typography variant="body1" className={classes.title}>
            {props.data.title}
          </Typography>
        </Box>
        <Box>
          <img src={props.data.img} alt={props.data.title} />
        </Box>
      </Box>
      <Typography variant="body1" className={classes.subtitle}>
        {props.data.subtitle}
      </Typography>
    </div>
  );
};
