import {
    Avatar,
    Box,
    Button,
    Container,
    Grid,
    Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { TitleBar } from "../../components";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { MoveOutStep1, } from "./step1";
import { MoveOutStep2, } from "./step2";
import { MoveOutStep3, } from "./step3";
const useStyles = makeStyles((theme) => ({
    root: {},
    stepindicate: {
        color: theme.typography.color.Tertiary,
        fontSize: "12px",
        fontFamily: "tenant_semiBold",
    },

    stepindicateavatar: {
        backgroundColor: "#5078E1",

        padding: "10px",
    },
    stepindicateavatarborder: {
        border: "1px solid #00000029",
        borderRadius: "50%",
        padding: "4px",
    },
    steps: {
        borderBottom: "4px solid #F5F7FA",
    },
    search: {
        borderBottom: "4px solid #F5F7FA",

        padding: "18px 0px",
    },
    textbox: {
        [`& fieldset`]: {
            borderRadius: "8px",
            height: (props) => (props.multiline ? "unset" : 50),
            border: "1px solid #CED3DD",
            "& :hover": {
                border: "1px solid #5078E1",
            },
        },
        "& .MuiOutlinedInput-input": {
            padding: "11px 14px",
        },
    },
    btnroot: {
        backgroundColor: "#F5F7FA",
        padding: "10px",
        borderTopRightRadius: "16px",
        borderTopLeftRadius: "16px"
    },
    previousbtn: {
        backgroundColor: "white",
        color: "#091B29",
        border: "1px solid #CED3DD",
        borderRadius: "10px",
        padding: "12px 8px",
        boxShadow: "none",
    },
    submitbtn: {
        borderRadius: "10px",
        padding: "12px 8px",
    },
    name: {
        fontSize: "16px",
        fontFamily: "tenant_extraBold",
    },
    email: {
        fontSize: "10px",
        fontFamily: "tenant_bold",
        color: theme.typography.color.secondary,
        backgroundColor: "#F2F4F7",
        padding: "4px 6px",
        borderRadius: "8px",
        marginTop: "4px",
        display: "inline-block",
    },
    created: {
        fontSize: "10px",
        fontFamily: "tenant_regular",
        color: theme.typography.color.Tertiary,
        marginBottom: "10px",
        marginTop: "10px",
    },
    leadtype: {
        fontSize: "10px",
        fontFamily: "tenant_bold",

        backgroundColor: "#E1E8F8",
        padding: "1px 6px",
        borderRadius: "4px",
        textTransform: "capitalize",

        display: "inline-block",
    },
    imgdiv: {
        position: "relative",
    },
    prority: {
        position: "absolute",
        bottom: "4px",
        left: "0px",
        right: "0px",
    },
    proritydata: {
        fontFamily: "tenant_extraBold",
        fontSize: "10px",
        backgroundColor: "#071741",
        borderRadius: "4px",
        color: "white",
        padding: "2px 12px",
    },
    requirmentdata: {
        fontSize: "8px",
    },
    sqftdata: {
        fontSize: "12px",
        fontFamily: "tenant_bold",
    },
    secdule: {
        fontSize: "12px",
        fontFamily: "tenant_bold",
        marginLeft: "4px",
    },
    note: {
        fontSize: "12px",
        color: "#4E5A6B",
    },
    more: {
        color: "#5078E1",
        fontSize: "12px",
        cursor: "pointer",
    },
    screenPadding: {
        overflow: "overlay",
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": {
            display: "none",
        },
    },
}));

export const MoveOut = (props) => {
    const classes = useStyles();
    const size = useWindowDimensions();
    const [step, setStep] = React.useState(1);
    const goBack = () => {
        window.history.go(-1);
    };

    const Submit = () => {
        setStep(step + 1)
    };

    const previous = () => {
        if ((step) => 1 || step <= 3) {
            setStep(step - 1);
        }
    };

    return (
        <div className={classes.root}>
            <Container maxWidth="sm" sx={{ padding: "0px" }}>
                <Grid
                    container
                    className={classes.screen}
                    style={{ height: size?.height }}
                >
                    <Grid item xs={12} sx={{ position: "sticky", top: 0 }}>
                        <TitleBar text="Move Out" goBack={goBack} />
                        <Grid container>
                            <Grid item xs={12} className={classes.steps}>
                                <Box display="flex" alignItems="center" p={1}>
                                    <Box className={classes.stepindicateavatarborder}>
                                        <Avatar className={classes.stepindicateavatar}>
                                            {step}
                                        </Avatar>
                                    </Box>
                                    <Box flexGrow={1} marginLeft="10px">
                                        <Typography className={classes.stepindicate}>
                                            Step {step}/3{" "}
                                        </Typography>

                                        <Typography className={classes.name}>
                                            {step === 1 && "Move Out Details"}
                                            {step === 2 && "Move Out Note"}
                                            {step === 3 && "Move Out Completion"}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        className={classes.screenPadding}
                        style={{ height: size?.height - 200 }}
                        spacing={1}
                    >
                        {step === 1 && (
                            <>
                                <Grid item xs={12} style={{ padding: "14px" }}>

                                    <MoveOutStep1 />
                                </Grid>
                            </>
                        )}
                        {step === 2 && (
                            <Grid item xs={12} style={{ padding: "14px" }}>
                                <MoveOutStep2 />
                            </Grid>
                        )}

                        {step === 3 && (
                            <>
                                <Grid item xs={12} style={{ padding: "14px" }}>
                                    <MoveOutStep3 />
                                </Grid>
                            </>
                        )}
                    </Grid>
                    <Grid
                        container
                        className={classes.btnroot}
                        justifyContent="space-around"
                        spacing={1}
                    >
                        <Grid item xs={6}>
                            <Button
                                fullWidth
                                disabled={step === 1 && true}
                                className={classes.previousbtn}
                                variant="contained"
                                onClick={previous}
                            >
                                Previous
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                fullWidth
                                className={classes.submitbtn}
                                variant="contained"
                                onClick={Submit}
                            >
                                {step === 3 ? "Completed move Out" : "Next"}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
};
