import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Avatar, Box, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
const useStyles = makeStyles((theme) => ({
  img: {
    backgroundColor: "white",
  },
  rootcard: {
    borderRadius: "10px",
    padding: "8px 12px",
    marginTop: "10px",
    backgroundImage: `url("/images/Mask_Group_47.svg")`,
    cursor: "pointer",
    backgroundRepeat: "no-repeat",
    fontSize: "14px",
    fontFamily: "tenant_SemiBold",
    backgroundPositionX: "100%",
  },
  title: {
    color: "white",
  },
  arrow: {
    fontSize: "15px",
  },
}));

export const Actioncard = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid
        container
        onClick={() => {
          props.data.action();
          props.UpdateState("statusid", props.data.id);
        }}
      >
        <Grid item xs={12}>
          <Box
            display="flex"
            style={{ backgroundColor: props.data.color }}
            alignItems="center"
            className={classes.rootcard}
          >
            <Box>
              <Avatar className={classes.img}>
                <img src={props.data.img} alt={props.data.title} />
              </Avatar>
            </Box>
            <Box flexGrow={1}>
              <Typography className={classes.title}>
                &nbsp;&nbsp;{props.data.title}
              </Typography>
            </Box>
            <Box>
              <Avatar className={classes.img} sx={{ width: 30, height: 30 }}>
                <ArrowForwardIosIcon
                  className={classes.arrow}
                  style={{ color: props.data.color }}
                />
              </Avatar>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};
