import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Checkbox,
  Container,
  Dialog,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useHistory } from "react-router-dom";
import { DateTime, RangeSlider, SelectBox, TopNavBars } from "../../components";
import { Routes } from "../../router/routes";
import { SearchListContext } from "./searchContext";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: "16px",
  },
  textbox: {
    marginBottom: "10px",
    [`& fieldset`]: {
      borderRadius: "10px",
      height: (props) => (props.multiline ? "unset" : 50),
      border: "1px solid #CED3DD",
      "& :hover": {
        border: "1px solid #5078E1",
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: "11px 14px",
    },
  },
  UnitStatusactive: {
    padding: "10px 16px",

    textAlign: "center",
    borderRadius: "4px",
    color: "white",
    fontSize: "14px",
    backgroundColor: "#5078E1",
    cursor: "pointer",
  },
  UnitStatus: {
    padding: "10px 16px",
    textAlign: "center",
    borderRadius: "4px",
    fontSize: "14px",
    cursor: "pointer",

    backgroundColor: "#F2F4F7",
  },
  divider: {
    border: "2px solid #F5F7FA",
    marginTop: "18px",
  },
  Label: {
    color: theme.typography.color.secondary,
    fontSize: "12px",
    marginTop: "6px",
  },
  field: {
    marginTop: "10px",
  },
  areavalue: {
    fontSize: "16px",
    fontFamily: "tenant_semiBold",
  },
  slidervalue: {
    fontSize: "12px",
    color: theme.typography.color.secondary,
    marginBottom: "10px",
  },
  searchdrop: {
    borderRadius: "10px",
    border: "1px solid #E4E8EE",
    cursor: "pointer",
  },
  labelchecked: {
    fontSize: "14px",
    fontFamily: "tenant_semiBold",
  },
  head: {
    fontSize: "16px",
    fontFamily: "tenant_extraBold",
    marginLeft: "10px",
  },
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopLeftRadius: "10px",
      borderTopRightRadius: "10px",
    },
  },
  chip: {
    backgroundColor: "#F2F4F7",
    marginLeft: "6px",
    marginTop: "10px",
    borderRadius: "4px",
    fontSize: "14px",
    cursor: "pointer",
    border: "none",
    boxShadow: "none",
    color: theme.typography.color.secondary,
    "&:hover": {
      border: "none",
      boxShadow: "none",
    },
    display: "flex",
    alignItems: "center",
  },
  btnroot: {
    backgroundColor: "white",
    borderTop: "3px solid #E4E8EE",
    padding: "8px",
  },
  previousbtn: {
    backgroundColor: "white",
    color: "#091B29",
    border: "1px solid #CED3DD",
    borderRadius: "10px",
    padding: "12px 8px",
    boxShadow: "none",
  },
  submitbtn: {
    borderRadius: "10px",
    padding: "12px 8px",
  },
}));
const aminitties = [
  { id: "1", value: false, label: "A/C" },
  { id: "2", value: false, label: "Swimming Pool" },
  { id: "3", value: false, label: "Play Ground" },
  { id: "4", value: false, label: "CCTV" },
  { id: "5", value: false, label: "Car Parking" },
  { id: "6", value: true, label: "Watchman" },
];
const floors = [
  { id: "1", value: false, label: "1st Floor" },
  { id: "2", value: false, label: "2nd Floor" },
  { id: "3", value: false, label: "3nd Floor" },
  { id: "4", value: false, label: "4nd Floor" },
  { id: "5", value: false, label: "5nd Floor" },
  { id: "6", value: true, label: " 6nd Floor" },
];
export const Search = (props) => {
  const classes = useStyles();
  const { data, updateState } = React.useContext(SearchListContext);
  const [opensearch, setOpenSearch] = React.useState(false);
  const [opensearchfloor, setOpenSearchfloor] = React.useState(false);
  const [aminittiesdata, setAminitidata] = React.useState([...aminitties]);
  const [floordata, setfloordata] = React.useState([...floors]);
  const history = useHistory();
  const options = [
    { value: "chennai", label: "Chennai" },
    { value: "madurai", label: "Madurai" },
    { value: "kanchipuram", label: "Kanchipuram" },
  ];
  //Aminities data function
  const checkaminittiesdata = (val) => {
    if (val.value === true) {
      return val;
    }
  };
  const aminittiesdataresult = aminittiesdata.filter(checkaminittiesdata);

  const checkedfun = (data, e) => {
    const edited = aminittiesdata.map((task) =>
      task.id === data.id
        ? {
            id: data.id,
            value: e.target.checked,
            label: data.label,
          }
        : task
    );
    setAminitidata(edited);
    setOpenSearch(false);
  };
  const deletechip = (data, index) => {
    const edited = aminittiesdata.map((task) =>
      task.id === data.id
        ? {
            id: data.id,
            value: false,
            label: data.label,
          }
        : task
    );
    setAminitidata(edited);
  };
  //floordata function
  const checkfloordata = (val) => {
    if (val.value === true) {
      return val;
    }
  };
  const floordataresult = floordata.filter(checkfloordata);

  const checkedfunf = (data, e) => {
    const edited = floordata.map((task) =>
      task.id === data.id
        ? {
            id: data.id,
            value: e.target.checked,
            label: data.label,
          }
        : task
    );
    setfloordata(edited);
    setOpenSearchfloor(false);
  };
  const deletechips = (data, index) => {
    const edited = floordata.map((task) =>
      task.id === data.id
        ? {
            id: data.id,
            value: false,
            label: data.label,
          }
        : task
    );
    setfloordata(edited);
  };
  return (
    <div>
      <div className={classes.root}>
        <TopNavBars />
        <TextField
          fullWidth={true}
          className={classes.textbox}
          size="small"
          placeholder="Search Property"
          variant="outlined"
          color="secondary"
          InputProps={{
            startAdornment: (
              <InputAdornment position="end">
                <IconButton aria-label="Toggle password visibility">
                  <img src="/images/search_search.svg" alt="search" />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <SelectBox
          options={options}
          onChange={(value) => updateState("location", value)}
          value={data.location}
        />
        <Divider className={classes.divider} />
        <Grid container spacing={1}>
          <Grid item xs={12} className={classes.field}>
            <Typography variant="body1" className={classes.Label} gutterBottom>
              Unit Status
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              onClick={() => {
                updateState("unitstatus", "vacant");
              }}
              className={
                data.unitstatus === "vacant"
                  ? classes.UnitStatusactive
                  : classes.UnitStatus
              }
            >
              Vacant
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              onClick={() => {
                updateState("unitstatus", "reserved");
              }}
              className={
                data.unitstatus === "reserved"
                  ? classes.UnitStatusactive
                  : classes.UnitStatus
              }
            >
              Reserved
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              onClick={() => {
                updateState("unitstatus", "occupied");
              }}
              className={
                data.unitstatus === "occupied"
                  ? classes.UnitStatusactive
                  : classes.UnitStatus
              }
            >
              Occupied
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.field}>
            <Typography variant="body1" className={classes.Label} gutterBottom>
              Availablity
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <DateTime
              placeholder="From Date"
              value={data.availabiltystart}
              onChange={(value) => updateState("availabiltystart", value)}
            />
          </Grid>
          <Grid item xs={6}>
            <DateTime
              placeholder="To Date"
              value={data.availabiltyend}
              onChange={(value) => updateState("availabiltyend", value)}
            />
          </Grid>
          <Grid item xs={12} className={classes.field}>
            <Typography variant="body1" className={classes.Label} gutterBottom>
              Lease period
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              onClick={() => {
                updateState("leaseperiod", "monthly");
              }}
              className={
                data.leaseperiod === "monthly"
                  ? classes.UnitStatusactive
                  : classes.UnitStatus
              }
            >
              Monthly
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              onClick={() => {
                updateState("leaseperiod", "quarterly");
              }}
              className={
                data.leaseperiod === "quarterly"
                  ? classes.UnitStatusactive
                  : classes.UnitStatus
              }
            >
              Quarterly
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              onClick={() => {
                updateState("leaseperiod", "yearly");
              }}
              className={
                data.leaseperiod === "yearly"
                  ? classes.UnitStatusactive
                  : classes.UnitStatus
              }
            >
              Yearly
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.field}>
            <Typography variant="body1" className={classes.Label} gutterBottom>
              Furnishing
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              onClick={() => {
                updateState("furnishing", "furnished");
              }}
              className={
                data.furnishing === "furnished"
                  ? classes.UnitStatusactive
                  : classes.UnitStatus
              }
            >
              Furnished
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              onClick={() => {
                updateState("furnishing", "semifurnished");
              }}
              className={
                data.furnishing === "semifurnished"
                  ? classes.UnitStatusactive
                  : classes.UnitStatus
              }
            >
              Semi-Furnished
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              onClick={() => {
                updateState("furnishing", "unfurnished");
              }}
              className={
                data.furnishing === "unfurnished"
                  ? classes.UnitStatusactive
                  : classes.UnitStatus
              }
            >
              Unfurnished
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.field}>
            <Typography variant="body1" className={classes.Label} gutterBottom>
              Area
            </Typography>
            <Typography className={classes.areavalue}>
              {data.area.length > 0 && (
                <>
                  {data.area[0]} Sq.Ft - {data.area[1]} Sq.Ft
                </>
              )}
            </Typography>
            <RangeSlider
              min={0}
              max={50000}
              value={data.budget}
              onChange={(value) => updateState("area", value)}
              hide={true}
            />

            <Box display="flex" alignItems="center">
              <Box flexGrow={1}>
                <Typography className={classes.slidervalue}>0 Sq.Ft</Typography>
              </Box>
              <Box>
                <Typography className={classes.slidervalue}>
                  50,000 Sq.Ft
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} className={classes.field}>
            <Typography variant="body1" className={classes.Label} gutterBottom>
              Budget Range
            </Typography>
            <Typography className={classes.areavalue}>
              {data.budget.length > 0 && (
                <>
                  ${data.budget[0]} - ${data.budget[1]}000
                </>
              )}
            </Typography>
            <RangeSlider
              min={0}
              max={50000}
              value={data.budget}
              onChange={(value) => updateState("budget", value)}
              hide={true}
            />

            <Box display="flex" alignItems="center">
              <Box flexGrow={1}>
                <Typography className={classes.slidervalue}>$ 0</Typography>
              </Box>
              <Box>
                <Typography className={classes.slidervalue}>$ 50000</Typography>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} className={classes.field}>
            <Typography variant="body1" className={classes.Label} gutterBottom>
              Amenities
            </Typography>
            <Box
              display="flex"
              alignItems="center"
              className={classes.searchdrop}
              p={1}
              onClick={() => {
                setOpenSearch(true);
              }}
            >
              <Box marginLeft="22px">
                <img src="/images/search_search.svg" alt="search" />
              </Box>
              <Box></Box>
            </Box>
          </Grid>

          {aminittiesdataresult.map((data, index) => {
            return (
              <Grid item>
                <Button
                  className={classes.chip}
                  variant="outlined"
                  endIcon={<CloseIcon />}
                  onClick={() => deletechip(data, index)}
                >
                  {data.label}
                </Button>
              </Grid>
            );
          })}
          <Grid item xs={12} className={classes.field}>
            <Typography variant="body1" className={classes.Label} gutterBottom>
              Floor
            </Typography>
            <Box
              display="flex"
              alignItems="center"
              className={classes.searchdrop}
              p={1}
              onClick={() => {
                setOpenSearchfloor(true);
              }}
            >
              <Box marginLeft="22px">
                <img src="/images/search_search.svg" alt="search" />
              </Box>
              <Box></Box>
            </Box>
          </Grid>
          {floordataresult.map((data, index) => {
            return (
              <Grid item>
                <Button
                  className={classes.chip}
                  variant="outlined"
                  endIcon={<CloseIcon />}
                  onClick={() => deletechips(data, index)}
                >
                  {data.label}
                </Button>
              </Grid>
            );
          })}
        </Grid>
        <br />
        <br />
        <br />
      </div>
      <Grid
        sx={{
          position: "fixed",
          bottom: 54,
          left: 0,
          right: 0,

          zIndex: 999,
          width: "100%",
        }}
      >
        <Container maxWidth="sm" sx={{ padding: "0px" }}>
          <Grid
            container
            className={classes.btnroot}
            spacing={1}
            alignItems="center"
          >
            <Grid item xs={5.5}>
              <Button
                fullWidth
                className={classes.previousbtn}
                variant="contained"
              >
                Reset
              </Button>
            </Grid>
            <Grid item xs={1}>
              <center>
                <Box
                  style={{
                    height: "40px",
                    width: "0.1px",
                    backgroundColor: "#4E5A6B",
                  }}
                  display="flex"
                  justifySelf="center"
                />
              </center>
            </Grid>
            <Grid item xs={5.5}>
              <Button
                fullWidth
                className={classes.submitbtn}
                variant="contained"
                onClick={() => history.push(Routes.searchresult)}
              >
                Search
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Grid>

      <Dialog
        open={opensearch}
        fullWidth
        maxWidth="sm"
        onClose={() => {
          setOpenSearchfloor(false);
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          border={2}
          borderColor="#F5F7FA"
        >
          <Box flexGrow={1}>
            <Typography className={classes.head}>Amenities</Typography>
          </Box>
          <Box>
            <IconButton
              onClick={() => {
                setOpenSearch(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        <Grid container sx={{ padding: "12px" }}>
          <Grid item xs={12} className={classes.field}>
            <TextField
              fullWidth={true}
              className={classes.textbox}
              size="small"
              placeholder="Add Amenities"
              variant="outlined"
              color="secondary"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="end">
                    <IconButton aria-label="Toggle password visibility">
                      <img src="/images/search_search.svg" alt="search" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {aminittiesdata.map((val) => {
              return (
                <Box display="flex" alignItems="center">
                  <Box marginLeft="12px">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={val.value}
                          checkedIcon={<CheckCircleIcon />}
                          icon={
                            <CircleOutlinedIcon style={{ color: "#c1c1c1" }} />
                          }
                        />
                      }
                      label={
                        <span className={classes.labelchecked}>
                          {val.label}
                        </span>
                      }
                      onChange={(e) => checkedfun(val, e)}
                    />
                  </Box>
                </Box>
              );
            })}
          </Grid>
        </Grid>
      </Dialog>
      <Dialog
        open={opensearchfloor}
        fullWidth
        maxWidth="sm"
        onClose={() => {
          setOpenSearchfloor(false);
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          p={1}
          border={2}
          borderColor="#F5F7FA"
        >
          <Box flexGrow={1}>
            <Typography className={classes.head}>Floor</Typography>
          </Box>
          <Box>
            <IconButton
              onClick={() => {
                setOpenSearchfloor(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        <Grid container sx={{ padding: "12px" }}>
          <Grid item xs={12} className={classes.field}>
            <TextField
              fullWidth={true}
              className={classes.textbox}
              size="small"
              placeholder="Add Amenities"
              variant="outlined"
              color="secondary"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="end">
                    <IconButton aria-label="Toggle password visibility">
                      <img src="/images/search_search.svg" alt="search" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {floordata.map((val) => {
              return (
                <Box display="flex" alignItems="center">
                  <Box marginLeft="12px">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={val.value}
                          checkedIcon={<CheckCircleIcon />}
                          icon={
                            <CircleOutlinedIcon style={{ color: "#c1c1c1" }} />
                          }
                        />
                      }
                      label={
                        <span className={classes.labelchecked}>
                          {val.label}
                        </span>
                      }
                      onChange={(e) => checkedfunf(val, e)}
                    />
                  </Box>
                </Box>
              );
            })}
          </Grid>
        </Grid>
      </Dialog>
    </div>
  );
};
