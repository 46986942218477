import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import {
  Box,
  Checkbox,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "12px",
  },
  title: {
    fontSize: "14px",
    fontFamily: "tenant_bold",
  },
  address: {
    fontSize: "12px",
    color: theme.typography.color.Tertiary,
    marginBottom: "6px",
  },
  sqft: {
    fontSize: "12px",
    color: theme.typography.color.secondary,
  },
  amount: {
    fontSize: "14px",
    fontFamily: "tenant_bold",
  },
  img: {
    height: "100%",
    width: "100%",
    objectFit: "contain",
    borderRadius: "10px",
  },
  delbtn: {
    border: "1px solid #E4E8EE",
    backgroundColor: "white",
  },
  breakup: {
    color: "#5078E1",
    textDecoration: "underline",
    fontSize: "12px",
    fontFamily: "tenant_SemiBold",
    cursor: "pointer",
  },
  shortlist: {
    fontSize: "10px",
    fontFamily: "tenant_bold",
    marginLeft: "6px",
    padding: "4px 4px",
    backgroundColor: "#FFE6C9",
    color: "#E29336",
    borderRadius: "4px",
  },
}));

export const UnitList = (props) => {
  const classes = useStyles();
  const [showCheckBox, setShowCheckBox] = React.useState(false);
  const Checkeditem = (checked) => {
    setShowCheckBox(checked);
    props.setInvoiceCart({
      type: checked ? "ADD" : "REMOVE",
      payload: props.invoiceData,
    });
  };
  const handleClick = () => {
    if (props.checked) {
      Checkeditem(!showCheckBox);
    } else {
    }
  };
  return (
    <div className={classes.root}>
      {props.delete ? (
        <>
          <Grid
            container
            spacing={1}
            alignItems="center"
            style={{ cursor: "pointer" }}
            onClick={() => {
              props.onDelete(props.data);
            }}
          >
            <Grid item xs={2} md={2}>
              <img
                src={props.data.url}
                alt={props.data.name}
                className={classes.img}
              />
            </Grid>
            <Grid item xs={10} md={10}>
              <Box display="flex" alignItems="center" marginLeft="6px">
                <Box flexGrow={1}>
                  <Typography className={classes.title}>
                    {props.data.name}
                    {props.data.shortlist && (
                      <span className={classes.shortlist}>
                        {props.data.shortlist}&nbsp;Shortlisted
                      </span>
                    )}
                  </Typography>
                  <Typography className={classes.address}>
                    {props.data.block}
                  </Typography>
                </Box>
                <Box>
                  {props.Pending && (
                    <>
                      {props.index === 0 && (
                        <div className={classes.shortlist}>
                          Move In Update Pending
                        </div>
                      )}
                    </>
                  )}
                  {props.data.primary_total && (
                    <Typography className={classes.amount}>
                      $&nbsp;{props.data.primary_total}
                    </Typography>
                  )}
                </Box>
              </Box>

              <Box display="flex" alignItems="center" marginLeft="6px">
                <Box flexGrow={1}>
                  <Stack
                    direction="row"
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={2}
                  >
                    <Box display="flex" alignItems="center">
                      <img src="/images/SQ FT.svg" alt="sqft" />
                      <Typography className={classes.sqft} noWrap>
                        &nbsp;&nbsp;{props.data.leasable_area} &nbsp;
                        {props.data.leasable_area_metric}
                      </Typography>
                    </Box>
                    <Box display="flex" alignItems="center">
                      <img src="/images/Bedroom.svg" alt="bed" />
                      <Typography className={classes.sqft} noWrap>
                        &nbsp;&nbsp;{props.data.unit_type} &nbsp;Bedroom
                      </Typography>
                    </Box>
                    {props.location && (
                      <Box display="flex" alignItems="center">
                        <img src="/images/location.svg" alt="bed" />
                        <Typography className={classes.sqft} noWrap>
                          &nbsp;&nbsp;{props.data.location} &nbsp;
                        </Typography>
                      </Box>
                    )}
                    {props.breakup && (
                      <Box display="flex" alignItems="center">
                        <Typography
                          className={classes.breakup}
                          noWrap
                          onClick={() => {
                            props.onbreak(props.data);
                          }}
                        >
                          Break Up
                        </Typography>
                      </Box>
                    )}
                  </Stack>
                </Box>
                <Box>
                  {props.checked && (
                    <Checkbox
                      checked={showCheckBox}
                      checkedIcon={<CheckCircleIcon />}
                      icon={
                        <CheckCircleOutlineIcon style={{ color: "#c1c1c1" }} />
                      }
                    />
                  )}
                  {props.delete && (
                    <IconButton size="small" className={classes.delbtn}>
                      <img src="images/icons8-trash (2).svg" alt="" />
                    </IconButton>
                  )}
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Divider
                style={{
                  border: "1.2px solid #F1F3F7",
                  backgroundColor: "#F1F3F7",
                }}
              />
            </Grid>
          </Grid>
        </>
      ) : (
        <Grid
          container
          spacing={1}
          alignItems="center"
          style={{ cursor: "pointer" }}
          onClick={() => {
            handleClick();
          }}
        >
          <Grid item xs={2} md={2}>
            <img
              src={props.data.image}
              alt={props.data.title}
              className={classes.img}
            />
          </Grid>
          <Grid item xs={10} md={10}>
            <Box display="flex" alignItems="center" marginLeft="6px">
              <Box flexGrow={1}>
                <Typography className={classes.title}>
                  {props.data.title}
                  {props.data.shortlist && (
                    <span className={classes.shortlist}>
                      {props.data.shortlist}&nbsp;Shortlisted
                    </span>
                  )}
                </Typography>
                <Typography className={classes.address}>
                  {props.data.address}
                </Typography>
              </Box>
              <Box>
                {props.Pending && (
                  <>
                    {props.index === 0 && (
                      <div className={classes.shortlist}>
                        Move In Update Pending
                      </div>
                    )}
                  </>
                )}
                {props.data.amount && (
                  <Typography className={classes.amount}>
                    $&nbsp;{props.data.amount}
                  </Typography>
                )}
              </Box>
            </Box>

            <Box display="flex" alignItems="center" marginLeft="6px">
              <Box flexGrow={1}>
                <Stack
                  direction="row"
                  divider={<Divider orientation="vertical" flexItem />}
                  spacing={2}
                >
                  <Box display="flex" alignItems="center">
                    <img src="/images/SQ FT.svg" alt="sqft" />
                    <Typography className={classes.sqft} noWrap>
                      &nbsp;&nbsp;{props.data.sqft} &nbsp;Sqft
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center">
                    <img src="/images/Bedroom.svg" alt="bed" />
                    <Typography className={classes.sqft} noWrap>
                      &nbsp;&nbsp;{props.data.sqft} &nbsp;Bedroom
                    </Typography>
                  </Box>
                  {props.location && (
                    <Box display="flex" alignItems="center">
                      <img src="/images/location.svg" alt="bed" />
                      <Typography className={classes.sqft} noWrap>
                        &nbsp;&nbsp;{props.data.location} &nbsp;
                      </Typography>
                    </Box>
                  )}
                  {props.breakup && (
                    <Box display="flex" alignItems="center">
                      <Typography
                        className={classes.breakup}
                        noWrap
                        onClick={() => {
                          props.onbreak(props.data);
                        }}
                      >
                        Break Up
                      </Typography>
                    </Box>
                  )}
                </Stack>
              </Box>
              <Box>
                {props.checked && (
                  <Checkbox
                    checked={showCheckBox}
                    checkedIcon={<CheckCircleIcon />}
                    icon={
                      <CheckCircleOutlineIcon style={{ color: "#c1c1c1" }} />
                    }
                  />
                )}
                {props.delete && (
                  <IconButton size="small" className={classes.delbtn}>
                    <DeleteForeverOutlinedIcon
                      style={{ color: "red", fontSize: "16px" }}
                    />
                  </IconButton>
                )}
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Divider
              style={{
                border: "1.2px solid #F1F3F7",
                backgroundColor: "#F1F3F7",
              }}
            />
          </Grid>
        </Grid>
      )}
    </div>
  );
};
