import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Badge, Box, Grid, IconButton } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
const useStyles = makeStyles((theme) => ({
  grow: {},
  appBar: {
    background: `#5078E1`,
    // padding: "12px 20px",
  },
  navbarimg: {
    height: "30px",

  },
  id: {
    color: "black",
    fontFamily: "tenant_extraBold",
    fontSize: "14px",
  },
  containers: {
    "&.MuiContainer-root": {
      paddingLeft: "0px",
      paddingRight: "0px",
    },
    overflow: "hidden",
  },
}));

export const TopNavBars = (props) => {
  const classes = useStyles();

  const logo = JSON.parse(localStorage.getItem("roles"))?.find((item)=>item?.company_logo);
  const clientLogo=localStorage.getItem("clientLogo");

  return (
    <div className={classes.grow}>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          <Grid container alignItems="center">
            <Grid item xs={5}>
              <Box display="flex" alignItems="center">
                <Box>
                  <img
                    // src="/images/casagrandLogo.png"
                    src={logo?.company_logo ? logo?.company_logo : clientLogo}
                    alt="casagrandimage"
                    className={classes.navbarimg}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={7}>
              <Box display="flex" alignItems="center" justifyContent="flex-end">
                <Box alignSelf="center">
                  {props.note && (
                    <IconButton
                      size="small"
                      color="primary"
                      onClick={props.handleClicknote}
                    >
                      <Badge badgeContent={props.count} color="primary">
                        <img src="/images/icons8-notification.svg" alt="note" />
                      </Badge>
                    </IconButton>
                  )}
                  {props.search && (
                    <IconButton
                      size="small"
                      color="secondary"
                      onClick={props.handleClicksearch}
                    >
                      <Badge badgeContent={props.count} color="primary">
                        <img src="/images/icons8-search.svg" alt="note" />
                      </Badge>
                    </IconButton>
                  )}

                  {props.home && (
                    <>
                      <span className={classes.id}>AG-123-21</span>
                      <IconButton
                        size="small"
                        color="secondary"
                        onClick={props.handleClick}
                      >
                        <KeyboardArrowDownIcon />
                      </IconButton>
                    </>
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  );
};
