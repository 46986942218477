import {
  Avatar,
  Box,
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { ContactList, TitleBar } from "../../components";
import { config } from "../../config";
import { SEARCHLEADBYOWNER } from "../../graphql/queries";
import { NetworkCall } from "../../networkcall/index";
import { NetWorkCallMethods } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { ActivityDetails } from "./activitydetails";
import { ActivityPreview } from "./activitypreview";
import { ChooseActivity } from "./chooseactivity";
import { ActivityListContext } from "./createactivityContext";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.secondary,
  },
  stepindicate: {
    color: theme.typography.color.Tertiary,
    fontSize: "12px",
    fontFamily: "tenant_semiBold",
  },

  stepindicateavatar: {
    backgroundColor: "#5078E1",

    padding: "10px",
  },
  stepindicateavatarborder: {
    border: "1px solid #00000029",
    borderRadius: "50%",
    padding: "4px",
  },
  steps: {
    borderBottom: "4px solid #F5F7FA",
    backgroundColor: "white",
  },
  btnroot: {
    backgroundColor: "#F5F7FA",
    padding: "10px",
  },
  previousbtn: {
    backgroundColor: "white",
    color: "#091B29",
    border: "1px solid #CED3DD",
    borderRadius: "10px",
    padding: "12px 8px",
    boxShadow: "none",
  },
  submitbtn: {
    borderRadius: "10px",
    padding: "12px 8px",
  },
  screenPadding: {
    overflow: "overlay",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  name: {
    fontSize: "16px",
    fontFamily: "tenant_bold",
  },
  type: {
    textTransform: "capitalize",
    fontSize: "14px",
    fontFamily: "tenant_semiBold",
    color: "white",
  },
  previewroot: {
    backgroundColor: "#8887D4",
    borderRadius: "10px",
  },
  textbox: {
    [`& fieldset`]: {
      borderRadius: "8px",
      height: (props) => (props.multiline ? "unset" : 50),
      border: "1px solid #CED3DD",
      "& :hover": {
        border: "1px solid #5078E1",
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: "11px 14px",
    },
  },
  result: {
    fontSize: "12px",
    color: "#A4B2C1",
  },
}));
export const CreateActivity = (props) => {
  const classes = useStyles();
  const [contactList, setcontactList] = React.useState([]);
  const {
    step,
    previous,

    searchdata,

    setSearch,
    createAcivity,
    setId,
  } = React.useContext(ActivityListContext);
  const size = useWindowDimensions();

  const goBack = () => {
    window.history.go(-1);
  };
  const searchHandle = (search) => {
    const variables = {
      query: SEARCHLEADBYOWNER,
      variables: {
        search: `%${search}%`,
        ownerId: localStorage.getItem("userProfileId"),
      },
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      variables,
      null,
      true,
      false
    )
      .then((response) => {
        setcontactList(response.data.data.lead);
      })

      .catch((error) => {
        console.log(error);
      });
  };
  const goBacksearch = () => {
    setSearch(!searchdata);
  };
  const MyDivider = styled(Divider)(({ thiccness, orientation }) => ({
    ...(thiccness !== undefined &&
      (orientation === "vertical"
        ? { borderRightWidth: thiccness, borderColor: "#F5F7FA" }
        : { borderBottomWidth: thiccness, borderColor: "#F5F7FA" })),
  }));
  const getSearchdata = (data) => {
    setId(data.id);
    setSearch(!searchdata);
  };
  return (
    <div>
      <Container maxWidth="sm" sx={{ padding: "0px" }}>
        <Grid
          container
          className={classes.screen}
          style={{ height: size?.height }}
        >
          <Grid
            item
            xs={12}
            sx={{
              position: "sticky",
              top: 0,
              zIndex: 999,
            }}
          >
            {searchdata ? (
              <>
                <TitleBar text="Search" goBack={goBacksearch} />
                <div style={{ padding: "8px" }}>
                  <Grid item xs={12} className={classes.searchmain}>
                    <TextField
                      variant="outlined"
                      color="secondary"
                      style={{ backgroundColor: "#F5F7FA" }}
                      fullWidth
                      onChange={(e) => {
                        searchHandle(e.target.value);
                      }}
                      placeholder="Search Lead Here"
                      className={classes.textbox}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="end">
                            <IconButton aria-label="Toggle password visibility">
                              <img src="/images/icons8-search.svg" alt="note" />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  {contactList.length > 0 && (
                    <>
                      <Typography
                        className={classes.result}
                        style={{ marginBottom: "-12px" }}
                      >
                        Results
                      </Typography>
                      <MyDivider thiccness={4} />
                      <div className={classes.listpad}>
                        {contactList?.map((val) => {
                          return (
                            <>
                              <ContactList
                                data={val}
                                leadtype={true}
                                onClick={getSearchdata}
                              />
                              <MyDivider thiccness={4} />
                            </>
                          );
                        })}
                      </div>
                    </>
                  )}
                </div>
              </>
            ) : (
              <div>
                <TitleBar text="Create Activity" goBack={goBack} />
                <Grid container>
                  <Grid item xs={12} className={classes.steps}>
                    <Box display="flex" alignItems="center" p={1}>
                      <Box className={classes.stepindicateavatarborder}>
                        <Avatar className={classes.stepindicateavatar}>
                          {step}
                        </Avatar>
                      </Box>
                      <Box flexGrow={1} marginLeft="10px">
                        <Typography className={classes.stepindicate}>
                          Step {step}/3{" "}
                        </Typography>

                        <Typography className={classes.name}>
                          {step === 1 && "Choose the activity"}
                          {step === 2 && "Activity Details"}
                          {step === 3 && "Preview and Submit"}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
                <Grid
                  container
                  className={classes.screenPadding}
                  style={{ height: size?.height - 120 }}
                >
                  <Grid item xs={12}>
                    {step === 1 && <ChooseActivity />}
                    {step === 2 && (
                      <Grid item xs={12} style={{ padding: "12px" }}>
                        <ActivityDetails />
                        <br />
                        <br />
                        <br />
                        <br />
                      </Grid>
                    )}
                    {step === 3 && (
                      <Grid item xs={12}>
                        <ActivityPreview />
                      </Grid>
                    )}
                  </Grid>
                  {step !== 1 && (
                    <Grid
                      sx={{
                        position: "fixed",
                        bottom: 0,
                        left: 0,
                        right: 0,

                        zIndex: 999,
                        width: "100%",
                      }}
                    >
                      <Container maxWidth="sm" sx={{ padding: "0px" }}>
                        <Grid container className={classes.btnroot} spacing={1}>
                          <Grid item xs={6}>
                            <Button
                              fullWidth
                              disabled={step === 1 && true}
                              className={classes.previousbtn}
                              variant="contained"
                              onClick={previous}
                            >
                              Previous
                            </Button>
                          </Grid>
                          <Grid item xs={6}>
                            <Button
                              fullWidth
                              className={classes.submitbtn}
                              variant="contained"
                              onClick={() => {
                                createAcivity();
                              }}
                            >
                              Next
                            </Button>
                          </Grid>
                        </Grid>
                      </Container>
                    </Grid>
                  )}
                </Grid>
              </div>
            )}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};
