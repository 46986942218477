import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
    padding: "12px",
    borderBottom: `1px solid ${theme.palette.border.secondary}`,
    boxShadow: "0px 2px 6px #00000014",
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    fontSize: "16px",
    textTransform: "capitalize",
    fontFamily: "tenant_bold",
  },
  arrow: {
    fontSize: "20px",
    color: "black",
  },
  clear: {
    fontSize: "14px",
    color: "#5078E1",
    fontFamily: "tenant_SemiBold",
    cursor: "pointer",
  },
}));

export const TitleBar = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <Box display="flex" alignItems="center">
            <Box>
              <IconButton size="small" onClick={props.goBack}>
                <ArrowBackIosIcon fontSize="small" className={classes.arrow} />
              </IconButton>
            </Box>
            <Box flexGrow={1}>
              <Typography className={classes.title}>{props.text}</Typography>
            </Box>
            <Box>
              {props.clearFilter && (
                <Typography className={classes.clear} onClick={props.onClear}>
                  Clear Filter
                </Typography>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};
