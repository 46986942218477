import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Checkbox, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Box } from "@mui/system";
import moment from "moment";
import React from "react";
const useStyles = makeStyles((theme) => ({
  root: {},
  card: {
    cursor: "pointer",
    width: "-webkit-fill-available",
    alignItems: "center",
    justifyContent: "space-between",
  },
  checkBox: {
    color: theme.palette.primary.main,
    borderRadius: "5px",
  },
  iconContainer: {
    width: "42px",
    height: "42px",
    borderRadius: "8px",
    backgroundColor: (props) =>
      props?.data?.status === "open"
        ? props?.data?.lead_activitiy_master ===
          "67f34ccd-3f23-4f10-8334-80ed1d425781"
          ? "#30AFCE"
          : props?.data?.lead_activitiy_master ===
            "fff87381-de52-455d-9f69-8400b9a2a0ff"
          ? "#35A27D"
          : props?.data?.lead_activitiy_master ===
            "cbc1480a-a7e5-4bab-9a07-9f08d949f179"
          ? "#8887D4"
          : props?.data?.lead_activitiy_master ===
            "50fae8f5-17d0-417a-ba01-568b35acfbc1"
          ? "#E53468"
          : props?.data?.lead_activitiy_master ===
            "60f6e6f0-1328-47b4-8dc6-34035ee49c6e"
          ? "#E29336"
          : props?.data?.lead_activitiy_master ===
            "ac53c561-9ab2-437b-b80c-e7a738bab8f2"
          ? "#78B1FE"
          : props?.data?.lead_activitiy_master ===
            "1931346b-a32e-4834-b399-708f07448278"
          ? "#5078E1"
          : "#30AFCE"
        : theme.palette.background.secondary,
    backgroundImage: (props) =>
      props?.data?.status === "open"
        ? props?.data?.lead_activitiy_master ===
          "67f34ccd-3f23-4f10-8334-80ed1d425781"
          ? "url('images/activity_phonecall_icon_bg.svg')"
          : props?.data?.lead_activitiy_master ===
            "fff87381-de52-455d-9f69-8400b9a2a0f"
          ? "url('images/activity_followup_icon_bg.svg')"
          : props?.data?.lead_activitiy_master ===
            "cbc1480a-a7e5-4bab-9a07-9f08d949f179"
          ? "url('images/activity_meeting_icon_bg.svg')"
          : props?.data?.lead_activitiy_master ===
            "50fae8f5-17d0-417a-ba01-568b35acfbc1"
          ? "url('images/activity_appointment_icon_bg.svg')"
          : props?.data?.lead_activitiy_master ===
            "60f6e6f0-1328-47b4-8dc6-34035ee49c6e"
          ? "url('images/activity_sitevisit_icon_bg.svg')"
          : props?.data?.lead_activitiy_master ===
            "ac53c561-9ab2-437b-b80c-e7a738bab8f2"
          ? "url('images/activity_email_icon_bg.svg')"
          : props?.data?.lead_activitiy_master ===
            "1931346b-a32e-4834-b399-708f07448278"
          ? "url('images/activity_sendquotation_icon_bg.svg')"
          : "url('images/activity_phonecall_icon_bg.svg')"
        : "none",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
  infoContainer: {
    padding: "0px 8px 0px 8px",
    alignItems: "center",
    justifyContent: "start",
  },
  infoLineStyle: {
    alignItems: "center",
    justifyContent: "start",
  },
  rightChevronIconContainer: {
    alignItems: "center",
    justifyContent: "end",
  },
  titleStyle: {
    fontSize: "16px",
    fontFamily: "tenant_bold",
    color: theme.typography.color.secondary,
    paddingRight: "6px",
  },
  priorityStyle: {
    fontSize: "10px",
    fontFamily: "tenant_bold",
    color: theme.typography.color.secondary,
    backgroundColor: theme.palette.background.tertiary,
    borderRadius: "4px",
    padding: "2px 8px 2px 8px",
    alignSelf: "center",
  },
  subTitleStyle: {
    fontSize: "12px",
    fontFamily: "tenant_regular",
    color: theme.typography.color.secondary,
  },
  periodStyle: {
    height: "6px",
    width: "6px",
    borderRadius: "50%",
    backgroundColor: "#CED3DD",
    margin: "6px",
  },
}));

export const ActivityListCard = (props) => {
  const classes = useStyles(props);
  const [showCheckBox, setShowCheckBox] = React.useState(false);
  const handleClick = (data) => {
    const checked = (checked) => {
      setShowCheckBox(checked);
      props.setInvoiceCart({
        type: checked ? "ADD" : "REMOVE",
        payload: props.invoiceData,
      });
    };
    if (props.type === "selectable") {
      checked(!showCheckBox);
    } else {
      props.onClick(props.data);
    }
  };

  return (
    <Grid className={classes.card} container direction={"row"}>
      {showCheckBox && (
        <Checkbox
          className={classes.checkBox}
          checkedIcon={<CheckCircleIcon />}
          icon={<CheckCircleIcon />}
        />
      )}
      <Box
        className={classes.iconContainer}
        onClick={() => handleClick(props.data)}
      >
        <img
          src={
            props?.data?.status === "open"
              ? props?.data?.lead_activitiy_master ===
                "67f34ccd-3f23-4f10-8334-80ed1d425781"
                ? "images/activity_phonecall_icon_open.svg"
                : props?.data?.lead_activitiy_master ===
                  "fff87381-de52-455d-9f69-8400b9a2a0ff"
                ? "images/activity_followup_icon_open.svg"
                : props?.data?.lead_activitiy_master ===
                  "cbc1480a-a7e5-4bab-9a07-9f08d949f179"
                ? "images/activity_meeting_icon_open.svg"
                : props?.data?.lead_activitiy_master ===
                  "50fae8f5-17d0-417a-ba01-568b35acfbc1"
                ? "images/activity_appointment_icon_open.svg"
                : props?.data?.lead_activitiy_master ===
                  "60f6e6f0-1328-47b4-8dc6-34035ee49c6e"
                ? "images/activity_sitevisit_icon_open.svg"
                : props?.data?.lead_activitiy_master ===
                  "ac53c561-9ab2-437b-b80c-e7a738bab8f2"
                ? "images/activity_email_icon_open.svg"
                : props?.data?.lead_activitiy_master ===
                  "1931346b-a32e-4834-b399-708f07448278"
                ? "images/activity_sendquotation_icon_open.svg"
                : "images/activity_phonecall_icon_open.svg"
              : props?.data?.lead_activitiy_master ===
                "67f34ccd-3f23-4f10-8334-80ed1d425781"
              ? "images/activity_phonecall_icon_closed.svg"
              : props?.data?.lead_activitiy_master ===
                "fff87381-de52-455d-9f69-8400b9a2a0ff"
              ? "images/activity_followup_icon_closed.svg"
              : props?.data?.lead_activitiy_master ===
                "cbc1480a-a7e5-4bab-9a07-9f08d949f179"
              ? "images/activity_meeting_icon_closed.svg"
              : props?.data?.lead_activitiy_master ===
                "50fae8f5-17d0-417a-ba01-568b35acfbc1"
              ? "images/activity_appointment_icon_closed.svg"
              : props?.data?.lead_activitiy_master ===
                "60f6e6f0-1328-47b4-8dc6-34035ee49c6e"
              ? "images/activity_sitevisit_icon_closed.svg"
              : props?.data?.lead_activitiy_master ===
                "ac53c561-9ab2-437b-b80c-e7a738bab8f2"
              ? "images/activity_email_icon_closed.svg"
              : props?.data?.lead_activitiy_master ===
                "1931346b-a32e-4834-b399-708f07448278"
              ? "images/activity_sendquotation_icon_closed.svg"
              : "images/activity_phonecall_icon_closed.svg"
          }
          alt="card_icon"
        />
      </Box>
      <Grid
        onClick={() => handleClick(props.data)}
        className={classes.infoContainer}
        container
        xs={showCheckBox ? 8 : 10}
        direction={"column"}
      >
        <Grid className={classes.infoLineStyle} container direction={"row"}>
          <Typography className={classes.titleStyle}>
            {props?.data?.lead_activitiy_master ===
              "67f34ccd-3f23-4f10-8334-80ed1d425781" && "Phone Call"}
            {props?.data?.lead_activitiy_master ===
              "fff87381-de52-455d-9f69-8400b9a2a0ff" && "Follow up"}
            {props?.data?.lead_activitiy_master ===
              "cbc1480a-a7e5-4bab-9a07-9f08d949f179" && "Meeting"}
            {props?.data?.lead_activitiy_master ===
              "50fae8f5-17d0-417a-ba01-568b35acfbc1" && "Appointment"}
            {props?.data?.lead_activitiy_master ===
              "60f6e6f0-1328-47b4-8dc6-34035ee49c6e" && "Arrange site visit"}
            {props?.data?.lead_activitiy_master ===
              "1931346b-a32e-4834-b399-708f07448278" && "Send quotation"}
            {props?.data?.lead_activitiy_master ===
              "ac53c561-9ab2-437b-b80c-e7a738bab8f2" && "Send email"}
          </Typography>
          <Typography className={classes.priorityStyle}>
            {props?.data?.priority}
          </Typography>
        </Grid>
        <Grid className={classes.infoLineStyle} container direction={"row"}>
          {props?.contactName && (
            <>
              <Typography className={classes.subTitleStyle}>
                {props?.contactName}
              </Typography>
              <Box className={classes.periodStyle} />
            </>
          )}

          <Typography className={classes.subTitleStyle}>
            {moment(props?.data?.date_time).format("DD MMM")}
          </Typography>
          <Box className={classes.periodStyle} />
          <Typography className={classes.subTitleStyle}>
            {props?.data?.lead?.lead_no}
          </Typography>
          <Box className={classes.periodStyle} />
          <Typography className={classes.subTitleStyle}>
            {props?.data?.lead_activitiy_master ===
              "67f34ccd-3f23-4f10-8334-80ed1d425781" && (
              <>{props?.data?.phone_number}</>
            )}
            {props?.data?.lead_activitiy_master ===
              "fff87381-de52-455d-9f69-8400b9a2a0ff" && (
              <>{props?.data?.type}</>
            )}
            {props?.data?.lead_activitiy_master ===
              "cbc1480a-a7e5-4bab-9a07-9f08d949f179" && (
              <>{props?.data?.type}</>
            )}
            {props?.data?.lead_activitiy_master ===
              "50fae8f5-17d0-417a-ba01-568b35acfbc1" && (
              <>{props?.data?.type}</>
            )}
            {props?.data?.lead_activitiy_master ===
              "60f6e6f0-1328-47b4-8dc6-34035ee49c6e" && (
              <>{props?.data?.location}</>
            )}

            {props?.data?.lead_activitiy_master ===
              "1931346b-a32e-4834-b399-708f07448278" && (
              <>{props?.data?.email_id}</>
            )}
            {props?.data?.lead_activitiy_master ===
              "ac53c561-9ab2-437b-b80c-e7a738bab8f2" && (
              <>{props?.data?.email_id}</>
            )}
          </Typography>
        </Grid>
      </Grid>
      {props?.onselect ? (
        <Grid
          onClick={() => {
            props?.onselect(props?.data);
          }}
          className={classes.rightChevronIconContainer}
        >
          <img src="images/right_chevron.svg" alt="navigation_icon" />
        </Grid>
      ) : (
        <Grid className={classes.rightChevronIconContainer}>
          <img src="images/right_chevron.svg" alt="navigation_icon" />
        </Grid>
      )}
    </Grid>
  );
};
