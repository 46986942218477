import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  Divider,
  Drawer,
  Grid,
  Hidden,
  IconButton,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { DateTime, RangeSlider, TextBox } from "../../components";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "14px",
  },

  profileimg: {
    backgroundColor: theme.palette.background.tertiary,
    color: "#E4E8EE",
    position: "relative",
  },
  profileicon: {
    fontSize: "80px",
    padding: "14px",
  },
  profileimgs: {
    backgroundColor: theme.palette.background.tertiary,
    color: "#E4E8EE",
    position: "absolute",
    bottom: "0px",
    right: "0px",
    border: "2px solid white",
    borderRadius: "50%",
  },

  profilesection: {
    marginTop: "14px",
  },
  field: {
    marginTop: "10px",
  },
  address: {
    marginTop: "10px",
    borderBottom: "4px solid #F5F7FA",
    borderTop: "4px solid #F5F7FA",
    marginBottom: "10px",
    padding: "8px 0px",
  },
  addAddress: {
    fontSize: "14px",
    fontFamily: "tenant_bold",
    color: "#5078E1",
    cursor: "pointer",
  },
  addresstitle: {
    fontSize: "16px",
    fontFamily: "tenant_bold",
  },
  budget: {
    height: "44px",
    borderRadius: "10px",
    border: "1px solid #CED3DD",
  },
  Label: {
    color: theme.typography.color.Tertiary,
    fontSize: "12px",
  },
  budgettitle: {
    fontSize: "16px",
    fontFamily: "tenant_extraBold",
  },
  budgetroot: {
    padding: "12px 4px",
    borderBottom: "1px solid black",
  },
  budgetrange: {
    fontSize: "12px",
    fontFamily: "tenant_bold",
    color: theme.typography.color.secondary,
  },
  budgetvalue: {
    fontSize: "16px",
    fontFamily: "tenant_semiBold",
  },
  rangeroot: {
    padding: "8px 14px",
  },
  slidervalue: {
    fontSize: "12px",
    color: theme.typography.color.secondary,
    marginBottom: "10px",
  },
  Requirement: {
    padding: "10px",
    marginTop: "24px",
    textAlign: "center",
    borderRadius: "10px",
    color: "white",
    fontSize: "14px",
    backgroundColor: "#5078E1",
    cursor: "pointer",
  },
  Requirementsqft: {
    padding: "10px",
    marginTop: "24px",
    textAlign: "center",
    borderRadius: "10px",

    fontSize: "14px",

    cursor: "pointer",
    border: "1px solid #E4E8EE",
  },
  Requirements: {
    padding: "10px",

    textAlign: "center",
    borderRadius: "10px",
    color: "white",
    fontSize: "14px",
    backgroundColor: "#5078E1",
    cursor: "pointer",
  },
  Requirementsqfts: {
    padding: "10px",

    textAlign: "center",
    borderRadius: "10px",

    fontSize: "14px",

    cursor: "pointer",
    border: "1px solid #E4E8EE",
  },
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopLeftRadius: "16px",
      borderTopRightRadius: "16px",
      width: "100vw",
    },
    "& .MuiContainer-root": {
      padding: "0px",
      width: "100vw",
    },
  },
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: "16px",
      padding: "0px",
    },
  },
}));

export const Step2 = (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [requirement, setRequirement] = React.useState(false);

  return (
    <>
      <Grid container className={classes.root} spacing={1} alignItems="center">
        {/* name */}
        <Grid item xs={12}>
          <TextBox
            label="Subject"
            placeholder=""
            onChange={(value) => props.updateState("name", value)}
            isError={props?.data?.error?.name?.length > 0}
            errorMessage={props?.data?.error?.name}
            value={props.data.name}
          />
        </Grid>
        {/* requirments */}
        <Grid item xs={12} className={classes.field}>
          <Typography variant="body1" className={classes.Label} gutterBottom>
            Requirements
          </Typography>
          <Box
            onClick={() => setRequirement(!requirement)}
            className={classes.budget}
            display="flex"
            style={{ cursor: "pointer" }}
            alignItems="center"
          >
            <Box flexGrow={1} marginLeft="12px">
              {props.data.requirment}&nbsp;
              {props.data.requirmentType}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Typography
            style={{
              fontSize: "12px",
              color: "red",
              whiteSpace: "nowrap",
            }}
          >
            {props?.data?.error?.requirment}
          </Typography>
        </Grid>

        {/* budget */}
        <Grid item xs={12} className={classes.field}>
          <Typography variant="body1" className={classes.Label} gutterBottom>
            Budget
          </Typography>
          <Box
            onClick={() => setOpen(!open)}
            className={classes.budget}
            display="flex"
            style={{ cursor: "pointer" }}
            alignItems="center"
          >
            <Box flexGrow={1} marginLeft="12px">
              {new Intl.NumberFormat("en-IN", {
                style: "currency",
                currency: "INR",
                minimumFractionDigits: 0,
              }).format(Math.round(props.data.budget[0]))}
              -{" "}
              {new Intl.NumberFormat("en-IN", {
                style: "currency",
                currency: "INR",
                minimumFractionDigits: 0,
              }).format(Math.round(props.data.budget[1]))}
            </Box>
          </Box>
        </Grid>

        {/* lease period */}
        <Grid item xs={12} className={classes.field}>
          <Typography variant="body1" className={classes.Label} gutterBottom>
            Lease period
          </Typography>
        </Grid>
        <Grid item xs={4} sm={3}>
          <Typography
            onClick={() => {
              props.updateState("leaseperiod", "Monthly");
            }}
            className={
              props.data.leaseperiod === "Monthly"
                ? classes.Requirements
                : classes.Requirementsqfts
            }
          >
            Monthly
          </Typography>
        </Grid>
        <Grid item xs={4} sm={3}>
          <Typography
            onClick={() => {
              props.updateState("leaseperiod", "Quarterly");
            }}
            className={
              props.data.leaseperiod === "Quarterly"
                ? classes.Requirements
                : classes.Requirementsqfts
            }
          >
            Quarterly
          </Typography>
        </Grid>
        <Grid item xs={4} sm={3}>
          <Typography
            onClick={() => {
              props.updateState("leaseperiod", "Yearly");
            }}
            className={
              props.data.leaseperiod === "Yearly"
                ? classes.Requirements
                : classes.Requirementsqfts
            }
          >
            Yearly
          </Typography>
        </Grid>
        {props?.data?.error?.leaseperiod > 0 && (
          <Grid item xs={12}>
            <Typography
              style={{
                fontSize: "12px",
                color: "red",
                whiteSpace: "nowrap",
              }}
            >
              {props?.data?.error?.leaseperiod}
            </Typography>
          </Grid>
        )}

        {/* date */}
        <Grid item xs={6} className={classes.field}>
          <DateTime
            onChange={(value) => props.updateState("startdate", value)}
            title="Lease Start Date"
            value={props.data.startdate}
            disableFuture={true}
            time={true}
          />
          <Grid item xs={12}>
            <Typography
              style={{
                fontSize: "12px",
                color: "red",
                whiteSpace: "nowrap",
                marginTop: "8px",
              }}
            >
              {props?.data?.error?.startdate}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={6} className={classes.field}>
          <DateTime
            onChange={(value) => props.updateState("endDate", value)}
            title="Lease End Date"
            value={props.data.endDate}
            time={true}
          />
          <Grid item xs={12}>
            <Typography
              style={{
                fontSize: "12px",
                color: "red",
                whiteSpace: "nowrap",
                marginTop: "8px",
              }}
            >
              {props?.data?.error?.endDate}
            </Typography>
          </Grid>
        </Grid>
        {/* Lead Type */}
        <Grid item xs={12} className={classes.field}>
          <Typography variant="body1" className={classes.Label} gutterBottom>
            Lead Type
          </Typography>
        </Grid>
        <Grid item xs={4} sm={3}>
          <Typography
            onClick={() => {
              props.updateState("leadtype", 1);
            }}
            className={
              props.data.leadtype === 1
                ? classes.Requirements
                : classes.Requirementsqfts
            }
          >
            Commercial
          </Typography>
        </Grid>
        <Grid item xs={4} sm={3}>
          <Typography
            onClick={() => {
              props.updateState("leadtype", 2);
            }}
            className={
              props.data.leadtype === 2
                ? classes.Requirements
                : classes.Requirementsqfts
            }
          >
            Residential
          </Typography>
        </Grid>

        {/* Priority Type */}
        <Grid item xs={12} className={classes.field}>
          <Typography variant="body1" className={classes.Label} gutterBottom>
            Priority Type
          </Typography>
        </Grid>

        <Grid item xs={4} sm={3}>
          <Typography
            onClick={() => {
              props.updateState("priority", "high");
            }}
            className={
              props.data.priority === "high"
                ? classes.Requirements
                : classes.Requirementsqfts
            }
          >
            High
          </Typography>
        </Grid>
        <Grid item xs={4} sm={3}>
          <Typography
            onClick={() => {
              props.updateState("priority", "medium");
            }}
            className={
              props.data.priority === "medium"
                ? classes.Requirements
                : classes.Requirementsqfts
            }
          >
            Medium
          </Typography>
        </Grid>
        <Grid item xs={4} sm={3}>
          <Typography
            onClick={() => {
              props.updateState("priority", "low");
            }}
            className={
              props.data.priority === "low"
                ? classes.Requirements
                : classes.Requirementsqfts
            }
          >
            Low
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            style={{
              fontSize: "12px",
              color: "red",
              whiteSpace: "nowrap",
            }}
          >
            {props?.data?.error?.priority}
          </Typography>
        </Grid>
        {/* Notes */}
        <Grid item xs={12} className={classes.field}>
          <TextBox
            label="Notes"
            placeholder=" "
            multiline
            onChange={(value) => props.updateState("notes", value)}
            value={props.data.notes}
          />
        </Grid>
        {/* Lead owner */}
        {/* <Grid item xs={12} className={classes.field}>
          <SelectBox
            label="Lead owner"
            options={options}
            onChange={(value) => props.updateState("owner", value)}
            value={props.data.owner}
            isDisabled={true}
          />
          <br />
          <br />
        </Grid> */}
      </Grid>
      <br />
      <Hidden smDown>
        <Dialog
          onClose={() => {
            setOpen(!open);
          }}
          className={classes.dialog}
          open={open}
          maxWidth="sm"
          fullWidth="true"
        >
          <Box
            display="flex"
            className={classes.budgeroot}
            p={1}
            alignItems="center"
          >
            <Box flexGrow={1}>
              <Typography className={classes.budgettitle}>Budget</Typography>
            </Box>
            <Box>
              <IconButton
                size="small"
                className={classes.close}
                onClick={() => {
                  setOpen(!open);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          <Divider />

          <div className={classes.rangeroot}>
            <Typography className={classes.budgetrange}>
              Budget Range
            </Typography>
            {props.data.budget.length > 0 && (
              <>
                <Typography className={classes.budgetvalue}>
                  {new Intl.NumberFormat("en-IN", {
                    style: "currency",
                    currency: "INR",
                    minimumFractionDigits: 0,
                  }).format(Math.round(props.data.budget[0]))}{" "}
                  -{" "}
                  {new Intl.NumberFormat("en-IN", {
                    style: "currency",
                    currency: "INR",
                    minimumFractionDigits: 0,
                  }).format(Math.round(props.data.budget[1]))}
                </Typography>
              </>
            )}

            <br />
            <RangeSlider
              min={0}
              max={50000}
              value={props.data.budget}
              onChange={(value) => props.updateState("budget", value)}
            />
            <Box display="flex" alignItems="center">
              <Box flexGrow={1}>
                <Typography className={classes.slidervalue}>$ 0</Typography>
              </Box>
              <Box>
                <Typography className={classes.slidervalue}>
                  $ 50,000
                </Typography>
              </Box>
            </Box>
            <br />
            <Button
              fullWidth
              variant="contained"
              onClick={() => {
                setOpen(!open);
              }}
              save={(value) => props.updateState("budget", value)}
            >
              Save
            </Button>
          </div>
        </Dialog>
      </Hidden>
      <Hidden smUp>
        <Drawer
          onClose={() => {
            setOpen(!open);
          }}
          className={classes.drawer}
          anchor="bottom"
          open={open}
        >
          <Box
            display="flex"
            className={classes.budgeroot}
            p={1}
            alignItems="center"
          >
            <Box flexGrow={1}>
              <Typography className={classes.budgettitle}>Budget</Typography>
            </Box>
            <Box>
              <IconButton
                size="small"
                className={classes.close}
                onClick={() => {
                  setOpen(!open);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          <Divider />

          <div className={classes.rangeroot}>
            <Typography className={classes.budgetrange}>
              Budget Range
            </Typography>
            {props.data.budget.length > 0 && (
              <>
                <Typography className={classes.budgetvalue}>
                  {new Intl.NumberFormat("en-IN", {
                    style: "currency",
                    currency: "INR",
                    minimumFractionDigits: 0,
                  }).format(Math.round(props.data.budget[0]))}{" "}
                  -{" "}
                  {new Intl.NumberFormat("en-IN", {
                    style: "currency",
                    currency: "INR",
                    minimumFractionDigits: 0,
                  }).format(Math.round(props.data.budget[1]))}
                </Typography>
              </>
            )}

            <br />
            <RangeSlider
              min={0}
              max={50000}
              value={props.data.budget}
              onChange={(value) => props.updateState("budget", value)}
            />
            <Box display="flex" alignItems="center">
              <Box flexGrow={1}>
                <Typography className={classes.slidervalue}>$ 0</Typography>
              </Box>
              <Box>
                <Typography className={classes.slidervalue}>
                  $ 50,000
                </Typography>
              </Box>
            </Box>
            <br />
            <Button
              fullWidth
              variant="contained"
              onClick={() => {
                setOpen(!open);
              }}
            >
              Save
            </Button>
          </div>
        </Drawer>
      </Hidden>
      <Hidden smDown>
        <Dialog
          onClose={() => {
            setRequirement(!requirement);
          }}
          className={classes.dialog}
          open={requirement}
          maxWidth="sm"
          fullWidth="true"
        >
          <Box
            display="flex"
            className={classes.budgeroot}
            p={1}
            alignItems="center"
          >
            <Box flexGrow={1}>
              <Typography className={classes.budgettitle}>
                Requirement
              </Typography>
            </Box>
            <Box>
              <IconButton
                size="small"
                className={classes.close}
                onClick={() => {
                  setRequirement(!requirement);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          <Divider />

          <div className={classes.rangeroot}>
            <Grid container>
              <Grid item xs={12}>
                <TextBox
                  label="Requirement"
                  placeholder=""
                  onChange={(value) => props.updateState("requirment", value)}
                  isError={props?.data?.error?.requirment?.length > 0}
                  errorMessage={props?.data?.error?.requirment}
                  value={props.data.requirment}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={1} style={{ padding: "0px" }}>
                  <Grid item xs={3}>
                    <Typography
                      onClick={() => {
                        props.updateState("requirmentType", "sq.ft");
                      }}
                      className={
                        props.data.requirmentType === "sq.ft"
                          ? classes.Requirement
                          : classes.Requirementsqft
                      }
                    >
                      sq.ft
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography
                      onClick={() => {
                        props.updateState("requirmentType", "sq.mts");
                      }}
                      className={
                        props.data.requirmentType === "sq.mts"
                          ? classes.Requirement
                          : classes.Requirementsqft
                      }
                    >
                      sq.mts
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography
                      onClick={() => {
                        props.updateState("requirmentType", "acre");
                      }}
                      className={
                        props.data.requirmentType === "acre"
                          ? classes.Requirement
                          : classes.Requirementsqft
                      }
                    >
                      acre
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography
                      onClick={() => {
                        props.updateState("requirmentType", "hectare");
                      }}
                      className={
                        props.data.requirmentType === "hectare"
                          ? classes.Requirement
                          : classes.Requirementsqft
                      }
                    >
                      hectare
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      style={{
                        fontSize: "12px",
                        color: "red",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {props?.data?.error?.requirmentType}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <br />
            <Button
              fullWidth
              variant="contained"
              onClick={() => {
                setRequirement(!requirement);
              }}
            >
              Save
            </Button>
          </div>
        </Dialog>
      </Hidden>
      <Hidden smUp>
        <Drawer
          onClose={() => {
            setRequirement(!requirement);
          }}
          className={classes.drawer}
          anchor="bottom"
          open={requirement}
        >
          <Box
            display="flex"
            className={classes.budgeroot}
            p={1}
            alignItems="center"
          >
            <Box flexGrow={1}>
              <Typography className={classes.budgettitle}>
                Requirement
              </Typography>
            </Box>
            <Box>
              <IconButton
                size="small"
                className={classes.close}
                onClick={() => {
                  setRequirement(!requirement);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          <Divider />

          <div className={classes.rangeroot}>
            <Grid container>
              <Grid item xs={12}>
                <TextBox
                  label="Requirement"
                  placeholder=""
                  onChange={(value) => props.updateState("requirment", value)}
                  isError={props?.data?.error?.requirment?.length > 0}
                  errorMessage={props?.data?.error?.requirment}
                  value={props.data.requirment}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={1} style={{ padding: "0px" }}>
                  <Grid item xs={3}>
                    <Typography
                      onClick={() => {
                        props.updateState("requirmentType", "sq.ft");
                      }}
                      className={
                        props.data.requirmentType === "sq.ft"
                          ? classes.Requirement
                          : classes.Requirementsqft
                      }
                    >
                      sq.ft
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography
                      onClick={() => {
                        props.updateState("requirmentType", "sq.mts");
                      }}
                      className={
                        props.data.requirmentType === "sq.mts"
                          ? classes.Requirement
                          : classes.Requirementsqft
                      }
                    >
                      sq.mts
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography
                      onClick={() => {
                        props.updateState("requirmentType", "acre");
                      }}
                      className={
                        props.data.requirmentType === "acre"
                          ? classes.Requirement
                          : classes.Requirementsqft
                      }
                    >
                      acre
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography
                      onClick={() => {
                        props.updateState("requirmentType", "hectare");
                      }}
                      className={
                        props.data.requirmentType === "hectare"
                          ? classes.Requirement
                          : classes.Requirementsqft
                      }
                    >
                      hectare
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      style={{
                        fontSize: "12px",
                        color: "red",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {props?.data?.error?.requirmentType}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <br />
            <Button
              fullWidth
              variant="contained"
              onClick={() => {
                setRequirement(!requirement);
              }}
            >
              Save
            </Button>
          </div>
        </Drawer>
      </Hidden>
    </>
  );
};
