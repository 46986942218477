import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Container,
  Dialog,
  Divider,
  Drawer,
  Grid,
  Hidden,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import {
  ContactList,
  DocumentViewer,
  PayCard,
  PayCard2,
  TitleBar,
  UnitList,
} from "../../components";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { PaymentScreen } from "./paymentScreen";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "white",
    overflow: "hidden",
  },
  total: {
    fontSize: "14px",
    fontFamily: "tenant_bold",
  },
  addProperties: {
    fontSize: "14px",
    fontFamily: "tenant_bold",
    color: theme.palette.primary.main,
  },
  content: {
    padding: "16px",
  },
  content2: {
    padding: " 0px 16px",
  },
  content3: {
    padding: "16px 16px 4px",
  },
  topNavBarStyle: {
    boxShadow: "0px 2px 6px #00000014",
    // position: "sticky",
    // top: 0
  },
  containers: {
    "&.MuiContainer-root": {
      paddingLeft: "40px",
      paddingRight: "40px",
    },
    overflow: "hidden",
    backgroundColor: "transparent",
  },
  screen: {
    backgroundColor: "white",
  },
  bottomScreen: {
    backgroundColor: "white",
    borderRadius: "8px",
    border: "1px solid #E4E8EE",
    boxShadow: "0px 8px 24px #1C1C1C40",
    [theme.breakpoints.up("sm")]: {
      margin: "0px 26px",
    },
  },
  leadDetails: {
    backgroundColor: "white",
    padding: "4px 16px",
    borderBottom: "4px solid #F5F7FA",
  },
  btnRoot: {
    backgroundColor: "#F5F7FA",
    padding: "16px",
    border: "1px solid #F9FAFE",
    borderTopLeftRadius: "20px",
    borderTopRightRadius: "20px",
    position: "fixed",
    bottom: 0,
    right: 0,
    left: 0,
  },
  btnPrevious: {
    borderRadius: "12px",
    padding: "8px",
  },
  screenPadding: {
    overflow: "overlay",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  drawer: {
    "& .css-9emuhu-MuiPaper-root-MuiDrawer-paper": {
      "background-color": "transparent",
      "box-shadow": "none",
    },
  },
  dialog: {
    "& .css-tlc64q-MuiPaper-root-MuiDialog-paper": {
      "background-color": "transparent",
      "box-shadow": "none",
    },
  },
  bottomSheetStyle: {
    background: "white",
    borderRadius: "16px 16px 0px 0px",
    border: "1px solid #E4E8EE",
    justifyContent: "center",
  },
  dialogStyle: {
    background: "white",
    borderRadius: "16px",
    border: "1px solid #E4E8EE",
    justifyContent: "center",
  },
  drawerHeaderStyle: {
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px",
  },
  drawerHeaderTextStyle: {
    fontSize: "16px",
    fontWeight: "bolder",
    color: theme.typography.color.primary,
  },
  drawerHeaderTextStyle1: {
    textAlign: "center",
    fontSize: "16px",
    fontWeight: "bolder",
    color: theme.typography.color.primary,
  },
  drawerBodyStyle: {
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px",
  },
  drawerButtonStyle: {
    "&:hover": "none",
    borderRadius: "12px",
    padding: "14px",
    background: theme.palette.primary.main,
    fontSize: "14px",
    fontWeight: "normal",
    color: "#FFF",
  },
  drawerButtonStyle1: {
    "&:hover": {
      background: "white",
      color: theme.palette.primary.main,
    },
    borderRadius: "12px",
    padding: "14px",
    background: "white",
    fontSize: "14px",
    fontWeight: "normal",
    color: theme.palette.primary.main,
  },
}));

const reducer = (state, action) => {
  let invoicesCartCopy = {
    ...state,
  };
  switch (action.type) {
    case "ADD":
      //to add that invoice to the invoices key
      invoicesCartCopy.invoices = [
        ...invoicesCartCopy.invoices,
        action.payload,
      ];
      //to increment the total value
      invoicesCartCopy.total =
        invoicesCartCopy.total + parseFloat(action.payload.amount);

      return invoicesCartCopy;
    case "REMOVE":
      //to remove that invoice to the invoices key
      let invoices = [...invoicesCartCopy.invoices];
      const result = invoices.filter(
        (invoice) => invoice.id !== action.payload.id
      );
      invoicesCartCopy.invoices = result;
      //to decrement the total value
      invoicesCartCopy.total =
        invoicesCartCopy.total - parseFloat(action.payload.amount);

      return invoicesCartCopy;
    default:
      return state;
  }
};

export const LeadShortList = (props) => {
  const classes = useStyles();
  const size = useWindowDimensions();
  const [showDrawer, setShowDrawer] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const [openDrawer, setopenDrawer] = React.useState(false);
  const [breakDrawer, setbreakDrawer] = React.useState(false);
  const [breakData, setbreakData] = React.useState([]);
  const Preview = () => {
    setopenDrawer(true);
  };
  const breakPreview = (data) => {
    setbreakData(data);
    setbreakDrawer(true);
  };
  const view = () => {
    setShowDrawer(true);
    setShow(true);
  };

  const Back = () => {
    setShowDrawer(false);
    setShow(false);
  };
  const [invoiceCart, setInvoiceCart] = React.useReducer(reducer, {
    invoices: [],
    total: 0,
  });

  const goBack = () => {
    window.history.go(-1);
  };
  const unitlisted = [
    {
      id: 0,
      image: "/images/image1.png",
      title: "K-F01-394",
      address: "K Tower, 1 Floor",
      sqft: 1250,
      bed: 3,
      amount: "1,200.00",
      shortlist: 12,
      location: "tnagar",
    },
    {
      id: 1,
      image: "/images/image1.png",
      title: "K-F01-394",
      address: "K Tower, 1 Floor",
      sqft: 1250,
      bed: 3,
      amount: "1,200.00",
      shortlist: 12,
      location: "tnagar",
    },
  ];
  const contactlist = [
    {
      id: 1,
      name: "Tom Cruise",
      mail: "414151243 (Account ID)",
      phone: "+91 9090808012",
      img: "/images/contact1.png",
      arg: "AGR1234",
    },
  ];

  return (
    <div className={classes.root}>
      <div hidden={show}>
        <Container maxWidth="sm" sx={{ padding: 0 }}>
          <Grid container className={classes.screen}>
            <Grid item xs={12} className={classes.topNavBarStyle}>
              {props.titlebar ? (
                ""
              ) : (
                <TitleBar text="Shortlists" goBack={goBack} />
              )}
            </Grid>
            <Grid
              container
              className={classes.screenPadding}
              style={{ height: size?.height - 60 }}
            >
              <Grid item xs={12}>
                <div className={classes.content}>
                  <Box display="flex" alignItems="center">
                    {/* Total Invoice */}
                    <Box flexGrow={1}>
                      <Typography className={classes.total}>
                        Shortlists&nbsp;(3)
                      </Typography>
                    </Box>
                    <Box>
                      <Typography className={classes.addProperties}>
                        + Add Properties
                      </Typography>
                    </Box>
                  </Box>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.content2}>
                  <Box display="flex" alignItems="center">
                    {/* Total Invoice */}
                    <Box>
                      <img src="images/rubix.svg" alt="apartment" />
                    </Box>
                    <Box>
                      <Typography className={classes.total}>
                        Rubix Apartment
                      </Typography>
                    </Box>
                  </Box>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div style={{ backgroundColor: "white", padding: "4px 16px" }}>
                  {unitlisted.map((val) => {
                    return (
                      <React.Fragment key={val.id}>
                        <UnitList
                          setInvoiceCart={setInvoiceCart}
                          data={val}
                          checked
                        />
                      </React.Fragment>
                    );
                  })}
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.content3}>
                  <Box display="flex" alignItems="center">
                    {/* Total Invoice */}
                    <Box>
                      <img src="images/rubix.svg" alt="apartment" />
                    </Box>
                    <Box>
                      <Typography className={classes.total}>
                        Topas Apartment
                      </Typography>
                    </Box>
                  </Box>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div style={{ backgroundColor: "white", padding: "4px 16px" }}>
                  {unitlisted.map((val) => {
                    return (
                      <React.Fragment key={val.id}>
                        <UnitList
                          setInvoiceCart={setInvoiceCart}
                          data={val}
                          checked
                        />
                      </React.Fragment>
                    );
                  })}
                </div>
              </Grid>
            </Grid>
          </Grid>
          {invoiceCart.invoices.length > 0 && (
            <Grid container>
              <Grid item xs={12} md={12} lg={12} className={classes.containers}>
                <Paper
                  sx={{
                    position: "fixed",
                    bottom: 0,
                    left: 0,
                    right: 0,
                    boxShadow: "none",
                    backgroundColor: "transparent",
                    padding: "12px",
                  }}
                  elevation={3}
                >
                  <Container maxWidth="sm" sx={{ padding: 0 }}>
                    <div className={classes.bottomScreen}>
                      <PayCard
                        type="invoice"
                        data={invoiceCart}
                        handleOnClick={view}
                      />
                    </div>
                  </Container>
                </Paper>
              </Grid>
            </Grid>
          )}
        </Container>
      </div>
      {/* create quotation */}
      {showDrawer && (
        <>
          <Container maxWidth="sm" sx={{ padding: 0 }}>
            <Grid container className={classes.screen}>
              <Grid item xs={12} className={classes.topNavBarStyle}>
                {props.titlebar ? (
                  ""
                ) : (
                  <TitleBar text="Create Quotation" goBack={Back} />
                )}
              </Grid>
            </Grid>
            <Grid container className={classes.leadDetails}>
              <Grid item xs={12}>
                <Box display="flex" alignItems="center">
                  {/* Total Invoice */}
                  <Box>
                    <Typography className={classes.total}>
                      Lead Details
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                {contactlist.map((val) => {
                  return <ContactList data={val} />;
                })}
              </Grid>
            </Grid>
            <Grid
              container
              className={classes.screenPadding}
              style={{
                height:
                  invoiceCart.invoices.length > 1
                    ? size?.height - 300
                    : size?.height - 450,
              }}
            >
              {" "}
              <Grid item xs={12}>
                <Box display="flex" alignItems="center">
                  <Box>
                    <Typography className={classes.total}>
                      Quotations
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                {invoiceCart?.invoices?.map((val) => {
                  return (
                    <UnitList
                      data={val}
                      breakup={true}
                      onClick={breakPreview}
                    />
                  );
                })}
              </Grid>
            </Grid>
            {invoiceCart.invoices.length > 0 && (
              <Paper
                sx={{
                  position: "fixed",
                  bottom: "70px",
                  left: 0,
                  right: 0,
                  boxShadow: "none",
                  backgroundColor: "transparent",
                  padding: "12px",
                }}
                elevation={3}
              >
                <Container maxWidth="sm" sx={{ padding: 0 }}>
                  <div className={classes.bottomScreen}>
                    <PayCard2
                      type="invoice"
                      data={invoiceCart}
                      handleOnClick={Preview}
                    />
                  </div>
                </Container>
              </Paper>
            )}
          </Container>
          <Container maxWidth="sm" className={classes.btnRoot}>
            <Grid container className={classes.saveNUpdateButtonContainerStyle}>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="contained"
                  className={classes.btnPrevious}
                >
                  Create
                </Button>
              </Grid>
            </Grid>
          </Container>
        </>
      )}
      {/*preview drawer */}
      <Hidden smDown>
        <Dialog
          className={classes.dialog}
          fullWidth={true}
          maxWidth="sm"
          open={openDrawer}
        >
          <Grid container direction="column" className={classes.dialogStyle}>
            <Grid
              container
              direction="row"
              className={classes.drawerHeaderStyle}
            >
              <Grid>
                <Typography className={classes.drawerHeaderTextStyle}>
                  Preview Quotation
                </Typography>
              </Grid>
              <Grid>
                <IconButton
                  style={{
                    padding: "0px",
                  }}
                  onClick={() => setopenDrawer(false)}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Divider />
            <Grid className={classes.drawerBodyStyle}>
              <DocumentViewer url="https://storage.healthcircles.care/docs/v1/file/8803bdf0-a94e-4433-ad51-376ef2a2d4f5.pdf" />
            </Grid>
          </Grid>
        </Dialog>
      </Hidden>
      <Hidden smUp>
        <Drawer className={classes.drawer} anchor="bottom" open={openDrawer}>
          <Grid
            container
            direction="column"
            className={classes.bottomSheetStyle}
          >
            <Grid
              container
              direction="row"
              className={classes.drawerHeaderStyle}
            >
              <Grid>
                <Typography className={classes.drawerHeaderTextStyle}>
                  Preview Quotation
                </Typography>
              </Grid>
              <Grid>
                <IconButton
                  style={{
                    padding: "0px",
                  }}
                  onClick={() => setopenDrawer(false)}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Divider />
            <Grid className={classes.drawerBodyStyle}>
              <DocumentViewer url="https://storage.healthcircles.care/docs/v1/file/8803bdf0-a94e-4433-ad51-376ef2a2d4f5.pdf" />
            </Grid>
          </Grid>
        </Drawer>
      </Hidden>
      {/* drawer */}
      <Hidden smDown>
        <Dialog
          className={classes.dialog}
          fullWidth={true}
          maxWidth="sm"
          open={breakDrawer}
        >
          <Grid container direction="column" className={classes.dialogStyle}>
            <Grid
              container
              direction="row"
              className={classes.drawerHeaderStyle}
            >
              <Grid>
                <Typography className={classes.drawerHeaderTextStyle}>
                  Quotation Breakup
                </Typography>
              </Grid>
              <Grid>
                <IconButton
                  style={{
                    padding: "0px",
                  }}
                  onClick={() => setbreakDrawer(false)}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Divider />
            <Grid className={classes.drawerBodyStyle}>
              <PaymentScreen invoiceCart={breakData} />
            </Grid>
          </Grid>
        </Dialog>
      </Hidden>
      <Hidden smUp>
        <Drawer className={classes.drawer} anchor="bottom" open={breakDrawer}>
          <Grid
            container
            direction="column"
            className={classes.bottomSheetStyle}
          >
            <Grid
              container
              direction="row"
              className={classes.drawerHeaderStyle}
            >
              <Grid>
                <Typography className={classes.drawerHeaderTextStyle}>
                  Quotation Breakup
                </Typography>
              </Grid>
              <Grid>
                <IconButton
                  style={{
                    padding: "0px",
                  }}
                  onClick={() => setbreakDrawer(false)}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Divider />
            <Grid className={classes.drawerBodyStyle}>
              <PaymentScreen invoiceCart={breakData} />
            </Grid>
          </Grid>
        </Drawer>
      </Hidden>
    </div>
  );
};
