import React from "react";
import { ActivityLead } from "./activitylead";

class ActivityLeadParent extends React.Component {
  render(props) {
    return (
      <ActivityLead leadId={this.props.leadId} active={this.props.active} />
    );
  }
}

export default ActivityLeadParent;
