import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { TitleBar, UnitList } from "../../components";
import { Routes } from "../../router/routes";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "rgba(245, 247, 250, 1)",
  },
  propertyimg: {
    position: "relative",
  },
  propertydata: {
    position: "absolute",
    bottom: "8px",
    backgroundColor: "#08080862",
    width: "100%",
    padding: "18px",
  },
  title: {
    fontSize: "18px",
    fontFamily: "tenant_semiBold",
    color: "white",
  },
  reqid: {
    fontSize: "10px",
    fontFamily: "tenant_bold",
    backgroundColor: "rgba(210, 215, 228, 1)",
    padding: "2px 8px",
    borderRadius: "4px",
  },
  address: {
    fontSize: "12px",
    fontFamily: "tenant_bold",
    color: "white",
  },
  location: {
    marginTop: "8px",
  },
  selectroot: {
    backgroundColor: "",
  },
  unitlist: {
    padding: "12px",
    backgroundColor: "white",
  },
  unittotal: {
    fontSize: "16px",
    fontFamily: "tenant_bold",
  },
  select: {
    fontSize: "12px",
    fontFamily: "tenant_semiBold",
    color: theme.typography.color.Tertiary,
  },
  payCard: {
    backgroundColor: "white",
    padding: "8px",
    borderRadius: "10px",
    margin: "0px 12px",
    boxShadow: "0px 8px 24px #1C1C1C40",
  },
  iconButtonStyle: {
    borderRadius: "8px",
    padding: "8px 16px",

    fontFamily: "tenant_bold",
    boxShadow: "0px 2px 6px #00000021",
  },
  selected: {
    fontSize: "16px",
    fontFamily: "tenant_bold",
    marginLeft: "4px",
  },
  payButtonTextStyle: {
    fontSize: "14px",
    fontFamily: "tenant_semiBold",
  },
  calunit: {
    fontSize: "12px",
    fontFamily: "tenant_semiBold",
    color: theme.typography.color.Tertiary,
  },
  shortlist: {
    fontSize: "10px",
    fontFamily: "tenant_semiBold",
    color: theme.typography.color.secondary,
  },
}));
const reducer = (state, action) => {
  let invoicesCartCopy = {
    ...state,
  };
  switch (action.type) {
    case "ADD":
      //to add that invoice to the invoices key
      invoicesCartCopy.invoices = [
        ...invoicesCartCopy.invoices,
        action.payload,
      ];
      //to increment the total value
      invoicesCartCopy.total =
        invoicesCartCopy.total + parseFloat(action.payload.amount);

      return invoicesCartCopy;
    case "REMOVE":
      //to remove that invoice to the invoices key
      let invoices = [...invoicesCartCopy.invoices];
      const result = invoices.filter(
        (invoice) => invoice.id !== action.payload.id
      );
      invoicesCartCopy.invoices = result;
      //to decrement the total value
      invoicesCartCopy.total =
        invoicesCartCopy.total - parseFloat(action.payload.amount);

      return invoicesCartCopy;
    default:
      return state;
  }
};
export const Propertyview = (props) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const [data, setData] = React.useState([]);
  const [invoiceCart, setInvoiceCart] = React.useReducer(reducer, {
    invoices: [],
    total: 0,
  });
  React.useEffect(() => {
    setData(location.state.data);
  }, [location.state.data]);
  const goback = () => {
    history.goBack();
  };
  const unitlist = [
    {
      id: 1,
      image: "/images/image1.png",
      title: "K-F01-394",
      address: "K Tower, 1 Floor",
      sqft: 1250,
      bed: 3,
      amount: "1,200.00",
      shortlist: 12,
    },
    {
      id: 2,
      image: "/images/image1.png",
      title: "K-F01-394",
      address: "K Tower, 1 Floor",
      sqft: 1250,
      bed: 3,
      amount: "1,200.00",
    },
    {
      id: 3,
      image: "/images/image1.png",
      title: "K-F01-394",
      address: "K Tower, 1 Floor",
      sqft: 1250,
      bed: 3,
      amount: "1,200.00",
    },
  ];
  return (
    <div>
      <Container maxWidth="sm" sx={{ padding: "0px" }}>
        <Grid container className={classes.root}>
          <Grid item xs={12}>
            <TitleBar text={data.title} goBack={goback} />

            <div className={classes.propertyimg}>
              <img
                style={{ height: "100%", width: "100%", objectFit: "cover" }}
                src="https://media.istockphoto.com/photos/classic-italian-appartement-picture-id1290254343?b=1&k=20&m=1290254343&s=170667a&w=0&h=fjFhURiEyDW8au7_iwhjkpxTvFzGM9bFY4SZxzlyMzU="
                alt=""
              />
              <div className={classes.propertydata}>
                <Box display={"flex"} alignItems={"center"}>
                  <Box>
                    <Typography className={classes.title}>
                      {data.title}
                    </Typography>
                  </Box>
                  <Box marginLeft="8px">
                    <Typography className={classes.reqid}>
                      {data.Reqid}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  className={classes.location}
                >
                  <Box>
                    <img src="/images/icons8-locationpeop.svg" alt="" />
                  </Box>
                  <Box marginLeft="8px">
                    <Typography className={classes.address}>
                      {data.address}
                    </Typography>
                  </Box>
                </Box>
              </div>
            </div>
            <Box
              display={"flex"}
              alignItems={"center"}
              className={classes.selectroot}
            >
              <Box flexGrow={1}>
                <Typography className={classes.unittotal}>
                  &nbsp;&nbsp;Units(
                  {unitlist.length < 10
                    ? `0${unitlist.length}`
                    : unitlist.length}
                  )
                </Typography>
              </Box>
              <Box marginLeft="8px" display="flex" alignItems={"center"}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checkedIcon={<CheckCircleIcon />}
                      icon={
                        <CheckCircleOutlineIcon style={{ color: "#c1c1c1" }} />
                      }
                    />
                  }
                  label={
                    <Typography className={classes.select}>
                      Select All
                    </Typography>
                  }
                />
              </Box>
            </Box>
            <div className={classes.unitlist}>
              {unitlist.map((val) => {
                return (
                  <UnitList
                    setInvoiceCart={setInvoiceCart}
                    data={val}
                    invoiceData={val}
                    checked={true}
                    propertyview={true}
                  />
                );
              })}
              <br />
              <br />
            </div>
          </Grid>
        </Grid>
        {invoiceCart.invoices.length > 0 && (
          <Grid container>
            <Grid item xs={12} md={12} lg={12} className={classes.containers}>
              <Paper
                sx={{
                  position: "fixed",
                  bottom: 0,
                  left: 0,
                  right: 0,
                  boxShadow: "none",
                  backgroundColor: "transparent",
                  padding: "12px",
                }}
                elevation={3}
              >
                <Container maxWidth="sm" sx={{ padding: 0 }}>
                  <div className={classes.bottomScreen}>
                    <Box
                      display="flex"
                      alignItems="center"
                      p={1}
                      className={classes.payCard}
                    >
                      <Box flexGrow={1}>
                        <Typography className={classes.shortlist}>
                          &nbsp;SHORTLISTED&nbsp;
                        </Typography>
                        <Typography className={classes.selected}>
                          {invoiceCart.invoices.length < 10
                            ? `0${invoiceCart.invoices.length}`
                            : `${invoiceCart.invoices.length} `}
                          <span className={classes.calunit}> Units</span>
                        </Typography>
                      </Box>
                      <Box>
                        <Button
                          variant="contained"
                          className={classes.iconButtonStyle}
                          onClick={() => {
                            history.push(Routes.shortlistedunits);
                          }}
                        >
                          <Typography className={classes.payButtonTextStyle}>
                            &nbsp;View Shortlist&nbsp;
                          </Typography>
                        </Button>
                      </Box>
                    </Box>
                  </div>
                </Container>
              </Paper>
            </Grid>
          </Grid>
        )}
      </Container>
    </div>
  );
};
