
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import {
    IconButton,
    InputAdornment,

    TextField,
    Typography
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from "prop-types";
import React from "react";

const useStyles = makeStyles((theme) => ({
    Label: {
        color: theme.typography.color.Tertiary,
        fontSize: "12px"
    },
    text: {

    },

    textbox: {

        [`& fieldset`]: {
            borderRadius: "10px",
            height: (props) => props.multiline ? "unset" : 50,
            border: "1px solid #CED3DD",
            "& :hover": {
                border: "1px solid #5078E1",
            },
        },

    },

}));



export const Password = (props) => {

    const classes = useStyles(props);

    const getTitle = (props) => {
        return <Typography variant="body1" style={{ fontSize: "11px", color: "textsecondary" }} gutterBottom >{props.label} </Typography>
    }
    const [showPassword, setShowPassword] = React.useState();

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <div className={classes.root}>
            <>
                {getTitle(props)}
                <TextField
                    className={classes.textbox}
                    id={props.id}
                    placeholder={props.placeholder}
                    variant={props.variant ?? "outlined"}
                    fullWidth
                    type={showPassword ? "text" : "password"}
                    InputLabelProps={{
                        shrink: false
                    }}
                    size="small"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="Toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                >
                                    {showPassword ? (
                                        <RemoveRedEyeOutlinedIcon />
                                    ) : (
                                        <VisibilityOffOutlinedIcon />
                                    )}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    disabled={props?.disabled ?? false}


                    onChange={props.onChange}
                    value={props.value}


                />
            </>
            {props.isError && <Typography variant={"caption"} color={"error"}>{props.errorMessage}</Typography>}

        </div>
    );
};

Password.propTypes = {
    value: PropTypes.string,
    label: PropTypes.string,
    id: PropTypes.string,
    multiline: PropTypes.bool,
    type: PropTypes.string,
    isReadonly: PropTypes.bool,
    onChange: PropTypes.func,
};
Password.defaultProps = {
    label: "Textbox",
    multiline: false,
    type: "text",
};


