import { Avatar, Box, Divider, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import ShowMoreText from "react-show-more-text";
import { ActivityListContext } from "./createactivityContext";
const useStyles = makeStyles((theme) => ({
  root: {
    overflow: "overlay",
    display: "-webkit-box",
    scrollbarWidth: "none",
    margin: "10px 16px",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  previewCardphone: {
    backgroundColor: "#55B0CF",
    color: "#EFFCFF",
    borderRadius: "10px",
  },
  name: {
    fontSize: "14px",
    fontFamily: "tenant_semiBold",

    textTransform: "capitalize",
  },
  icon: {
    backgroundColor: "#F7F5FF",
  },
  title: {
    fontSize: "10px",
    fontFamily: "tenant_semiBold",
    color: theme.typography.color.Tertiary,
    textTransform: "capitalize",
  },
  sub: {
    fontSize: "13px",
    fontFamily: "tenant_semiBold",
    color: theme.typography.color.secondary,
    textTransform: "capitalize",
  },
  data: {
    padding: "6px",
    textAlign: "center",
  },
  divider: {
    border: "2px solid #F5F7FA",
  },
  note: {
    fontSize: "14px",
    fontFamily: "tenant_semiBold",
    textTransform: "capitalize",
    color: theme.typography.color.secondary,
  },
  notetitle: {
    fontSize: "10px",
    fontFamily: "tenant_semiBold",
    color: theme.typography.color.Tertiary,
    textTransform: "capitalize",
    marginTop: "10px",
  },
  previewCardfollow: {
    backgroundColor: "#35A27D",
    color: "#EFFCFF",
    borderRadius: "10px",
  },
  previewCardmeeting: {
    backgroundColor: "#8887D4",
    color: "#F8F8FF",
    borderRadius: "10px",
  },
  previewCardappoinment: {
    backgroundColor: "#E53468 ",
    color: "#FFF3F7",
    borderRadius: "10px",
  },
  previewCardsite: {
    backgroundColor: "#E29336",
    color: "#FFF8EF",
    borderRadius: "10px",
  },
  previewCardqutation: {
    backgroundColor: "#5078E1",
    color: "#F2F6FA",
    borderRadius: "10px",
  },
  previewCardmail: {
    backgroundColor: "#78B1FE",
    color: "#F2F6FA",
    borderRadius: "10px",
  },
  noteTextStyle2: {
    fontSize: "12px",
    fontFamily: "tenant_regular",
    color: theme.typography.color.secondary,
    textOverflow: "clip",
  },
  seeMoreLessTextStyle: {
    fontSize: "12px",
    fontFamily: "tenant_semiBold",
    color: theme.palette.primary.main,
    textDecoration: "none",
  },
}));

export const ActivityPreview = (props) => {
  const classes = useStyles();
  const { data, type } = React.useContext(ActivityListContext);
  const date = JSON.stringify(data.date);
  return (
    <div>
      <div className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {/* phonecall */}
            {type === "phone call" && (
              <>
                <Box
                  p={1}
                  display="flex"
                  alignItems="center"
                  className={classes.previewCardphone}
                >
                  <Box>
                    <Avatar className={classes.icon}>
                      <img src="/images/callreview.svg" alt={type} />
                    </Avatar>
                  </Box>
                  <Box flexGrow={1} marginLeft="10px">
                    <Typography className={classes.name}>{type}</Typography>
                  </Box>
                </Box>
              </>
            )}

            {/* follow up */}
            {type === "follow up" && (
              <>
                <Box
                  p={1}
                  display="flex"
                  alignItems="center"
                  className={classes.previewCardfollow}
                >
                  <Box>
                    <Avatar className={classes.icon}>
                      <img src="/images/followreview.svg" alt={type} />
                    </Avatar>
                  </Box>
                  <Box flexGrow={1} marginLeft="10px">
                    <Typography className={classes.name}>{type}</Typography>
                  </Box>
                </Box>
              </>
            )}

            {/* meeting up */}
            {type === "meeting" && (
              <>
                <Box
                  p={1}
                  display="flex"
                  alignItems="center"
                  className={classes.previewCardmeeting}
                >
                  <Box>
                    <Avatar className={classes.icon}>
                      <img src="images/meetreview.svg" alt={type} />
                    </Avatar>
                  </Box>
                  <Box flexGrow={1} marginLeft="10px">
                    <Typography className={classes.name}>{type}</Typography>
                  </Box>
                </Box>
              </>
            )}

            {/* appoinment up */}
            {type === "appoinment" && (
              <>
                <Box
                  p={1}
                  display="flex"
                  alignItems="center"
                  className={classes.previewCardappoinment}
                >
                  <Box>
                    <Avatar className={classes.icon}>
                      <img src="/images/Group 4957.svg" alt={type} />
                    </Avatar>
                  </Box>
                  <Box flexGrow={1} marginLeft="10px">
                    <Typography className={classes.name}>{type}</Typography>
                  </Box>
                </Box>
              </>
            )}

            {/* site visit */}
            {type === "arrange site visit" && (
              <>
                <Box
                  p={1}
                  display="flex"
                  alignItems="center"
                  className={classes.previewCardsite}
                >
                  <Box>
                    <Avatar className={classes.icon}>
                      <img src="/images/sitereview.svg" alt={type} />
                    </Avatar>
                  </Box>
                  <Box flexGrow={1} marginLeft="10px">
                    <Typography className={classes.name}>{type}</Typography>
                  </Box>
                </Box>
              </>
            )}

            {/* site qutation */}
            {type === "send quotation" && (
              <>
                <Box
                  p={1}
                  display="flex"
                  alignItems="center"
                  className={classes.previewCardqutation}
                >
                  <Box>
                    <Avatar className={classes.icon}>
                      <img src="/images/qutationreview.svg" alt={type} />
                    </Avatar>
                  </Box>
                  <Box flexGrow={1} marginLeft="10px">
                    <Typography className={classes.name}>{type}</Typography>
                  </Box>
                </Box>
              </>
            )}

            {/* site mail */}
            {type === "send email" && (
              <>
                <Box
                  p={1}
                  display="flex"
                  alignItems="center"
                  className={classes.previewCardmail}
                >
                  <Box>
                    <Avatar className={classes.icon}>
                      <img src="/images/mail.svg" alt={type} />
                    </Avatar>
                  </Box>
                  <Box flexGrow={1} marginLeft="10px">
                    <Typography className={classes.name}>{type}</Typography>
                  </Box>
                </Box>
              </>
            )}
          </Grid>

          {data.category.value && (
            <Grid item xs={3} className={classes.data}>
              <Typography className={classes.title}>CATEGORY</Typography>
              <Typography className={classes.sub}>
                {data.category.label}
              </Typography>
            </Grid>
          )}
          {data.purpose.value && (
            <Grid item xs={3} className={classes.data}>
              <Typography className={classes.title}>PURPOSE</Typography>
              <Typography className={classes.sub}>
                {data.purpose.label}
              </Typography>
            </Grid>
          )}
          {data.priority && (
            <Grid item xs={3} className={classes.data}>
              <Typography className={classes.title}>PRIORITY</Typography>
              <Typography className={classes.sub}>{data.priority}</Typography>
            </Grid>
          )}
          {data.followtype.value && (
            <Grid item xs={3} className={classes.data}>
              <Typography className={classes.title}>Type</Typography>
              <Typography className={classes.sub}>
                {data.followtype.value}
              </Typography>
            </Grid>
          )}
          {type === "send email" && (
            <Grid item xs={3} className={classes.data}>
              <Typography className={classes.title}>Type</Typography>
              <Typography className={classes.sub}>Mail</Typography>
            </Grid>
          )}
          {data.location && (
            <Grid item xs={3} className={classes.data}>
              <Typography className={classes.title}>Location</Typography>
              <Typography className={classes.sub}>{data.location}</Typography>
            </Grid>
          )}
          {data.mail && (
            <Grid item xs={3} className={classes.data}>
              <Typography className={classes.title}>Mail ID</Typography>
              <Typography className={classes.sub}>{data.mail}</Typography>
            </Grid>
          )}

          {data.date && (
            <Grid item xs={3} className={classes.data}>
              <Typography className={classes.title}>START DATE</Typography>
              <Typography className={classes.sub}>
                {date.slice(1, 11)}
              </Typography>
            </Grid>
          )}
        </Grid>
      </div>
      <Divider className={classes.divider} />
      {data.notes && (
        <div className={classes.root}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography className={classes.notetitle}>NOTE:</Typography>
              <ShowMoreText
                lines={1}
                more="Show More"
                less="Show Less"
                className={classes.noteTextStyle2}
                anchorClass={classes.seeMoreLessTextStyle}
                expanded={false}
                truncatedEndingComponent={"... "}
              >
                <Typography className={classes.note}>{data.notes}</Typography>
              </ShowMoreText>
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  );
};
