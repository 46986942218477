import { Box, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import {
  DateTime,
  MobileNumberInputComponent,
  SelectBox,
  TextBox,
} from "../../components";
import { config } from "../../config";
import { GETCATEGORY, GETSUBCATEGORY } from "../../graphql/queries";
import { NetworkCall } from "../../networkcall/index";
import { NetWorkCallMethods } from "../../utils";
import { ActivityListContext } from "./createactivityContext";

const useStyles = makeStyles((theme) => ({
  field: {
    marginTop: "10px",
  },
  label: {
    fontSize: "12px",
    color: "#98A0AC",
  },
  Requirements: {
    padding: "10px",
    textAlign: "center",
    borderRadius: "10px",
    color: "white",
    fontSize: "14px",
    backgroundColor: "#5078E1",
    cursor: "pointer",
  },
  Requirementsqfts: {
    padding: "10px",
    textAlign: "center",
    borderRadius: "10px",
    fontSize: "14px",
    cursor: "pointer",
    border: "1px solid #E4E8EE",
  },
  search: {
    borderBottom: "4px solid #F5F7FA",
    padding: "18px 0px",
  },
  searchmain: {
    padding: "18px 0px",
  },
  searchtext: {
    color: "#98A0AC",
    fontSize: "14px",
  },
  searchbox: {
    border: "1px solid #E4E8EE",
    borderRadius: "8px",
    backgroundColor: "#F5F7FA",
    padding: "8px 12px",
    cursor: "pointer",
  },
}));
export const ActivityDetails = () => {
  const classes = useStyles();
  const [priority, setPriority] = React.useState("");
  const { data, updateState, type, searchdata, setSearch, leadIds } =
    React.useContext(ActivityListContext);
  const [category, setCategory] = React.useState([]);
  const [subCategory, setSubCategory] = React.useState([]);

  const options2 = [
    { value: "Online", label: "Online" },
    { value: "In-Person", label: "In-Person" },
  ];
  React.useEffect(() => {
    const variables = {
      query: GETCATEGORY,
      variables: {
        activity_id: data.activitytypeId,
      },
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      variables,
      null,
      true,
      false
    )
      .then((response) => {
        const temp_category = response.data.data.activity_category_master?.map(
          (item) => {
            let _cat;
            try {
              _cat = {
                label: item?.activity_category ? item?.activity_category : "",
                value: item?.id ? item?.id : "",
              };
            } catch (error) {
              console.log(error);
            }
            return _cat;
          }
        );
        setCategory(temp_category);
      })

      .catch((error) => {
        console.log(error);
      });
    // eslint-disable-next-line
  }, []);
  const handlePurpose = (val) => {
    updateState("category", val);
    const variables = {
      query: GETSUBCATEGORY,
      variables: {
        master_id: val.value,
      },
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      variables,
      null,
      true,
      false
    )
      .then((response) => {
        const temp_subcategory =
          response.data.data.activity_sub_category_master?.map((item) => {
            let _cat;
            try {
              _cat = {
                label: item?.sub_category ? item?.sub_category : "",
                value: item?.id ? item?.id : "",
              };
            } catch (error) {
              console.log(error);
            }
            return _cat;
          });
        setSubCategory(temp_subcategory);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <Grid container spacing={1}>
        {leadIds ? (
          ""
        ) : (
          <Grid item xs={12} className={classes.search}>
            <Box
              display="flex"
              alignItems="center"
              className={classes.searchbox}
              p={1}
              onClick={() => {
                setSearch(!searchdata);
              }}
            >
              <Box>
                {" "}
                <img src="/images/icons8-search.svg" alt="note" />
              </Box>
              <Box flexGrow={1} marginLeft="14px">
                <Typography className={classes.searchtext}>
                  Search Lead Here
                </Typography>
              </Box>
            </Box>
          </Grid>
        )}
        <Grid item xs={12}>
          <SelectBox
            label="Category"
            options={category}
            value={data?.category}
            onChange={(value) => handlePurpose(value)}
            isError={data.error.category.length > 0}
            errorMessage={data.error.category}
          />

          <SelectBox
            label="Purpose"
            options={subCategory}
            value={data.purpose}
            onChange={(value) => updateState("purpose", value)}
            isError={data.error.purpose.length > 0}
            errorMessage={data.error.purpose}
          />

          {type === "send email" || type === "send quotation" ? (
            <div className={classes.field}>
              <TextBox
                label="Mail ID"
                placeholder=""
                value={data.mail}
                onChange={(e) => updateState("mail", e.target.value)}
                isError={data.error.mail.length > 0}
                errorMessage={data.error.mail}
              />
            </div>
          ) : (
            ""
          )}

          {type === "follow up" ||
          type === "meeting" ||
          type === "appoinment" ? (
            <>
              <SelectBox
                label="Followup Type"
                options={options2}
                value={data.followtype}
                onChange={(value) => updateState("followtype", value)}
                isError={data?.error?.followtype?.length > 0}
                errorMessage={data?.error?.followtype}
              />

              {data.followtype.value === "In-Person" && (
                <div className={classes.field}>
                  <TextBox
                    label="location"
                    placeholder=""
                    onChange={(e) => updateState("location", e.target.value)}
                    value={data.location}
                    isError={data.error.location.length > 0}
                    errorMessage={data.error.location}
                  />
                </div>
              )}
            </>
          ) : (
            ""
          )}

          {type === "phone call" && (
            <div className={classes.field}>
              <MobileNumberInputComponent
                label="Mobile"
                value={data.phone}
                handleChange={(value) => updateState("phone", value)}
                isError={data.error.phone.length > 0}
                errorMessage={data.error.phone}
              />
            </div>
          )}
          <div className={classes.field}>
            <DateTime
              title="Date"
              placeholder=""
              value={data.date}
              onChange={(value) => updateState("date", value)}
              isError={data.error.date.length > 0}
              errorMessage={data.error.date}
            />

            <Typography className={classes.label} style={{ color: "red" }}>
              {data.error.date}
            </Typography>
          </div>
          {type === "arrange site visit" && (
            <div className={classes.field}>
              <TextBox
                label="Location"
                placeholder=""
                value={data.location}
                isError={data.error.location.length > 0}
                errorMessage={data.error.location}
                onChange={(e) => updateState("location", e.target.value)}
              />
            </div>
          )}
          <div className={classes.field}>
            <TextBox
              label="Notes"
              multiline
              placeholder=""
              onChange={(e) => updateState("notes", e.target.value)}
              value={data.notes}
              isError={data.error.notes.length > 0}
              errorMessage={data.error.notes}
            />
          </div>
        </Grid>
        {/* priority */}
        <Grid item xs={12}>
          <Typography className={classes.label}>Priority</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography
            onClick={() => {
              setPriority("Urgent");
              updateState("priority", "Urgent");
            }}
            className={
              priority === "Urgent" || data.priority === "Urgent"
                ? classes.Requirements
                : classes.Requirementsqfts
            }
          >
            Urgent
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography
            onClick={() => {
              setPriority("High");
              updateState("priority", "High");
            }}
            className={
              priority === "High" || data.priority === "High"
                ? classes.Requirements
                : classes.Requirementsqfts
            }
          >
            High
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography
            onClick={() => {
              setPriority("Normal");
              updateState("priority", "Normal");
            }}
            className={
              priority === "Normal" || data.priority === "Normal"
                ? classes.Requirements
                : classes.Requirementsqfts
            }
          >
            Normal
          </Typography>
        </Grid>{" "}
        <Grid item xs={3}>
          <Typography
            onClick={() => {
              setPriority("Low");
              updateState("priority", "Low");
            }}
            className={
              priority === "Low" || data.priority === "Low"
                ? classes.Requirements
                : classes.Requirementsqfts
            }
          >
            Low
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.label} style={{ color: "red" }}>
            {data?.error?.priority}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};
