import {
  Avatar,
  Box,
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useHistory } from "react-router-dom";
import { ContactList, TitleBar } from "../../components";
import { config } from "../../config";
import { SEARCHLEAD } from "../../graphql/queries";
import { NetworkCall } from "../../networkcall/index";
import { NetWorkCallMethods } from "../../utils";
import { CreateQuotationListContext } from "./createquotationcontaxt";
import { ExistingLead } from "./leadDetailsExistingLead";
import { LeadDetailsnew } from "./leadDetailsnew";
import { LeadPreview } from "./leadpreview";
import { Preview } from "./preview";
import { QutationDetails } from "./quotationdetails";
import { Step5 } from "./step5";
import { Typeselect } from "./typeselect";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: "20px",
    backgroundColor: "#F5F7FA",
  },
  type: {
    fontSize: "16px",
    fontFamily: "tenant_bold",
    marginBottom: "10px",
    marginTop: "10px",
  },
  leadroot: {
    boxShadow: "0px 1px 6px #00000014",
    padding: "20px 12px",
    borderRadius: "16px",
    cursor: "pointer",
    backgroundColor: "white",
  },
  textbox: {
    marginBottom: "10px",
    [`& fieldset`]: {
      borderRadius: "10px",
      height: (props) => (props.multiline ? "unset" : 50),
      border: "1px solid #CED3DD",
      "& :hover": {
        border: "1px solid #5078E1",
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: "11px 14px",
    },
    backgroundColor: "#F5F7FA",
  },
  stepindicate: {
    color: theme.typography.color.Tertiary,
    fontSize: "12px",
    fontFamily: "tenant_semiBold",
  },

  stepindicateavatar: {
    backgroundColor: "#5078E1",

    padding: "10px",
  },
  stepindicateavatarborder: {
    border: "1px solid #00000029",
    borderRadius: "50%",
    padding: "4px",
  },
  steps: {
    borderBottom: "4px solid #F5F7FA",
    backgroundColor: "white",
  },
  btnroot: {
    backgroundColor: "#F5F7FA",
    padding: "10px",
  },
  previousbtn: {
    backgroundColor: "white",
    color: "#091B29",
    border: "1px solid #CED3DD",
    borderRadius: "10px",
    padding: "12px 8px",
    boxShadow: "none",
  },
  submitbtn: {
    borderRadius: "10px",
    padding: "12px 8px",
  },
  name: {
    fontSize: "16px",
    fontFamily: "tenant_bold",
  },
}));
export const CreateQuotation = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const {
    data,
    step,
    previous,
    next,
    search,
    setSearch,
    setContactId,
    setAlready,
    setdata,
    setStep,
  } = React.useContext(CreateQuotationListContext);
  const [contactList, setContactList] = React.useState([]);
  const goback = () => {
    if (step > 0) {
      previous();
    } else {
      history.goBack();
    }
  };
  const searchHandle = (search) => {
    const variables = {
      query: SEARCHLEAD,
      variables: {
        search: `%${search}%`,
      },
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      variables,
      null,
      true,
      false
    )
      .then((response) => {
        setContactList(response.data.data.contact);
      })

      .catch((error) => {
        console.log(error);
      });
  };
  const goBacksearch = () => {
    setSearch(!search);
  };
  const getSearchdata = (val) => {
    setStep(1);
    setdata({
      type: "new lead",
      leadDetails: [],
      leaseperiod: "",
      leasestartdate: "",
      leaseenddate: "",

      duration: "",
      durationtype: "",
      desp: "",
      valid: "",
      gradeperiod: "",
      gradeperiodtype: "",

      newleadprofile: val.get_assets_url_id,
      newleadfirst_name: val.first_name,
      newleadlast_name: val.last_name,
      newleadmobile: {
        mobile: val.mobile_no,
        mobile_code: "+91",
      },
      newleadaltmobile: "",

      newleademail: val.email_id,
      newleadtele: "",
      newleadfax: "",
      newleadfacebook: "",
      newleadlinkedin: "",
      newleadtwitter: "",

      newleadstreet: "",
      newleadaddress2: "",
      newleadaddress3: "",
      newleadstate: {},
      newleadcity: {},
      newleadcountry: {},
      newleadpincode: "",
      newleaderror: {},
      error: {
        newleadfirst_name: "",
        newleadlast_name: "",
        newleadmobile: "",
        newleademail: "",
      },
    });

    setContactId(val.id);
    setAlready(true);
    setSearch(false);
  };
  const MyDivider = styled(Divider)(({ thiccness, orientation }) => ({
    ...(thiccness !== undefined &&
      (orientation === "vertical"
        ? { borderRightWidth: thiccness, borderColor: "#F5F7FA" }
        : { borderBottomWidth: thiccness, borderColor: "#F5F7FA" })),
  }));
  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          {search ? "" : <TitleBar text="Create Quotation" goBack={goback} />}
          {step === 0 && <Typeselect />}
          {search ? (
            <>
              <TitleBar text="Search" goBack={goBacksearch} />
              <div style={{ padding: "8px" }}>
                <Grid item xs={12} className={classes.searchmain}>
                  <TextField
                    variant="outlined"
                    color="secondary"
                    style={{ backgroundColor: "#F5F7FA" }}
                    fullWidth
                    onChange={(e) => {
                      searchHandle(e.target.value);
                    }}
                    placeholder="Search Contact Here"
                    className={classes.textbox}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="end">
                          <IconButton aria-label="Toggle password visibility">
                            <img src="/images/icons8-search.svg" alt="note" />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                {contactList.length > 0 && (
                  <>
                    <Typography
                      className={classes.result}
                      style={{ marginBottom: "-12px" }}
                    >
                      Results
                    </Typography>
                    <MyDivider thiccness={4} />
                    <div className={classes.listpad}>
                      {contactList?.map((val) => {
                        return (
                          <>
                            <ContactList
                              data={val}
                              type="search"
                              onClick={getSearchdata}
                            />
                            <MyDivider thiccness={4} />
                          </>
                        );
                      })}
                    </div>
                  </>
                )}
              </div>
            </>
          ) : (
            <>
              {step !== 0 && (
                <Grid container>
                  <Grid item xs={12} className={classes.steps}>
                    <Box display="flex" alignItems="center" p={1}>
                      <Box className={classes.stepindicateavatarborder}>
                        <Avatar className={classes.stepindicateavatar}>
                          {step}
                        </Avatar>
                      </Box>
                      <Box flexGrow={1} marginLeft="10px">
                        <Typography className={classes.stepindicate}>
                          Step {step}/3{" "}
                        </Typography>
                        <Typography className={classes.name}>
                          {data.type === "existing lead" && (
                            <>
                              {step === 1 && "Lead Details"}
                              {step === 2 && "Quotation Details"}
                              {step === 3 && "Preview and Submit"}
                            </>
                          )}
                          {data.type === "new lead" && (
                            <>
                              {step === 1 && "Create Lead"}
                              {step === 2 && "Quotation Details"}
                              {step === 3 && "Preview and Submit"}
                              {step === 4 && "Lead Details"}
                              {step === 5 && "Quotation Details"}
                              {step === 6 && "Preview and Submit"}
                            </>
                          )}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              )}

              <div style={{ padding: "20px" }}>
                {step === 1 && (
                  <>
                    {data.type === "existing lead" && <ExistingLead />}
                    {data.type === "new lead" && <LeadDetailsnew />}
                  </>
                )}

                {data.type === "new lead" && (
                  <>
                    {step === 2 && <Step5 />}
                    {step === 3 && <LeadPreview />}

                    {step === 4 && <QutationDetails />}
                    {step === 5 && <Preview />}
                  </>
                )}
                {data.type === "existing lead" && (
                  <>
                    {step === 2 && <QutationDetails />}
                    {step === 3 && <Preview />}
                  </>
                )}
              </div>
            </>
          )}
        </Grid>
      </Grid>

      {step !== 0 && (
        <Grid
          sx={{
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,

            zIndex: 999,
            width: "100%",
          }}
        >
          <Container maxWidth="sm" sx={{ padding: "0px" }}>
            <Grid container className={classes.btnroot} spacing={1}>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  disabled={step === 1 && true}
                  className={classes.previousbtn}
                  variant="contained"
                  onClick={previous}
                >
                  Previous
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  disabled={step === 7 && true}
                  fullWidth
                  className={classes.submitbtn}
                  variant="contained"
                  onClick={next}
                >
                  Next
                </Button>
              </Grid>
            </Grid>
          </Container>
        </Grid>
      )}
    </div>
  );
};
