import React from "react";
import { WelcomePage } from "./welcomePage";

class WelcomePageParent extends React.Component {
    render() {
        return <WelcomePage />;
    }
}

export default (WelcomePageParent);
