import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Container,
  Dialog,
  Divider,
  Drawer,
  Grid,
  Hidden,
  IconButton,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Box } from "@mui/system";
import jwt_decode from "jwt-decode";
import React from "react";
import { useHistory } from "react-router-dom";
import Slider from "react-slick";
import {
  DashboardCard,
  LeadsCard,
  SurveyCard,
  TextBox,
  TodayActionCard,
  ToDoListCard,
  TopNavBars,
} from "../../components";
import { config } from "../../config";
import { GETPROFILEID } from "../../graphql/queries";
import { NetworkCall } from "../../networkcall/index";
import { Routes } from "../../router/routes";
import { NetWorkCallMethods } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0px",
    backgroundColor: "#F2F5FA",
  },
  screenScrollEffect: {
    backgroundColor: "#F2F5FA",
    overflow: "overlay",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  section1Style: {
    padding: "16px",
    width: "inherit",
  },
  section2Style: {
    padding: "0px 16px",
    width: "inherit",
  },
  section3Style: {
    backgroundColor: "white",
    borderRadius: "15px 15px 0px 0px",
    padding: "16px 16px 0px 16px",
    margin: "16px 0px 0px 0px",
    width: "inherit",
  },
  section4Style: {
    backgroundColor: "white",
    padding: "16px 0px",
    width: "inherit",
  },
  section4HeaderStyle: {
    padding: "0px 16px 0px 16px",
  },
  section5Style: {
    backgroundColor: "white",
    padding: "16px",
    width: "inherit",
  },
  greeting1Style: {
    fontSize: "18px",
    fontFamily: "tenant_extraBold",
    color: theme.typography.color.primary,
  },
  greeting2Style: {
    fontSize: "14px",
    fontFamily: "tenant_regular",
    color: theme.typography.color.primary,
  },
  sectionLableStyle: {
    fontSize: "14px",
    fontFamily: "tenant_bold",
    color: theme.palette.secondary.main,
  },
  unSelectedFilterDurationTabStyle: {
    padding: "8px 0px",
    borderBottom: "2px solid #F5F5F5",
    cursor: "pointer",
  },
  unSelectedFilterDurationTabTextStyle: {
    fontSize: "13px",
    fontFamily: "tenant_semiBold",
    color: theme.palette.secondary.main,
  },
  selectedFilterDurationTabStyle: {
    padding: "8px 0px",
    borderBottom: "2px solid #071741",
    cursor: "pointer",
  },
  selectedFilterDurationTabTextStyle: {
    fontSize: "13px",
    fontFamily: "tenant_extraBold",
    color: theme.palette.secondary.main,
  },
  createNewTextStyle: {
    cursor: "pointer",
    color: theme.palette.primary.main,
    fontFamily: "tenant_semiBold",
    fontSize: "14px",
  },
  actionCardContainer: {
    width: "inherit",
    overflow: "overlay",
    display: "-webkit-box",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  drawer: {
    "& .css-9emuhu-MuiPaper-root-MuiDrawer-paper": {
      "background-color": "transparent",
      "box-shadow": "none",
    },
  },
  dialog: {
    "& .css-tlc64q-MuiPaper-root-MuiDialog-paper": {
      "background-color": "transparent",
      "box-shadow": "none",
    },
  },
  bottomSheetStyle: {
    background: "white",
    borderRadius: "16px 16px 0px 0px",
    border: "1px solid #E4E8EE",
    justifyContent: "center",
  },
  dialogStyle: {
    background: "white",
    borderRadius: "16px",
    border: "1px solid #E4E8EE",
    justifyContent: "center",
  },
  drawerHeaderStyle: {
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px",
  },
  drawerHeaderTextStyle: {
    fontSize: "16px",
    fontWeight: "bolder",
    color: theme.typography.color.primary,
  },
  drawerBodyStyle: {
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px",
  },
  drawerFooterStyle: {
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px",
  },
  drawerButtonStyle: {
    "&:hover": "none",
    borderRadius: "8px",
    padding: "14px",
    background: theme.palette.primary.main,
    fontSize: "14px",
    fontWeight: "normal",
    color: "#FFF",
  },
}));
export const Dashboard = (props) => {
  const classes = useStyles();
  const size = useWindowDimensions();
  const date = new Date();
  const tempDecoded = jwt_decode(localStorage.getItem("authToken"));
  const history = useHistory();

  const hour = date.getHours();
  const [selectedFilterDuration, setSelectedFilterDuration] =
    React.useState("Max");
  const [selectedLeadsCardData, setSelectedLeadsCardData] = React.useState(0);
  const getprofileid = () => {
    const variables = {
      query: GETPROFILEID,
      variables: {
        id: tempDecoded.id,
      },
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      variables,
      null,
      true,
      false
    )
      .then((response) => {
        localStorage.setItem(
          "userProfileId",
          response.data.data.user_profiles[0].id
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  React.useEffect(() => {
    getprofileid();
    // eslint-disable-next-line
  }, []);
  const userData = {
    name: "Bala Ganesh",
  };
  const dashboardData = [
    {
      id: 1,
      title: "14",
      subtitle: "Active Leads",
      img: "/images/Leads2.svg",
    },
    {
      id: 2,
      title: "10",
      subtitle: "Active Quotations",
      img: "/images/icons8-ticket (1).svg",
    },
    {
      id: 3,
      title: "04",
      subtitle: "Active Customers",
      img: "/images/Icon.svg",
      onClick: () => history.push(Routes.activecustomers),
    },
    {
      id: 4,
      title: "07",
      subtitle: "Requested Visits",
      img: "/images/icons8-leave-house (1).svg",
    },
    {
      id: 5,
      title: "16",
      subtitle: "Requested re-quotes",
      img: "/images/icons8-ticket.svg",
    },
    {
      id: 6,
      title: "17",
      subtitle: "Available units",
      img: "/images/My Units.svg",
    },
  ];
  const LeadsCardData = [
    {
      filterDuration: "Max",
      data: [
        {
          id: 0,
          count: 22,
          title: "leads",
          backgroundColor: "#DFF8FF",
          imgColor: "#46B9DA",
          src: "images/Leads.svg",
        },
        {
          id: 1,
          count: 22,
          title: "Leads to Quotations",
          backgroundColor: "#FFF1E0",
          imgColor: "#E29336",
          src: "images/Leads.svg",
        },
        {
          id: 2,
          count: 22,
          title: "Leads to Tenant",
          backgroundColor: "#EAECFB",
          imgColor: "#6248E2",
          src: "images/contactus.svg",
        },
      ],
    },
    {
      filterDuration: "5Y",
      data: [
        {
          id: 0,
          count: 20,
          title: "leads",
          backgroundColor: "#DFF8FF",
          imgColor: "#46B9DA",
          src: "images/Leads.svg",
        },
        {
          id: 1,
          count: 20,
          title: "Leads to Quotations",
          backgroundColor: "#FFF1E0",
          imgColor: "#E29336",
          src: "images/Leads.svg",
        },
        {
          id: 2,
          count: 20,
          title: "Leads to Tenant",
          backgroundColor: "#EAECFB",
          imgColor: "#6248E2",
          src: "images/contactus.svg",
        },
      ],
    },
    {
      filterDuration: "3Y",
      data: [
        {
          id: 0,
          count: 18,
          title: "leads",
          backgroundColor: "#DFF8FF",
          imgColor: "#46B9DA",
          src: "images/Leads.svg",
        },
        {
          id: 1,
          count: 18,
          title: "Leads to Quotations",
          backgroundColor: "#FFF1E0",
          imgColor: "#E29336",
          src: "images/Leads.svg",
        },
        {
          id: 2,
          count: 18,
          title: "Leads to Tenant",
          backgroundColor: "#EAECFB",
          imgColor: "#6248E2",
          src: "images/contactus.svg",
        },
      ],
    },
    {
      filterDuration: "1Y",
      data: [
        {
          id: 0,
          count: 16,
          title: "leads",
          backgroundColor: "#DFF8FF",
          imgColor: "#46B9DA",
          src: "images/Leads.svg",
        },
        {
          id: 1,
          count: 16,
          title: "Leads to Quotations",
          backgroundColor: "#FFF1E0",
          imgColor: "#E29336",
          src: "images/Leads.svg",
        },
        {
          id: 2,
          count: 16,
          title: "Leads to Tenant",
          backgroundColor: "#EAECFB",
          imgColor: "#6248E2",
          src: "images/contactus.svg",
        },
      ],
    },
    {
      filterDuration: "6M",
      data: [
        {
          id: 0,
          count: 14,
          title: "leads",
          backgroundColor: "#DFF8FF",
          imgColor: "#46B9DA",
          src: "images/Leads.svg",
        },
        {
          id: 1,
          count: 14,
          title: "Leads to Quotations",
          backgroundColor: "#FFF1E0",
          imgColor: "#E29336",
          src: "images/Leads.svg",
        },
        {
          id: 2,
          count: 14,
          title: "Leads to Tenant",
          backgroundColor: "#EAECFB",
          imgColor: "#6248E2",
          src: "images/contactus.svg",
        },
      ],
    },
    {
      filterDuration: "1M",
      data: [
        {
          id: 0,
          count: 12,
          title: "leads",
          backgroundColor: "#DFF8FF",
          imgColor: "#46B9DA",
          src: "images/Leads.svg",
        },
        {
          id: 1,
          count: 12,
          title: "Leads to Quotations",
          backgroundColor: "#FFF1E0",
          imgColor: "#E29336",
          src: "images/Leads.svg",
        },
        {
          id: 2,
          count: 12,
          title: "Leads to Tenant",
          backgroundColor: "#EAECFB",
          imgColor: "#6248E2",
          src: "images/contactus.svg",
        },
      ],
    },
  ];
  const filterDuration = ["Max", "5Y", "3Y", "1Y", "6M", "1M"];
  const handleOnClickFilterDuration = (
    filterDuration,
    selectedLeadsCardData
  ) => {
    setSelectedFilterDuration(filterDuration);
    setSelectedLeadsCardData(selectedLeadsCardData);
  };
  const actionCardData = [
    {
      type: "Phone Call",
      priority: "Medium",
      date: "2021-12-09T00:00:00.000Z",
      mode: "+91 9988445544",
      onClick: () => {
        alert("Phone Call");
      },
    },
    {
      type: "Follow Up",
      priority: "Urgent",
      date: "2021-12-08T00:00:00.000Z",
      mode: "Inperson",
      onClick: () => {
        alert("Follow Up");
      },
    },
    {
      type: "Meeting",
      priority: "Urgent",
      date: "2021-12-10T00:00:00.000Z",
      mode: "Online",
      onClick: () => {
        alert("Meeting");
      },
    },
    {
      type: "Appointment",
      priority: "Urgent",
      date: "2021-12-03T00:00:00.000Z",
      mode: "Online",
      onClick: () => {
        alert("Appointment");
      },
    },
    {
      type: "Site Visit",
      priority: "Low",
      date: "2021-12-12T00:00:00.000Z",
      mode: "T,Nagar, Chennai",
      onClick: () => {
        alert("Site Visit");
      },
    },
    {
      type: "Email",
      priority: "Low",
      date: "2021-12-12T00:00:00.000Z",
      mode: "Tomcruz@gmail.com",
      onClick: () => {
        alert("Email");
      },
    },
    {
      type: "Send Quotation",
      priority: "Low",
      date: "2021-12-24T00:00:00.000Z",
      mode: "Tomcruz@gmail.com",
      onClick: () => {
        alert("Send Quotation");
      },
    },
  ];
  const handleOnclick = () => {
    alert("Clicked");
  };
  const toDoListData = [
    {
      completed: false,
      description: "Client Works",
      contactPerson: "Tom Cruise",
    },
    {
      completed: false,
      description: "Rental Paper Works",
      contactPerson: "Jonathan Valdez",
    },
    {
      completed: false,
      description: "Client Works",
      contactPerson: "Charles Forster",
    },
    {
      completed: false,
      description: "Parking Images",
      contactPerson: "Charles Forster",
    },
  ];
  const [isCreateToDo, setIsCreateToDoOpen] = React.useState(false);
  const closeCreateToDo = () => {
    setIsCreateToDoOpen(false);
  };
  const openCreateToDo = () => {
    setIsCreateToDoOpen(true);
  };
  const survaydata = [
    {
      id: 1,
      type: "feedback",
      msg: "Swimming pool hygiene feedback",
    },
    {
      id: 2,
      type: "survey",
      msg: "Survey",
    },
  ];
  var settings = {
    dots: true,
    infinite: true,
    cssEase: "linear",
    arrows: false,
  };
  return (
    <Container className={classes.root} maxWidth="sm">
      <TopNavBars note />
      <Grid
        className={classes.screenScrollEffect}
        style={{ height: size?.height - (56 + 56), width: "inherit" }}
      >
        <Grid container direction={"column"} className={classes.section1Style}>
          <Typography className={classes.greeting1Style}>
            {"Hi, " + userData.name + "!"}
          </Typography>
          <Box height={4} />
          <Typography className={classes.greeting2Style}>
            {"Good " +
              (hour < 12
                ? "Morning"
                : hour >= 12 && hour < 18
                  ? "Afternoon"
                  : "Evening") +
              ", Welcome Back!"}
          </Typography>
        </Grid>
        <Grid className={classes.section2Style} container direction={"column"}>
          <Grid
            container
            justifyContent={"center"}
            alignSelf={"center"}
            direction={"row"}
            spacing={2}
          >
            {dashboardData.map((val) => {
              return (
                <Grid item xs={4}>
                  <DashboardCard data={val} />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        <Grid className={classes.section3Style}>
          <Typography className={classes.sectionLableStyle}>
            {"Performance metrics"}
          </Typography>
          <Box height={6} />
          <Grid container direction={"row"}>
            {filterDuration.map((item, index) => {
              return (
                <>
                  <Grid
                    onClick={() => handleOnClickFilterDuration(item, index)}
                    className={
                      selectedFilterDuration === item
                        ? classes.selectedFilterDurationTabStyle
                        : classes.unSelectedFilterDurationTabStyle
                    }
                    container
                    xs={2}
                    justifyContent={"center"}
                    alignItems={"center"}
                    alignSelf={"center"}
                    direction={"row"}
                  >
                    <Typography
                      className={
                        selectedFilterDuration === item
                          ? classes.selectedFilterDurationTabTextStyle
                          : classes.unSelectedFilterDurationTabTextStyle
                      }
                    >
                      {item}
                    </Typography>
                  </Grid>
                </>
              );
            })}
          </Grid>
          <Box height={12} />
          <Grid container direction={"row"}>
            {LeadsCardData[selectedLeadsCardData].data.map((item) => {
              return (
                <Grid item xs={4}>
                  <LeadsCard data={item} />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        <Grid className={classes.section4Style}>
          <Grid
            className={classes.section4HeaderStyle}
            container
            direction={"row"}
            justifyContent={"space-between"}
          >
            <Typography className={classes.sectionLableStyle}>
              {"Activity"}
            </Typography>
            <Typography
              onClick={handleOnclick}
              className={classes.createNewTextStyle}
            >
              {"Create New"}
            </Typography>
          </Grid>
          <Box height={16} />
          <Grid className={classes.actionCardContainer}>
            <Box width={16} />
            {actionCardData.map((actionCard) => {
              return (
                <TodayActionCard
                  onClick={actionCard.onClick}
                  type={actionCard.type}
                  priority={actionCard.priority}
                  date={actionCard.date}
                  mode={actionCard.mode}
                />
              );
            })}
            <Box width={16} />
          </Grid>
        </Grid>
        <Box height={6} />
        <Grid className={classes.section5Style} container direction={"column"}>
          <Grid container direction={"row"} justifyContent={"space-between"}>
            <Typography className={classes.sectionLableStyle}>
              {"To Do List"}
            </Typography>
            <Typography
              onClick={openCreateToDo}
              className={classes.createNewTextStyle}
            >
              {"Create New"}
            </Typography>
          </Grid>
          <Box height={16} />
          {toDoListData.map((toDoList, index, array) => {
            return (
              <Grid container direction={"column"}>
                <ToDoListCard
                  completed={toDoList.completed}
                  description={toDoList.description}
                  contactPerson={toDoList.contactPerson}
                />
                <Box height={"8px"} />
              </Grid>
            );
          })}
        </Grid>
        <Box height={12} />
        <Slider {...settings}>
          {survaydata.map((val) => {
            return <SurveyCard data={val} />;
          })}
        </Slider>
        <Box height={12} />
      </Grid>
      <Hidden smDown>
        <Dialog
          className={classes.dialog}
          open={isCreateToDo}
          fullWidth={true}
          maxWidth="sm"
          onClose={closeCreateToDo}
        >
          <Grid container direction="column" className={classes.dialogStyle}>
            <Grid
              container
              direction="row"
              className={classes.drawerHeaderStyle}
            >
              <Grid>
                <Typography className={classes.drawerHeaderTextStyle}>
                  Create To Do
                </Typography>
              </Grid>
              <Grid>
                <IconButton
                  style={{
                    padding: "0px",
                  }}
                  onClick={closeCreateToDo}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Divider />
            <Grid className={classes.drawerBodyStyle}>
              <TextBox label="Name" />
              <Box height={"16px"} />
              <TextBox label="Description" multiline />
            </Grid>
            <Divider />
            <Grid className={classes.drawerFooterStyle}>
              <Button
                fullWidth
                className={classes.drawerButtonStyle}
                variant="contained"
                onClick={closeCreateToDo}
              >
                Create
              </Button>
            </Grid>
          </Grid>
        </Dialog>
      </Hidden>
      <Hidden smUp>
        <Drawer
          className={classes.drawer}
          anchor="bottom"
          open={isCreateToDo}
          onClose={closeCreateToDo}
        >
          <Grid
            container
            direction="column"
            className={classes.bottomSheetStyle}
          >
            <Grid
              container
              direction="row"
              className={classes.drawerHeaderStyle}
            >
              <Grid>
                <Typography className={classes.drawerHeaderTextStyle}>
                  Create To Do
                </Typography>
              </Grid>
              <Grid>
                <IconButton
                  style={{
                    padding: "0px",
                  }}
                  onClick={closeCreateToDo}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Divider />
            <Grid className={classes.drawerBodyStyle}>
              <TextBox label="Name" />
              <Box height={"16px"} />
              <TextBox label="Description" multiline />
            </Grid>
            <Divider />
            <Grid className={classes.drawerFooterStyle}>
              <Button
                fullWidth
                className={classes.drawerButtonStyle}
                variant="contained"
                onClick={closeCreateToDo}
              >
                Create
              </Button>
            </Grid>
          </Grid>
        </Drawer>
      </Hidden>
    </Container>
  );
};
