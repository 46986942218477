import { Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "16px",
    backgroundColor: "#B8A9FF",
    backgroundImage: "url(images/lead_info_shortlist_bg_img.svg)",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "100% 0%",
  },
  textStyle: {
    color: "#FFFFFF",
    fontFamily: "tenant_bold",
    fontSize: "16px",
  },
}));
export const ShortlistedUnitsSection = (props) => {
  const classes = useStyles();
  const shortlistedUnitsData = {
    count: 4,
  };
  const handleOnclick = () => {
    alert("Clicked");
  };

  return (
    <Grid
      onClick={handleOnclick}
      className={classes.root}
      container
      direction={"row"}
      alignItems={"start"}
    >
      <Typography className={classes.textStyle}>
        {shortlistedUnitsData.count}
      </Typography>
      <Typography
        className={classes.textStyle}
        style={{
          margin: "0px 5px 0px 5px",
        }}
      >
        {shortlistedUnitsData.count === 1 ? "Unit" : "Units"}
      </Typography>
      <Typography className={classes.textStyle}>{"Shortlisted"}</Typography>
    </Grid>
  );
};
