import { Box, Button, Container, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useHistory } from "react-router-dom";
import { TitleBar, UnitList } from "../../components";
import { Routes } from "../../router/routes";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "rgba(245, 247, 250, 1)",
  },

  shortlist: {
    fontSize: "14px",
    fontFamily: "tenant_bold",
    color: theme.typography.color.secondary,
  },
  unitlist: {
    padding: "12px 20px",
    backgroundColor: "white",
  },
  btnroot: {
    backgroundColor: "white",

    padding: "16px 8px ",
  },
  previousbtn: {
    backgroundColor: "white",
    color: "#091B29",
    border: "1px solid #CED3DD",
    borderRadius: "10px",
    padding: "12px 8px",
    boxShadow: "none",
  },
  submitbtn: {
    borderRadius: "10px",
    padding: "12px 8px",
  },
}));
const unitList = [
  {
    property_name: "Casagrande Zenith",
    units: [
      {
        unit_id: " 01a42e39-9d47-435f-ae6b-b8e6450f6203",
        name: "Zenith B-203",
        block: "Tower B - Gandhi",
        floor: 1,
        leasable_area: 1200,
        leasable_area_metric: "sq.ft",
        unit_type: "1BHK",
        primary_total: 1200,
        url: "https://protomate.objectstore.e2enetworks.net/e46a91bab7cfd9065e66b59613dcb49b.jpeg",
      },
      {
        unit_id: "250e79f9-15aa-4dc7-a287-e2495ca90d77",
        name: "Zenith A-101",
        block: "Tower A - Vivekananda",
        floor: 2,
        leasable_area: 1566,
        leasable_area_metric: "sq.ft",
        unit_type: "4BHK",
        primary_total: 7000,
        url: "https://protomate.objectstore.e2enetworks.net/e46a91bab7cfd9065e66b59613dcb49b.jpeg",
      },
    ],
  },
  {
    property_name: "Casagrande Athens",
    units: [
      {
        unit_id: "5b68104a-88b9-4f25-bd39-a1ffb785c6e2",
        name: "B202",
        block: "A Block",
        floor: 2,
        leasable_area: 1700,
        leasable_area_metric: "sq.ft",
        unit_type: "2BHK",
        primary_total: 3600,
        url: "https://protomate.objectstore.e2enetworks.net/e46a91bab7cfd9065e66b59613dcb49b.jpeg",
      },
      {
        unit_id: "705b6872-5081-4664-938c-98e3830e649f",
        name: "A101",
        block: "Block B",
        floor: 3,
        leasable_area: 1666,
        leasable_area_metric: "sq.ft",
        unit_type: "1BHK",
        primary_total: 8000,
        url: "https://protomate.objectstore.e2enetworks.net/e46a91bab7cfd9065e66b59613dcb49b.jpeg",
      },
    ],
  },
];
export const ShortListunits = (props) => {
  const classes = useStyles();
  const history = useHistory();
  //const [unitList, setUnitlist] = React.useState(unitLists);
  const goback = () => {
    history.goBack();
  };

  const onDelete = (data) => {};
  return (
    <div>
      <Container maxWidth="sm" sx={{ padding: "0px" }}>
        <Grid container className={classes.root}>
          <Grid item xs={12}>
            <TitleBar text="Shortlisted Units" goBack={goback} />
            <div>
              {unitList.map((val) => {
                return (
                  <>
                    <Box display="flex" alignItems="center" p={1}>
                      <Box>
                        <img src="/images/shortlistbuils.svg" alt="" />
                      </Box>
                      <Box>
                        <Typography className={classes.shortlist}>
                          &nbsp;{val?.property_name}&nbsp;
                        </Typography>
                      </Box>
                    </Box>
                    <div className={classes.unitlist}>
                      {val?.units?.map((val) => {
                        return (
                          <UnitList
                            data={val}
                            delete={true}
                            onDelete={onDelete}
                          />
                        );
                      })}
                    </div>
                  </>
                );
              })}
            </div>
            <br />
            <br />
            <br />
            <br />
          </Grid>
        </Grid>
        <Grid
          sx={{
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,

            zIndex: 999,
            width: "100%",
          }}
        >
          <Container maxWidth="sm" sx={{ padding: "0px" }}>
            <Grid
              container
              className={classes.btnroot}
              spacing={1}
              alignItems="center"
            >
              <Grid item xs={6}>
                <Button
                  fullWidth
                  className={classes.previousbtn}
                  variant="contained"
                >
                  Shortlist To Lead
                </Button>
              </Grid>

              <Grid item xs={6}>
                <Button
                  fullWidth
                  className={classes.submitbtn}
                  variant="contained"
                  onClick={() => history.push(Routes.createquotation)}
                >
                  Create Quotation
                </Button>
              </Grid>
            </Grid>
          </Container>
        </Grid>
      </Container>
    </div>
  );
};
