import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import {
  Avatar,
  Box,
  Button,
  Container,
  Dialog,
  Divider,
  Drawer,
  Fab,
  Grid,
  Hidden,
  IconButton,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment-timezone";
import React from "react";
import { useHistory } from "react-router-dom";
import ShowMoreText from "react-show-more-text";
import {
  ActivityListCard,
  DateTime,
  LoderSimmer,
  TextBox,
} from "../../components";
import { config } from "../../config";
import { UPDATEACTIVITIES } from "../../graphql/mutations";
import { GETACTIVITYBYID } from "../../graphql/queries";
import { NetworkCall } from "../../networkcall/index";
import { Routes } from "../../router/routes";
import { NetWorkCallMethods } from "../../utils";
const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "5%",
  },
  dividerStyle: {
    backgroundColor: theme.palette.border.secondary,
    height: "1px",
    width: "100%",
    margin: "14px 0px 14px 0px",
  },
  previewCardphone: {
    backgroundColor: "#55B0CF",
    color: "#EFFCFF",
    borderRadius: "10px",
  },
  icon: {
    backgroundColor: "white",
  },
  name: {
    fontSize: "14px",
    fontFamily: "tenant_semiBold",

    textTransform: "capitalize",
  },
  title: {
    fontSize: "10px",
    fontFamily: "tenant_semiBold",
    color: theme.typography.color.Tertiary,
    textTransform: "capitalize",
  },
  sub: {
    fontSize: "13px",
    fontFamily: "tenant_semiBold",
    color: theme.typography.color.secondary,
    textTransform: "capitalize",
  },
  data: {
    padding: "6px",
    textAlign: "center",
  },
  divider: {
    border: "2px solid #F5F7FA",
  },
  note: {
    fontSize: "14px",
    fontFamily: "tenant_semiBold",
    textTransform: "capitalize",
    color: theme.typography.color.secondary,
  },
  notetitle: {
    fontSize: "10px",
    fontFamily: "tenant_semiBold",
    color: theme.typography.color.Tertiary,
    textTransform: "capitalize",
    marginTop: "10px",
  },
  viewdata: {
    padding: "18px",
  },
  head: {
    fontSize: "16px",
    fontFamily: "tenant_extraBold",
    marginLeft: "10px",
  },
  completedbtn: {
    boxShadow: "none",
    padding: "10px",
    borderRadius: "10px",
    marginTop: "14px",
  },
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopLeftRadius: "10px",
      borderTopRightRadius: "10px",
    },
  },
  fab: {
    textTransform: "capitalize",
    "& .MuiButton-root": {
      color: theme.palette.background.paper,
    },

    [theme.breakpoints.down("sm")]: {
      position: "fixed",
      bottom: "60px",
      right: "10%",
    },
    [theme.breakpoints.up("sm")]: {
      position: "fixed",
      bottom: "60px",
      left: "65%",
    },
  },

  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: "16px",
      padding: "0px",
    },
  },
  previewCardfollow: {
    backgroundColor: "#35A27D",
    color: "#EFFCFF",
    borderRadius: "10px",
  },
  previewCardmeeting: {
    backgroundColor: "#8887D4",
    color: "#F8F8FF",
    borderRadius: "10px",
  },
  previewCardappoinment: {
    backgroundColor: "#E53468 ",
    color: "#FFF3F7",
    borderRadius: "10px",
  },
  previewCardsite: {
    backgroundColor: "#E29336",
    color: "#FFF8EF",
    borderRadius: "10px",
  },
  previewCardqutation: {
    backgroundColor: "#5078E1",
    color: "#F2F6FA",
    borderRadius: "10px",
  },
  previewCardmail: {
    backgroundColor: "#78B1FE",
    color: "#F2F6FA",
    borderRadius: "10px",
  },
  noteTextStyle2: {
    fontSize: "12px",
    fontFamily: "tenant_regular",
    color: theme.typography.color.secondary,
    textOverflow: "clip",
  },
  seeMoreLessTextStyle: {
    fontSize: "12px",
    fontFamily: "tenant_semiBold",
    color: theme.palette.primary.main,
    textDecoration: "none",
  },
}));
export const ActivityLead = (props) => {
  const classes = useStyles();
  moment.defaultFormat = "DD MMM YY";
  const [open, setOpen] = React.useState(false);
  const [mark, setMark] = React.useState(false);
  const [leadActivities, setLeadActivities] = React.useState([]);
  const [preview, setPreview] = React.useState();
  const [completed, setCompleted] = React.useState("");
  const history = useHistory();
  const [loading, setLoading] = React.useState(true);
  const handleToggle = () => {
    history.push(Routes.createactivity + "?leadId=" + props.leadId);
  };
  const viewActivities = (data) => {
    if (data.status === "open") {
      setPreview(data);
      setOpen(true);
    }
  };
  const getActivitiesByLeadid = () => {
    const variables = {
      query: GETACTIVITYBYID,
      variables: {
        leadId: props.leadId,
      },
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      variables,
      null,
      true,
      false
    )
      .then((response) => {
        setLeadActivities(response.data.data.lead_activities);
        setLoading(false);
      })

      .catch((error) => {
        console.log(error);
      });
  };

  React.useEffect(() => {
    getActivitiesByLeadid();
    // eslint-disable-next-line
  }, []);
  const markCompleted = () => {
    setMark(true);
    setOpen(false);
  };
  const closeCompleted = () => {
    const variables = {
      query: UPDATEACTIVITIES,
      variables: {
        leadActivityId: preview.id,
        updatePayload: {
          updated_by: localStorage.getItem("userProfileId"),
          updated_at: new Date().toISOString(),
          is_active: true,
          status: "closed",
          closed_by: localStorage.getItem("userProfileId"),
          closed_on: new Date().toISOString(),
          remarks: completed,
        },
      },
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      variables,
      null,
      true,
      false
    )
      .then((response) => {
        setMark(false);
        getActivitiesByLeadid();
      })

      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className={classes.root}>
      {loading ? (
        <LoderSimmer count={10} />
      ) : (
        <Container maxWidth="sm">
          {leadActivities.map((val) => {
            return (
              <div>
                <ActivityListCard data={val} onClick={viewActivities} />

                <Box className={classes.dividerStyle} />
              </div>
            );
          })}
          {props?.active === "true" && (
            <Fab
              color="primary"
              className={classes.fab}
              aria-label="add"
              onClick={handleToggle}
            >
              <AddIcon />
            </Fab>
          )}
          <Hidden smDown>
            <Dialog
              className={classes.dialog}
              fullWidth
              maxWidth="sm"
              open={open}
              onClose={() => {
                setOpen(false);
              }}
            >
              <Box
                display="flex"
                alignItems="center"
                p={1}
                border={2}
                borderColor="#F5F7FA"
              >
                <Box flexGrow={1}>
                  <Typography className={classes.head}>Activity</Typography>
                </Box>
                <Box>
                  <IconButton
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Box>
              <div className={classes.viewdata}>
                <div>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      {/* phonecall */}

                      {preview?.lead_activitiy_master ===
                        "67f34ccd-3f23-4f10-8334-80ed1d425781" && (
                        <>
                          <Box
                            p={1}
                            display="flex"
                            alignItems="center"
                            className={classes.previewCardphone}
                          >
                            <Box>
                              <Avatar className={classes.icon}>
                                <img
                                  src="/images/callreview.svg"
                                  alt=" Phone Call"
                                />
                              </Avatar>
                            </Box>
                            <Box flexGrow={1} marginLeft="10px">
                              <Typography className={classes.name}>
                                Phone Call
                              </Typography>
                            </Box>
                          </Box>
                        </>
                      )}
                      {/* follow up */}
                      {preview?.lead_activitiy_master ===
                        "fff87381-de52-455d-9f69-8400b9a2a0ff" && (
                        <>
                          <Box
                            p={1}
                            display="flex"
                            alignItems="center"
                            className={classes.previewCardfollow}
                          >
                            <Box>
                              <Avatar className={classes.icon}>
                                <img
                                  src="/images/followreview.svg"
                                  alt="Follow up"
                                />
                              </Avatar>
                            </Box>
                            <Box flexGrow={1} marginLeft="10px">
                              <Typography className={classes.name}>
                                Follow up
                              </Typography>
                            </Box>
                          </Box>
                        </>
                      )}
                      {/* meeting up */}
                      {preview?.lead_activitiy_master ===
                        "cbc1480a-a7e5-4bab-9a07-9f08d949f179" && (
                        <>
                          <Box
                            p={1}
                            display="flex"
                            alignItems="center"
                            className={classes.previewCardmeeting}
                          >
                            <Box>
                              <Avatar className={classes.icon}>
                                <img
                                  src="images/meetreview.svg"
                                  alt="Meeting"
                                />
                              </Avatar>
                            </Box>
                            <Box flexGrow={1} marginLeft="10px">
                              <Typography className={classes.name}>
                                Meeting
                              </Typography>
                            </Box>
                          </Box>
                        </>
                      )}
                      {/* appoinment up */}
                      {preview?.lead_activitiy_master ===
                        "50fae8f5-17d0-417a-ba01-568b35acfbc1" && (
                        <>
                          <Box
                            p={1}
                            display="flex"
                            alignItems="center"
                            className={classes.previewCardappoinment}
                          >
                            <Box>
                              <Avatar className={classes.icon}>
                                <img
                                  src="/images/Group 4957.svg"
                                  alt="Appointment"
                                />
                              </Avatar>
                            </Box>
                            <Box flexGrow={1} marginLeft="10px">
                              <Typography className={classes.name}>
                                Appointment
                              </Typography>
                            </Box>
                          </Box>
                        </>
                      )}
                      {/* site visit */}
                      {preview?.lead_activitiy_master ===
                        "60f6e6f0-1328-47b4-8dc6-34035ee49c6e" && (
                        <>
                          <Box
                            p={1}
                            display="flex"
                            alignItems="center"
                            className={classes.previewCardsite}
                          >
                            <Box>
                              <Avatar className={classes.icon}>
                                <img
                                  src="/images/sitereview.svg"
                                  alt="Arrange site visit"
                                />
                              </Avatar>
                            </Box>
                            <Box flexGrow={1} marginLeft="10px">
                              <Typography className={classes.name}>
                                Arrange site visit
                              </Typography>
                            </Box>
                          </Box>
                        </>
                      )}
                      {/* site qutation */}
                      {preview?.lead_activitiy_master ===
                        "ac53c561-9ab2-437b-b80c-e7a738bab8f2" && (
                        <>
                          <Box
                            p={1}
                            display="flex"
                            alignItems="center"
                            className={classes.previewCardqutation}
                          >
                            <Box>
                              <Avatar className={classes.icon}>
                                <img
                                  src="/images/qutationreview.svg"
                                  alt="Send email"
                                />
                              </Avatar>
                            </Box>
                            <Box flexGrow={1} marginLeft="10px">
                              <Typography className={classes.name}>
                                Send email
                              </Typography>
                            </Box>
                          </Box>
                        </>
                      )}
                      {/* site mail */}
                      {preview?.lead_activitiy_master ===
                        "1931346b-a32e-4834-b399-708f07448278" && (
                        <>
                          <Box
                            p={1}
                            display="flex"
                            alignItems="center"
                            className={classes.previewCardmail}
                          >
                            <Box>
                              <Avatar className={classes.icon}>
                                <img
                                  src="/images/mail.svg"
                                  alt="Send quotation"
                                />
                              </Avatar>
                            </Box>
                            <Box flexGrow={1} marginLeft="10px">
                              <Typography className={classes.name}>
                                Send quotation
                              </Typography>
                            </Box>
                          </Box>
                        </>
                      )}
                    </Grid>

                    <Grid item xs={3} className={classes.data}>
                      <Typography className={classes.title}>
                        CATEGORY
                      </Typography>
                      <Typography className={classes.sub}>
                        {preview?.activity_category?.activity_category}
                      </Typography>
                    </Grid>

                    <Grid item xs={3} className={classes.data}>
                      <Typography className={classes.title}>PURPOSE</Typography>
                      <Typography className={classes.sub}>
                        {preview?.activity_sub_category?.sub_category}
                      </Typography>
                    </Grid>

                    <Grid item xs={3} className={classes.data}>
                      <Typography className={classes.title}>
                        PRIORITY
                      </Typography>
                      <Typography className={classes.sub}>
                        {preview?.priority}
                      </Typography>
                    </Grid>

                    <Grid item xs={3} className={classes.data}>
                      <Typography className={classes.title}>
                        START DATE
                      </Typography>
                      <Typography className={classes.sub}>
                        {moment(preview?.date_time)
                          .tz(moment.tz.guess())
                          .format()}
                      </Typography>
                    </Grid>
                    {preview?.phone_number && (
                      <Grid item xs={3} className={classes.data}>
                        <Typography className={classes.title}>
                          PHONE NUMBER
                        </Typography>
                        <Typography className={classes.sub}>
                          {preview?.phone_number}
                        </Typography>
                      </Grid>
                    )}
                    {preview?.email_id && (
                      <Grid item xs={3} className={classes.data}>
                        <Typography className={classes.title}>
                          START DATE
                        </Typography>
                        <Typography className={classes.sub}>
                          {preview?.email_id}
                        </Typography>
                      </Grid>
                    )}
                    {preview?.location && (
                      <Grid item xs={3} className={classes.data}>
                        <Typography className={classes.title}>
                          START DATE
                        </Typography>
                        <Typography className={classes.sub}>
                          {preview?.location}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </div>
                <Divider className={classes.divider} />

                <div style={{ padding: "8px" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography className={classes.notetitle}>
                        NOTE:
                      </Typography>
                      <ShowMoreText
                        lines={1}
                        more="Show More"
                        less="Show Less"
                        className={classes.noteTextStyle2}
                        anchorClass={classes.seeMoreLessTextStyle}
                        expanded={false}
                        truncatedEndingComponent={"... "}
                      >
                        <Typography className={classes.note}>
                          {preview?.notes}
                        </Typography>
                      </ShowMoreText>
                    </Grid>
                  </Grid>
                </div>
                <Divider className={classes.divider} />
                <Button
                  variant="contained"
                  fullWidth
                  className={classes.completedbtn}
                  onClick={markCompleted}
                >
                  Mark As Completed
                </Button>
              </div>
            </Dialog>
          </Hidden>
          <Hidden smUp>
            <Drawer
              anchor="bottom"
              className={classes.drawer}
              open={open}
              onClose={() => {
                setOpen(false);
              }}
            >
              <Box
                display="flex"
                alignItems="center"
                p={1}
                border={2}
                borderColor="#F5F7FA"
              >
                <Box flexGrow={1}>
                  <Typography className={classes.head}>Activity</Typography>
                </Box>
                <Box>
                  <IconButton
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Box>
              <div className={classes.viewdata}>
                <div>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      {/* phonecall */}

                      {preview?.lead_activitiy_master ===
                        "67f34ccd-3f23-4f10-8334-80ed1d425781" && (
                        <>
                          <Box
                            p={1}
                            display="flex"
                            alignItems="center"
                            className={classes.previewCardphone}
                          >
                            <Box>
                              <Avatar className={classes.icon}>
                                <img
                                  src="/images/callreview.svg"
                                  alt=" Phone Call"
                                />
                              </Avatar>
                            </Box>
                            <Box flexGrow={1} marginLeft="10px">
                              <Typography className={classes.name}>
                                Phone Call
                              </Typography>
                            </Box>
                          </Box>
                        </>
                      )}
                      {/* follow up */}
                      {preview?.lead_activitiy_master ===
                        "fff87381-de52-455d-9f69-8400b9a2a0ff" && (
                        <>
                          <Box
                            p={1}
                            display="flex"
                            alignItems="center"
                            className={classes.previewCardfollow}
                          >
                            <Box>
                              <Avatar className={classes.icon}>
                                <img
                                  src="/images/followreview.svg"
                                  alt="Follow up"
                                />
                              </Avatar>
                            </Box>
                            <Box flexGrow={1} marginLeft="10px">
                              <Typography className={classes.name}>
                                Follow up
                              </Typography>
                            </Box>
                          </Box>
                        </>
                      )}
                      {/* meeting up */}
                      {preview?.lead_activitiy_master ===
                        "cbc1480a-a7e5-4bab-9a07-9f08d949f179" && (
                        <>
                          <Box
                            p={1}
                            display="flex"
                            alignItems="center"
                            className={classes.previewCardmeeting}
                          >
                            <Box>
                              <Avatar className={classes.icon}>
                                <img
                                  src="images/meetreview.svg"
                                  alt="Meeting"
                                />
                              </Avatar>
                            </Box>
                            <Box flexGrow={1} marginLeft="10px">
                              <Typography className={classes.name}>
                                Meeting
                              </Typography>
                            </Box>
                          </Box>
                        </>
                      )}
                      {/* appoinment up */}
                      {preview?.lead_activitiy_master ===
                        "50fae8f5-17d0-417a-ba01-568b35acfbc1" && (
                        <>
                          <Box
                            p={1}
                            display="flex"
                            alignItems="center"
                            className={classes.previewCardappoinment}
                          >
                            <Box>
                              <Avatar className={classes.icon}>
                                <img
                                  src="/images/Group 4957.svg"
                                  alt="Appointment"
                                />
                              </Avatar>
                            </Box>
                            <Box flexGrow={1} marginLeft="10px">
                              <Typography className={classes.name}>
                                Appointment
                              </Typography>
                            </Box>
                          </Box>
                        </>
                      )}
                      {/* site visit */}
                      {preview?.lead_activitiy_master ===
                        "60f6e6f0-1328-47b4-8dc6-34035ee49c6e" && (
                        <>
                          <Box
                            p={1}
                            display="flex"
                            alignItems="center"
                            className={classes.previewCardsite}
                          >
                            <Box>
                              <Avatar className={classes.icon}>
                                <img
                                  src="/images/sitereview.svg"
                                  alt="Arrange site visit"
                                />
                              </Avatar>
                            </Box>
                            <Box flexGrow={1} marginLeft="10px">
                              <Typography className={classes.name}>
                                Arrange site visit
                              </Typography>
                            </Box>
                          </Box>
                        </>
                      )}
                      {/* site qutation */}
                      {preview?.lead_activitiy_master ===
                        "ac53c561-9ab2-437b-b80c-e7a738bab8f2" && (
                        <>
                          <Box
                            p={1}
                            display="flex"
                            alignItems="center"
                            className={classes.previewCardqutation}
                          >
                            <Box>
                              <Avatar className={classes.icon}>
                                <img
                                  src="/images/qutationreview.svg"
                                  alt="Send email"
                                />
                              </Avatar>
                            </Box>
                            <Box flexGrow={1} marginLeft="10px">
                              <Typography className={classes.name}>
                                Send email
                              </Typography>
                            </Box>
                          </Box>
                        </>
                      )}
                      {/* site mail */}
                      {preview?.lead_activitiy_master ===
                        "1931346b-a32e-4834-b399-708f07448278" && (
                        <>
                          <Box
                            p={1}
                            display="flex"
                            alignItems="center"
                            className={classes.previewCardmail}
                          >
                            <Box>
                              <Avatar className={classes.icon}>
                                <img
                                  src="/images/mail.svg"
                                  alt="Send quotation"
                                />
                              </Avatar>
                            </Box>
                            <Box flexGrow={1} marginLeft="10px">
                              <Typography className={classes.name}>
                                Send quotation
                              </Typography>
                            </Box>
                          </Box>
                        </>
                      )}
                    </Grid>

                    <Grid item xs={3} className={classes.data}>
                      <Typography className={classes.title}>
                        CATEGORY
                      </Typography>
                      <Typography className={classes.sub}>
                        {preview?.activity_category?.activity_category}
                      </Typography>
                    </Grid>

                    <Grid item xs={3} className={classes.data}>
                      <Typography className={classes.title}>PURPOSE</Typography>
                      <Typography className={classes.sub}>
                        {preview?.activity_sub_category?.sub_category}
                      </Typography>
                    </Grid>

                    <Grid item xs={3} className={classes.data}>
                      <Typography className={classes.title}>
                        PRIORITY
                      </Typography>
                      <Typography className={classes.sub}>
                        {preview?.priority}
                      </Typography>
                    </Grid>

                    <Grid item xs={3} className={classes.data}>
                      <Typography className={classes.title}>
                        START DATE
                      </Typography>
                      <Typography className={classes.sub}>
                        {moment(preview?.date_time)
                          .tz(moment.tz.guess())
                          .format()}
                      </Typography>
                    </Grid>
                    {preview?.phone_number && (
                      <Grid item xs={3} className={classes.data}>
                        <Typography className={classes.title}>
                          PHONE NUMBER
                        </Typography>
                        <Typography className={classes.sub}>
                          {preview?.phone_number}
                        </Typography>
                      </Grid>
                    )}
                    {preview?.email_id && (
                      <Grid item xs={3} className={classes.data}>
                        <Typography className={classes.title}>
                          START DATE
                        </Typography>
                        <Typography className={classes.sub}>
                          {preview?.email_id}
                        </Typography>
                      </Grid>
                    )}
                    {preview?.location && (
                      <Grid item xs={3} className={classes.data}>
                        <Typography className={classes.title}>
                          START DATE
                        </Typography>
                        <Typography className={classes.sub}>
                          {preview?.location}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </div>
                <Divider className={classes.divider} />

                <div style={{ padding: "8px" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography className={classes.notetitle}>
                        NOTE:
                      </Typography>
                      <ShowMoreText
                        lines={1}
                        more="Show More"
                        less="Show Less"
                        className={classes.noteTextStyle2}
                        anchorClass={classes.seeMoreLessTextStyle}
                        expanded={false}
                        truncatedEndingComponent={"... "}
                      >
                        <Typography className={classes.note}>
                          {preview?.notes}
                        </Typography>
                      </ShowMoreText>
                    </Grid>
                  </Grid>
                </div>
                <Divider className={classes.divider} />
                <Button
                  variant="contained"
                  fullWidth
                  className={classes.completedbtn}
                  onClick={markCompleted}
                >
                  Mark As Completed
                </Button>
              </div>
            </Drawer>
          </Hidden>

          <Hidden smDown>
            <Dialog
              fullWidth
              maxWidth="sm"
              open={mark}
              className={classes.dialog}
              onClose={() => {
                setMark(false);
              }}
            >
              <Box
                display="flex"
                alignItems="center"
                p={1}
                border={2}
                borderColor="#F5F7FA"
              >
                <Box flexGrow={1}>
                  <Typography className={classes.head}>
                    Close Activity
                  </Typography>
                </Box>
                <Box>
                  <IconButton
                    onClick={() => {
                      setMark(false);
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Box>
              <div className={classes.viewdata}>
                <TextBox
                  multiline
                  value={completed}
                  onChange={(e) => setCompleted(e.target.value)}
                  label="Reason/ Note,if any"
                  placeholder=""
                />
                <Button
                  variant="contained"
                  fullWidth
                  className={classes.completedbtn}
                  onClick={closeCompleted}
                >
                  Submit
                </Button>
              </div>
            </Dialog>
          </Hidden>
          <Hidden smUp>
            <Drawer
              open={mark}
              anchor="bottom"
              className={classes.drawer}
              onClose={() => {
                setMark(false);
              }}
            >
              <Box
                display="flex"
                alignItems="center"
                p={1}
                border={2}
                borderColor="#F5F7FA"
              >
                <Box flexGrow={1}>
                  <Typography className={classes.head}>
                    Close Activity
                  </Typography>
                </Box>
                <Box>
                  <IconButton
                    onClick={() => {
                      setMark(false);
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Box>
              <div className={classes.viewdata}>
                <DateTime title="End Date & Time" />
                <br />
                <TextBox
                  multiline
                  label="Reason/ Note,if any"
                  value={completed}
                  onChange={(e) => setCompleted(e.target.value)}
                  placeholder=""
                />
                <Button
                  variant="contained"
                  fullWidth
                  className={classes.completedbtn}
                  onClick={closeCompleted}
                >
                  Submit
                </Button>
              </div>
            </Drawer>
          </Hidden>
        </Container>
      )}
    </div>
  );
};
