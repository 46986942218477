import React from "react";
import { SetupAccount } from "./setupAccount";

class SetupAccountParent extends React.Component {
    render() {
        return <SetupAccount />;
    }
}

export default (SetupAccountParent);
