export let Message = {
  'English': {
    requiredMessage: (field) => `${field ? field : "This field"} is required`,
    loading: "Loading...",
    unAuthorizedAccess: "404 - Unauthorized Access",
    navigationMessage: "Redirecting.... Please wait....",
    createAccount: {
      title: "Create Account",
      email: {
        label: "Email Id",
        placeholder: "Enter Email Id"
      },
      enterPassword: {
        label: "Password",
        placeholder: "Enter Password"
      },
      confirmPassword: {
        label: "Confirm Password",
        placeholder: "Confirm Password"
      },
      primaryMobileNumber: {
        label: "Enter Primary Mobile Number",
        placeholder: "Enter Primary Mobile Number "
      },
      alternateMobileNumber: {
        label: "Enter Alternative Mobile Number",
        placeholder: "Enter Alternative Mobile Number"
      },

    },
    login: {
      title: "login",
      email: {
        label: "Mobile Number / Email-Id",
        placeholder: "Enter Mobile Number / Email-Id"
      },
      password: {
        label: "Password",
        placeholder: "Enter Password"
      },

    },
  }
};