import { Grid } from "@mui/material";
import React from "react";
import { ActivityCard } from "../../components";
import { ActivityListContext } from "./createactivityContext";
export const ChooseActivity = () => {
  const { updateState, next, setType } = React.useContext(ActivityListContext);
  const Activitycarddata = [
    {
      id: "67f34ccd-3f23-4f10-8334-80ed1d425781",
      image: "/images/phonecall.svg",
      title: "Phone call",
      onClick: () => {
        setType("phone call");
        updateState("activitytypeId", "67f34ccd-3f23-4f10-8334-80ed1d425781");
        next();
      },
    },
    {
      id: "fff87381-de52-455d-9f69-8400b9a2a0ff",
      image: "/images/followup.svg",
      title: "Follow up",
      onClick: () => {
        updateState("activitytypeId", "fff87381-de52-455d-9f69-8400b9a2a0ff");
        setType("follow up");
        next();
      },
    },
    {
      id: "cbc1480a-a7e5-4bab-9a07-9f08d949f179",
      image: "/images/meet.svg",
      title: "Meeting",
      onClick: () => {
        setType("meeting");
        updateState("activitytypeId", "cbc1480a-a7e5-4bab-9a07-9f08d949f179");
        next();
      },
    },
    {
      id: "50fae8f5-17d0-417a-ba01-568b35acfbc1",
      image: "/images/appoinment.svg",
      title: "Appointment",

      onClick: () => {
        setType("appoinment");
        updateState("activitytypeId", "50fae8f5-17d0-417a-ba01-568b35acfbc1");
        next();
      },
    },
    {
      id: "60f6e6f0-1328-47b4-8dc6-34035ee49c6e",
      image: "/images/sitevisit.svg",
      title: "Arrange Site Visit",
      onClick: () => {
        setType("arrange site visit");
        updateState("activitytypeId", "60f6e6f0-1328-47b4-8dc6-34035ee49c6e");
        next();
      },
    },
    {
      id: "1931346b-a32e-4834-b399-708f07448278",
      image: "/images/quotation.svg",
      title: "Send Quotation",
      onClick: () => {
        setType("send quotation");
        updateState("activitytypeId", "1931346b-a32e-4834-b399-708f07448278");
        next();
      },
    },
    {
      id: "ac53c561-9ab2-437b-b80c-e7a738bab8f2",
      image: "/images/email.svg",
      title: "Send Email",
      onClick: () => {
        setType("send email");
        updateState("activitytypeId", "ac53c561-9ab2-437b-b80c-e7a738bab8f2");
        next();
      },
    },
  ];

  return (
    <>
      <Grid container style={{ backgroundColor: "#F5F7FA", padding: "12px" }}>
        {Activitycarddata.map((val) => {
          return (
            <Grid item xs={6} md={4}>
              <ActivityCard data={val} />
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};
