import React from "react";
import {ProfileAddressUpsert } from "./profileAddressUpsert";

class ProfileAddressUpsertParent extends React.Component {
  render() {
    return <ProfileAddressUpsert />;
  }
}

export default ProfileAddressUpsertParent;
