import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import { Avatar, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {},
  card: {
    borderRadius: "10px",
    backgroundColor: "#F1F7FF",
    padding: "10px 12px 10px 8px",
    alignItems: "center",
    justifyContent: "space-between",
    cursor: "pointer",
  },
  completedCard: {
    borderRadius: "10px",
    backgroundColor: "#F5F7FA",
    padding: "10px 12px 10px 8px",
    alignItems: "center",
    justifyContent: "space-between",
  },
  checkboxStyle: {
    height: "20px",
    width: "20px",
    backgroundColor: "white",
    border: "1px solid #98A0AC",
  },
  completedCheckboxStyle: {
    height: "20px",
    width: "20px",
    backgroundColor: "#F5F7FA",
    border: "1px solid #5AC782",
  },
  checkboxIconStyle: {
    fontSize: "small",
    color: "white",
  },
  completedCheckboxIconStyle: {
    fontSize: "small",
    color: "#5AC782",
  },
  descriptionStyle: {
    padding: "0px 12px 0px 12px",
    fontSize: "14px",
    fontFamily: "tenant_semiBold",
    color: theme.typography.color.primary,
    wordWrap: "break-word",
  },
  completedDescriptionStyle: {
    padding: "0px 12px 0px 12px",
    fontSize: "14px",
    fontFamily: "tenant_semiBold",
    color: theme.typography.color.Tertiary,
    wordWrap: "break-word",
  },
  contactPersonStyle: {
    padding: "0px 12px 0px 12px",
    fontSize: "10px",
    fontFamily: "tenant_regular",
    color: theme.typography.color.secondary,
    wordWrap: "break-word",
  },
  completedContactPersonStyle: {
    padding: "0px 12px 0px 12px",
    fontSize: "10px",
    fontFamily: "tenant_regular",
    color: theme.typography.color.Tertiary,
    wordWrap: "break-word",
  },
  chevronRightIconStyle: {
    color: theme.typography.color.primary,
  },
  completedChevronRightIconStyle: {
    color: theme.typography.color.Tertiary,
  },
}));

export const ToDoListCard = (props) => {
  const classes = useStyles(props);

  return (
    <Grid className={classes.card} container direction={"row"}>
      <Avatar
        className={
          props?.data?.is_done
            ? classes.completedCheckboxStyle
            : classes.checkboxStyle
        }
        variant="circular"
        onClick={() => {
          props.checked(props?.data);
        }}
      >
        <DoneOutlinedIcon
          className={
            props?.data?.is_done
              ? classes.completedCheckboxIconStyle
              : classes.checkboxIconStyle
          }
        />
      </Avatar>
      <Grid
        container
        direction={"column"}
        xs={10}
        onClick={() => props.onClick(props?.data)}
      >
        <Typography
          className={
            props?.data?.is_done
              ? classes.completedDescriptionStyle
              : classes.descriptionStyle
          }
        >
          {props?.data?.name}
        </Typography>
      </Grid>
      <ChevronRightIcon
        className={
          props?.data?.is_done
            ? classes.completedChevronRightIconStyle
            : classes.chevronRightIconStyle
        }
      />
    </Grid>
  );
};
