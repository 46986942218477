import { Box, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment-timezone";
import React from "react";
import { useHistory } from "react-router-dom";
import { Routes } from "../../router/routes";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "white",
    textTransform: "capitalize",
    cursor: "pointer",
  },
  roots: {
    borderBottom: "1px solid #E4E8EE",
    paddingTop: "8px",
    margin: "0px 16px",
  },
  periodStyle: {
    height: "6px",
    width: "6px",
    borderRadius: "50%",
    backgroundColor: "#CED3DD",
    margin: "6px",
  },
  name: {
    fontSize: "14px",
    fontFamily: "tenant_bold",
  },
  call: {
    fontSize: "12px",
    color: theme.typography.color.secondary,
  },
  mail: {
    fontSize: "12px",
    color: theme.typography.color.secondary,
  },
  img: {
    objectFit: "cover",
    height: "60px",
    width: "60px",
    borderRadius: "10px",
  },

  tagStyle: {
    backgroundColor: "#5078E11E",
    borderRadius: "4px",
    padding: "2px",
    fontSize: "10px",
    fontFamily: "tenant_bold",
    color: theme.typography.color.primary,
  },
}));
export const LeadsList = (props) => {
  const classes = useStyles();
  const history = useHistory();
  moment.defaultFormat = "DD MMM YY";

  return (
    <div
      className={classes.root}
      onClick={() => {
        history.push(
          Routes.leadView +
            "?leadId=" +
            props.data.id +
            "&open=" +
            `${props.data.openedDate ? true : false}`
        );
      }}
    >
      <Grid container>
        <Grid item xs={12} className={classes.roots}>
          <Box display="flex" alignItems="center">
            <Box>
              <img
                src={
                  props.data.leadAsset
                    ? props.data.leadAsset
                    : "/images/blank-profile-picture.png"
                }
                alt=""
                className={classes.img}
              />
            </Box>
            <Box flexGrow={1} marginLeft="12px">
              <Box display="flex" alignItems="center" marginBottom="6px">
                <Typography className={classes.name}>
                  {props.data.subject} &nbsp;{" "}
                  <span className={classes.tagStyle}>
                    {" "}
                    &nbsp;&nbsp;{props.data.status} &nbsp;&nbsp;
                  </span>
                </Typography>
              </Box>

              <Box display="flex">
                <Box>
                  <Typography className={classes.call}>
                    {props.data.urgent}
                  </Typography>
                </Box>
                <Box className={classes.periodStyle} />
                <Box>
                  <Typography className={classes.mail}>
                    {props.data.name}
                  </Typography>
                </Box>
                <Box className={classes.periodStyle} />
                <Box>
                  <Typography className={classes.mail}>
                    {props.data.openedDate && (
                      <>
                        opened on&nbsp;
                        {moment(props.data.openedDate)
                          .tz(moment.tz.guess())
                          .format()}
                      </>
                    )}

                    {props.data.closedData && (
                      <>
                        closed on&nbsp;
                        {moment(props.data.closedData)
                          .tz(moment.tz.guess())
                          .format()}
                      </>
                    )}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};
