import {
  Avatar,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import jwt_decode from "jwt-decode";
import React from "react";
import { useHistory } from "react-router-dom";
import {
  MobileNumberInputComponent,
  TextBox,
  TitleBar,
} from "../../../components";
import { NetworkCall } from "../../../networkcall";
import { Routes } from "../../../router/routes";
import { AlertProps, LocalStorageKeys, Message, NetWorkCallMethods } from "../../../utils";
import useWindowDimensions from "../../../utils/useWindowDimensions";
import {
  GET_PROFILE_DETAILS as queryOne,
  GET_DETAILS_FOR_PROFILE_SCREEN as queryTwo,
} from "../../../graphql/queries";
import {
  INSERT_PROFILE_IMAGE_BY_CONTACT_ID as mutationOne,
  UPDATE_PROFILE_IMAGE_BY_CONTACT_ID as mutationTwo,
  UPDATE as mutationThree,
} from "../../../graphql/mutations";
import { config } from "../../../config";
import { AlertContext } from '../../../contexts';

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.background.tertiary1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  screen: {
    borderRadius: "16px",
    border: "1px solid #F5F7FA",
    background: theme.palette.background.tertiary1,
  },
  screenPadding: {
    padding: "0px 16px 0px 16px",
    overflow: "overlay",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  profilePicturePadding: {
    justifyContent: "center",
    padding: "24px 16px 24px 16px",
  },
  iconButtonStyle: {
    height: "44px",

    padding: "12px 16px 12px 16px",
    borderRadius: "8px",
    color: "#5078E1",
    background: "#5078E1",
    backgroundColor: "#5078E1",
    boxShadow: "0px 2px 6px #00000021",
  },
  saveNUpdateButtonTextStyle: {
    fontSize: "14px",
    color: "#FFFFFF",
    fontWeight: "bold",
  },
  saveNUpdateButtonContainerStyle: {
    padding: "16px",
    background: "white",
    border: "1px solid #E4E8EE",
    boxShadow: "0px -7px 20px #0717410D",
    // position: "fixed",
    width: "-webkit-fill-available",
  },
  addressBoxStyle: {
    borderRadius: "8px",
    border: "1px solid #E4E8EE",
    padding: "12px",
    backgroundRepeat: "no-repeat",
    backgroundPositionX: "100%",
    backgroundPositionY: "100%",
  },
}));

export const ProfileEdit = () => {
  const classes = useStyles();
  const history = useHistory();
  const size = useWindowDimensions();
  const alert = React.useContext(AlertContext);
  const authToken = localStorage.getItem(LocalStorageKeys.authToken);
  const [initialData, setInitialData] = React.useState({
    userProfileID: "",
    contactID: "",
    firstName: "",
    lastName: "",
    profileImageID: "",
    profileImage: "",
    userType: "Tenant",
    clientRoleNo: "",
    primaryMobileNo: "",
    alternativeMobileNo: "",
    primaryEmailID: "",
    alternativeEmailID: "",
    address: "",
    addressLineOne: "",
    addressLineTwo: "",
    addressLineThree: "",
    district: "",
    city: "",
    state: "",
    zipCode: "",
    country: "",
    latitude: "",
    longitude: "",
    error: {
      primaryMobileNo: "",
      alternativeMobileNo: ""
    }
  });
  const [loading, setLoading] = React.useState(true);
  const [profileImage, setProfileImage] = React.useState("");

  React.useEffect(() => {
    if (authToken !== null && authToken !== "") {
      const decodedAuthToken = jwt_decode(authToken);
      const userID = decodedAuthToken.id;
      const payload = {
        query: queryOne,
        variables: { userID: userID },
      };
      NetworkCall(
        `https://graphql-dev.propertyautomate.com/graphql`,
        NetWorkCallMethods.post,
        payload,
        null,
        true,
        false
      ).then((response) => {
        const userProfile = response?.data?.data?.user_profiles?.[0] !== null ? response?.data?.data?.user_profiles?.[0] : null;
        if (userProfile !== null) {
          initialData.userProfileID = userProfile?.id !== null ? userProfile?.id : "";
          initialData.contactID = userProfile?.contact_id !== null ? userProfile?.contact_id : "";
          initialData.firstName = userProfile?.first_name !== null ? userProfile?.first_name : "";
          initialData.lastName = userProfile?.last_name !== null ? userProfile?.last_name : "";
          initialData.primaryMobileNo = userProfile?.mobile_no !== null ? userProfile?.mobile_no : "";
          initialData.alternativeMobileNo = userProfile?.alternative_mobile_no !== null ? userProfile?.alternative_mobile_no : "";
          initialData.primaryEmailID = userProfile?.email_id !== null ? userProfile?.email_id : "";
          initialData.alternativeEmailID = userProfile?.alternative_email_id !== null ? userProfile?.alternative_email_id : "";
          const payload = {
            query: queryTwo,
            variables: {
              // referenceID: userProfile?.contact_id,
              referenceID: userProfile?.id,
              userProfileID: userProfile?.id,
              contactID: userProfile?.contact_id
            },
          };
          NetworkCall(
            `https://graphql-dev.propertyautomate.com/graphql`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
          ).then((response) => {
            const otherDetails = response?.data?.data !== null ? response?.data?.data : null;
            if (otherDetails !== null) {
              if (otherDetails?.assets !== null && otherDetails?.assets?.length > 0) {
                initialData.profileImageID = otherDetails?.assets?.[0]?.id !== null ? otherDetails?.assets?.[0]?.id : "";
                initialData.profileImage = otherDetails?.assets?.[0]?.url !== null ? otherDetails?.assets?.[0]?.url : "";
              }
              if (otherDetails?.client_user_roles !== null && otherDetails?.client_user_roles?.length > 0) {
                initialData.clientRoleNo = otherDetails?.client_user_roles?.[0]?.client_role_no !== null ? otherDetails?.client_user_roles?.[0]?.client_role_no : "";
              }
              if (otherDetails?.contact !== null && otherDetails?.contact?.length > 0) {
                initialData.addressLineOne = otherDetails?.contact?.[0]?.street_1 !== null ? otherDetails?.contact?.[0]?.street_1 : "";
                initialData.addressLineTwo = otherDetails?.contact?.[0]?.street_2 !== null ? otherDetails?.contact?.[0]?.street_2 : "";
                initialData.addressLineThree = otherDetails?.contact?.[0]?.street_3 !== null ? otherDetails?.contact?.[0]?.street_3 : "";
                initialData.district = otherDetails?.contact?.[0]?.district !== null ? otherDetails?.contact?.[0]?.district : "";
                initialData.city = otherDetails?.contact?.[0]?.city !== null ? otherDetails?.contact?.[0]?.city : "";
                initialData.state = otherDetails?.contact?.[0]?.state !== null ? otherDetails?.contact?.[0]?.state : "";
                initialData.zipCode = otherDetails?.contact?.[0]?.zipcode !== null ? otherDetails?.contact?.[0]?.zipcode : "";
                initialData.country = otherDetails?.contact?.[0]?.country !== null ? otherDetails?.contact?.[0]?.country : "";
                initialData.latitude = otherDetails?.contact?.[0]?.latitude !== null ? otherDetails?.contact?.[0]?.latitude : "";
                initialData.longitude = otherDetails?.contact?.[0]?.longitude !== null ? otherDetails?.contact?.[0]?.longitude : "";
                initialData.address =
                  ((otherDetails?.contact?.[0]?.street_1 !== null && otherDetails?.contact?.[0]?.street_1 !== "") ? otherDetails?.contact?.[0]?.street_1 : "") +
                  ((otherDetails?.contact?.[0]?.street_2 !== null && otherDetails?.contact?.[0]?.street_2 !== "") ? (", " + otherDetails?.contact?.[0]?.street_2) : "") +
                  ((otherDetails?.contact?.[0]?.street_3 !== null && otherDetails?.contact?.[0]?.street_3 !== "") ? (", " + otherDetails?.contact?.[0]?.street_3) : "") +
                  ((otherDetails?.contact?.[0]?.district !== null && otherDetails?.contact?.[0]?.district !== "") ? (", " + otherDetails?.contact?.[0]?.district) : "") +
                  ((otherDetails?.contact?.[0]?.city !== null && otherDetails?.contact?.[0]?.city !== "") ? (", " + otherDetails?.contact?.[0]?.city) : "") +
                  ((otherDetails?.contact?.[0]?.state !== null && otherDetails?.contact?.[0]?.state !== "") ? (", " + otherDetails?.contact?.[0]?.state) : "") +
                  ((otherDetails?.contact?.[0]?.country !== null && otherDetails?.contact?.[0]?.country !== "") ? (", " + otherDetails?.contact?.[0]?.country) : "") +
                  ((otherDetails?.contact?.[0]?.zipcode !== null && otherDetails?.contact?.[0]?.zipcode !== "") ? (", " + otherDetails?.contact?.[0]?.zipcode) : "")
              }
              setInitialData({ ...initialData });
              setLoading(false);
            } else { setLoading(false); }
          }).catch((error) => { console.log(error); });
        } else { setLoading(false); }
      }).catch((error) => { console.log(error); });
    } else {
      localStorage.clear();
      history.push(Routes.login);
    }
    // eslint-disable-next-line
  }, []);

  const isFormValid = () => {
    let isValid = true;
    let error = initialData.error;
    if (initialData.primaryMobileNo.length === 0 || initialData.primaryMobileNo.length !== 10) {
      isValid = false;
      error.primaryMobileNo = Message.English.requiredMessage("Mobile Number");
    }
    if (initialData.alternativeMobileNo.length === 0 || initialData.alternativeMobileNo.length !== 10) {
      isValid = false;
      error.alternativeMobileNo = Message.English.requiredMessage("Mobile Number");
    }
    setInitialData({ ...initialData, error });
    return isValid;
  };

  const saveProfileImageDetails = () => {
    const payload = new FormData();
    payload.append("files", profileImage);
    payload.append("tenantId", `${config.tenantid}`);
    NetworkCall(
      `https://dev-api.propertyautomate.com/api/v1/file-upload`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    ).then((response) => {
      let tempProfilePictureUrl = response.data.fileUrls[0].url;
      if (initialData?.profileImageID !== "") {
        const payload = {
          query: mutationTwo,
          variables: {
            assetsID: initialData?.profileImageID,
            updatePayload: {
              url: tempProfilePictureUrl,
            },
          },
        };
        NetworkCall(
          `https://graphql-dev.propertyautomate.com/graphql`,
          NetWorkCallMethods.post,
          payload,
          null,
          true,
          false
        ).then((response) => { saveProfileTextDetails() }).catch((error) => { console.log(error); });
      } else {
        const payload = {
          query: mutationOne,
          variables: {
            insertPayload: [
              {
                reference_id: initialData?.contactID,
                url: tempProfilePictureUrl,
                assets_master_type: 2,
                priority: 1,
                is_active: true
              }
            ],
          },
        };
        NetworkCall(
          `https://graphql-dev.propertyautomate.com/graphql`,
          NetWorkCallMethods.post,
          payload,
          null,
          true,
          false
        ).then((response) => { saveProfileTextDetails() }).catch((error) => { console.log(error); });
      }
    }).catch((err) => { console.log(err); });
  }

  const saveProfileTextDetails = () => {
    const tempDecoded = jwt_decode(authToken);
    const userId = tempDecoded.id;
    const update_data = {
      query: mutationThree,
      variables: {
        update: {
          email_id: initialData?.primaryEmailID,
          mobile_no: initialData?.primaryMobileNo,
          alternative_mobile_no: initialData?.alternativeMobileNo
        },
        userId: userId,
      },
    };
    NetworkCall(
      `https://graphql-dev.propertyautomate.com/graphql`,
      NetWorkCallMethods.post,
      update_data,
      null,
      true,
      false
    ).then((response) => {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.success,
        msg: "Profile Updated Successfully."
      })
      goBack();
    }).catch((error) => { console.log(error); });
  };

  const saveProfileData = () => {
    if (isFormValid()) {
      if (profileImage !== "") {
        saveProfileImageDetails()
      } else {
        saveProfileTextDetails()
      }
    }
  }

  const goBack = () => {
    window.history.go(-1);
  };

  return (
    <div className={classes.root}>
      <Container maxWidth="sm" style={{ padding: "0px" }}>
        {loading ? (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}>
            <CircularProgress color="inherit" />
          </Backdrop>
        ) :
          (<>
            <Grid
              className={classes.screen}
              style={{ height: size?.height }}
              item
              xs={12}
            >
              <TitleBar text="Profile Edit" goBack={goBack} />
              <Grid
                className={classes.screenPadding}
                style={{ height: size?.height - 135 }}
              >
                <Grid container className={classes.profilePicturePadding}>
                  <Avatar
                    variant="circular"
                    style={{
                      height: "128px",
                      width: "128px",
                      border: "2px solid #ffffff",
                    }}
                  >
                    <img height={128} width={128}
                      src={initialData?.profileImage !== "" ? initialData?.profileImage : "/images/profile_image.png"}
                      alt="profile_image" />
                  </Avatar>
                  <label htmlFor="uplodebtn">
                    <Avatar
                      variant="circular"
                      style={{
                        backgroundColor: "#E4E8EE",
                        border: "3px solid #ffffff",
                        marginTop: "90px",
                        marginLeft: "-25px",
                        height: "30px",
                        width: "30px",
                      }}
                    >
                      <img
                        style={{
                          height: "12px",
                        }}
                        src="/images/icons8-camera.svg"
                        alt=""
                      />
                    </Avatar>
                  </label>
                  <input
                    type="file"
                    name="img"
                    accept="image/*"
                    style={{ display: "none" }}
                    id="uplodebtn"
                    onChange={(e) => {
                      setProfileImage(e.target.files[0])
                      initialData.profileImage = URL.createObjectURL(e.target.files[0]);
                      setInitialData({ ...initialData });
                    }}
                  />
                </Grid>
                <Divider />
                <Box height="16px" />
                <TextBox label="Name" disabled value={initialData?.firstName + " " + initialData?.lastName} />
                <Box height="16px" />
                <MobileNumberInputComponent
                  label="Primary Contact"
                  placeholder=" 9876543210"
                  value={{ mobile: initialData?.primaryMobileNo }}
                  handleChange={(value) => {
                    initialData.primaryMobileNo = value.mobile;
                    setInitialData({ ...initialData })
                  }}
                />
                <Box height="16px" />
                <MobileNumberInputComponent
                  label="Alternative Contact"
                  placeholder=" 9876543210"
                  value={{ mobile: initialData?.alternativeMobileNo }}
                  handleChange={(value) => {
                    initialData.alternativeMobileNo = value.mobile;
                    setInitialData({ ...initialData })
                  }}
                />
                <Box height="16px" />
                <TextBox label="Email Address" value={initialData.primaryEmailID} />
                <Box height="16px" />
                <Grid
                  container
                  direction={"row"}
                  style={{ justifyContent: "space-between" }}
                >
                  <Typography style={{ fontFamily: "tenant_bold" }}>
                    Address
                  </Typography>
                  <Typography
                    style={{
                      fontFamily: "tenant_semiBold",
                      fontSize: "14px",
                      color: "#5078E1",
                      cursor: "pointer",
                    }}
                  >
                    {/* <div onClick={() => history.push(Routes.addAddress)}>
                  Add Address
                </div> */}
                  </Typography>
                </Grid>
                <Box height="16px" />
                <Grid container direction={"column"}>
                  <Grid
                    container
                    display="inline"
                    direction={"column"}
                    className={classes.addressBoxStyle}
                  >
                    <Typography
                      onClick={() => history.push(Routes.profileAddressEdit)}
                      style={{
                        textAlign: "right",
                        fontFamily: "tenant_semiBold",
                        fontSize: "12px",
                        color: "#5078E1",
                        cursor: "pointer",
                      }}
                    >
                      Edit
                    </Typography>
                    <Grid container direction={"row"} alignItems={"center"}>
                      <img
                        height={"16px"}
                        width={"14px"}
                        src="/images/icons8-location.svg"
                        alt=""
                      />
                      <Box width="16px" />
                      <Grid container xs={10} direction={"column"}>
                        <Typography
                          style={{
                            fontSize: "12px",
                            color: "#091B29",
                            fontFamily: "tenant_bold",
                            wordWrap: "break-word",
                          }}
                        >
                          {"Address " /*+ (index + 1)*/}
                        </Typography>
                        <Box height={"4px"} />
                        <Typography
                          style={{
                            fontSize: "12px",
                            color: "#98A0AC",
                            wordWrap: "break-word",
                          }}
                        >
                          {initialData.address}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Box height="16px" />
              </Grid>
              <Grid xs={12} className={classes.saveNUpdateButtonContainerStyle}>
                <Button
                  variant="contained"
                  fullWidth
                  className={classes.iconButtonStyle}
                  onClick={saveProfileData}
                >
                  <Typography className={classes.saveNUpdateButtonTextStyle}>
                    {"Save & Update"}
                  </Typography>
                </Button>
              </Grid>
            </Grid></>)}
      </Container>
    </div >
  );
};
