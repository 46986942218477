import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Container,
  Dialog,
  Divider,
  Drawer,
  Fab,
  Grid,
  Hidden,
  IconButton,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { TextBox, ToDoListCard } from "../../components";
import { config } from "../../config";
import { ADDTODOLIST, UPDATETODO } from "../../graphql/mutations";
import { TODOLIST } from "../../graphql/queries";
import { NetworkCall } from "../../networkcall/index";
import { NetWorkCallMethods } from "../../utils";
const useStyles = makeStyles((theme) => ({
  root: {},
  fab: {
    textTransform: "capitalize",
    "& .MuiButton-root": {
      color: theme.palette.background.paper,
    },

    [theme.breakpoints.down("sm")]: {
      position: "fixed",
      bottom: "60px",
      right: "10%",
    },
    [theme.breakpoints.up("sm")]: {
      position: "fixed",
      bottom: "60px",
      left: "65%",
    },
  },
  submitbtn: {
    padding: "12px",
    boxShadow: "none",
    borderRadius: "10px",
    border: "none",
  },
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopRightRadius: "16px",
      borderTopLeftRadius: "16px",
    },
    "& .MuiContainer-root": {
      padding: "0px 8px",
    },
  },
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: "16px",
      padding: "0px",
    },
  },
  bottomSheetStyle: {
    background: "white",
    borderRadius: "16px 16px 0px 0px",
    border: "1px solid #E4E8EE",
    justifyContent: "center",
  },
  dialogStyle: {
    background: "white",
    borderRadius: "16px",
    border: "1px solid #E4E8EE",
    justifyContent: "center",
  },
  drawerHeaderStyle: {
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px",
  },
  drawerHeaderTextStyle: {
    fontSize: "16px",
    fontWeight: "bolder",
    color: theme.typography.color.primary,
  },
  drawerHeaderTextStyle1: {
    textAlign: "center",
    fontSize: "16px",
    fontWeight: "bolder",
    color: theme.typography.color.primary,
  },
  drawerBodyStyle: {
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px",
  },
  drawerButtonStyle: {
    "&:hover": "none",
    borderRadius: "12px",
    padding: "14px",
    background: theme.palette.primary.main,
    fontSize: "14px",
    fontWeight: "normal",
    color: "#FFF",
  },
  drawerButtonStyle1: {
    "&:hover": {
      background: "white",
      color: theme.palette.primary.main,
    },
    borderRadius: "12px",
    padding: "14px",
    background: "white",
    fontSize: "14px",
    fontWeight: "normal",
    color: theme.palette.primary.main,
  },
}));

const initialState = {
  id: "",
  name: "",
  description: "",
  is_active: true,
  id_done: "",
  created_by: "",
  error: {
    name: "",
    description: "",
  },
};

export const Todo = (props) => {
  const classes = useStyles();
  const [edit, setEdit] = React.useState(false);
  const [breakDrawer, setbreakDrawer] = React.useState(false);
  const [todoListdata, setTodoListData] = React.useState([]);

  const [addto, setTodo] = React.useState({ ...initialState });

  const todo = () => {
    const variables = {
      query: TODOLIST,
      variables: {
        id: props.leadId,
      },
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      variables,
      null,
      true,
      false
    )
      .then((response) => {
        setTodoListData(response.data.data.lead_checklist);
      })

      .catch((error) => {
        console.log(error);
      });
  };
  React.useEffect(() => {
    todo();
    // eslint-disable-next-line
  }, []);
  const checked = (val) => {
    const variables = {
      query: UPDATETODO,
      variables: {
        leadChecklistID: val.id,
        leadChecklistUpdateData: {
          updated_by: localStorage.getItem("userProfileId"),
          description: val.description,
          name: val.name,
          is_done: val.is_done ? false : true,
        },
      },
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      variables,
      null,
      true,
      false
    )
      .then((response) => {
        todo();
      })

      .catch((error) => {
        console.log(error);
      });
  };
  const view = (data) => {
    if (!data.is_done) {
      setEdit(true);
      let clonedata = {
        id: data?.id,
        name: data?.name,
        description: data?.description,
        is_active: true,
        is_done: data.is_done,
        created_by: data.created_by,

        error: {
          name: "",
          description: "",
        },
      };

      setTodo(clonedata);
      setbreakDrawer(!breakDrawer);
    } else {
    }
  };
  const handleToggle = () => {
    setbreakDrawer(true);
  };
  const isIamValide = () => {
    let isValid = true;
    let error = addto.error;
    //Checking name
    if (addto.name.length === 0) {
      isValid = false;
      error.name = "Name is Required";
    }
    //Checking description
    if (addto.description.length === 0) {
      isValid = false;
      error.description = "Description is Required";
      setTodo({ ...addto, error });
    }
    return isValid;
  };
  const updateStateadd = (key, value) => {
    let error = addto.error;
    error[key] = "";
    setTodo({ ...addto, [key]: value, error });
  };
  const addTodosubmit = () => {
    if (isIamValide()) {
      if (edit) {
        const variables = {
          query: UPDATETODO,
          variables: {
            leadChecklistID: addto.id,
            leadChecklistUpdateData: {
              updated_by: localStorage.getItem("userProfileId"),
              description: addto.description,
              name: addto.name,
            },
          },
        };
        NetworkCall(
          `${config.graphql_url}`,
          NetWorkCallMethods.post,
          variables,
          null,
          true,
          false
        )
          .then((response) => {
            todo();
            let clonedata = {
              id: "",
              name: "",
              description: "",
              is_active: "",
              is_done: "",
              created_by: "",

              error: {
                name: "",
                description: "",
              },
            };

            setTodo(clonedata);
            setbreakDrawer(false);
            setEdit(false);
          })

          .catch((error) => {
            console.log(error);
          });
      } else {
        const variables = {
          query: ADDTODOLIST,
          variables: {
            insert: [
              {
                name: addto.name,
                description: addto.description,
                is_active: true,
                created_by: localStorage.getItem("userProfileId"),
                lead_id: props?.leadId,
              },
            ],
          },
        };
        NetworkCall(
          `${config.graphql_url}`,
          NetWorkCallMethods.post,
          variables,
          null,
          true,
          false
        )
          .then((response) => {
            let clonedata = {
              id: "",
              name: "",
              description: "",
              is_active: "",
              is_done: "",
              created_by: "",

              error: {
                name: "",
                description: "",
              },
            };

            setTodo(clonedata);
            todo();
            setbreakDrawer(false);
          })

          .catch((error) => {
            console.log(error);
          });
      }
    } else {
    }
  };
  return (
    <div className={classes.root}>
      <Container maxWidth="sm">
        <Grid container>
          <Grid item xs={12}>
            <br />
            {todoListdata.map((val) => {
              return (
                <>
                  {!val.is_done && (
                    <div>
                      <ToDoListCard
                        data={val}
                        onClick={view}
                        checked={checked}
                      />
                      <Box height={"8px"} />
                    </div>
                  )}
                </>
              );
            })}
            {todoListdata.map((val) => {
              return (
                <>
                  {val.is_done && (
                    <div>
                      <ToDoListCard
                        data={val}
                        onClick={view}
                        checked={checked}
                      />
                      <Box height={"8px"} />
                    </div>
                  )}
                </>
              );
            })}
          </Grid>
          <Grid item xs={12}>
            {props?.active === "true" && (
              <Fab
                color="primary"
                className={classes.fab}
                aria-label="add"
                onClick={handleToggle}
              >
                <AddIcon />
              </Fab>
            )}
          </Grid>
        </Grid>
      </Container>

      {/* drawer */}
      <Hidden smDown>
        <Dialog
          className={classes.dialog}
          fullWidth={true}
          maxWidth="sm"
          open={breakDrawer}
        >
          <Grid
            container
            direction="column"
            className={classes.bottomSheetStyle}
          >
            <Grid
              container
              direction="row"
              className={classes.drawerHeaderStyle}
            >
              <Grid>
                <Typography className={classes.drawerHeaderTextStyle}>
                  {edit ? "Edit Todo" : "Add Todo"}
                </Typography>
              </Grid>
              <Grid>
                <IconButton
                  style={{
                    padding: "0px",
                  }}
                  onClick={() => setbreakDrawer(false)}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Divider />
            <Grid container className={classes.drawerBodyStyle} spacing={2}>
              <Grid item xs={12}>
                <TextBox
                  label="Title"
                  placeholder="Enter Todo Name"
                  isError={addto?.error?.name?.length > 0}
                  value={addto?.name}
                  errorMessage={addto?.error?.name}
                  onChange={(e) => updateStateadd("name", e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextBox
                  value={addto?.description}
                  label="Description"
                  placeholder="Enter Todo Description"
                  multiline
                  isError={addto?.error?.description?.length > 0}
                  errorMessage={addto?.error?.description}
                  onChange={(e) =>
                    updateStateadd("description", e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={addTodosubmit}
                  className={classes.submitbtn}
                >
                  {edit ? "Edit Todo" : "Create Todo"}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Dialog>
      </Hidden>
      <Hidden smUp>
        <Drawer className={classes.drawer} anchor="bottom" open={breakDrawer}>
          <Grid
            container
            direction="column"
            className={classes.bottomSheetStyle}
          >
            <Grid
              container
              direction="row"
              className={classes.drawerHeaderStyle}
            >
              <Grid>
                <Typography className={classes.drawerHeaderTextStyle}>
                  {edit ? "Edit Todo" : "Add Todo"}
                </Typography>
              </Grid>
              <Grid>
                <IconButton
                  style={{
                    padding: "0px",
                  }}
                  onClick={() => setbreakDrawer(false)}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Divider />
            <Grid container className={classes.drawerBodyStyle} spacing={2}>
              <Grid item xs={12}>
                <TextBox
                  label="Title"
                  placeholder="Enter Todo Name"
                  isError={addto?.error?.name?.length > 0}
                  value={addto?.name}
                  errorMessage={addto?.error?.name}
                  onChange={(e) => updateStateadd("name", e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextBox
                  value={addto?.description}
                  label="Description"
                  placeholder="Enter Todo Description"
                  multiline
                  isError={addto?.error?.description?.length > 0}
                  errorMessage={addto?.error?.description}
                  onChange={(e) =>
                    updateStateadd("description", e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={addTodosubmit}
                  className={classes.submitbtn}
                >
                  {edit ? "Edit Todo" : "Create Todo"}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Drawer>
      </Hidden>
      {/* drawer */}
    </div>
  );
};
