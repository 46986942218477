import React from "react";
import { LeadInfoEdit } from "./leadInfoEdit";

class LeadInfoEditParent extends React.Component {
  render() {
    return <LeadInfoEdit />;
  }
}

export default LeadInfoEditParent;
