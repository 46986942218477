import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Container,
  Dialog,
  Divider,
  Drawer,
  Grid,
  Hidden,
  IconButton,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Box } from "@mui/system";
import moment from "moment-timezone";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  DateTime,
  RangeSlider,
  TextBox,
  TitleBar,
} from "../../../../components";
import { config } from "../../../../config";
import { TRANSFERLEAD } from "../../../../graphql/mutations";
import { NetworkCall } from "../../../../networkcall/index";
import { Routes } from "../../../../router/routes";
import { NetWorkCallMethods } from "../../../../utils";
import useWindowDimensions from "../../../../utils/useWindowDimensions";
const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "14px",
  },

  profileimg: {
    backgroundColor: theme.palette.background.tertiary,
    color: "#E4E8EE",
    position: "relative",
  },
  profileicon: {
    fontSize: "80px",
    padding: "14px",
  },
  profileimgs: {
    backgroundColor: theme.palette.background.tertiary,
    color: "#E4E8EE",
    position: "absolute",
    bottom: "0px",
    right: "0px",
    border: "2px solid white",
    borderRadius: "50%",
  },

  profilesection: {
    marginTop: "14px",
  },
  field: {
    marginTop: "10px",
  },
  address: {
    marginTop: "10px",
    borderBottom: "4px solid #F5F7FA",
    borderTop: "4px solid #F5F7FA",
    marginBottom: "10px",
    padding: "8px 0px",
  },
  addAddress: {
    fontSize: "14px",
    fontFamily: "tenant_bold",
    color: "#5078E1",
    cursor: "pointer",
  },
  addresstitle: {
    fontSize: "16px",
    fontFamily: "tenant_bold",
  },
  budget: {
    height: "47px",
    borderRadius: "10px",
    border: "1px solid #CED3DD",
  },
  Label: {
    color: theme.typography.color.Tertiary,
    fontSize: "12px",
  },
  budgettitle: {
    fontSize: "16px",
    fontFamily: "tenant_extraBold",
  },
  budgetroot: {
    padding: "12px 4px",
    borderBottom: "1px solid black",
  },
  budgetrange: {
    fontSize: "12px",
    fontFamily: "tenant_bold",
    color: theme.typography.color.secondary,
  },
  budgetvalue: {
    fontSize: "16px",
    fontFamily: "tenant_semiBold",
  },
  rangeroot: {
    padding: "8px 14px",
  },
  slidervalue: {
    fontSize: "12px",
    color: theme.typography.color.secondary,
    marginBottom: "10px",
  },
  Requirement: {
    padding: "10px",
    marginTop: "24px",
    textAlign: "center",
    borderRadius: "10px",
    color: "white",
    fontSize: "14px",
    backgroundColor: "#5078E1",
    cursor: "pointer",
  },
  Requirementsqft: {
    padding: "10px",
    marginTop: "24px",
    textAlign: "center",
    borderRadius: "10px",

    fontSize: "14px",

    cursor: "pointer",
    border: "1px solid #E4E8EE",
  },
  Requirements: {
    padding: "10px",

    textAlign: "center",
    borderRadius: "10px",
    color: "white",
    fontSize: "14px",
    backgroundColor: "#5078E1",
    cursor: "pointer",
  },
  Requirementsqfts: {
    padding: "10px",

    textAlign: "center",
    borderRadius: "10px",

    fontSize: "14px",

    cursor: "pointer",
    border: "1px solid #E4E8EE",
  },
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopLeftRadius: "16px",
      borderTopRightRadius: "16px",
      width: "100vw",
    },
    "& .MuiContainer-root": {
      padding: "0px",
      width: "100vw",
    },
  },
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: "16px",
      padding: "0px",
    },
  },
  btnroot: {
    backgroundColor: "#F5F7FA",
    padding: "10px",
  },

  submitbtn: {
    borderRadius: "10px",
    padding: "12px 8px",
  },
  screenPadding: {
    overflow: "overlay",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    padding: "8px",
  },
}));
const InitialStatelead = {
  name: "",
  requirment: "",
  requirmentType: "",
  budget: [0, 2000],
  leaseperiod: "",
  startdate: "",
  endDate: "",
  leadtype: 0,
  priority: "",
  notes: "",

  error: {
    requirment: "",
    requirmentType: "",
    budget: "",
    leaseperiod: "",
    startdate: "",
    endDate: "",
    leadtype: "",
    priority: "",
    notes: "",
    owner: "",
  },
};
moment.defaultFormat = "DD MMM YY";
export const LeadInfoEdit = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const size = useWindowDimensions();
  const [open, setOpen] = React.useState(false);
  const [leaddata, setLeadData] = React.useState({ ...InitialStatelead });
  const search = useLocation().search;
  const leadId = new URLSearchParams(search).get("leadId");
  const goBack = () => {
    window.history.go(-1);
  };
  const isIamValidelead = () => {
    let isValid = true;
    let error = leaddata.error;

    //Checking name
    if (leaddata.name.length === 0) {
      isValid = false;
      error.name = "Subject is Required";
    }
    //Checking Requirement
    if (leaddata.requirment.length === 0) {
      isValid = false;
      error.requirment = "Requirement is Required";
    }

    //Checking notes
    if (leaddata.requirmentType.length === 0) {
      isValid = false;
      error.requirmentType = " Requirment Type is Required";
    }

    //Checking priority
    if (leaddata.priority.length === 0) {
      isValid = false;
      error.priority = "Priority is Required";
    }

    //Checking startdata
    if (leaddata.startdate.length === 0) {
      isValid = false;
      error.startdate = "Start Date is Required";
    }
    //Checking endDate
    if (leaddata.endDate.length === 0) {
      isValid = false;
      error.endDate = "End Date is Required";
    }
    //Checking leaseperiod
    if (leaddata.leaseperiod.length === 0) {
      isValid = false;
      error.leaseperiod = "Lease Period is Required";
    }

    if (
      moment(moment(leaddata.endDate).tz(moment.tz.guess()).format()).isAfter(
        moment(leaddata.startdate).tz(moment.tz.guess()).format()
      ) === false
    ) {
      isValid = false;
      error.endDate = "End Date is More then Start Date";
    }
    if (
      moment(moment(leaddata.startdate).tz(moment.tz.guess()).format()).isAfter(
        moment(leaddata.endDate).tz(moment.tz.guess()).format()
      )
    ) {
      isValid = false;
      error.startdate = "Start Date is Less then End Date";
    }

    setLeadData({ ...leaddata, error });
    return isValid;
  };
  const updateState = (key, value) => {
    if (
      key === "requirmentType" ||
      key === "leaseperiod" ||
      key === "leadtype" ||
      key === "priority" ||
      key === "startdate" ||
      key === "endDate" ||
      key === "owner" ||
      key === "budget"
    ) {
      let error = leaddata.error;
      error[key] = "";
      setLeadData({ ...leaddata, [key]: value, error });
    } else {
      let error = leaddata.error;
      error[key] = "";
      setLeadData({ ...leaddata, [key]: value.target.value, error });
    }
  };
  const Submit = () => {
    if (isIamValidelead()) {
      const variables = {
        query: TRANSFERLEAD,
        variables: {
          leadID: leadId,
          updatePayload: {
            subject: leaddata.name,
            value: leaddata.requirment,
            type: leaddata.requirmentType,
            lease_start_date: leaddata.startdate,
            lease_end_date: leaddata.endDate,
            lead_type: leaddata.leadtype,
            notes: leaddata.notes,
            lease_period: leaddata.leaseperiod,
            budget_range: leaddata.budget,
            urgent: leaddata.priority,
            updated_by: localStorage.getItem("userProfileId"),
            updated_at: new Date().toISOString(),
          },
        },
      };
      NetworkCall(
        `${config.graphql_url}`,
        NetWorkCallMethods.post,
        variables,
        null,
        true,
        false
      )
        .then((response) => {
          history.push(Routes.leadView + "?leadId=" + leadId);
        })

        .catch((error) => {
          console.log(error);
        });
    } else {
      return false;
    }
  };
  return (
    <Container
      maxWidth="sm"
      className={classes.screen}
      style={{ height: size?.height, padding: "0px" }}
    >
      <div style={{ position: "sticky", top: 0, zIndex: 1 }}>
        <TitleBar text="Edit Lead Details" goBack={goBack} />
      </div>
      <div style={{ padding: "12px" }}>
        <Grid
          container
          spacing={1}
          alignItems="center"
          className={classes.screenPadding}
          style={{ height: size?.height - 140 }}
        >
          {/* name */}
          <Grid item xs={12}>
            <TextBox
              label="Subject"
              placeholder=""
              onChange={(value) => updateState("name", value)}
              isError={leaddata?.error?.name?.length > 0}
              errorMessage={leaddata?.error?.name}
              value={leaddata?.name}
            />
          </Grid>
          {/* requirments */}
          <Grid item xs={6} className={classes.field}>
            <TextBox
              label="Requirement"
              placeholder=""
              onChange={(value) => updateState("requirment", value)}
              isError={leaddata?.error?.requirment?.length > 0}
              errorMessage={leaddata?.error?.requirment}
              value={leaddata?.requirment}
            />
          </Grid>
          <Grid item xs={6}>
            <Grid
              container
              spacing={1}
              style={{ padding: "0px", marginTop: "8px" }}
            >
              <Grid item xs={6}>
                <Typography
                  onClick={() => {
                    updateState("requirmentType", "sqft");
                  }}
                  className={
                    leaddata.requirmentType === "sqft"
                      ? classes.Requirement
                      : classes.Requirementsqft
                  }
                >
                  Sqft
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  onClick={() => {
                    updateState("requirmentType", "units");
                  }}
                  className={
                    leaddata.requirmentType === "units"
                      ? classes.Requirement
                      : classes.Requirementsqft
                  }
                >
                  Units
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  style={{
                    fontSize: "12px",
                    color: "red",
                    whiteSpace: "nowrap",
                  }}
                >
                  {leaddata?.error?.requirmentType}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          {/* budget */}
          <Grid item xs={12} className={classes.field}>
            <Typography variant="body1" className={classes.Label} gutterBottom>
              Budget
            </Typography>
            <Box
              onClick={() => setOpen(!open)}
              className={classes.budget}
              display="flex"
              style={{ cursor: "pointer" }}
              alignItems="center"
            >
              <Box flexGrow={1} marginLeft="12px">
                {new Intl.NumberFormat("en-IN", {
                  style: "currency",
                  currency: "INR",
                  minimumFractionDigits: 0,
                }).format(Math.round(leaddata.budget[0]))}
                -{" "}
                {new Intl.NumberFormat("en-IN", {
                  style: "currency",
                  currency: "INR",
                  minimumFractionDigits: 0,
                }).format(Math.round(leaddata.budget[1]))}
              </Box>
            </Box>
          </Grid>

          {/* lease period */}
          <Grid item xs={12} className={classes.field}>
            <Typography variant="body1" className={classes.Label} gutterBottom>
              Lease period
            </Typography>
          </Grid>
          <Grid item xs={4} sm={3}>
            <Typography
              onClick={() => {
                updateState("leaseperiod", "Monthly");
              }}
              className={
                leaddata.leaseperiod === "Monthly"
                  ? classes.Requirements
                  : classes.Requirementsqfts
              }
            >
              Monthly
            </Typography>
          </Grid>
          <Grid item xs={4} sm={3}>
            <Typography
              onClick={() => {
                updateState("leaseperiod", "Quarterly");
              }}
              className={
                leaddata.leaseperiod === "Quarterly"
                  ? classes.Requirements
                  : classes.Requirementsqfts
              }
            >
              Quarterly
            </Typography>
          </Grid>
          <Grid item xs={4} sm={3}>
            <Typography
              onClick={() => {
                updateState("leaseperiod", "Yearly");
              }}
              className={
                leaddata.leaseperiod === "Yearly"
                  ? classes.Requirements
                  : classes.Requirementsqfts
              }
            >
              Yearly
            </Typography>
          </Grid>
          {leaddata?.error?.leaseperiod > 0 && (
            <Grid item xs={12}>
              <Typography
                style={{
                  fontSize: "12px",
                  color: "red",
                  whiteSpace: "nowrap",
                }}
              >
                {leaddata?.error?.leaseperiod}
              </Typography>
            </Grid>
          )}

          {/* date */}
          <Grid item xs={6} className={classes.field}>
            <DateTime
              onChange={(value) => updateState("startdate", value)}
              title="Lease Start Date"
              value={leaddata?.startdate}
              disableFuture={true}
            />
            <Grid item xs={12}>
              <Typography
                style={{
                  fontSize: "12px",
                  color: "red",
                  whiteSpace: "nowrap",
                  marginTop: "8px",
                }}
              >
                {leaddata?.error?.startdate}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={6} className={classes.field}>
            <DateTime
              onChange={(value) => updateState("endDate", value)}
              title="Lease End Date"
              value={leaddata?.endDate}
            />
            <Grid item xs={12}>
              <Typography
                style={{
                  fontSize: "12px",
                  color: "red",
                  whiteSpace: "nowrap",
                  marginTop: "8px",
                }}
              >
                {leaddata?.error?.endDate}
              </Typography>
            </Grid>
          </Grid>
          {/* Lead Type */}
          <Grid item xs={12} className={classes.field}>
            <Typography variant="body1" className={classes.Label} gutterBottom>
              Lead Type
            </Typography>
          </Grid>
          <Grid item xs={4} sm={3}>
            <Typography
              onClick={() => {
                updateState("leadtype", 1);
              }}
              className={
                leaddata.leadtype === 1
                  ? classes.Requirements
                  : classes.Requirementsqfts
              }
            >
              Commercial
            </Typography>
          </Grid>
          <Grid item xs={4} sm={3}>
            <Typography
              onClick={() => {
                updateState("leadtype", 2);
              }}
              className={
                leaddata.leadtype === 2
                  ? classes.Requirements
                  : classes.Requirementsqfts
              }
            >
              Residential
            </Typography>
          </Grid>

          {/* Priority Type */}
          <Grid item xs={12} className={classes.field}>
            <Typography variant="body1" className={classes.Label} gutterBottom>
              Priority Type
            </Typography>
          </Grid>

          <Grid item xs={4} sm={3}>
            <Typography
              onClick={() => {
                updateState("priority", "high");
              }}
              className={
                leaddata.priority === "high"
                  ? classes.Requirements
                  : classes.Requirementsqfts
              }
            >
              High
            </Typography>
          </Grid>
          <Grid item xs={4} sm={3}>
            <Typography
              onClick={() => {
                updateState("priority", "medium");
              }}
              className={
                leaddata.priority === "medium"
                  ? classes.Requirements
                  : classes.Requirementsqfts
              }
            >
              Medium
            </Typography>
          </Grid>
          <Grid item xs={4} sm={3}>
            <Typography
              onClick={() => {
                updateState("priority", "low");
              }}
              className={
                leaddata.priority === "low"
                  ? classes.Requirements
                  : classes.Requirementsqfts
              }
            >
              Low
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              style={{
                fontSize: "12px",
                color: "red",
                whiteSpace: "nowrap",
              }}
            >
              {leaddata?.error?.priority}
            </Typography>
          </Grid>
          {/* Notes */}
          <Grid item xs={12} className={classes.field}>
            <TextBox
              label="Notes"
              placeholder=" "
              multiline
              onChange={(value) => updateState("notes", value)}
              value={leaddata?.notes}
            />
          </Grid>
          {/* Lead owner */}
          {/* <Grid item xs={12} className={classes.field}>
          <SelectBox
            label="Lead owner"
            options={options}
            onChange={(value) => updateState("owner", value)}
            value={leaddata?.owner}
            isDisabled={true}
          />
          <br />
          <br />
        </Grid> */}
        </Grid>
      </div>
      <Grid
        container
        className={classes.btnroot}
        justifyContent="space-around"
        spacing={1}
      >
        <Grid item xs={12}>
          <Button
            fullWidth
            className={classes.submitbtn}
            variant="contained"
            onClick={Submit}
          >
            Save
          </Button>
        </Grid>
      </Grid>
      <Hidden smDown>
        <Dialog
          onClose={() => {
            setOpen(!open);
          }}
          className={classes.dialog}
          open={open}
          maxWidth="sm"
          fullWidth="true"
        >
          <Box
            display="flex"
            className={classes.budgeroot}
            p={1}
            alignItems="center"
          >
            <Box flexGrow={1}>
              <Typography className={classes.budgettitle}>Budget</Typography>
            </Box>
            <Box>
              <IconButton
                size="small"
                className={classes.close}
                onClick={() => {
                  setOpen(!open);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          <Divider />

          <div className={classes.rangeroot}>
            <Typography className={classes.budgetrange}>
              Budget Range
            </Typography>
            {leaddata?.budget.length > 0 && (
              <>
                <Typography className={classes.budgetvalue}>
                  {new Intl.NumberFormat("en-IN", {
                    style: "currency",
                    currency: "INR",
                    minimumFractionDigits: 0,
                  }).format(Math.round(leaddata?.budget[0]))}{" "}
                  -{" "}
                  {new Intl.NumberFormat("en-IN", {
                    style: "currency",
                    currency: "INR",
                    minimumFractionDigits: 0,
                  }).format(Math.round(leaddata?.budget[1]))}
                </Typography>
              </>
            )}

            <br />
            <RangeSlider
              min={0}
              max={50000}
              value={leaddata?.budget}
              onChange={(value) => updateState("budget", value)}
            />
            <Box display="flex" alignItems="center">
              <Box flexGrow={1}>
                <Typography className={classes.slidervalue}>$ 0</Typography>
              </Box>
              <Box>
                <Typography className={classes.slidervalue}>
                  $ 50,000
                </Typography>
              </Box>
            </Box>
            <br />
            <Button
              fullWidth
              variant="contained"
              onClick={() => {
                setOpen(!open);
              }}
              save={(value) => updateState("budget", value)}
            >
              Save
            </Button>
          </div>
        </Dialog>
      </Hidden>
      <Hidden smUp>
        <Drawer
          onClose={() => {
            setOpen(!open);
          }}
          className={classes.drawer}
          anchor="bottom"
          open={open}
        >
          <Box
            display="flex"
            className={classes.budgeroot}
            p={1}
            alignItems="center"
          >
            <Box flexGrow={1}>
              <Typography className={classes.budgettitle}>Budget</Typography>
            </Box>
            <Box>
              <IconButton
                size="small"
                className={classes.close}
                onClick={() => {
                  setOpen(!open);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          <Divider />

          <div className={classes.rangeroot}>
            <Typography className={classes.budgetrange}>
              Budget Range
            </Typography>
            {leaddata?.budget.length > 0 && (
              <>
                <Typography className={classes.budgetvalue}>
                  {new Intl.NumberFormat("en-IN", {
                    style: "currency",
                    currency: "INR",
                    minimumFractionDigits: 0,
                  }).format(Math.round(leaddata?.budget[0]))}{" "}
                  -{" "}
                  {new Intl.NumberFormat("en-IN", {
                    style: "currency",
                    currency: "INR",
                    minimumFractionDigits: 0,
                  }).format(Math.round(leaddata?.budget[1]))}
                </Typography>
              </>
            )}

            <br />
            <RangeSlider
              min={0}
              max={50000}
              value={leaddata?.budget}
              onChange={(value) => updateState("budget", value)}
            />
            <Box display="flex" alignItems="center">
              <Box flexGrow={1}>
                <Typography className={classes.slidervalue}>$ 0</Typography>
              </Box>
              <Box>
                <Typography className={classes.slidervalue}>
                  $ 50,000
                </Typography>
              </Box>
            </Box>
            <br />
            <Button
              fullWidth
              variant="contained"
              onClick={() => {
                setOpen(!open);
              }}
            >
              Save
            </Button>
          </div>
        </Drawer>
      </Hidden>
    </Container>
  );
};
