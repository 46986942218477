import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControlLabel-label": {
      textTransform: "capitalize",
    },
  },
  errorText: {
    color: "red",
    fontSize: "0.75rem",
  },
  caption: {
    fontSize: "12px",
    color: theme.palette.warning.main,
  },
  radioroot: {
    display: "flex",
    alignItems: "center",
  },
  label: {
    fontFamily: "tenant_semiBold",
    fontSize: "16px",
  },
}));
export const RadioButton = ({
  option = [],
  value = {},
  handleChange = false,
  direction = "column",
  heading,
  isReadonly = false,
  isrequired,
  errorValidation = {},
  disabled = false,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography variant="body1">
        {heading}{" "}
        {isrequired && (
          <Typography variant="caption" style={{ color: "red" }}>
            *
          </Typography>
        )}
      </Typography>
      <FormControl component="fieldset">
        <RadioGroup
          value={value?.value}
          onChange={(value) =>
            handleChange({
              value: value.target.value,
              label: value.target.value,
            })
          }
          style={{
            flexDirection: direction,
          }}
        >
          {option &&
            option.map((data) => {
              return (
                <>
                  <div className={classes.radioroot}>
                    <FormControlLabel
                      value={data.value}
                      control={
                        <Radio
                          color="primary"
                          disabled={(isReadonly || disabled) ?? false}
                        />
                      }
                      label={
                        <Typography className={classes.label}>
                          {data.label}
                        </Typography>
                      }
                    />
                    {data.caption && (
                      <Typography className={classes.caption}>
                        ({data.caption})
                      </Typography>
                    )}
                  </div>
                </>
              );
            })}
        </RadioGroup>
      </FormControl>
      {errorValidation && errorValidation?.error && (
        <div>
          <Typography variant="caption" className={classes.errorText}>
            {errorValidation?.errorMessage}
          </Typography>
        </div>
      )}
    </div>
  );
};
RadioButton.propTypes = {
  option: PropTypes.array, // EX: [{value: "", label: ""}],
  value: PropTypes.object, // Ex {value: "", label: ""}
  handleChange: PropTypes.func,
  direction: "row" | "column",
  heading: PropTypes.string,
};
