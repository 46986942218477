import { Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { TextBox, UploadReports } from "../../components";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: "14px",
    },


}));
export const MoveOutStep2 = (props) => {
    const classes = useStyles();

    return (
        <>
            <Grid container className={classes.root}>
                <Grid item xs={12}>
                    <TextBox multiline />
                </Grid>
                <Grid item xs={12}>
                    <UploadReports />
                </Grid>
            </Grid>

        </>
    );
};
