import { Divider, IconButton, InputAdornment, TextField } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { ContactList } from "../../components";
import { config } from "../../config";
import { GETLEADBYOWNER, SEARCHLEADBYOWNER } from "../../graphql/queries";
import { NetworkCall } from "../../networkcall/index";
import { NetWorkCallMethods } from "../../utils";
import { CreateQuotationListContext } from "./createquotationcontaxt";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: "8px",
  },
  textbox: {
    marginBottom: "10px",
    [`& fieldset`]: {
      borderRadius: "10px",
      height: (props) => (props.multiline ? "unset" : 50),
      border: "1px solid #CED3DD",
      "& :hover": {
        border: "1px solid #5078E1",
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: "11px 14px",
    },
    backgroundColor: "#F5F7FA",
  },
}));
export const ExistingLead = (props) => {
  const classes = useStyles();

  const { updateState, next } = React.useContext(CreateQuotationListContext);
  const [leadList, setLeadlist] = React.useState([]);

  const handleexisitlead = (data) => {
    updateState("leadDetails", data);
    next();
  };
  React.useEffect(() => {
    const variables = {
      query: GETLEADBYOWNER,
      variables: {
        ownerId: localStorage.getItem("userProfileId"),
      },
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      variables,
      null,
      true,
      false
    ).then((response) => {
      setLeadlist(response.data.data.lead);
    });
  }, []);
  const searchHandle = (search) => {
    const variables = {
      query: SEARCHLEADBYOWNER,
      variables: {
        search: `%${search}%`,
        ownerId: localStorage.getItem("userProfileId"),
      },
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      variables,
      null,
      true,
      false
    )
      .then((response) => {
        setLeadlist(response.data.data.lead);
      })

      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div>
      <div className={classes.root}>
        <TextField
          variant="outlined"
          color="secondary"
          style={{ backgroundColor: "#F5F7FA" }}
          fullWidth
          onChange={(e) => {
            searchHandle(e.target.value);
          }}
          placeholder="Search Lead Here"
          className={classes.textbox}
          InputProps={{
            startAdornment: (
              <InputAdornment position="end">
                <IconButton aria-label="Toggle password visibility">
                  <img src="/images/icons8-search.svg" alt="note" />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        {leadList.map((val) => {
          return (
            <>
              <ContactList
                data={val}
                onClick={handleexisitlead}
                leadtype={true}
              />
              <Divider />
            </>
          );
        })}
      </div>
    </div>
  );
};
