import { useEffect,useState } from "react";
import { config } from "../config";
import { NetworkCall } from "../networkcall";
import { NetWorkCallMethods } from "../utils";
import { CommonApiContext } from '../contexts';

const WithCommanApi = (props) => {

    const [apiData,setApiData] = useState({
        logo:{}
    })

    const getLogo = async () => {
        const data = {
            tenantId: `${config.tenantid}`,
        };
        await NetworkCall(
            `${config.api_url}/get-banner`,
            NetWorkCallMethods.post,
            data,
            null,
            false,
            false
        ).then((res) => {
            setApiData({
                ...apiData,logo:res?.data?.data
            })
        }).catch((err) => {
            console.log(err)
        })
    }

    useEffect(() => {
        getLogo()
        // eslint-disable-next-line
    }, [])

    return (
        <CommonApiContext.Provider
            value={{ ...apiData, setApiData }}
        >
            {props.children}
        </CommonApiContext.Provider>
    )
}

export default WithCommanApi




