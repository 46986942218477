import React from "react";
import { withBottombar } from "../../HOCs";
import { Kishore } from "./kishore";

class KishoreParent extends React.Component {
  render() {
    return <Kishore />;
  }
}

export default withBottombar(KishoreParent);
