import CloseIcon from "@mui/icons-material/Close";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import {
  Avatar,
  Box,
  Button,
  Dialog,
  Drawer,
  Grid,
  Hidden,
  IconButton,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import {
  MobileNumberInputComponent,
  SelectBox,
  TextBox,
} from "../../components";
import { city, country, state } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { CreateQuotationListContext } from "./createquotationcontaxt";
const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "14px",
  },

  profileimg: {
    backgroundColor: theme.palette.background.tertiary,
    color: "#E4E8EE",
    position: "relative",
  },
  profileicon: {
    fontSize: "80px",
    padding: "14px",
  },
  profileimgs: {
    backgroundColor: theme.palette.background.tertiary,
    color: "#E4E8EE",
    position: "absolute",
    bottom: "0px",
    right: "0px",
    border: "2px solid white",
    borderRadius: "50%",
  },

  profilesection: {
    marginTop: "14px",
  },
  field: {
    marginTop: "10px",
  },
  address: {
    marginTop: "10px",
    borderBottom: "4px solid #F5F7FA",
    borderTop: "4px solid #F5F7FA",
    marginBottom: "10px",
    padding: "8px 0px",
  },
  imgdiv: {
    position: "relative",
    display: "inline-block",
    borderRadius: "50%",
  },
  close: {
    position: "absolute",
    right: "0px",
    top: "0px",
    backgroundColor: "red",
    borderRadius: "50%",
    color: "white",
    fontSize: "20px",
  },
  addAddress: {
    fontSize: "14px",
    fontFamily: "tenant_bold",
    color: "#5078E1",
    cursor: "pointer",
  },
  addresstitle: {
    fontSize: "16px",
    fontFamily: "tenant_bold",
  },
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopLeftRadius: "16px",
      borderTopRightRadius: "16px",
      width: "100vw",
    },
    "& .MuiContainer-root": {
      padding: "0px",
      width: "100vw",
    },
  },
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: "16px",
      padding: "0px",
    },
  },
  titleroot: {
    borderBottom: "1px solid #c1c1c1",
    paddingBottom: "8px",
    marginBottom: "8px",
  },
  addressFieldsContainerStyle: {
    padding: "8px",
  },
  addAddressButtonContainerStyle: {
    padding: "16px",
    background: "white",
    border: "1px solid #E4E8EE",
    boxShadow: "0px -7px 20px #0717410D",
    // position: "fixed",
    width: "-webkit-fill-available",
  },
  iconButtonStyle: {
    borderRadius: "10px",
    padding: "12px 8px",
  },
  searchbox: {
    border: "1px solid #E4E8EE",
    borderRadius: "8px",
    backgroundColor: "#F5F7FA",
    padding: "8px 12px",
    cursor: "pointer",
  },
  addressList: {
    fontSize: "12px",
    color: "#98A0AC",
    fontFamily: "tenant_semiBold",
    marginTop: "26px",
  },
  addresstitles: {
    fontSize: "14px",
    color: "#091B29",
    fontFamily: "tenant_bold",
  },
  primary: {
    fontSize: "8px",
    padding: "2px 4px 1px 4px",
    backgroundColor: "#071741",
    borderRadius: "4px",
    color: "white",
    fontFamily: "tenant_extraBold",
  },
  addressbox: {
    padding: "8px",
    border: "1px solid #071741",
    borderRadius: "8px",
    position: "relative",
  },
}));
export const LeadDetailsnew = (props) => {
  const classes = useStyles();
  const { data, updateState, contactid, search, setSearch, already } =
    React.useContext(CreateQuotationListContext);
  const [open, setOpen] = React.useState(false);
  const size = useWindowDimensions();
  return (
    <>
      <div>
        <Grid container className={classes.root}>
          <Grid item xs={12} className={classes.search}>
            <Box
              display="flex"
              alignItems="center"
              className={classes.searchbox}
              p={1}
              onClick={() => {
                setSearch(!search);
              }}
            >
              <Box>
                {" "}
                <img src="/images/icons8-search.svg" alt="note" />
              </Box>
              <Box flexGrow={1} marginLeft="14px">
                <Typography className={classes.searchtext}>
                  Search Contact Here
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} className={classes.profilesection}>
            <center>
              {data.newleadprofile ? (
                <div className={classes.imgdiv}>
                  <img
                    style={{
                      height: "100px",
                      width: "100px",
                      objectFit: "cover",
                      borderRadius: "50%",
                    }}
                    alt="-"
                    src={
                      already
                        ? data.newleadprofile
                        : URL.createObjectURL(data.newleadprofile)
                    }
                    className={classes.profileicons}
                  />
                  {contactid.length === 0 && (
                    <CloseIcon
                      className={classes.close}
                      onClick={() => {
                        updateState("newleadprofile", "");
                      }}
                    />
                  )}
                </div>
              ) : (
                <IconButton className={classes.profileimg}>
                  <PersonOutlineIcon className={classes.profileicon} />
                  <label htmlFor="uplodebtn">
                    <Avatar
                      sx={{ width: 24, height: 24 }}
                      className={classes.profileimgs}
                      type="file"
                    >
                      <img
                        src="/images/craeateprofilequtation.svg"
                        alt="-"
                        className={classes.profileicons}
                      />
                    </Avatar>
                  </label>
                  <input
                    type="file"
                    name="img"
                    accept="image/*"
                    style={{ display: "none" }}
                    id="uplodebtn"
                    onChange={(value) =>
                      updateState("newleadprofile", value.target.files[0])
                    }
                  />
                </IconButton>
              )}
            </center>
          </Grid>
          <Grid item xs={12}>
            <TextBox
              label="First Name"
              placeholder=""
              onChange={(value) =>
                updateState("newleadfirst_name", value.target.value)
              }
              isError={data.error.newleadfirst_name.length > 0}
              errorMessage={data.error.newleadfirst_name}
              value={data.newleadfirst_name}
              disabled={already}
            />
          </Grid>
          <Grid item xs={12}>
            <TextBox
              label="Last Name"
              placeholder=""
              onChange={(value) =>
                updateState("newleadlast_name", value.target.value)
              }
              isError={data.error.newleadlast_name.length > 0}
              errorMessage={data.error.newleadlast_name}
              value={data.newleadlast_name}
              disabled={already}
            />
          </Grid>
          <Grid item xs={12} className={classes.field}>
            <MobileNumberInputComponent
              label="Mobile"
              placeholder=""
              handleChange={(value) => updateState("newleadmobile", value)}
              isError={data.error.newleadmobile.length > 0}
              errorMessage={data.error.newleadmobile}
              value={data.newleadmobile}
              disabled={already}
            />
          </Grid>
          <Grid item xs={12} className={classes.field}>
            <MobileNumberInputComponent
              label="Alternative Mobile"
              placeholder=""
              handleChange={(value) => updateState("newleadaltmobile", value)}
              value={data.newleadaltmobile}
              disabled={already}
            />
          </Grid>
          <Grid item xs={12} className={classes.field}>
            <TextBox
              label="Email Address"
              placeholder=""
              onChange={(value) =>
                updateState("newleademail", value.target.value)
              }
              isError={data.error.newleademail.length > 0}
              errorMessage={data.error.newleademail}
              value={data.newleademail}
              disabled={already}
            />
          </Grid>
          <Grid item xs={12} className={classes.field}>
            <MobileNumberInputComponent
              label="Telephone"
              placeholder=""
              handleChange={(value) => updateState("newleadtele", value)}
              value={data.newleadtele}
              disabled={already}
            />
          </Grid>
          <Grid item xs={12} className={classes.field}>
            <MobileNumberInputComponent
              label="Fax"
              placeholder=""
              handleChange={(value) => updateState("newleadfax", value)}
              value={data.newleadfax}
              disabled={already}
            />
          </Grid>
          {already ? (
            ""
          ) : (
            <Grid item xs={12} className={classes.address}>
              <Box display="flex" alignItems="center" p={1}>
                <Box flexGrow={1}>
                  <Typography className={classes.addresstitle}>
                    Address
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    className={classes.addAddress}
                    onClick={() => setOpen(!open)}
                  >
                    Add Address
                  </Typography>
                </Box>
              </Box>
              {data?.newleadstreet && (
                <div className={classes.addressbox}>
                  <Box
                    className={classes.addressChid}
                    display="flex"
                    alignItems="center"
                  >
                    <Box>
                      <Typography className={classes.primary}>
                        PRIMARY
                      </Typography>
                    </Box>
                    <Box flexGrow={1} marginLeft="14px">
                      <Typography className={classes.addresstitles}>
                        {data?.newleadfirst_name}&nbsp;{data?.newleadcity.value}
                      </Typography>
                    </Box>
                  </Box>
                  <Typography className={classes.addressList}>
                    {data?.newleadstreet + ","}
                    {data?.newleadaddress2 + ","}
                    {data?.newleadaddress3 + ","}
                    {data?.newleadcity?.value + ","}
                    {data?.newleadstate?.value + ","}
                    {data?.newleadcountry?.value + "-"}
                    {data?.newleadpincode + "."}
                  </Typography>
                </div>
              )}
            </Grid>
          )}

          <Grid item xs={12} className={classes.field}>
            <Typography className={classes.addresstitle}>
              Social Profile
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.field}>
            <TextBox
              label="Facebook"
              placeholder="Enter facebook id"
              onChange={(value) =>
                updateState("newleadfacebook", value.target.value)
              }
              disabled={already}
              value={data.newleadfacebook}
            />
          </Grid>
          <Grid item xs={12} className={classes.field}>
            <TextBox
              label="Twitter"
              placeholder="Enter Twitter id"
              onChange={(value) =>
                updateState("newleadlinkedin", value.target.value)
              }
              disabled={already}
              value={data.newleadlinkedin}
            />
          </Grid>
          <Grid item xs={12} className={classes.field}>
            <TextBox
              label="Linkdedin"
              placeholder="Enter Linkdedin id"
              onChange={(value) =>
                updateState("newleadtwitter", value.target.value)
              }
              disabled={already}
              value={data.newleadtwitter}
            />
          </Grid>
        </Grid>
        <br />
        <br />
        <Hidden smDown>
          <Dialog
            maxWidth="sm"
            fullWidth
            open={open}
            onClose={() => {
              setOpen(!open);
            }}
            className={classes.dialog}
          >
            <div>
              <Box
                display="flex"
                className={classes.titleroot}
                p={1}
                alignItems="center"
              >
                <Box flexGrow={1}>
                  <Typography
                    style={{
                      fontFamily: "tenant_bold",
                      fontSize: "16px",
                      color: "#091B29",
                    }}
                  >
                    Add Address
                  </Typography>
                </Box>
                <Box>
                  <IconButton size="small" onClick={() => setOpen(!open)}>
                    <CloseIcon color="secondary" />
                  </IconButton>
                </Box>
              </Box>

              <Grid
                className={classes.screenScrollEffect}
                style={{ height: size?.height - 250 }}
              >
                <Grid className={classes.addressFieldsContainerStyle}>
                  <Grid>
                    <TextBox
                      label="Address Line 1"
                      placeholder="Enter Address Line 1"
                      onChange={(e) =>
                        updateState("newleadstreet", e.target.value)
                      }
                      value={data.newleadstreet}
                    />
                    <Box height="16px" />
                    <TextBox
                      label="Address Line 2"
                      placeholder="Enter Address Line 2"
                      onChange={(e) =>
                        updateState("newleadaddress2", e.target.value)
                      }
                      value={data.newleadaddress2}
                    />
                    <Box height="16px" />
                    <TextBox
                      label="Address Line 3"
                      placeholder="Enter Address Line 3"
                      onChange={(e) =>
                        updateState("newleadaddress3", e.target.value)
                      }
                      value={data.newleadaddress3}
                    />
                    <Box height="16px" />
                    <Grid container direction={"row"}>
                      <Grid xs={6} style={{ paddingLeft: "8px" }}>
                        <SelectBox
                          label="City"
                          placeholder="Select City"
                          onChange={(value) =>
                            updateState("newleadcity", value)
                          }
                          value={data.newleadcity}
                          options={city}
                          createSelect={true}
                        />
                      </Grid>
                      <Grid xs={6} style={{ paddingLeft: "8px" }}>
                        <SelectBox
                          label="State"
                          onChange={(value) =>
                            updateState("newleadstate", value)
                          }
                          value={data.newleadstate}
                          placeholder="Select State"
                          options={state}
                          createSelect={true}
                        />
                      </Grid>
                    </Grid>
                    <Box height="16px" />
                    <Grid container direction={"row"}>
                      <Grid xs={6} style={{ paddingLeft: "8px" }}>
                        <SelectBox
                          label="Country"
                          onChange={(value) =>
                            updateState("newleadcountry", value)
                          }
                          value={data.newleadcountry}
                          placeholder="Select Country"
                          options={country}
                          createSelect={true}
                        />
                      </Grid>
                      <Grid xs={6} style={{ paddingLeft: "8px" }}>
                        <TextBox
                          label="Pincode"
                          fullWidth
                          placeholder="Enter Pincode"
                          onChange={(e) =>
                            updateState("newleadpincode", e.target.value)
                          }
                          value={data.newleadpincode}
                        />
                      </Grid>
                    </Grid>
                    <Box height="16px" />
                  </Grid>
                </Grid>
              </Grid>
              <Grid xs={12} className={classes.addAddressButtonContainerStyle}>
                <Button
                  variant="contained"
                  fullWidth
                  className={classes.iconButtonStyle}
                  onClick={() => {
                    setOpen(!open);
                  }}
                >
                  <Typography className={classes.addAddressButtonTextStyle}>
                    {"Add Address"}
                  </Typography>
                </Button>
              </Grid>
            </div>
          </Dialog>
        </Hidden>
        <Hidden smUp>
          <Drawer
            anchor="bottom"
            maxWidth="sm"
            open={open}
            onClose={() => {
              setOpen(!open);
            }}
            className={classes.drawer}
          >
            <div>
              <Box
                display="flex"
                className={classes.titleroot}
                p={1}
                alignItems="center"
              >
                <Box flexGrow={1}>
                  <Typography
                    style={{
                      fontFamily: "tenant_bold",
                      fontSize: "16px",
                      color: "#091B29",
                    }}
                  >
                    Add Address
                  </Typography>
                </Box>
                <Box>
                  <IconButton size="small" onClick={() => setOpen(!open)}>
                    <CloseIcon color="secondary" />
                  </IconButton>
                </Box>
              </Box>

              <Grid
                className={classes.screenScrollEffect}
                style={{ height: size?.height - 250 }}
              >
                <Grid className={classes.addressFieldsContainerStyle}>
                  <Grid>
                    <TextBox
                      label="Address Line 1"
                      placeholder="Enter Address Line 1"
                      onChange={(e) =>
                        updateState("newleadstreet", e.target.value)
                      }
                      value={data.newleadstreet}
                    />
                    <Box height="16px" />
                    <TextBox
                      label="Address Line 2"
                      placeholder="Enter Address Line 2"
                      onChange={(e) =>
                        updateState("newleadaddress2", e.target.value)
                      }
                      value={data.newleadaddress2}
                    />
                    <Box height="16px" />
                    <TextBox
                      label="Address Line 3"
                      placeholder="Enter Address Line 3"
                      onChange={(e) =>
                        updateState("newleadaddress3", e.target.value)
                      }
                      value={data.newleadaddress3}
                    />
                    <Box height="16px" />
                    <Grid container direction={"row"}>
                      <Grid xs={6} style={{ paddingLeft: "8px" }}>
                        <SelectBox
                          label="City"
                          placeholder="Select City"
                          onChange={(value) =>
                            updateState("newleadcity", value)
                          }
                          value={data.newleadcity}
                          options={city}
                          createSelect={true}
                        />
                      </Grid>
                      <Grid xs={6} style={{ paddingLeft: "8px" }}>
                        <SelectBox
                          label="State"
                          onChange={(value) =>
                            updateState("newleadstate", value)
                          }
                          value={data.newleadstate}
                          placeholder="Select State"
                          options={state}
                          createSelect={true}
                        />
                      </Grid>
                    </Grid>
                    <Box height="16px" />
                    <Grid container direction={"row"}>
                      <Grid xs={6} style={{ paddingLeft: "8px" }}>
                        <SelectBox
                          label="Country"
                          onChange={(value) =>
                            updateState("newleadcountry", value)
                          }
                          value={data.newleadcountry}
                          placeholder="Select Country"
                          options={country}
                          createSelect={true}
                        />
                      </Grid>
                      <Grid xs={6} style={{ paddingLeft: "8px" }}>
                        <TextBox
                          label="Pincode"
                          fullWidth
                          placeholder="Enter Pincode"
                          onChange={(e) =>
                            updateState("newleadpincode", e.target.value)
                          }
                          value={data.newleadpincode}
                        />
                      </Grid>
                    </Grid>
                    <br />
                    <br />
                    <Box height="16px" />
                  </Grid>
                </Grid>
                <Grid
                  xs={12}
                  style={{
                    position: "fixed",
                    bottom: "0px",
                    width: "100%",
                    padding: "12px",
                    backgroundColor: "white",
                  }}
                >
                  <Button
                    variant="contained"
                    fullWidth
                    className={classes.iconButtonStyle}
                    onClick={() => {
                      setOpen(!open);
                    }}
                  >
                    <Typography className={classes.addAddressButtonTextStyle}>
                      {"Add Address"}
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Drawer>
        </Hidden>
      </div>
    </>
  );
};
