import React from "react";
import { ActiveCustomers } from "./activeCustomers";

class ActiveCustomersParent extends React.Component {
  render() {
    return <ActiveCustomers />;
  }
}

export default (ActiveCustomersParent);
