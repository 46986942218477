import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "white",
    borderRadius: "24px",
    textAlign: "center",
    padding: "14px 0px",
    cursor: "pointer",
    boxShadow: "0px 2px 6px #00000014",
    margin: "8px",
  },
  title: {
    fontSize: "14px",
    fontFamily: "tenant_SemiBold",
  },
}));

export const ActivityCard = (props) => {
  const classes = useStyles();
  const handleClick = () => {
    props?.data?.onClick();
  };
  return (
    <div className={classes.root} onClick={handleClick}>
      <img src={props.data.image} alt={props.data.title} />
      <Typography className={classes.title}>{props.data.title}</Typography>
    </div>
  );
};
