import { Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import SignatureCanvas from 'react-signature-canvas'


const useStyles = makeStyles((theme) => ({
    root: {

    },


}));
export const MoveStep3 = (props) => {
    const classes = useStyles();

    return (
        <>
            <Grid container className={classes.root}>
                <Grid item xs={12}>
                    <Typography gutterBottom>Signature </Typography>
                </Grid>
                <Grid item xs={12}>
                    <SignatureCanvas penColor='black'
                        canvasProps={{ width: 500, height: 100, className: 'sigCanvas' }} />,
                </Grid>
            </Grid>

        </>
    );
};
