import ChevronRight from "@mui/icons-material/ChevronRight";
import CloseIcon from "@mui/icons-material/Close";
import {
  Avatar,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Checkbox,
  Container,
  Dialog,
  Divider,
  Drawer,
  Grid,
  Hidden,
  IconButton,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import jwt_decode from "jwt-decode";
import React from "react";
import { useHistory } from "react-router-dom";
import { TopNavBars } from "../../components";
import {
  GET_PROFILE_DETAILS as queryOne,
  GET_DETAILS_FOR_PROFILE_SCREEN as queryTwo,
} from "../../graphql/queries";
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";
import { LocalStorageKeys, NetWorkCallMethods } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0px",
    backgroundColor: "#F2F5FA",
  },
  screenScrollEffect: {
    overflow: "overlay",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none"
    },
  },
  // screen: {
  //   borderRadius: "16px",
  //   border: "1px solid #F5F7FA",
  //   background: theme.palette.background.tertiary,
  // },
  topNavBarStyle: {
    // padding: "0px 16px 0px 16px",
  },
  screenPadding: {
    overflow: "overlay",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  profileCardStyle: {
    padding: "24px 16px 16px 16px",
    backgroundImage: `url(${"/images/Illustration.svg"})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "50%",
    background: theme.palette.background.tertiary1,
  },
  detailsCardStyle: {
    borderRadius: "8px",
    padding: "12px",
    backgroundColor: theme.palette.background.tertiary1,
    backgroundRepeat: "no-repeat",
    backgroundPositionX: "100%",
    backgroundPositionY: "100%",
  },
  bottomNavigationBarStyle: {
    borderRadius: "20px 20px 0px 0px",
    position: "fixed",
    width: "-webkit-fill-available",
  },
  drawer: {
    "& .css-9emuhu-MuiPaper-root-MuiDrawer-paper": {
      "background-color": "transparent",
      "box-shadow": "none",
    },
  },
  dialog: {
    "& .css-tlc64q-MuiPaper-root-MuiDialog-paper": {
      "background-color": "transparent",
      "box-shadow": "none",
    },
  },
  bottomSheetStyle: {
    background: "white",
    borderRadius: "16px 16px 0px 0px",
    border: "1px solid #E4E8EE",
    justifyContent: "center",
  },
  dialogStyle: {
    background: "white",
    borderRadius: "16px",
    border: "1px solid #E4E8EE",
    justifyContent: "center",
  },
  drawerHeaderStyle: {
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px",
  },
  drawerHeaderTextStyle: {
    fontSize: "16px",
    fontWeight: "bolder",
    color: theme.typography.color.primary,
  },
  drawerHeaderTextStyle1: {
    textAlign: "center",
    fontSize: "16px",
    fontWeight: "bolder",
    color: theme.typography.color.primary,
  },
  drawerBodyStyle: {
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px",
  },
  drawerButtonStyle: {
    "&:hover": "none",
    borderRadius: "12px",
    padding: "14px",
    background: theme.palette.primary.main,
    fontSize: "14px",
    fontWeight: "normal",
    color: "#FFF",
  },
  drawerButtonStyle1: {
    "&:hover": {
      background: "white",
      color: theme.palette.primary.main,
    },
    borderRadius: "12px",
    padding: "14px",
    background: "white",
    fontSize: "14px",
    fontWeight: "normal",
    color: theme.palette.primary.main,
  },
  containers: {
    "&.MuiContainer-root": {
      paddingLeft: "0px",
      paddingRight: "0px",
    },
    overflow: "hidden",
  },
}));

export const Profile = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const authToken = localStorage.getItem(LocalStorageKeys.authToken);
  const [company, setCompany] = React.useState(false);
  const roles = JSON.parse(localStorage.getItem("roles"));
  const selectedroles = JSON.parse(localStorage.getItem("selectedroles"));
  const [initialData, setInitialData] = React.useState({
    userProfileID: "",
    contactID: "",
    firstName: "",
    lastName: "",
    profileImageID: "",
    profileImage: "",
    userType: "Agent",
    clientRoleNo: "",
    primaryMobileNo: "",
    alternativeMobileNo: "",
    primaryEmailID: "",
    alternativeEmailID: "",
    address: "",
    addressLineOne: "",
    addressLineTwo: "",
    addressLineThree: "",
    district: "",
    city: "",
    state: "",
    zipCode: "",
    country: "",
    latitude: "",
    longitude: ""
  });
  const [openDrawer, setopenDrawer] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const size = useWindowDimensions();

  React.useEffect(() => {
    if (authToken !== null && authToken !== "") {
      const decodedAuthToken = jwt_decode(authToken);
      const userID = decodedAuthToken.id;
      const payload = {
        query: queryOne,
        variables: { userID: userID },
      };
      NetworkCall(
        `https://graphql-dev.propertyautomate.com/graphql`,
        NetWorkCallMethods.post,
        payload,
        null,
        true,
        false
      ).then((response) => {
        const userProfile = response?.data?.data?.user_profiles?.[0] !== null ? response?.data?.data?.user_profiles?.[0] : null;
        if (userProfile !== null) {
          initialData.userProfileID = userProfile?.id !== null ? userProfile?.id : "";
          initialData.contactID = userProfile?.contact_id !== null ? userProfile?.contact_id : "";
          initialData.firstName = userProfile?.first_name !== null ? userProfile?.first_name : "";
          initialData.lastName = userProfile?.last_name !== null ? userProfile?.last_name : "";
          initialData.primaryMobileNo = userProfile?.mobile_no !== null ? userProfile?.mobile_no : "";
          initialData.alternativeMobileNo = userProfile?.alternative_mobile_no !== null ? userProfile?.alternative_mobile_no : "";
          initialData.primaryEmailID = userProfile?.email_id !== null ? userProfile?.email_id : "";
          initialData.alternativeEmailID = userProfile?.alternative_email_id !== null ? userProfile?.alternative_email_id : "";
          const payload = {
            query: queryTwo,
            variables: {
              // referenceID: userProfile?.contact_id,
              referenceID: userProfile?.id,
              userProfileID: userProfile?.id,
              contactID: userProfile?.contact_id
            },
          };
          NetworkCall(
            `https://graphql-dev.propertyautomate.com/graphql`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
          ).then((response) => {
            const otherDetails = response?.data?.data !== null ? response?.data?.data : null;
            if (otherDetails !== null) {
              if (otherDetails?.assets !== null && otherDetails?.assets?.length > 0) {
                initialData.profileImageID = otherDetails?.assets?.[0]?.id !== null ? otherDetails?.assets?.[0]?.id : "";
                initialData.profileImage = otherDetails?.assets?.[0]?.url !== null ? otherDetails?.assets?.[0]?.url : "";
              }
              if (otherDetails?.client_user_roles !== null && otherDetails?.client_user_roles?.length > 0) {
                initialData.clientRoleNo = otherDetails?.client_user_roles?.[0]?.client_role_no !== null ? otherDetails?.client_user_roles?.[0]?.client_role_no : "";
              }
              if (otherDetails?.contact !== null && otherDetails?.contact?.length > 0) {
                initialData.addressLineOne = otherDetails?.contact?.[0]?.street_1 !== null ? otherDetails?.contact?.[0]?.street_1 : "";
                initialData.addressLineTwo = otherDetails?.contact?.[0]?.street_2 !== null ? otherDetails?.contact?.[0]?.street_2 : "";
                initialData.addressLineThree = otherDetails?.contact?.[0]?.street_3 !== null ? otherDetails?.contact?.[0]?.street_3 : "";
                initialData.district = otherDetails?.contact?.[0]?.district !== null ? otherDetails?.contact?.[0]?.district : "";
                initialData.city = otherDetails?.contact?.[0]?.city !== null ? otherDetails?.contact?.[0]?.city : "";
                initialData.state = otherDetails?.contact?.[0]?.state !== null ? otherDetails?.contact?.[0]?.state : "";
                initialData.zipCode = otherDetails?.contact?.[0]?.zipcode !== null ? otherDetails?.contact?.[0]?.zipcode : "";
                initialData.country = otherDetails?.contact?.[0]?.country !== null ? otherDetails?.contact?.[0]?.country : "";
                initialData.latitude = otherDetails?.contact?.[0]?.latitude !== null ? otherDetails?.contact?.[0]?.latitude : "";
                initialData.longitude = otherDetails?.contact?.[0]?.longitude !== null ? otherDetails?.contact?.[0]?.longitude : "";
                initialData.address =
                  ((otherDetails?.contact?.[0]?.street_1 !== null && otherDetails?.contact?.[0]?.street_1 !== "") ? otherDetails?.contact?.[0]?.street_1 : "") +
                  ((otherDetails?.contact?.[0]?.street_2 !== null && otherDetails?.contact?.[0]?.street_2 !== "") ? (", " + otherDetails?.contact?.[0]?.street_2) : "") +
                  ((otherDetails?.contact?.[0]?.street_3 !== null && otherDetails?.contact?.[0]?.street_3 !== "") ? (", " + otherDetails?.contact?.[0]?.street_3) : "") +
                  ((otherDetails?.contact?.[0]?.district !== null && otherDetails?.contact?.[0]?.district !== "") ? (", " + otherDetails?.contact?.[0]?.district) : "") +
                  ((otherDetails?.contact?.[0]?.city !== null && otherDetails?.contact?.[0]?.city !== "") ? (", " + otherDetails?.contact?.[0]?.city) : "") +
                  ((otherDetails?.contact?.[0]?.state !== null && otherDetails?.contact?.[0]?.state !== "") ? (", " + otherDetails?.contact?.[0]?.state) : "") +
                  ((otherDetails?.contact?.[0]?.country !== null && otherDetails?.contact?.[0]?.country !== "") ? (", " + otherDetails?.contact?.[0]?.country) : "") +
                  ((otherDetails?.contact?.[0]?.zipcode !== null && otherDetails?.contact?.[0]?.zipcode !== "") ? (", " + otherDetails?.contact?.[0]?.zipcode) : "")
              }
              setInitialData({ ...initialData });
              setLoading(false);
            } else { setLoading(false); }
          }).catch((error) => { console.log(error); });
        } else { setLoading(false); }
      }).catch((error) => { console.log(error); });
    } else {
      localStorage.clear();
      history.push(Routes.login);
    }
    // eslint-disable-next-line
  }, []);

  const handleCompany = () => {
    setCompany(true);
  };
  const closeCompany = () => {
    setCompany(false);
  };
  const changeCompany = (val) => {
    localStorage.setItem("selectedroles", JSON.stringify(val));
    closeCompany();
  };
  const statementOfAccount = () => {
    history.push(Routes.statementofaccount);
  };
  const Logout = () => {
    setopenDrawer(true);
  };

  // const fetchProfileImage = React.useCallback(async () => {
  //   const getProfileImagePayload = {
  //     query: GETPROFILEIMAGE,
  //     variables: { referenceId: profileData.contact.id },
  //   };
  //   NetworkCall(
  //     `https://graphql-dev.propertyautomate.com/graphql`,
  //     NetWorkCallMethods.post,
  //     getProfileImagePayload,
  //     null,
  //     true,
  //     false
  //   )
  //     .then((response) => {
  //       setProfileImage(response.data.data.assets[0]);
  //       setLoading(false);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }, [profileData]);

  // const fetchClientUserRole = React.useCallback(async () => {
  //   const getClientUserRolePayload = {
  //     query: GETCLIENTUSERROLE,
  //     variables: { userProfileId: profileData.id },
  //   };
  //   NetworkCall(
  //     `https://graphql-dev.propertyautomate.com/graphql`,
  //     NetWorkCallMethods.post,
  //     getClientUserRolePayload,
  //     null,
  //     true,
  //     false
  //   )
  //     .then((response) => {
  //       setClientUserRole(response.data.data.client_user_roles[0]);
  //       setLoading(false);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }, [profileData]);


  // React.useEffect(() => {
  //   fetchProfileImage()
  // }, [fetchProfileImage]);

  // React.useEffect(() => {
  //   fetchClientUserRole()
  // }, [fetchClientUserRole]);

  return (
    <Container
      className={classes.root}
      maxWidth="sm" >
      {loading ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) :
        (<>
          <TopNavBars note={false} home={false} />
          <Grid
            className={classes.screenScrollEffect}
            style={{ height: size?.height - (64 + 56), width: "inherit" }}>
            <Grid className={classes.screen} item xs={12}>
              <Grid
                container
                direction={"row"}
                alignItems={"center"}
                className={classes.profileCardStyle}
              >
                <Grid>
                  <Avatar
                    variant="circular"
                    style={{
                      height: "128px",
                      width: "128px",
                      border: "2px solid #5078E1",
                    }}
                  >
                    <Avatar
                      variant="circular"
                      style={{
                        height: "100%",
                        width: "100%",
                        border: "2px solid #ffffff",
                      }}
                    >
                      <img
                        src={initialData?.profileImage !== "" ? initialData?.profileImage : "/images/profile_image.png"}
                        alt="profile_image"
                        style={{
                          height: "128px",
                          width: "128px",
                        }} />
                    </Avatar>
                  </Avatar>
                  <Button
                    variant="contained"
                    style={{
                      padding: "4px 7px 4px 7px",
                      marginTop: "-30px",
                      marginLeft: "45px",
                      minWidth: "35px",
                      height: "24px",
                      backgroundColor: "#5078E1",
                      border: "2px solid #F5F7FA",
                      borderRadius: "8px",
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: "12px",
                        color: "#FFFFFF",
                      }}
                    >
                      <div onClick={() => history.push(Routes.profileEdit)}>
                        Edit
                      </div>
                    </Typography>
                  </Button>
                </Grid>
                <Box width="26px" />
                <Grid>
                  <Typography
                    style={{
                      fontSize: "24px",
                      fontWeight: "bolder",
                      color: "#091B29",
                    }}
                  >
                    {initialData?.firstName + " " + initialData?.lastName}
                  </Typography>
                  <Box height={"8px"} />
                  <Grid container direction={"row"}>
                    <Box
                      style={{
                        padding: "4px 8px 4px 8px",
                        border: "1px solid #27AFCF",
                        borderRadius: "4px",
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: "10px",
                          fontWeight: "bold",
                          color: "#27AFCF",
                        }}
                      >
                        {initialData?.userType}
                      </Typography>
                    </Box>
                    <Box width="8px" />
                    <Box
                      style={{
                        padding: "4px 8px 4px 8px",
                        border: "1px solid #6A68CC",
                        borderRadius: "4px",
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: "10px",
                          fontWeight: "bold",
                          color: "#6A68CC",
                        }}
                      >
                        {initialData?.clientRoleNo}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid style={{ padding: "16px" }}>
                <Grid
                  container
                  direction={"row"}
                // style={{
                //     display: "flex",
                //     alignItems: "stretch",
                //     flexWrap: "wrap",
                //     height: "100px",
                //     width: "100%",
                // }}
                >
                  <Grid
                    style={{ padding: "0px 8px 0px 0px" }}
                    xs={6}
                    container
                    direction={"column"}
                  >
                    <Grid
                      container
                      display="inline"
                      direction={"column"}
                      className={classes.detailsCardStyle}
                      style={{
                        backgroundImage: `url(${"/images/Mask_Group_39.svg"})`,
                        height: "-webkit-fill-available",
                      }}
                    >
                      <Avatar
                        variant="circular"
                        style={{
                          height: "28px",
                          width: "28px",
                          backgroundColor: "#46B9DA",
                        }}
                      >
                        <img src="/images/icons8-phone.svg" alt="" />
                      </Avatar>
                      <Box height="12px" />
                      <Typography
                        style={{
                          fontSize: "14px",
                          color: "#4E5A6B",
                          fontWeight: "bold",
                          wordWrap: "break-word",
                        }}
                      >
                        {initialData?.primaryMobileNo}
                      </Typography>
                      <Box height={"6px"} />
                      <Typography
                        style={{
                          fontSize: "14px",
                          color: "#4E5A6B",
                          fontWeight: "bold",
                          wordWrap: "break-word",
                        }}
                      >
                        {initialData?.alternativeMobileNo}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    style={{ padding: "0px 0px 0px 8px" }}
                    xs={6}
                    container
                    direction={"column"}
                  >
                    <Grid
                      container
                      display="inline"
                      direction={"column"}
                      className={classes.detailsCardStyle}
                      style={{
                        backgroundImage: `url(${"/images/Mask_Group_37.svg"})`,
                        height: "-webkit-fill-available",
                      }}
                    >
                      <Avatar
                        variant="circular"
                        style={{
                          height: "28px",
                          width: "28px",
                          backgroundColor: "#6A68CC",
                        }}
                      >
                        <img src="/images/icons8-mail.svg" alt="" />
                      </Avatar>
                      <Box height="12px" />
                      <Typography
                        style={{
                          fontSize: "14px",
                          color: "#4E5A6B",
                          fontWeight: "bold",
                          wordWrap: "break-word",
                        }}
                      >
                        {initialData?.primaryEmailID}
                      </Typography>
                      <Box height={"6px"} />
                      <Typography
                        style={{
                          fontSize: "14px",
                          color: "#4E5A6B",
                          fontWeight: "bold",
                          wordWrap: "break-word",
                        }}
                      >
                        {initialData?.alternativeEmailID}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Box height={"16px"} />
                <Grid container direction={"column"}>
                  <Grid
                    container
                    display="inline"
                    direction={"column"}
                    className={classes.detailsCardStyle}
                    style={{
                      backgroundImage: `url(${"/images/Mask_Group_38.svg"})`,
                    }}
                  >
                    <Avatar
                      variant="circular"
                      style={{
                        height: "28px",
                        width: "28px",
                        backgroundColor: "#FF4B4B",
                      }}
                    >
                      <img src="/images/icons8-map-marker.svg" alt="" />
                    </Avatar>
                    <Box height="12px" />
                    <Typography
                      style={{
                        fontSize: "14px",
                        color: "#4E5A6B",
                        fontWeight: "bold",
                        wordWrap: "break-word",
                      }}
                    >
                      {initialData?.address}
                    </Typography>
                    <Box height={"12px"} />
                  </Grid>
                </Grid>
                <Box height={"16px"} />
                <Grid
                  onClick={statementOfAccount}
                  container
                  direction={"row"}
                  justifyContent={"space-between"}
                  className={classes.detailsCardStyle}
                  style={{ cursor: "pointer" }}
                >
                  <Grid xs={10} container alignItems="center" direction={"row"}>
                    <img src="/images/icons8-cheque.svg" alt="" />
                    <Box width={"12px"} />
                    <Typography
                      style={{
                        fontSize: "14px",
                        color: "#091B29",
                        fontWeight: "bold",
                        wordWrap: "break-word",
                      }}
                    >
                      Statement Of Account
                    </Typography>
                  </Grid>
                  <ChevronRight />
                </Grid>
                <Box height={"16px"} />
                <Grid
                  onClick={Logout}
                  container
                  direction={"row"}
                  justifyContent={"space-between"}
                  className={classes.detailsCardStyle}
                  style={{ cursor: "pointer" }}
                >
                  <Grid xs={10} container alignItems="center" direction={"row"}>
                    <img src="/images/signout.svg" alt="signout" />
                    <Box width={"12px"} />
                    <Typography
                      style={{
                        fontSize: "14px",
                        color: "#091B29",
                        fontWeight: "bold",
                        wordWrap: "break-word",
                      }}
                    >
                      Log out
                    </Typography>
                  </Grid>
                  <ChevronRight />
                </Grid>
                <Box height={"16px"} />
                <Grid
                  onClick={handleCompany}
                  container
                  direction={"row"}
                  justifyContent={"space-between"}
                  className={classes.detailsCardStyle}
                  style={{ cursor: "pointer" }}
                >
                  <Grid xs={10} container alignItems="center" direction={"row"}>
                    <img src="images/signout.svg" alt="signout" />
                    <Box width={"12px"} />
                    <Typography
                      style={{
                        fontSize: "14px",
                        color: "#091B29",
                        fontWeight: "bold",
                        wordWrap: "break-word",
                      }}
                    >
                      {`${selectedroles?.company_name}`}-
                      {`${selectedroles?.company_id}`}
                    </Typography>
                  </Grid>
                  <Typography
                    style={{
                      fontSize: "14px",
                      color: "#091B29",
                      fontWeight: "bold",
                      wordWrap: "break-word",
                    }}
                  >
                    {`${selectedroles?.currency_type}`}-
                    {selectedroles?.currency_symbol}
                  </Typography>
                  <ChevronRight />
                </Grid>
                <Grid
                  container
                  direction={"column"}
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                  }}>
                  <Box height={"32px"} />
                  <Typography
                    style={{
                      fontSize: "14px",
                      color: "#091B29",
                      fontWeight: "bold",
                      wordWrap: "break-word",
                      textAlign: "center",
                    }}
                  >
                    About Us
                  </Typography>
                  <Box height={"8px"} />
                  <img
                    src="/images/PA Logo-01.svg"
                    alt=""
                    link
                    style={{
                      width: "30%",
                      justifyContent: "center",
                      alignItems: "center",
                    }} />
                  <Box height={"16px"} />
                </Grid>
              </Grid>
            </Grid>
            <Hidden smDown>
              <Dialog
                className={classes.dialog}
                fullWidth={true}
                maxWidth="sm"
                open={openDrawer}
              >
                <Grid container direction="column" className={classes.dialogStyle}>
                  <Grid
                    container
                    direction="row"
                    className={classes.drawerHeaderStyle}
                  >
                    <Grid>
                      <Typography className={classes.drawerHeaderTextStyle}>
                        Confirmation
                      </Typography>
                    </Grid>
                    <Grid>
                      <IconButton
                        style={{
                          padding: "0px",
                        }}
                        onClick={() => setopenDrawer(false)}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid className={classes.drawerBodyStyle}>
                    <Typography className={classes.drawerHeaderTextStyle1}>
                      Are you sure to logout?
                    </Typography>
                    <Box height={"32px"} />
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Button
                          onClick={() => setopenDrawer(false)}
                          fullWidth
                          className={classes.drawerButtonStyle1}
                          variant="contained"
                        >
                          Cancel
                        </Button>
                      </Grid>
                      <Grid item xs={6}>
                        <Button
                          onClick={() => {
                            localStorage.clear();
                            history.push(Routes.login);
                          }}
                          fullWidth
                          className={classes.drawerButtonStyle}
                          variant="contained"
                        >
                          Logout
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Dialog>
            </Hidden>
            <Hidden smUp>
              <Drawer className={classes.drawer} anchor="bottom" open={openDrawer}>
                <Grid
                  container
                  direction="column"
                  className={classes.bottomSheetStyle}
                >
                  <Grid
                    container
                    direction="row"
                    className={classes.drawerHeaderStyle}
                  >
                    <Grid>
                      <Typography className={classes.drawerHeaderTextStyle}>
                        Confirmation
                      </Typography>
                    </Grid>
                    <Grid>
                      <IconButton
                        style={{
                          padding: "0px",
                        }}
                        onClick={() => setopenDrawer(false)}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid className={classes.drawerBodyStyle}>
                    <Typography className={classes.drawerHeaderTextStyle1}>
                      Are you sure to logout?
                    </Typography>
                    <Box height={"32px"} />
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Button
                          onClick={() => setopenDrawer(false)}
                          fullWidth
                          className={classes.drawerButtonStyle1}
                          variant="contained"
                        >
                          Cancel
                        </Button>
                      </Grid>
                      <Grid item xs={6}>
                        <Button
                          onClick={() => {
                            localStorage.clear();
                            history.push(Routes.login);
                          }}
                          fullWidth
                          className={classes.drawerButtonStyle}
                          variant="contained"
                        >
                          Logout
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Drawer>
            </Hidden>
            <Hidden smDown>
              <Dialog
                className={classes.dialog}
                fullWidth={true}
                maxWidth="sm"
                open={company}
                onClose={closeCompany}
              >
                <Grid container direction="column" className={classes.dialogStyle}>
                  <Grid
                    container
                    direction="row"
                    className={classes.drawerHeaderStyle}
                  >
                    <Grid>
                      <Typography className={classes.drawerHeaderTextStyle}>
                        Select Company
                      </Typography>
                    </Grid>
                    <Grid>
                      <IconButton
                        style={{
                          padding: "0px",
                        }}
                        onClick={closeCompany}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid className={classes.drawerBodyStyle}>
                    {roles.map((val) => {
                      return (
                        <>
                          <Box
                            display="flex"
                            alignItems="center"
                            onClick={() => changeCompany(val)}
                            style={{ cursor: "pointer" }}
                          >
                            <Box>
                              <Checkbox
                                checked={
                                  val.company_name === selectedroles.company_name
                                }
                              />
                            </Box>

                            <Box flexGrow={1} marginLeft="12px">
                              <Typography
                                style={{
                                  fontSize: "14px",
                                  color: "#091B29",
                                  fontWeight: "bold",
                                }}
                                noWrap
                              >
                                {`${val?.company_name}`}-{`${val?.company_id}`}
                              </Typography>
                            </Box>
                            <Box width={"12px"} />
                            <Box>
                              <Typography
                                noWrap
                                style={{
                                  fontSize: "14px",
                                  color: "#091B29",
                                  fontWeight: "bold",
                                  wordWrap: "break-word",
                                  textAlign: "right",
                                }}
                              >
                                {`${val?.currency_type}`}-{val?.currency_symbol}
                              </Typography>
                            </Box>
                          </Box>
                        </>
                      );
                    })}
                  </Grid>
                </Grid>
              </Dialog>
            </Hidden>
            <Hidden smUp>
              <Drawer
                className={classes.drawer}
                anchor="bottom"
                open={company}
                onClose={closeCompany}
              >
                <Grid
                  container
                  direction="column"
                  className={classes.bottomSheetStyle}
                >
                  <Grid
                    container
                    direction="row"
                    className={classes.drawerHeaderStyle}
                  >
                    <Grid>
                      <Typography className={classes.drawerHeaderTextStyle}>
                        Select Company
                      </Typography>
                    </Grid>
                    <Grid>
                      <IconButton
                        style={{
                          padding: "0px",
                        }}
                        onClick={closeCompany}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid className={classes.drawerBodyStyle}>
                    {roles.map((val) => {
                      return (
                        <>
                          <Box
                            display="flex"
                            alignItems="center"
                            onClick={() => changeCompany(val)}
                            style={{ cursor: "pointer" }}
                          >
                            <Box>
                              <Checkbox
                                checked={
                                  val.company_name === selectedroles.company_name
                                }
                              />
                            </Box>

                            <Box flexGrow={1} marginLeft="12px">
                              <Typography
                                style={{
                                  fontSize: "14px",
                                  color: "#091B29",
                                  fontWeight: "bold",
                                }}
                                noWrap
                              >
                                {`${val?.company_name}`}-{`${val?.company_id}`}
                              </Typography>
                            </Box>
                            <Box width={"12px"} />
                            <Box>
                              <Typography
                                noWrap
                                style={{
                                  fontSize: "14px",
                                  color: "#091B29",
                                  fontWeight: "bold",
                                  wordWrap: "break-word",
                                  textAlign: "right",
                                }}
                              >
                                {`${val?.currency_type}`}-{val?.currency_symbol}
                              </Typography>
                            </Box>
                          </Box>
                        </>
                      );
                    })}
                  </Grid>
                </Grid>
              </Drawer>
            </Hidden>
          </Grid>
        </>)}
    </Container>
  );
};
