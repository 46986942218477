import React from "react";
import { LeadView } from "./leadView";

class LeadViewParent extends React.Component {
  render() {
    return <LeadView />;
  }
}

export default LeadViewParent;
