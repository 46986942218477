import moment from "moment-timezone";
import React, { createContext, useState } from "react";
import { config } from "../../config";
import {
  CREATEQUOTATION,
  POSTLEADDETAILS,
  POSTNEWCONTACT,
  UPLOADLEADIMAGE,
} from "../../graphql/mutations";
import { NetworkCall } from "../../networkcall/index";
import { NetWorkCallMethods } from "../../utils";
const InitialState = {
  type: "",
  leadDetails: [],
  leaseperiod: "",
  leasestartdate: "",
  leaseenddate: "",

  duration: "",
  durationtype: "",
  desp: "",
  valid: "",
  gradeperiod: "",
  gradeperiodtype: "",

  newleadprofile: "",
  newleadfirst_name: "",
  newleadlast_name: "",
  newleadmobile: "",
  newleadaltmobile: "",

  newleademail: "",
  newleadtele: "",
  newleadfax: "",
  newleadfacebook: "",
  newleadlinkedin: "",
  newleadtwitter: "",

  newleadstreet: "",
  newleadaddress2: "",
  newleadaddress3: "",
  newleadstate: {},
  newleadcity: {},
  newleadcountry: {},
  newleadpincode: "",
  newleaderror: {},
  error: {
    newleadfirst_name: "",
    newleadlast_name: "",
    newleadmobile: "",
    newleademail: "",
    duration: "",
    durationtype: "",
    desp: "",
    valid: "",
    gradeperiod: "",
    gradeperiodtype: "",
    leaseperiod: "",
  },
};
const InitialStatecontect = {
  email: "",
  mobile: "",
  name: "",
  altmobile: "",
  telephone: "",
  fax: "",
  facebook: "",
  twitter: "",
  linkedin: "",
  image: "",
  error: {
    email: "",
    mobile: "",
    name: "",
  },
};
const InitialStatelead = {
  name: "",
  requirment: "",
  requirmentType: "",
  budget: "",
  leaseperiod: "",
  startdate: "",
  endDate: "",
  leadtype: "",
  priority: "",
  notes: "",
  owner: "",

  error: {
    requirment: "",
    requirmentType: "",
    budget: "",
    leaseperiod: "",
    startdate: "",
    endDate: "",
    leadtype: "",
    priority: "",
    notes: "",
    owner: "",
  },
};
export const CreateQuotationListContext = createContext();

const CreateQuotationContextProvider = (props) => {
  const [data, setdata] = useState({ ...InitialState });
  const [data2, setData2] = React.useState({ ...InitialStatecontect });
  const [leaddata, setLeadData] = React.useState({ ...InitialStatelead });
  const [search, setSearch] = React.useState(false);
  const [step, setStep] = useState(0);
  const user_profile_id = localStorage.getItem("userProfileId");
  const [url, setUrl] = React.useState("");
  const [create, setCreate] = React.useState(false);
  moment.defaultFormat = "DD MMM YY";
  const [leadId, setLeadId] = React.useState("");
  const [already, setAlready] = React.useState(false);
  const [contactid, setContactId] = React.useState("");

  const updateState = (key, value) => {
    let error = data.error;
    error[key] = "";
    setdata({ ...data, [key]: value, error });
  };
  const updateStatelead2 = (key, value) => {
    let error = data2.error;
    error[key] = "";
    setData2({ ...data2, [key]: value, error });
  };
  const updateStatelead3 = (key, value) => {
    let error = leaddata.error;
    error[key] = "";
    setLeadData({ ...leaddata, [key]: value, error });
  };
  const previous = () => {
    setStep(step - 1);
  };
  const uploadleadimage = (id, url) => {
    const variables = {
      query: UPLOADLEADIMAGE,
      variables: {
        insert: [
          {
            is_active: true,
            reference_id: id,
            url: url,
            created_by: user_profile_id,
            assets_master_type: 2,
            priority: 1,
          },
        ],
      },
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      variables,
      null,
      true,
      false
    )
      .then((response) => {
        setStep(2);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const uploadImageS3 = (contactId) => {
    if (data.newleadprofile) {
      const formData = new FormData();
      formData.append("files", data.newleadprofile);
      formData.append("tenantId", `${config.tenantid}`);

      NetworkCall(
        `${config.api_url}/file-upload`,
        NetWorkCallMethods.post,
        formData,
        null,
        true,
        false
      )
        .then((response) => {
          setUrl(response.data.fileUrls[0].url);
          uploadleadimage(contactId, response.data.fileUrls[0].url);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const isIamValide = () => {
    let isValid = true;
    let error = data.error;

    //Checking name
    if (data.newleadfirst_name.length === 0) {
      isValid = false;
      error.newleadfirst_name = "First Name is Required";
    }
    //Checking name
    if (data.newleadfirst_name.length === 0) {
      isValid = false;
      error.newleadlast_name = "Last Name is Required";
    }
    //Checking Mobile
    if (data.newleadmobile.length === 0) {
      isValid = false;
      error.newleadmobile = "Mobile is Required";
    }
    //Checking mail
    if (data.newleademail.length === 0) {
      isValid = false;
      error.newleademail = "Email is Required";
    }

    setdata({ ...data, error });

    return isValid;
  };
  const isIamValidelead = () => {
    let isValid = true;
    let error = leaddata.error;

    //Checking name
    if (leaddata.name.length === 0) {
      isValid = false;
      error.name = "Subject is Required";
    }
    //Checking Requirement
    if (leaddata.requirment.length === 0) {
      isValid = false;
      error.requirment = "Requirement is Required";
    }

    //Checking notes
    if (leaddata.requirmentType.length === 0) {
      isValid = false;
      error.requirmentType = " Requirment Type is Required";
    }

    //Checking priority
    if (leaddata.priority.length === 0) {
      isValid = false;
      error.priority = "Priority is Required";
    }

    //Checking startdata
    if (leaddata.startdate.length === 0) {
      isValid = false;
      error.startdate = "Start Date is Required";
    }
    //Checking endDate
    if (leaddata.endDate.length === 0) {
      isValid = false;
      error.endDate = "End Date is Required";
    }
    //Checking notes
    if (leaddata.notes.length === 0) {
      isValid = false;
      error.notes = "Notes is Required";
    }
    //Checking leasePeriod
    if (leaddata.leaseperiod.length === 0) {
      isValid = false;
      error.leaseperiod = "Leaseperiod is Required";
    }

    if (
      moment(moment(leaddata.endDate).tz(moment.tz.guess()).format()).isAfter(
        moment(leaddata.startdate).tz(moment.tz.guess()).format()
      ) === false
    ) {
      isValid = false;
      error.endDate = "End Date is More then Start Date";
    }
    if (
      moment(moment(leaddata.startdate).tz(moment.tz.guess()).format()).isAfter(
        moment(leaddata.endDate).tz(moment.tz.guess()).format()
      )
    ) {
      isValid = false;
      error.startdate = "Start Date is Less then End Date";
    }

    setLeadData({ ...leaddata, error });
    return isValid;
  };
  const isIamValidequotation = () => {
    let isValid = true;
    let error = data.error;

    //Checking Duration
    if (data.duration.length === 0) {
      isValid = false;
      error.duration = "Duration is Required";
    }
    //Checking Durationtype
    if (data.durationtype.length === 0) {
      isValid = false;
      error.durationtype = "Durationtype is Required";
    }
    //Checking Description
    if (data.desp.length === 0) {
      isValid = false;
      error.desp = "Description is Required";
    }
    //Checking valid
    if (data.valid.length === 0) {
      isValid = false;
      error.valid = "Valid Till is Required";
    }
    //Checking leaseperiod
    if (data.leaseperiod.length === 0) {
      isValid = false;
      error.leaseperiod = "Leaseperiod Till is Required";
    }

    //Checkinggradeperiod
    if (data.gradeperiod.length === 0) {
      isValid = false;
      error.gradeperiod = "Gradeperiod Till is Required";
    }

    setdata({ ...data, error });
    return isValid;
  };
  const nextStep1 = () => {
    if (step === 1) {
      if (contactid.length === 0) {
        if (isIamValide()) {
          const variables = {
            query: POSTNEWCONTACT,
            variables: {
              insert: [
                {
                  first_name: data.newleadfirst_name,
                  last_name: data.newleadlast_name,
                  email_id: data.newleademail,
                  mobile_no: data.newleadmobile.mobile,
                  mobile_no_country_code: "+91",
                  alternative_mobile: data.newleadaltmobile.mobile_no,
                  street_1: data.newleadstreet,
                  street_2: data.newleadaddress2,
                  street_3: data.newleadaddress3,
                  city: data.newleadcity.value,
                  state: data.newleadstate.value,
                  country: data.newleadcountry.value,
                  facebook: data.newleadfacebook,
                  linkedin: data.newleadlinkedin,
                  twitter: data.newleadtwitter,
                  is_active: true,
                  zipcode: data.newleadpincode,
                },
              ],
            },
          };
          NetworkCall(
            `${config.graphql_url}`,
            NetWorkCallMethods.post,
            variables,
            null,
            true,
            false
          ).then((response) => {
            uploadImageS3(response.data.data.contact[0].id);
            setContactId(response.data.data.contact[0].id);
          });
        } else {
          return false;
        }
      } else {
        setStep(2);
      }
    } else if (step === 2) {
      if (isIamValidelead()) {
        setStep(3);
      } else {
        return false;
      }
    } else if (step === 3) {
      if (create) {
        setStep(4);
      } else {
        const variables = {
          query: POSTLEADDETAILS,
          variables: {
            insert: [
              {
                subject: leaddata.name,
                value: parseInt(leaddata.requirment),
                type: leaddata.requirmentType,
                lease_start_date: leaddata.startdate,
                lease_end_date: leaddata.endDate,
                lead_type: leaddata.leadtype,
                notes: leaddata.notes,
                lead_owner: user_profile_id,
                lease_period: leaddata.leaseperiod,
                budget_range: leaddata.budget,
                urgent: leaddata.priority,
                created_by: user_profile_id,
                contact_id: contactid,
                is_active: true,
              },
            ],
          },
        };
        NetworkCall(
          `${config.graphql_url}`,
          NetWorkCallMethods.post,
          variables,
          null,
          true,
          false
        ).then((response) => {
          setLeadId(response.data.data.lead[0].id);
          setCreate(true);
          setStep(4);
        });
      }
    } else if (step === 4) {
      if (isIamValidequotation()) {
        const variables = {
          query: CREATEQUOTATION,
          variables: {
            insert: [
              {
                lease_period: data.leaseperiod,
                lease_payment_period: data.leaseperiod,
                lease_start_date: data.leasestartdate,
                lease_end_date: data.leaseenddate,
                quotation_duration: data.durationtype,
                quotation_duration_value: data.duration,
                description: data.desp,
                grace_period: data.gradeperiod,
                quotation_expiry_date: data.valid,
                lead_id: leadId,
              },
            ],
          },
        };
        NetworkCall(
          `${config.graphql_url}`,
          NetWorkCallMethods.post,
          variables,
          null,
          true,
          false
        ).then((response) => {
          setStep(5);
        });
      } else {
        return false;
      }
    } else if (step === 5) {
    }
  };
  const nextStep2 = () => {
    if (step === 1) {
      setStep(2);
    } else if (step === 2) {
      if (isIamValidequotation()) {
        const variables = {
          query: CREATEQUOTATION,
          variables: {
            insert: [
              {
                lease_period: data.leaseperiod,
                lease_payment_period: data.leaseperiod,
                lease_start_date: data.leasestartdate,
                lease_end_date: data.leaseenddate,
                quotation_duration: data.durationtype,
                quotation_duration_value: data.duration,
                description: data.desp,
                grace_period: data.gradeperiod,
                quotation_expiry_date: data.valid,
                lead_id: leadId ? leadId : data.leadDetails.id,
              },
            ],
          },
        };
        NetworkCall(
          `${config.graphql_url}`,
          NetWorkCallMethods.post,
          variables,
          null,
          true,
          false
        ).then((response) => {
          setStep(3);
        });
      } else {
        return false;
      }
    }
  };

  const next = () => {
    if (data.type === "new lead") {
      nextStep1();
    } else if (data.type === "existing lead") {
      nextStep2();
    }
  };

  return (
    <CreateQuotationListContext.Provider
      value={{
        data,
        updateState,
        step,
        previous,
        next,
        updateStatelead2,
        updateStatelead3,
        data2,
        contactid,
        url,
        leaddata,
        search,
        setSearch,
        already,
        setAlready,
        setContactId,

        setdata,
        setStep,
      }}
    >
      {props.children}
    </CreateQuotationListContext.Provider>
  );
};

export default CreateQuotationContextProvider;
