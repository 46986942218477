import { Box, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

const useStyles = makeStyles((theme) => ({
    root: {
        cursor: "pointer",
    },
    periodStyle: {
        height: "6px",
        width: "6px",
        borderRadius: "50%",
        backgroundColor: "#CED3DD",
        margin: "6px",
    },
    name: {
        fontSize: "14px",
        fontFamily: "tenant_bold",
        textTransform: "capitalize",
    },
    call: {
        fontSize: "12px",
        color: theme.typography.color.secondary,
    },
    mail: {
        fontSize: "12px",
        color: theme.typography.color.secondary,
    },
    img: {
        objectFit: "cover",
        height: "60px",
        width: "60px",
        borderRadius: "12px",
    },
    tagStyle: {
        backgroundColor: "#5078E11E",
        borderRadius: "5px",
        padding: "3px 10px 3px 10px",
        fontSize: "10px",
        fontFamily: "tenant_bold",
        color: theme.typography.color.primary,
    },
}));

export const AgreementCard = (props) => {
    const classes = useStyles();

    return (
        <Grid
            container
            // justifyContent={"center"}
            // alignItems={"center"}
            direction={"column"}>
            <Grid
                container
                direction={"row"}
                // justifyContent={"center"}
                alignItems={"center"}
                className={classes.root}
                onClick={() => { props.onClick(props.data); }}
                style={{
                    padding: "8px 0px 8px 0px"
                }}>
                <Box
                    variant="circular"
                    style={{
                        borderRadius: "50%",
                        height: "16px",
                        width: "16px",
                        border: (props.selectedAgreement && props.selectedAgreement.id === props.data.id) ? "2px solid #5078E1" : "1px solid #DDE7F3",
                    }}>
                    <Box
                        variant="circular"
                        style={{
                            borderRadius: "50%",
                            height: "100%",
                            width: "100%",
                            backgroundColor: (props.selectedAgreement && props.selectedAgreement.id === props.data.id) ? "#5078E1" : "#ffffff",
                            border: "2px solid #ffffff",
                        }} />
                </Box>
                <Grid
                    container
                    direction={"row"}
                    // justifyContent={"center"}
                    // alignItems={"center"}
                    xs={11}>
                    <Grid
                        container
                        // justifyContent={"center"}
                        // alignItems={"center"}
                        direction={"column"}
                        style={{
                            padding: "0px 0px 0px 8px"
                        }}>
                        <Grid
                            container
                            // justifyContent={"center"}
                            // alignItems={"center"}
                            direction={"row"}>
                            <Typography className={classes.name}>
                                {props?.data?.agreementNo}
                            </Typography>
                        </Grid>
                        <Grid
                            container
                            // justifyContent={"center"}
                            // alignItems={"center"}
                            direction={"row"}>
                            <Typography className={classes.mail}>
                                {props?.data?.properties !== null ?
                                    (props?.data?.properties?.length + (props?.data?.properties?.length === 1 ? " Property" : " Properties")) :
                                    ""}
                            </Typography>
                            <Box className={classes.periodStyle} />
                            <Typography className={classes.mail}>
                                {props?.data?.units !== null ?
                                    (props?.data?.units?.length + (props?.data?.units?.length === 1 ? " Unit" : " Units")) :
                                    ""}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {(props.array.length !== (props.index + 1)) ? <Box
                style={{
                    backgroundColor: "#E4E8EE",
                    height: "1px",
                    width: "inherit"
                }} /> : <></>}
        </Grid>
    );
};
