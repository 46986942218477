import React from "react";
import { CreateActivity } from "./createactivity";
import ContextProvider from "./createactivityContext";
const CreateActivityParent = () => {
  return (
    <ContextProvider>
      <CreateActivity />
    </ContextProvider>
  );
};

export default CreateActivityParent;
