import React from "react";
import { ProfileEdit } from "./profileEdit";

class ProfileEditParent extends React.Component {
  render() {
    return <ProfileEdit />;
  }
}

export default ProfileEditParent;
