import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { CreateQuotationListContext } from "./createquotationcontaxt";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: "20px",
    backgroundColor: "#F5F7FA",
  },
  type: {
    fontSize: "16px",
    fontFamily: "tenant_bold",
    marginBottom: "10px",
    marginTop: "10px",
  },
  leadroot: {
    boxShadow: "0px 1px 6px #00000014",
    padding: "20px 12px",
    borderRadius: "16px",
    cursor: "pointer",
    backgroundColor: "white",
  },
}));
export const Typeselect = (props) => {
  const classes = useStyles();

  const { updateState, setStep } = React.useContext(CreateQuotationListContext);

  return (
    <div>
      <div className={classes.root}>
        <Box
          className={classes.leadroot}
          onClick={() => {
            updateState("type", "existing lead");
            setStep(1);
          }}
        >
          <center>
            <img src="/images/excestionlead.svg" alt="" />

            <Typography className={classes.type}>Existing Lead</Typography>
            <img src="/images/nextarrow.svg" alt="" />
          </center>
        </Box>
        <br />
        <Box
          className={classes.leadroot}
          onClick={() => {
            updateState("type", "new lead");
            setStep(1);
          }}
        >
          <center>
            <img src="/images/newlead.svg" alt="" />

            <Typography className={classes.type}>New Lead</Typography>
            <img src="/images/nextarrow.svg" alt="" />
          </center>
        </Box>
      </div>
    </div>
  );
};
