import React from "react";
import { withNavBars } from "../../HOCs";
import { Prem } from "./prem";

class PremParent extends React.Component {
  render() {
    return <Prem />;
  }
}

export default withNavBars(PremParent);
