import { Box, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    cursor: "pointer",
    marginTop: "8px",
  },
  periodStyle: {
    height: "6px",
    width: "6px",
    borderRadius: "50%",
    backgroundColor: "#CED3DD",
    margin: "6px",
  },
  name: {
    fontSize: "14px",
    fontFamily: "tenant_bold",
    textTransform: "capitalize",
  },
  call: {
    fontSize: "12px",
    color: theme.typography.color.secondary,
  },
  mail: {
    fontSize: "12px",
    color: theme.typography.color.secondary,
  },
  img: {
    objectFit: "cover",
    height: "60px",
    width: "60px",
    borderRadius: "12px",
  },
  tagStyle: {
    backgroundColor: "#5078E11E",
    borderRadius: "5px",
    padding: "3px 10px 3px 10px",
    fontSize: "10px",
    fontFamily: "tenant_bold",
    color: theme.typography.color.primary,
  },
}));

export const ContactList = (props) => {
  const classes = useStyles();

  return (
    <div
      className={classes.root}
      onClick={() => {
        props.onClick(props.data);
      }}
    >
      <Grid container>
        <Grid item xs={12}>
          <Box display="flex">
            {props.leadtype ? (
              <Box>
                <img
                  src={
                    props?.data?.contact?.get_assets_url_id
                      ? props?.data?.contact?.get_assets_url_id
                      : "/images/blank-profile-picture.png"
                  }
                  alt=""
                  className={classes.img}
                />
              </Box>
            ) : (
              <Box>
                <img
                  src={
                    props.data.get_assets_url_id
                      ? props.data.get_assets_url_id
                      : "/images/blank-profile-picture.png"
                  }
                  alt=""
                  className={classes.img}
                />
              </Box>
            )}

            <Box flexGrow={1} marginTop="10px" marginLeft="8px">
              <Typography className={classes.name}>
                {props?.leadtype ? (
                  <>
                    {props?.data?.contact?.first_name}{" "}
                    {props?.data?.contact?.last_name}
                  </>
                ) : (
                  <>
                    {props?.data?.first_name} {props?.data?.last_name}
                  </>
                )}

                {/* <span className={classes.tagStyle}>{props.data.arg}</span> */}
              </Typography>
              <Box display="flex">
                <Box>
                  <Typography className={classes.call}>
                    {props?.leadtype ? (
                      <>+91 {props?.data?.contact?.mobile_no}</>
                    ) : (
                      <>+91 {props?.data?.mobile_no}</>
                    )}
                  </Typography>
                </Box>
                <Box className={classes.periodStyle} />
                <Box>
                  <Typography className={classes.mail}>
                    {props?.leadtype ? (
                      <> {props?.data?.contact?.email_id}</>
                    ) : (
                      <> {props?.data?.email_id}</>
                    )}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};
