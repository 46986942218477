export const city = [
    { value: "Abohar", label: "Abohar" },
    { value: "Abu", label: "Abu" },
    { value: "Addanki", label: "Addanki" },
    { value: "Adilabad", label: "Adilabad" },
    { value: "Adoni", label: "Adoni" },
    { value: "Agartala", label: "Agartala" },
    { value: "Agra", label: "Agra" },
    { value: "Ahmadnagar", label: "Ahmadnagar" },
    { value: "Ahmedabad", label: "Ahmedabad" },
    { value: "Aizawl", label: "Aizawl" },
    { value: "Ajmer", label: "Ajmer" },
    { value: "Akola", label: "Akola" },
    { value: "Aligarh", label: "Aligarh" },
    { value: "Alipur Duar", label: "Alipur Duar" },
    { value: "Allahabad", label: "Allahabad" },
    { value: "Alleppey", label: "Alleppey" },
    { value: "Alwar", label: "Alwar" },
    { value: "Amalapuram", label: "Amalapuram" },
    { value: "Amarkantak", label: "Amarkantak" },
    { value: "Ambala", label: "Ambala" },
    { value: "Amravati", label: "Amravati" },
    { value: "Amritsar", label: "Amritsar" },
    { value: "Amudalavalasa", label: "Amudalavalasa" },
    { value: "Anakapalle", label: "Anakapalle" },
    { value: "Anantapur", label: "Anantapur" },
    { value: "Angamali", label: "Angamali" },
    { value: "Armur", label: "Armur" },
    { value: "Asansol", label: "Asansol" },
    { value: "Aurangabad", label: "Aurangabad" },
    { value: "Aurangabad", label: "Aurangabad" },
    { value: "Badvel", label: "Badvel" },
    { value: "Baharampur", label: "Baharampur" },
    { value: "Bahraigh", label: "Bahraigh" },
    { value: "Baidyabati", label: "Baidyabati" },
    { value: "Bali", label: "Bali" },
    { value: "Balurghat", label: "Balurghat" },
    { value: "Bandipura", label: "Bandipura" },
    { value: "Bangalore", label: "Bangalore" },
    { value: "Bangaon", label: "Bangaon" },
    { value: "Bankura", label: "Bankura" },
    { value: "Bansbaria", label: "Bansbaria" },
    { value: "Bapatla", label: "Bapatla" },
    { value: "Baramula", label: "Baramula" },
    { value: "Barasat", label: "Barasat" },
    { value: "Barddhaman", label: "Barddhaman" },
    { value: "Barehra", label: "Barehra" },
    { value: "Bareilly", label: "Bareilly" },
    { value: "Basirhat", label: "Basirhat" },
    { value: "Begusarai", label: "Begusarai" },
    { value: "Belampalli", label: "Belampalli" },
    { value: "Belgaum", label: "Belgaum" },
    { value: "Bellary", label: "Bellary" },
    { value: "Belur", label: "Belur" },
    { value: "Betma", label: "Betma" },
    { value: "Bezwada", label: "Bezwada" },
    { value: "Bhadreswar", label: "Bhadreswar" },
    { value: "Bhagalpur", label: "Bhagalpur" },
    { value: "Bhainsa", label: "Bhainsa" },
    { value: "Bharatpur", label: "Bharatpur" },
    { value: "Bhatpara", label: "Bhatpara" },
    { value: "Bhavnagar", label: "Bhavnagar" },
    { value: "Bhayandar", label: "Bhayandar" },
    { value: "Bhilai", label: "Bhilai" },
    { value: "Bhilwara", label: "Bhilwara" },
    { value: "Bhimavaram", label: "Bhimavaram" },
    { value: "Bhimunipatnam", label: "Bhimunipatnam" },
    { value: "Bhiwandi", label: "Bhiwandi" },
    { value: "Bhiwani", label: "Bhiwani" },
    { value: "Bhopal", label: "Bhopal" },
    { value: "Bhubaneshwar", label: "Bhubaneshwar" },
    { value: "Bhuj", label: "Bhuj" },
    { value: "Bhusaval", label: "Bhusaval" },
    { value: "Bidar", label: "Bidar" },
    { value: "Bijapur", label: "Bijapur" },
    { value: "Bikaner", label: "Bikaner" },
    { value: "Bilaspur", label: "Bilaspur" },
    { value: "Bobbili", label: "Bobbili" },
    { value: "Bodhan", label: "Bodhan" },
    { value: "Bodupal", label: "Bodupal" },
    { value: "Brahmapur", label: "Brahmapur" },
    { value: "Budaun", label: "Budaun" },
    { value: "Bulandshahr", label: "Bulandshahr" },
    { value: "Burhanpur", label: "Burhanpur" },
    { value: "Calicut", label: "Calicut" },
    { value: "Cawnpore", label: "Cawnpore" },
    { value: "Chakradharpur", label: "Chakradharpur" },
    { value: "Champdani", label: "Champdani" },
    { value: "Chanda", label: "Chanda" },
    { value: "Chandannagar", label: "Chandannagar" },
    { value: "Chandigarh", label: "Chandigarh" },
    { value: "Channarayapatna", label: "Channarayapatna" },
    { value: "Chennai", label: "Chennai" },
    { value: "Chicacole", label: "Chicacole" },
    { value: "Chikhli", label: "Chikhli" },
    { value: "Chilakalurupet", label: "Chilakalurupet" },
    { value: "Chimakurti", label: "Chimakurti" },
    { value: "Chinchvad", label: "Chinchvad" },
    { value: "Chipurupalle", label: "Chipurupalle" },
    { value: "Chirala", label: "Chirala" },
    { value: "Chittaurgarh", label: "Chittaurgarh" },
    { value: "Chittoor", label: "Chittoor" },
    { value: "Coimbatore", label: "Coimbatore" },
    { value: "Conjeeveram", label: "Conjeeveram" },
    { value: "Cuddalore", label: "Cuddalore" },
    { value: "Cuddapah", label: "Cuddapah" },
    { value: "Curchorem", label: "Curchorem" },
    { value: "Cuttack", label: "Cuttack" },
    { value: "Dam Dam", label: "Dam Dam" },
    { value: "Daman", label: "Daman" },
    { value: "Darjeeling", label: "Darjeeling" },
    { value: "Davangere", label: "Davangere" },
    { value: "Dehra Dun", label: "Dehra Dun" },
    { value: "Delhi", label: "Delhi" },
    { value: "Deo", label: "Deo" },
    { value: "Devarkonda", label: "Devarkonda" },
    { value: "Dhanbad", label: "Dhanbad" },
    { value: "Dharmavaram", label: "Dharmavaram" },
    { value: "Dharmsala", label: "Dharmsala" },
    { value: "Dholka", label: "Dholka" },
    { value: "Dhulia", label: "Dhulia" },
    { value: "Dibrugarh", label: "Dibrugarh" },
    { value: "Dindigul", label: "Dindigul" },
    { value: "Dispur", label: "Dispur" },
    { value: "Diu", label: "Diu" },
    { value: "Draksharama", label: "Draksharama" },
    { value: "Drug", label: "Drug" },
    { value: "Dubak", label: "Dubak" },
    { value: "Durgapur", label: "Durgapur" },
    { value: "Dwarka", label: "Dwarka" },
    { value: "Ellore", label: "Ellore" },
    { value: "Emmiganur", label: "Emmiganur" },
    { value: "Erode", label: "Erode" },
    { value: "Etawah", label: "Etawah" },
    { value: "Faridabad", label: "Faridabad" },
    { value: "Fatehpur Sikri", label: "Fatehpur Sikri" },
    { value: "Fatehpur", label: "Fatehpur" },
    { value: "Firozabad", label: "Firozabad" },
    { value: "Fyzabad", label: "Fyzabad" },
    { value: "Gadwal", label: "Gadwal" },
    { value: "Gangtok", label: "Gangtok" },
    { value: "Gauripur", label: "Gauripur" },
    { value: "Gaya", label: "Gaya" },
    { value: "Ghandinagar", label: "Ghandinagar" },
    { value: "Ghaziabad", label: "Ghaziabad" },
    { value: "Giddalur", label: "Giddalur" },
    { value: "Godhra", label: "Godhra" },
    { value: "Gorakhpur", label: "Gorakhpur" },
    { value: "Gudivada", label: "Gudivada" },
    { value: "Gulbarga", label: "Gulbarga" },
    { value: "Guntakal", label: "Guntakal" },
    { value: "Guntur", label: "Guntur" },
    { value: "Gurgaon", label: "Gurgaon" },
    { value: "Guwahati", label: "Guwahati" },
    { value: "Gwalior", label: "Gwalior" },
    { value: "Habra", label: "Habra" },
    { value: "Haldia", label: "Haldia" },
    { value: "Halisahar", label: "Halisahar" },
    { value: "Handwara", label: "Handwara" },
    { value: "Haora", label: "Haora" },
    { value: "Hapur", label: "Hapur" },
    { value: "Haripur", label: "Haripur" },
    { value: "Hassan", label: "Hassan" },
    { value: "Hathras", label: "Hathras" },
    { value: "Hindupur", label: "Hindupur" },
    { value: "Hisar", label: "Hisar" },
    { value: "Hospet", label: "Hospet" },
    { value: "Hubli", label: "Hubli" },
    { value: "Hugli", label: "Hugli" },
    { value: "Hyderabad", label: "Hyderabad" },
    { value: "Ichalkaranji", label: "Ichalkaranji" },
    { value: "Imphal", label: "Imphal" },
    { value: "Indore", label: "Indore" },
    { value: "Ingraj Bazar", label: "Ingraj Bazar" },
    { value: "Itanagar", label: "Itanagar" },
    { value: "Jabalpur", label: "Jabalpur" },
    { value: "Jaggayyapeta", label: "Jaggayyapeta" },
    { value: "Jagtial", label: "Jagtial" },
    { value: "Jaipur", label: "Jaipur" },
    { value: "Jaisalmer", label: "Jaisalmer" },
    { value: "Jalandhar", label: "Jalandhar" },
    { value: "Jalgaon", label: "Jalgaon" },
    { value: "Jalor", label: "Jalor" },
    { value: "Jalpaiguri", label: "Jalpaiguri" },
    { value: "Jammalamadugu", label: "Jammalamadugu" },
    { value: "Jammu", label: "Jammu" },
    { value: "Jamnagar", label: "Jamnagar" },
    { value: "Jamshedpur", label: "Jamshedpur" },
    { value: "Jamuria", label: "Jamuria" },
    { value: "Jangaon", label: "Jangaon" },
    { value: "Jasdan", label: "Jasdan" },
    { value: "Jatani", label: "Jatani" },
    { value: "Jaunpur", label: "Jaunpur" },
    { value: "Jaynagar-Majilpur", label: "Jaynagar-Majilpur" },
    { value: "Jhansi", label: "Jhansi" },
    { value: "Jodhpur", label: "Jodhpur" },
    { value: "Jorhat", label: "Jorhat" },
    { value: "Junnar", label: "Junnar" },
    { value: "Kadiri", label: "Kadiri" },
    { value: "Kairana", label: "Kairana" },
    { value: "Kakinada", label: "Kakinada" },
    { value: "Kalyan", label: "Kalyan" },
    { value: "Kalyani", label: "Kalyani" },
    { value: "Kamareddipet", label: "Kamareddipet" },
    { value: "Kamarhati", label: "Kamarhati" },
    { value: "Kanchrapara", label: "Kanchrapara" },
    { value: "Kandukur", label: "Kandukur" },
    { value: "Karimnagar", label: "Karimnagar" },
    { value: "Karnal", label: "Karnal" },
    { value: "Karur", label: "Karur" },
    { value: "Kavali", label: "Kavali" },
    { value: "Kavaratti", label: "Kavaratti" },
    { value: "Khajuraho", label: "Khajuraho" },
    { value: "Khambhat", label: "Khambhat" },
    { value: "Khammam", label: "Khammam" },
    { value: "Kharagpur", label: "Kharagpur" },
    { value: "Khardah", label: "Khardah" },
    { value: "Khed Brahma", label: "Khed Brahma" },
    { value: "Khed", label: "Khed" },
    { value: "Khem Karan", label: "Khem Karan" },
    { value: "Kilkunda", label: "Kilkunda" },
    { value: "Kochi", label: "Kochi" },
    { value: "Kodaikanal", label: "Kodaikanal" },
    { value: "Kohima", label: "Kohima" },
    { value: "Koilkuntla", label: "Koilkuntla" },
    { value: "Kolar", label: "Kolar" },
    { value: "Kolhapur", label: "Kolhapur" },
    { value: "Kolkata", label: "Kolkata" },
    { value: "Konarka", label: "Konarka" },
    { value: "Koratla", label: "Koratla" },
    { value: "Kota", label: "Kota" },
    { value: "Kothapet", label: "Kothapet" },
    { value: "Kottagudem", label: "Kottagudem" },
    { value: "Kovvur", label: "Kovvur" },
    { value: "Krishnanagar", label: "Krishnanagar" },
    { value: "Kulgam", label: "Kulgam" },
    { value: "Kulti", label: "Kulti" },
    { value: "Kulu", label: "Kulu" },
    { value: "Kumbakonam", label: "Kumbakonam" },
    { value: "Kurnool", label: "Kurnool" },
    { value: "Lansdowne", label: "Lansdowne" },
    { value: "Latur", label: "Latur" },
    { value: "Loutolim", label: "Loutolim" },
    { value: "Lucknow", label: "Lucknow" },
    { value: "Ludhiana", label: "Ludhiana" },
    { value: "Macherla", label: "Macherla" },
    { value: "Machilipatnam", label: "Machilipatnam" },
    { value: "Madanapalle", label: "Madanapalle" },
    { value: "Madhyamgram", label: "Madhyamgram" },
    { value: "Madurai", label: "Madurai" },
    { value: "Mahadipur", label: "Mahadipur" },
    { value: "Mahbubnagar", label: "Mahbubnagar" },
    { value: "Mahesana", label: "Mahesana" },
    { value: "Maihar", label: "Maihar" },
    { value: "Malaut", label: "Malaut" },
    { value: "Malegaon", label: "Malegaon" },
    { value: "Manali", label: "Manali" },
    { value: "Mancheral", label: "Mancheral" },
    { value: "Mandamari", label: "Mandamari" },
    { value: "Mandapeta", label: "Mandapeta" },
    { value: "Mandsaur", label: "Mandsaur" },
    { value: "Mandya", label: "Mandya" },
    { value: "Mangalagiri", label: "Mangalagiri" },
    { value: "Mangalore", label: "Mangalore" },
    { value: "Mangur", label: "Mangur" },
    { value: "Markapur", label: "Markapur" },
    { value: "Mathura", label: "Mathura" },
    { value: "Mauli", label: "Mauli" },
    { value: "Medinipur", label: "Medinipur" },
    { value: "Meerut", label: "Meerut" },
    { value: "Meghraj", label: "Meghraj" },
    { value: "Metpalli", label: "Metpalli" },
    { value: "Mirzapur", label: "Mirzapur" },
    { value: "Moradabad", label: "Moradabad" },
    { value: "Mumbai", label: "Mumbai" },
    { value: "Mundra", label: "Mundra" },
    { value: "Muzaffarnagar", label: "Muzaffarnagar" },
    { value: "Muzaffarpur", label: "Muzaffarpur" },
    { value: "Mysore", label: "Mysore" },
    { value: "Nagari", label: "Nagari" },
    { value: "Nagercoil", label: "Nagercoil" },
    { value: "Nagpur", label: "Nagpur" },
    { value: "Naihati", label: "Naihati" },
    { value: "Naini Tal", label: "Naini Tal" },
    { value: "Nalgonda", label: "Nalgonda" },
    { value: "Naliya", label: "Naliya" },
    { value: "Nanded", label: "Nanded" },
    { value: "Nandod", label: "Nandod" },
    { value: "Nandyal", label: "Nandyal" },
    { value: "Narasannapeta", label: "Narasannapeta" },
    { value: "Narasapur", label: "Narasapur" },
    { value: "Narasaraopet", label: "Narasaraopet" },
    { value: "Nasik", label: "Nasik" },
    { value: "Navadwip", label: "Navadwip" },
    { value: "Navsari", label: "Navsari" },
    { value: "Negapatam", label: "Negapatam" },
    { value: "Nellore", label: "Nellore" },
    { value: "New Delhi", label: "New Delhi" },
    { value: "Niala Kondapalle", label: "Niala Kondapalle" },
    { value: "Nidadavole", label: "Nidadavole" },
    { value: "Nizamabad", label: "Nizamabad" },
    { value: "Ongole", label: "Ongole" },
    { value: "Ootacamund", label: "Ootacamund" },
    { value: "Orchha", label: "Orchha" },
    { value: "Osmanabad", label: "Osmanabad" },
    { value: "Palakollu", label: "Palakollu" },
    { value: "Palghat", label: "Palghat" },
    { value: "Pali", label: "Pali" },
    { value: "Palmaner", label: "Palmaner" },
    { value: "Paloncha", label: "Paloncha" },
    { value: "Pamidi", label: "Pamidi" },
    { value: "Panaji", label: "Panaji" },
    { value: "Panchgani", label: "Panchgani" },
    { value: "Panihati", label: "Panihati" },
    { value: "Panipat", label: "Panipat" },
    { value: "Parbhani", label: "Parbhani" },
    { value: "Parigi", label: "Parigi" },
    { value: "Pathanamthitta", label: "Pathanamthitta" },
    { value: "Pathankot", label: "Pathankot" },
    { value: "Patiala", label: "Patiala" },
    { value: "Patna", label: "Patna" },
    { value: "Pedana", label: "Pedana" },
    { value: "Pedda Nandipadu", label: "Pedda Nandipadu" },
    { value: "Pilibhit", label: "Pilibhit" },
    { value: "Pithapuram", label: "Pithapuram" },
    { value: "Ponduru", label: "Ponduru" },
    { value: "Ponnuru", label: "Ponnuru" },
    { value: "Porbandar", label: "Porbandar" },
    { value: "Port Blair", label: "Port Blair" },
    { value: "Proddatur", label: "Proddatur" },
    { value: "Puducherry", label: "Puducherry" },
    { value: "Pulivendla", label: "Pulivendla" },
    { value: "Pune", label: "Pune" },
    { value: "Punganuru", label: "Punganuru" },
    { value: "Puri", label: "Puri" },
    { value: "Purnea", label: "Purnea" },
    { value: "Pushkar", label: "Pushkar" },
    { value: "Puttur", label: "Puttur" },
    { value: "Quilon", label: "Quilon" },
    { value: "Raichur", label: "Raichur" },
    { value: "Raiganj", label: "Raiganj" },
    { value: "Raipur", label: "Raipur" },
    { value: "Rajahmundry", label: "Rajahmundry" },
    { value: "Rajaori", label: "Rajaori" },
    { value: "Rajapalaiyam", label: "Rajapalaiyam" },
    { value: "Rajgir", label: "Rajgir" },
    { value: "Rajkot", label: "Rajkot" },
    { value: "Rajmahal", label: "Rajmahal" },
    { value: "Rameswaram", label: "Rameswaram" },
    { value: "Ramgundam", label: "Ramgundam" },
    { value: "Rampur", label: "Rampur" },
    { value: "Ranchi", label: "Ranchi" },
    { value: "Ratlam", label: "Ratlam" },
    { value: "Raurkela", label: "Raurkela" },
    { value: "Rayachoti", label: "Rayachoti" },
    { value: "Repalle", label: "Repalle" },
    { value: "Rishra", label: "Rishra" },
    { value: "Rohtak", label: "Rohtak" },
    { value: "Saharanpur", label: "Saharanpur" },
    { value: "Saharsa", label: "Saharsa" },
    { value: "Salem", label: "Salem" },
    { value: "Samalkot", label: "Samalkot" },
    { value: "Sambalpur", label: "Sambalpur" },
    { value: "Sambhal", label: "Sambhal" },
    { value: "Sangli", label: "Sangli" },
    { value: "Sannai", label: "Sannai" },
    { value: "Sattenapalle", label: "Sattenapalle" },
    { value: "Saugor", label: "Saugor" },
    { value: "Secunderabad", label: "Secunderabad" },
    { value: "Seven Pagodas", label: "Seven Pagodas" },
    { value: "Shahjanpur", label: "Shahjanpur" },
    { value: "Shantipur", label: "Shantipur" },
    { value: "Shiliguri", label: "Shiliguri" },
    { value: "Shillong", label: "Shillong" },
    { value: "Shimla", label: "Shimla" },
    { value: "Shimoga", label: "Shimoga" },
    { value: "Shrirampur", label: "Shrirampur" },
    { value: "Shrirangapattana", label: "Shrirangapattana" },
    { value: "Sihor", label: "Sihor" },
    { value: "Sikar", label: "Sikar" },
    { value: "Silchar", label: "Silchar" },
    { value: "Silvassa", label: "Silvassa" },
    { value: "Sirsa", label: "Sirsa" },
    { value: "Sirsilla", label: "Sirsilla" },
    { value: "Sitalpur", label: "Sitalpur" },
    { value: "Solan", label: "Solan" },
    { value: "Solapur", label: "Solapur" },
    { value: "Sonipat", label: "Sonipat" },
    { value: "Sopur", label: "Sopur" },
    { value: "Srinagar", label: "Srinagar" },
    { value: "Surat", label: "Surat" },
    { value: "Suriapet", label: "Suriapet" },
    { value: "Tadepalle", label: "Tadepalle" },
    { value: "Tadepallegudem", label: "Tadepallegudem" },
    { value: "Tadpatri", label: "Tadpatri" },
    { value: "Tanjore", label: "Tanjore" },
    { value: "Tanuku", label: "Tanuku" },
    { value: "Tenali", label: "Tenali" },
    { value: "Tezpur", label: "Tezpur" },
    { value: "Thane", label: "Thane" },
    { value: "Thiruvananthapuram", label: "Thiruvananthapuram" },
    { value: "Tinnevelly", label: "Tinnevelly" },
    { value: "Tirupati", label: "Tirupati" },
    { value: "Tiruppur", label: "Tiruppur" },
    { value: "Tiruvannamalai", label: "Tiruvannamalai" },
    { value: "Titagarh", label: "Titagarh" },
    { value: "Tonk", label: "Tonk" },
    { value: "Trichinopoly", label: "Trichinopoly" },
    { value: "Trichur", label: "Trichur" },
    { value: "Tumkur", label: "Tumkur" },
    { value: "Tuni", label: "Tuni" },
    { value: "Tuticorin", label: "Tuticorin" },
    { value: "Udaipur", label: "Udaipur" },
    { value: "Udhampur", label: "Udhampur" },
    { value: "Udipi", label: "Udipi" },
    { value: "Ujjain", label: "Ujjain" },
    { value: "Ulhasnagar", label: "Ulhasnagar" },
    { value: "Uluberiya", label: "Uluberiya" },
    { value: "Umaria", label: "Umaria" },
    { value: "Uran", label: "Uran" },
    { value: "Vadodara", label: "Vadodara" },
    { value: "Valparai", label: "Valparai" },
    { value: "Varanasi", label: "Varanasi" },
    { value: "Vellore", label: "Vellore" },
    { value: "Venkatagiri", label: "Venkatagiri" },
    { value: "Vidisha", label: "Vidisha" },
    { value: "Vikarabad", label: "Vikarabad" },
    { value: "Vinukonda", label: "Vinukonda" },
    { value: "Vishakhapatnam", label: "Vishakhapatnam" },
    { value: "Vizianagaram", label: "Vizianagaram" },
    { value: "Vrindavan", label: "Vrindavan" },
    { value: "Wanparti", label: "Wanparti" },
    { value: "Warangal", label: "Warangal" },
    { value: "Yavatmal", label: "Yavatmal" },
    { value: "Yellandu", label: "Yellandu" },
]