/* eslint-disable array-callback-return */
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormHelperText from "@mui/material/FormHelperText";
import FormLabel from "@mui/material/FormLabel";
import * as React from "react";

export const CheckboxesGroup = ({
  selectedValues = [],
  onSelect = () => false,
  required = false,
  error = false,
  title = "",
  helperText = "",
  filters = [],
  search = "",
}) => {
  const handleChange = (event, value) => {
    onSelect({ value, isChecked: event.target.checked });
  };

  return (
    <Box sx={{ display: "flex" }}>
      <FormControl
        required={required}
        error={error}
        sx={{ m: 3 }}
        component="fieldset"
        variant="standard"
      >
        {title && <FormLabel component="legend">{title}</FormLabel>}
        <FormGroup>
          {filters
            .filter((filter) => {
              if (search === "") {
                return filter;
              } else if (
                filter.label.toLowerCase().includes(search.toLowerCase())
              ) {
                return filter;
              }
            })
            .map((filter, key) => (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedValues.indexOf(filter.value) > -1}
                    onChange={(event) => handleChange(event, filter.value)}
                  />
                }
                label={filter.label}
              />
            ))}
        </FormGroup>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    </Box>
  );
};
