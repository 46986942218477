import GoogleMapReact from "google-map-react";
import React, { Component } from "react";
import styled from "styled-components";
import AutoComplete from "./autoComplete";
import Marker from "./marker";

const Wrapper = styled.main`
  width: 100%;
  height: 100%;
`;

class MyGoogleMap extends Component {
  state = {
    mapApiLoaded: false,
    mapInstance: null,
    mapApi: null,
    geoCoder: null,
    places: [],
    center: this.props.center,
    zoom: 9,
    address: "",
    result: null,
    draggable: true,
    lat: this.props.lat,
    lng: this.props.lng,
  };

  componentWillMount() {
    if (this.props.lat && this.props.lng) {
    } else {
      this.setCurrentLocation();
    }
  }

  onMarkerInteraction = (childKey, childProps, mouse) => {
    this.setState({
      draggable: false,
      lat: mouse.lat,
      lng: mouse.lng,
    });
  };
  onMarkerInteractionMouseUp = (childKey, childProps, mouse) => {
    this.setState({ draggable: true });
    this._generateAddress();
  };

  _onChange = ({ center, zoom }) => {
    this.setState({
      center: center,
      zoom: zoom,
    });
  };

  _onClick = (value) => {
    this.setState({
      lat: value.lat,
      lng: value.lng,
    });
    this._generateAddressForOnClick(value);
  };

  apiHasLoaded = (map, maps) => {
    this.setState({
      mapApiLoaded: true,
      mapInstance: map,
      mapApi: maps,
    });

    this._generateAddress();
  };

  addPlace = (place) => {
    this.setState({
      places: [place],
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
    });
    this._generateAddress();
  };

  _generateAddress() {
    const { mapApi } = this.state;

    const geocoder = new mapApi.Geocoder();

    geocoder.geocode(
      { location: { lat: this.state.lat, lng: this.state.lng } },
      (results, status) => {
        if (status === "OK") {
          if (results[0]) {
            this.zoom = 12;
            this.setState({
              result: results[0],
              address: results[0].formatted_address,
            });
            this.props.mapResult({
              ...this.state,
            });
          } else {
            window.alert("No results found");
          }
        } else {
          window.alert("Geocoder failed due to: " + status);
        }
      }
    );
  }

  _generateAddressForOnClick(value) {
    const { mapApi } = this.state;

    const geocoder = new mapApi.Geocoder();

    geocoder.geocode(
      { location: { lat: value.lat, lng: value.lng } },
      (results, status) => {
        if (status === "OK") {
          if (results[0]) {
            this.zoom = 12;
            this.setState({
              result: results[0],
              address: results[0].formatted_address,
            });
            this.props.mapResult({
              ...this.state,
            });
          } else {
            window.alert("No results found");
          }
        } else {
          window.alert("Geocoder failed due to: " + status);
        }
      }
    );
  }

  // Get Current Location Coordinates
  setCurrentLocation() {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.setState({
          center: [position.coords.latitude, position.coords.longitude],
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      });
    }
  }

  render() {
    // const {
    //   places, mapApiLoaded, mapInstance, mapApi,
    // } = this.state;
    const { mapApiLoaded, mapInstance, mapApi } = this.state;

    return (
      <Wrapper>
        {/* <div className="info-wrapper">
          <div className="map-details">Latitude: <span>{this.state.lat}</span>, Longitude: <span>{this.state.lng}</span></div>
          <div className="map-details">Zoom: <span>{this.state.zoom}</span></div>
          <div className="map-details">Address: <span>{this.state.address}</span></div>
        </div> */}
        <GoogleMapReact
          center={this.state.center}
          zoom={this.state.zoom}
          draggable={this.state.draggable}
          onChange={this._onChange}
          onChildMouseDown={this.onMarkerInteraction}
          onChildMouseUp={this.onMarkerInteractionMouseUp}
          onChildMouseMove={this.onMarkerInteraction}
          // onChildClick={this._onClick}
          onClick={this._onClick}
          bootstrapURLKeys={{
            key: "AIzaSyAOb6SGSupR3aquQkI8TEO_vPewSG8IaFM",
            libraries: ["places", "geometry"],
          }}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => this.apiHasLoaded(map, maps)}
        >
          <Marker
            text={this.state.address}
            lat={this.state.lat}
            lng={this.state.lng}
          />
        </GoogleMapReact>
        {mapApiLoaded && (
          <div style={{ marginTop: "-320px" }}>
            <AutoComplete
              map={mapInstance}
              mapApi={mapApi}
              addplace={this.addPlace}
            />
          </div>
        )}
      </Wrapper>
    );
  }
}

export default MyGoogleMap;
