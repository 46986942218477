import DateAdapter from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import DateTimePicker from "@mui/lab/DateTimePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { TextField, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import makeStyles from "@mui/styles/makeStyles";
import * as React from "react";
const useStyles = makeStyles((theme) => ({
  text: {
    fontSize: "12px",
    color: theme.typography.color.Tertiary,
  },
  datepicker: {
    border: "none",
  },
  textbox: {
    [`& fieldset`]: {
      borderRadius: "8px",

      boxShadow: "none",
    },
    "& .MuiOutlinedInput-input": {
      padding: "14px",
    },
  },
}));

export const DateTime = (props) => {
  const classes = useStyles(props);
  const getTitle = (props) => {
    return (
      <Typography variant="body1" className={classes.text} gutterBottom>
        {props.title}{" "}
      </Typography>
    );
  };

  return (
    <>
      {getTitle(props)}
      <LocalizationProvider dateAdapter={DateAdapter}>
        <Stack spacing={3}>
          {props.time ? (
            <DatePicker
              className={classes.datepicker}
              disableFuture={props.disableFuture}
              disablePast={props.disablePast}
              label={props.placeholder}
              views={["day", "month"]}
              value={props.value ? props?.value : new Date().toISOString()}
              onChange={(newValue) => {
                props.onChange(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  color="primary"
                  {...params}
                  className={classes.textbox}
                  placeholder={props.placeholder}
                />
              )}
            />
          ) : (
            <DateTimePicker
              label={props.placeholder}
              value={props.value ? props.value : new Date().toISOString()}
              disableFuture={props.disableFuture}
              disablePast={props.disablePast}
              onChange={(newValue) => {
                props.onChange(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} className={classes.textbox} />
              )}
            />
          )}
        </Stack>
      </LocalizationProvider>
    </>
  );
};
