import { Button, Container, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import jwt_decode from "jwt-decode";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { EnterOtp } from "../../components";
import { config } from "../../config";
import { AlertContext,CommonApiContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";
import { AlertProps } from "../../utils";
import { LocalStorageKeys, NetWorkCallMethods } from "../../utils/constants";
const useStyles = makeStyles((theme) => ({
  backgroundImage:props=>({
    // backgroundColor: theme.palette.primary.main,
    height: "22vh",
    // backgroundImage: `url("images/setupaccount.svg")`,
    backgroundImage: `url(${props?.bg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 22vh",
    backgroundPosition: "center",
  }),
  content: {
    height: "78vh",
    width: "100%",
    padding: 20,
    display:'flex',
    flexDirection:'column'
  },
  text: {
    fontSize: "24px",
    fontFamily: "tenant_extraBold",
    color: theme.typography.color.primary,
  },
  btn: {
    borderRadius: "12px",
    boxShadow: "opx 6px 10px #00000014",
    marginBlock:theme.spacing(2),
    padding: "16px",
    "&:hover": {
      color: "#FFFFFF",
      backgroundColor: "#5078E1",
    },
  },
  img: {
    height: "100%",
    width: "28%",
  },
  verificationText: {
    fontSize: "14px",
    fontFamily: "tenant_regular",
    textAlign: "center",
    color: theme.typography.color.primary,
    backgroundColor: theme.palette.background.secondary,
    borderRadius: "15px",
    padding: "20px",
  },
  poweredby: {
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    fontSize: "8px",
    color: theme.typography.color.Tertiary,
  },
  pa: {
    color: theme.typography.color.secondary,
    fontFamily: "tenant_semiBold",
    fontSize: "10px",
  },
}));

export const OtpPage = () => {
  const history = useHistory();
  const commanApi = React.useContext(CommonApiContext);
  const classes = useStyles({bg:commanApi?.logo?.otp_banner_url});
  const alert = React.useContext(AlertContext);
  const [value, setValue] = React.useState("");
  const handleChange = (otp) => {
    setValue(otp);
  };
  const search = useLocation().search;
  const welcomePageAuthToken = new URLSearchParams(search).get(
    "welcomeAuthToken"
  );
  const emailId = new URLSearchParams(search).get("emailId");
  const [decoded, setDecoded] = React.useState(null);

  React.useEffect(() => {
    if (welcomePageAuthToken !== null && welcomePageAuthToken !== "") {
      setDecoded(jwt_decode(welcomePageAuthToken));
    }
    // eslint-disable-next-line
  }, []);
  const verifyOtp = () => {
    const payload = {
      tenantId: `${config.tenantid}`,
      email_id: decoded && decoded.email_id ? decoded.email_id : emailId,
      OTP: value,
    };
    NetworkCall(
      `${config.api_url}/users/verifyOTP`,
      NetWorkCallMethods.post,
      payload,
      null,
      false,
      false
    )
      .then((response) => {
        if (response.status === 200) {
          const roles = response.data.roles.map((val) => {
            return val.name;
          });
          const roleslist = response.data.roles.filter(
            (val) => val.name === "Agent"
          );
          const even = (roles) => roles === "Agent";

          if (roles.some(even)) {
            localStorage.setItem(
              LocalStorageKeys.authToken,
              response.data.token
            );
            localStorage.setItem("roles", JSON.stringify(roleslist));
            localStorage.setItem("clientLogo",response?.data?.client_logo)
            localStorage.setItem("selectedroles", JSON.stringify(roleslist[0]));
            history.push(Routes.dashboard);
          } else {
            alert.setSnack({
              ...alert,
              open: true,
              severity: AlertProps.severity.error,
              msg: "Sorry You Don't Have Access",
            });
          }
        } else {
          localStorage.setItem(LocalStorageKeys.authToken, null);
          history.push(Routes.login);
        }
      })
      .catch((err) => {
        console.log(err);
        localStorage.setItem(LocalStorageKeys.authToken, null);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: " Invalid OTP",
        });
      });
  };
  return (
    <div>
      <Container maxWidth="sm" style={{ padding: 0 }}>
        <Grid container>
          <Grid item xs={12} className={classes.backgroundImage} />
          <div className={classes.content}>
          <Grid item style={{flex:1}}>
            <Grid
              container
              xs={12}
              direction="row"
              justifyContent={"space-between"}
              alignContent={"center"}
            >
              <img
                // src="/images/logoOne.svg"
                src={commanApi?.logo?.logo_url}
                alt="logo"
                className={classes.img}
              />
              <img
                // src="/images/Propgoto logo-01.svg"
                src="/images/PA Logo.svg"
                alt="logo"
                className={classes.img}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.text}>Enter OTP</Typography>
              <br />
            </Grid>

            <Grid item xs={12}>
              <EnterOtp
                value={value}
                handleChange={handleChange}
                emailId={
                  decoded && decoded.email_id ? decoded.email_id : emailId
                }
              />
              <br />
            </Grid>
            <Grid item xs={12}>
              <div className={classes.verificationText}>
                <Typography>
                  Verification code has been sent to your email & mobile number
                </Typography>
              </div>
            </Grid>
            </Grid>
            <div>
              <Button
                fullWidth
                variant="contained"
                onClick={verifyOtp}
                className={classes.btn}
                size="large"
                disabled={value.length === 4 ? false : true}
              >
                Verify
              </Button>
              </div>
          </div>
        </Grid>
      </Container>
    </div>
  );
};
