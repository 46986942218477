import { Container, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useHistory } from "react-router-dom";
import { PropertyList, TopNavBars } from "../../components";
import { Routes } from "../../router/routes";
import useWindowDimensions from "../../utils/useWindowDimensions";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.secondary,
  },
  propertyList: {
    backgroundColor: theme.palette.background.paper,
    paddingTop: "10px",
    paddingLeft: "12px",
    paddingRight: "12px",
  },
  total: {
    fontSize: "16px",
    fontFamily: "tenant_bold",
    padding: "10px 14px",
  },
}));
export const SearchResult = (props) => {
  const classes = useStyles();
  const size = useWindowDimensions();
  const history = useHistory();
  const unitlisted1 = [
    {
      id: 0,
      image: "/images/image1.png",
      title: "Rubix Apartment1",
      Reqid: "PID74432",
      loc: "t-nagar",
      units: 3,
      amount: "1,200.00",
      shortlist: 12,
      address: "Rubix Apartment1, South road, T.Nagar, Chennai",
    },
    {
      id: 1,
      image: "/images/image1.png",
      title: "Rubix Apartment2",
      Reqid: "PID74432",
      loc: "t-nagar",
      units: 4,
      amount: "1,200.00",
      shortlist: 12,
      address: "Rubix Apartmen2, South road, T.Nagar, Chennai",
    },
    {
      id: 2,
      image: "/images/image1.png",
      title: "Rubix Apartment3",
      Reqid: "PID74432",
      loc: "t-nagar",
      units: 4,
      amount: "1,200.00",
      shortlist: 12,
      address: "Rubix Apartmen3, South road, T.Nagar, Chennai",
    },
  ];
  const Propertyview = () => {
    history.push(Routes.propertyview);
  };
  return (
    <div>
      <Container maxWidth="sm" sx={{ padding: "0px" }}>
        <div className={classes.root} style={{ height: size?.height }}>
          <TopNavBars />
          <Grid container>
            <Grid item xs={12}>
              <Typography className={classes.total}>
                Properties (
                {unitlisted1.length < 10
                  ? `0${unitlisted1.length}`
                  : unitlisted1.length}
                )
              </Typography>

              <div className={classes.propertyList}>
                {unitlisted1.map((val) => {
                  return (
                    <PropertyList
                      data={val}
                      link={true}
                      onClick={Propertyview}
                    />
                  );
                })}
              </div>
            </Grid>
          </Grid>
        </div>
      </Container>
    </div>
  );
};
