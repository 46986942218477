import React from "react";
import { withBottombar } from "../../HOCs";
import { Search } from "./search";
import ContextProvider from "./searchContext";
const SearchParent = () => {
  return (
    <ContextProvider>
      <Search />
    </ContextProvider>
  );
};

export default withBottombar(SearchParent);
