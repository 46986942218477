import CloseIcon from "@mui/icons-material/Close";
import {
  Avatar,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  Divider,
  Drawer,
  Grid,
  Hidden,
  IconButton,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { saveAs } from "file-saver";
import moment from "moment";
import React from "react";
import { useParams } from "react-router-dom";
import {
  DateTime,
  DocumentViewer,
  Quotation,
  QuotationCard,
  QuotationHistory,
  SelectBox,
  TextBox,
  TopNavBars,
} from "../../components";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { ACTIVITYCREATE as mutationOne } from "../../graphql/mutations";
import {
  GETALL,
  GETALLSTATUS,
  GETCATEGORY as queryTwo,
  GETSUBCATEGORY as queryThree,
  INVOICEQ,
  USER,
} from "../../graphql/queries";
import { NetworkCall } from "../../networkcall/index";
import { AlertProps, NetWorkCallMethods } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.background.tertiary1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  field: {
    marginTop: "10px",
  },
  label: {
    fontSize: "12px",
    color: "#98A0AC",
  },
  Requirements: {
    padding: "10px",
    textAlign: "center",
    borderRadius: "10px",
    color: "white",
    fontSize: "14px",
    backgroundColor: "#5078E1",
    cursor: "pointer",
  },
  Requirementsqfts: {
    padding: "10px",
    textAlign: "center",
    borderRadius: "10px",
    fontSize: "14px",
    cursor: "pointer",
    border: "1px solid #E4E8EE",
  },
  screen: {
    borderRadius: "16px",
    border: "1px solid #F5F7FA",
    background: theme.palette.background.secondary,
  },

  screenPadding: {
    padding: "0px 16px 0px 16px",
    overflow: "overlay",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  btn: {
    borderRadius: "12px",
    boxShadow: "0px 6px 10px #00000014",
    padding: "16px",
    "&:hover": {
      color: "#FFFFFF",
      backgroundColor: "#5078E1",
    },
  },
  buttonTextStyle: {
    fontSize: "14px",
    color: "#FFFFFF",
    fontFamily: "tenant_bold",
  },
  actionButtonContainerStyle: {
    padding: "16px",
    background: "white",
    borderRadius: "16px 16px 0px 0px",
    border: "1px solid #E4E8EE",
    boxShadow: "0px -7px 20px #0717410D",
    position: "fixed",
    width: "-webkit-fill-available",
  },
  notInterestedStyle: {
    textAlign: "center",
    textDecoration: "underline",
    color: "#5078E1",
    fontSize: "14px",
    fontWeight: "bold",
    cursor: "pointer",
  },
  drawer: {
    "& .css-9emuhu-MuiPaper-root-MuiDrawer-paper": {
      "box-shadow": "none",
      borderTopLeftRadius: "14px",
      borderTopRightRadius: "14px",
    },
  },
  dialog: {
    "& .css-tlc64q-MuiPaper-root-MuiDialog-paper": {
      "background-color": "transparent",
      "box-shadow": "none",
    },
  },
  bottomSheetStyle: {
    background: "white",
    borderRadius: "16px 16px 0px 0px",
    border: "1px solid #E4E8EE",
    justifyContent: "center",
  },
  dialogStyle: {
    background: "white",
    borderRadius: "16px",
    border: "1px solid #E4E8EE",
    justifyContent: "center",
  },
  drawerHeaderStyle: {
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px",
  },
  drawerHeaderTextStyle: {
    fontSize: "16px",
    fontWeight: "bolder",
    color: theme.typography.color.primary,
  },
  drawerBodyStyle: {
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px",
  },
  pdfDrawerBodyStyle: {
    background: theme.palette.background.tertiary,
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px",
    height: "400px",
    width: "inherit",
    overflow: "overlay",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  pdfImageStyle: {
    width: "inherit",
  },
  drawerFooterStyle: {
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px",
  },
  drawerButtonStyle: {
    "&:hover": "none",
    borderRadius: "12px",
    padding: "14px",
    background: theme.palette.primary.main,
    fontSize: "14px",
    fontWeight: "normal",
    color: "#FFF",
  },
  periodStyle: {
    height: "6px",
    width: "6px",
    borderRadius: "50%",
    backgroundColor: "#CED3DD",
    margin: "6px",
  },
  status: {
    backgroundColor: "white",
    padding: "auto 12px",
    border: "1px solid #E4E8EE",
    borderRadius: "16px",
    boxShadow: "0px 3px 18px #0000000F",
  },
  statusdes: {
    fontSize: "16px",
    fontFamily: "tenant_SemiBold",
  },
}));

export const QuotationDetails = (props) => {
  const classes = useStyles();
  const size = useWindowDimensions();
  const alert = React.useContext(AlertContext);
  let { id } = useParams();
  //========================Build Datas State

  const [maindata, setMaindata] = React.useState([]);
  const [historydata, setHistorydata] = React.useState([]);
  const [filterChild, setfilterChild] = React.useState([]);

  const [invoice, setInvoice] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [payment1, setPayment1] = React.useState(false);
  const [payment, setPayment] = React.useState(false);
  const [priority, setPriority] = React.useState("");
  const [category, setCategory] = React.useState([]);
  const [subCategory, setSubCategory] = React.useState([]);

  const [initialCreateActivityData, setInitialCreateActivityData] =
    React.useState({
      leadID: maindata?.lead_id,
      category: [],
      purpose: [],
      date: "",
      location: "",
      notes: "",
      priority: "",
      error: {
        category: "",
        purpose: "",
        date: "",
        location: "",
        notes: "",
        priority: "",
      },
    });
  const updateInitialCreateActivityData = (key, value) => {
    let error = initialCreateActivityData.error;
    error[key] = "";
    setInitialCreateActivityData({
      ...initialCreateActivityData,
      [key]: value,
      error,
    });
  };
  //========================Build Datas Functions
  const handlePurpose = (val) => {
    initialCreateActivityData.category = val;
    initialCreateActivityData.purpose = [];
    setInitialCreateActivityData({ ...initialCreateActivityData });
    const variables = {
      query: queryThree,
      variables: {
        master_id: val.value,
      },
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      variables,
      null,
      true,
      false
    )
      .then((response) => {
        const temp_subcategory =
          response.data.data.activity_sub_category_master?.map((item) => {
            let _cat;
            try {
              _cat = {
                label: item?.sub_category ? item?.sub_category : "",
                value: item?.id ? item?.id : "",
              };
            } catch (error) {
              console.log(error);
            }
            return _cat;
          });
        setSubCategory(temp_subcategory);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  React.useEffect(() => {
    const payload = {
      query: queryTwo,
      variables: { activity_id: "60f6e6f0-1328-47b4-8dc6-34035ee49c6e" },
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        const temp_category = response.data.data.activity_category_master?.map(
          (item) => {
            let _cat;
            try {
              _cat = {
                label: item?.activity_category ? item?.activity_category : "",
                value: item?.id ? item?.id : "",
              };
            } catch (error) {
              console.log(error);
            }
            return _cat;
          }
        );
        setCategory(temp_category);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  const isCreateActivityFormValid = () => {
    let isValid = true;
    let error = initialCreateActivityData.error;
    if (initialCreateActivityData.priority.length === 0) {
      isValid = false;
      error.priority = "Priority is Required";
    }
    if (initialCreateActivityData.date.length === 0) {
      isValid = false;
      error.date = "Date is Required";
    }
    if (initialCreateActivityData.purpose.length === 0) {
      isValid = false;
      error.purpose = "Purpose is Required";
    }
    if (initialCreateActivityData.category.length === 0) {
      isValid = false;
      error.category = "Category is Required";
    }
    if (initialCreateActivityData.location.length === 0) {
      isValid = false;
      error.location = "Location is Required";
    }
    if (initialCreateActivityData.notes.length === 0) {
      isValid = false;
      error.notes = "Notes is Required";
    }
    setInitialCreateActivityData({ ...initialCreateActivityData, error });
    return isValid;
  };
  const createAcivity = () => {
    if (isCreateActivityFormValid()) {
      const variables = {
        query: mutationOne,
        variables: {
          insertPayload: [
            {
              lead_id: maindata?.lead_id,
              activity_category: initialCreateActivityData?.category?.value,
              activity_sub_category: initialCreateActivityData?.purpose?.value,
              created_at: new Date().toISOString(),
              created_by: localStorage.getItem("userProfileId"),
              date_time: initialCreateActivityData?.date,
              is_active: true,
              location: initialCreateActivityData?.location,
              notes: initialCreateActivityData?.notes,
              priority: initialCreateActivityData?.priority,
              responsible: localStorage.getItem("userProfileId"),
              status: "open",
              lead_activitiy_master: "60f6e6f0-1328-47b4-8dc6-34035ee49c6e",
              updated_by: localStorage.getItem("userProfileId"),
            },
          ],
        },
      };
      NetworkCall(
        `${config.graphql_url}`,
        NetWorkCallMethods.post,
        variables,
        null,
        true,
        false
      )
        .then((response) => {
          closeCreateActivity();
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: "Activity Successfully Created",
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const getuser = (quotation_id) => {
    const params = {
      query: USER,
      variables: {
        id: quotation_id,
      },
    };
    NetworkCall(
      `https://graphql-dev.propertyautomate.com/graphql`,
      NetWorkCallMethods.post,
      params,
      null,
      true,
      false
    )
      .then((response) => {})
      .catch((error) => {
        console.log(error);
      });
  };
  const getinvoice = (quotation_id) => {
    const params = {
      query: INVOICEQ,
      variables: {
        quotation_id: quotation_id,
      },
    };
    NetworkCall(
      `https://graphql-dev.propertyautomate.com/graphql`,
      NetWorkCallMethods.post,
      params,
      null,
      true,
      false
    )
      .then((response) => {
        setInvoice(response.data.data.invoice);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getallstatus = () => {
    const statusdata = {
      query: GETALLSTATUS,
    };
    NetworkCall(
      `https://graphql-dev.propertyautomate.com/graphql`,
      NetWorkCallMethods.post,
      statusdata,
      null,
      true,
      false
    )
      .then((response) => {
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const gethistory = () => {
    const data = {
      quotationNo: parseInt(id),
      tenantId: "8e9c54d2-45ef-4fac-bb37-6bc7d35bb199",
    };
    NetworkCall(
      `https://dev-api.propertyautomate.com/api/v1/quotation-version`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        setHistorydata(response.data.data.reverse());

        let filerdatavalue = response.data.data.filter((e) => {
          return e.quotationNo === parseInt(id);
        });
        setfilterChild(filerdatavalue);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  React.useEffect(() => {
    const data = {
      query: GETALL,
      variables: { quotation_no: id },
    };

    NetworkCall(
      `https://graphql-dev.propertyautomate.com/graphql`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        initialCreateActivityData.location =
          response.data.data.quotation[0].quotation_status[0].remarks;
        initialCreateActivityData.date =
          response.data.data.quotation[0].quotation_status[0].request_date_time;
        setInitialCreateActivityData({ ...initialCreateActivityData });
        setMaindata(response.data.data.quotation[0]);
        getinvoice(response.data.data.quotation[0].id);
        getuser(response.data.data.quotation[0].created_by);
        getallstatus();
        setPayment1(false);
        gethistory();
      })
      .catch((error) => {
        console.log(error);
      });
    // eslint-disable-next-line
  }, [id, payment]);

  //================================state of ui
  const [isQuotationHistoryOpen, setIsQuotationHistoryOpen] =
    React.useState(false);

  const [isPdfOpen, setIsPdfOpen] = React.useState(false);
  const [isCreateActivityOpen, setIsCreateActivityOpen] = React.useState(false);

  //================================function of ui

  const closeQuotationHistory = () => {
    setIsQuotationHistoryOpen(false);
    setPayment(false);
  };

  const openQuotationHistory = () => {
    setIsQuotationHistoryOpen(true);
  };

  const closePdf = () => {
    setIsPdfOpen(false);
  };
  const openPdf = () => {
    setIsPdfOpen(true);
  };
  const closeCreateActivity = () => {
    setIsCreateActivityOpen(false);
  };
  const openCreateActivity = () => {
    setIsCreateActivityOpen(true);
  };

  const datas = {
    id: 0,
    title: "Quotation",
    totalAmount: Math.round(maindata?.total_amount),
  };

  const downloadURI = (url, name) => {
    saveAs(url, `Quotation # ${maindata?.quotation_no}`);
  };
  return (
    <>
      <div className={classes.root}>
        {loading ? (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        ) : (
          <>
            {payment !== true && !payment1 ? (
              <Grid
                className={classes.screen}
                style={{ height: size?.height }}
                item
                xl={4}
                lg={5}
                md={8}
                sm={10}
                xs={12}
              >
                <Grid className={classes.topNavBarStyle}>
                  <TopNavBars note={false} home={false} />
                </Grid>
                <Grid
                  className={classes.screenPadding}
                  style={{
                    height:
                      maindata?.quotation_status?.length < 1 &&
                      invoice.length === 0
                        ? size?.height - 324
                        : "auto",
                  }}
                >
                  <Box height="16px" />

                  <QuotationCard
                    type="details"
                    onClick={openQuotationHistory}
                    quotationNo={maindata?.quotation_no}
                    generatedOn={maindata?.quote_start_date?.slice(0, 10)}
                    expiresOn={maindata?.lease_end_date?.slice(0, 10)}
                    leasePeriod={`${maindata?.lease_duration} ${maindata?.lease_period}`}
                  />
                  <Box height="8px" />
                  <Quotation onClickDownload={openPdf} data={datas} />
                  <Box height="16px" />
                  {maindata?.quotation_status?.length >= 1 && (
                    <Grid
                      container
                      direction={"column"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      xs={12}
                      style={{
                        padding: "16px",
                        background: "#FFFFFF",
                        border: "1px solid #E4E8EE",
                        borderRadius: "12px",
                      }}
                    >
                      <Grid container direction={"row"} alignItems={"center"}>
                        <Grid container xs={2} alignItems={"center"}>
                          <Avatar sx={{ backgroundColor: "#78B1FE" }}>
                            <img src="/images/clockWhite.svg" alt="" />
                          </Avatar>
                        </Grid>
                        <Grid container xs={10} alignItems={"center"}>
                          <Typography className={classes.statusdes}>
                            {maindata?.quotation_status?.[0]
                              ?.quotation_status_master?.quotation_status ===
                            "Accepted"
                              ? "Accepted on " +
                                moment(
                                  maindata?.quotation_status?.[0]?.created_at
                                )
                                  .tz(moment.tz.guess())
                                  .format("YYYY-MM-DD hh:mm A")
                              : maindata?.quotation_status?.[0]
                                  ?.quotation_status_master
                                  ?.quotation_status === "Requote"
                              ? "Request for reqoute on " +
                                moment(
                                  maindata?.quotation_status?.[0]?.created_at
                                )
                                  .tz(moment.tz.guess())
                                  .format("YYYY-MM-DD hh:mm A")
                              : maindata?.quotation_status?.[0]
                                  ?.quotation_status_master
                                  ?.quotation_status === "Not Interested"
                              ? "Not-interested on " +
                                moment(
                                  maindata?.quotation_status?.[0]?.created_at
                                )
                                  .tz(moment.tz.guess())
                                  .format("YYYY-MM-DD hh:mm A")
                              : maindata?.quotation_status?.[0]
                                  ?.quotation_status_master
                                  ?.quotation_status === "Request for visit"
                              ? "Visit requested on " +
                                moment(
                                  maindata?.quotation_status?.[0]?.created_at
                                )
                                  .tz(moment.tz.guess())
                                  .format("YYYY-MM-DD hh:mm A")
                              : ""}
                          </Typography>
                        </Grid>
                      </Grid>
                      {maindata?.quotation_status?.[0]?.quotation_status_master
                        ?.quotation_status === "Accepted" && (
                        <>
                          {" "}
                          <Box height={16} />
                          <Grid
                            container
                            direction={"row"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                          >
                            <Button
                              fullWidth
                              variant="contained"
                              className={classes.btn}
                            >
                              <Typography className={classes.buttonTextStyle}>
                                Request For Reserve
                              </Typography>
                            </Button>
                          </Grid>
                        </>
                      )}
                      {maindata?.quotation_status?.[0]?.quotation_status_master
                        ?.quotation_status === "Request for visit" && (
                        <>
                          {" "}
                          <Box height={16} />
                          <Grid
                            container
                            direction={"row"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                          >
                            <Button
                              onClick={openCreateActivity}
                              fullWidth
                              variant="contained"
                              className={classes.btn}
                            >
                              <Typography className={classes.buttonTextStyle}>
                                Create Visit Activity
                              </Typography>
                            </Button>
                          </Grid>
                        </>
                      )}
                      {maindata?.quotation_status?.[0]?.quotation_status_master
                        ?.quotation_status !== "Accepted" && (
                        <>
                          {" "}
                          <Box height={16} />
                          <Grid
                            container
                            direction={"row"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                          >
                            <Button
                              fullWidth
                              variant="contained"
                              className={classes.btn}
                            >
                              <Typography className={classes.buttonTextStyle}>
                                Re-Quote
                              </Typography>
                            </Button>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  )}
                </Grid>
                <Hidden smDown>
                  <Dialog
                    className={classes.dialog}
                    fullWidth={true}
                    maxWidth="sm"
                    open={isQuotationHistoryOpen}
                    onClose={closeQuotationHistory}
                  >
                    <Grid
                      container
                      direction="column"
                      className={classes.dialogStyle}
                    >
                      <Grid
                        container
                        direction="row"
                        className={classes.drawerHeaderStyle}
                      >
                        <Grid>
                          <Typography className={classes.drawerHeaderTextStyle}>
                            Quotation History
                          </Typography>
                        </Grid>
                        <Grid>
                          <IconButton
                            style={{
                              padding: "0px",
                            }}
                            onClick={closeQuotationHistory}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                      <Divider />
                      <Grid className={classes.drawerBodyStyle}>
                        {historydata.map((item, index, array) => {
                          return (
                            <Grid item>
                              <QuotationHistory
                                data={item}
                                index={index}
                                length={array.length}
                                id={id}
                                close={closeQuotationHistory}
                              />
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Grid>
                  </Dialog>
                </Hidden>
                <Hidden smUp>
                  <Drawer
                    className={classes.drawer}
                    anchor="bottom"
                    open={isQuotationHistoryOpen}
                    onClose={closeQuotationHistory}
                  >
                    <Grid
                      container
                      direction="column"
                      className={classes.bottomSheetStyle}
                    >
                      <Grid
                        container
                        direction="row"
                        className={classes.drawerHeaderStyle}
                      >
                        <Grid>
                          <Typography className={classes.drawerHeaderTextStyle}>
                            Quotation History
                          </Typography>
                        </Grid>
                        <Grid>
                          <IconButton
                            style={{
                              padding: "0px",
                            }}
                            onClick={closeQuotationHistory}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                      <Divider />
                      <Grid className={classes.drawerBodyStyle}>
                        {historydata.map((item, index, array) => {
                          return (
                            <Grid item>
                              <QuotationHistory
                                data={item}
                                index={index}
                                id={id}
                                length={array.length}
                                close={closeQuotationHistory}
                              />
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Grid>
                  </Drawer>
                </Hidden>
                <Hidden smDown>
                  <Dialog
                    className={classes.dialog}
                    maxWidth="sm"
                    fullWidth={true}
                    open={isPdfOpen}
                    onClose={closePdf}
                  >
                    <Grid
                      container
                      direction="column"
                      className={classes.dialogStyle}
                    >
                      <Grid
                        container
                        direction="row"
                        className={classes.drawerHeaderStyle}
                      >
                        <Grid>
                          <Typography className={classes.drawerHeaderTextStyle}>
                            {"Quotation #" + maindata?.quotation_no}
                          </Typography>

                          <Grid container alignItems="center">
                            <Typography
                              style={{
                                fontSize: "12px",
                                color: "#4E5A6B",
                                textAlign: "left",
                              }}
                            >
                              {maindata?.quote_date?.slice(0, 10)}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid>
                          <IconButton
                            style={{
                              padding: "0px",
                            }}
                            onClick={closePdf}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                      <Divider />
                      <Grid className={classes.pdfDrawerBodyStyle}>
                        {/* {maindata?.quotation_status?.[0]?.quotation_status_id */}
                        {filterChild[0]?.fileUrl !== null ? (
                          <DocumentViewer url={filterChild[0]?.fileUrl} />
                        ) : (
                          "No Document Found"
                        )}
                      </Grid>
                      <Grid className={classes.drawerFooterStyle}>
                        <Box height={"6px"} />
                        <Button
                          fullWidth
                          className={classes.drawerButtonStyle}
                          variant="contained"
                          onClick={() => {
                            downloadURI(filterChild[0]?.fileUrl);
                          }}
                        >
                          Download
                        </Button>
                      </Grid>
                    </Grid>
                  </Dialog>
                </Hidden>
                <Hidden smUp>
                  <Drawer
                    className={classes.drawer}
                    anchor="bottom"
                    open={isPdfOpen}
                    onClose={closePdf}
                  >
                    <Grid
                      container
                      direction="column"
                      className={classes.bottomSheetStyle}
                    >
                      <Grid
                        container
                        direction="row"
                        className={classes.drawerHeaderStyle}
                      >
                        <Grid>
                          <Typography className={classes.drawerHeaderTextStyle}>
                            {"Quotation #" + maindata?.quotation_no}
                          </Typography>

                          <Grid container alignItems="center">
                            <Typography
                              style={{
                                fontSize: "12px",
                                color: "#4E5A6B",
                                textAlign: "left",
                              }}
                            >
                              {maindata?.quote_date?.slice(0, 10)}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid>
                          <IconButton
                            style={{
                              padding: "0px",
                            }}
                            onClick={closePdf}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                      <Divider />
                      <Grid className={classes.pdfDrawerBodyStyle}>
                        {filterChild[0]?.fileUrl !== null ? (
                          <DocumentViewer url={filterChild[0]?.fileUrl} />
                        ) : (
                          "No Document Found"
                        )}
                      </Grid>
                      <Grid className={classes.drawerFooterStyle}>
                        <Box height={"6px"} />
                        <Button
                          fullWidth
                          className={classes.drawerButtonStyle}
                          variant="contained"
                          onClick={() => {
                            downloadURI(filterChild[0]?.fileUrl);
                          }}
                        >
                          Download
                        </Button>
                      </Grid>
                    </Grid>
                  </Drawer>
                </Hidden>
                <Hidden smDown>
                  <Dialog
                    className={classes.dialog}
                    maxWidth="sm"
                    fullWidth={true}
                    open={isCreateActivityOpen}
                    onClose={closeCreateActivity}
                  >
                    <Grid
                      container
                      direction="column"
                      className={classes.dialogStyle}
                    >
                      <Grid
                        container
                        direction="row"
                        className={classes.drawerHeaderStyle}
                      >
                        <Grid>
                          <Typography className={classes.drawerHeaderTextStyle}>
                            {"Create Activity"}
                          </Typography>
                        </Grid>
                        <Grid>
                          <IconButton
                            style={{
                              padding: "0px",
                            }}
                            onClick={closeCreateActivity}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                      <Divider />
                      <Grid
                        className={classes.pdfDrawerBodyStyle}
                        style={{ backgroundColor: "white" }}
                      >
                        <>
                          <Grid container spacing={1}>
                            <Grid item xs={12}>
                              <SelectBox
                                label="Category"
                                placeholder="Select Category"
                                options={category}
                                value={initialCreateActivityData.category}
                                onChange={(value) => handlePurpose(value)}
                                isError={
                                  initialCreateActivityData.error.category
                                    .length > 0
                                }
                                errorMessage={
                                  initialCreateActivityData.error.category
                                }
                              />
                              <SelectBox
                                label="Purpose"
                                placeholder="Select Purpose"
                                options={subCategory}
                                value={initialCreateActivityData.purpose}
                                onChange={(value) =>
                                  updateInitialCreateActivityData(
                                    "purpose",
                                    value
                                  )
                                }
                                isError={
                                  initialCreateActivityData.error.purpose
                                    .length > 0
                                }
                                errorMessage={
                                  initialCreateActivityData.error.purpose
                                }
                              />
                              <div className={classes.field}>
                                <DateTime
                                  title="Date"
                                  placeholder=""
                                  value={initialCreateActivityData.date}
                                  onChange={(value) =>
                                    updateInitialCreateActivityData(
                                      "date",
                                      value
                                    )
                                  }
                                  isError={
                                    initialCreateActivityData.error.date
                                      .length > 0
                                  }
                                  errorMessage={
                                    initialCreateActivityData.error.date
                                  }
                                />
                                <Typography
                                  className={classes.label}
                                  style={{ color: "red" }}
                                >
                                  {initialCreateActivityData.error.date}
                                </Typography>
                              </div>
                              <div className={classes.field}>
                                <TextBox
                                  label="Location"
                                  placeholder="Enter Location"
                                  value={initialCreateActivityData.location}
                                  isError={
                                    initialCreateActivityData.error.location
                                      .length > 0
                                  }
                                  errorMessage={
                                    initialCreateActivityData.error.location
                                  }
                                  onChange={(e) =>
                                    updateInitialCreateActivityData(
                                      "location",
                                      e.target.value
                                    )
                                  }
                                />
                              </div>
                              <div className={classes.field}>
                                <TextBox
                                  label="Notes"
                                  multiline
                                  placeholder="Enter Notes"
                                  onChange={(e) =>
                                    updateInitialCreateActivityData(
                                      "notes",
                                      e.target.value
                                    )
                                  }
                                  value={initialCreateActivityData.notes}
                                  isError={
                                    initialCreateActivityData.error.notes
                                      .length > 0
                                  }
                                  errorMessage={
                                    initialCreateActivityData.error.notes
                                  }
                                />
                              </div>
                            </Grid>
                            {/* priority */}
                            <Grid item xs={12}>
                              <Typography className={classes.label}>
                                Priority
                              </Typography>
                            </Grid>
                            <Grid item xs={3}>
                              <Typography
                                onClick={() => {
                                  setPriority("Urgent");
                                  updateInitialCreateActivityData(
                                    "priority",
                                    "Urgent"
                                  );
                                }}
                                className={
                                  priority === "Urgent" ||
                                  initialCreateActivityData.priority ===
                                    "Urgent"
                                    ? classes.Requirements
                                    : classes.Requirementsqfts
                                }
                              >
                                Urgent
                              </Typography>
                            </Grid>
                            <Grid item xs={3}>
                              <Typography
                                onClick={() => {
                                  setPriority("High");
                                  updateInitialCreateActivityData(
                                    "priority",
                                    "High"
                                  );
                                }}
                                className={
                                  priority === "High" ||
                                  initialCreateActivityData.priority === "High"
                                    ? classes.Requirements
                                    : classes.Requirementsqfts
                                }
                              >
                                High
                              </Typography>
                            </Grid>
                            <Grid item xs={3}>
                              <Typography
                                onClick={() => {
                                  setPriority("Normal");
                                  updateInitialCreateActivityData(
                                    "priority",
                                    "Normal"
                                  );
                                }}
                                className={
                                  priority === "Normal" ||
                                  initialCreateActivityData.priority ===
                                    "Normal"
                                    ? classes.Requirements
                                    : classes.Requirementsqfts
                                }
                              >
                                Normal
                              </Typography>
                            </Grid>{" "}
                            <Grid item xs={3}>
                              <Typography
                                onClick={() => {
                                  setPriority("Low");
                                  updateInitialCreateActivityData(
                                    "priority",
                                    "Low"
                                  );
                                }}
                                className={
                                  priority === "Low" ||
                                  initialCreateActivityData.priority === "Low"
                                    ? classes.Requirements
                                    : classes.Requirementsqfts
                                }
                              >
                                Low
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography
                                className={classes.label}
                                style={{ color: "red" }}
                              >
                                {initialCreateActivityData?.error?.priority}
                              </Typography>
                            </Grid>
                          </Grid>
                        </>
                      </Grid>
                      <Grid className={classes.drawerFooterStyle}>
                        <Box height={"6px"} />
                        <Button
                          fullWidth
                          className={classes.drawerButtonStyle}
                          variant="contained"
                          onClick={createAcivity}
                        >
                          Create
                        </Button>
                      </Grid>
                    </Grid>
                  </Dialog>
                </Hidden>
                <Hidden smUp>
                  <Drawer
                    className={classes.drawer}
                    anchor="bottom"
                    open={isCreateActivityOpen}
                    onClose={closeCreateActivity}
                  >
                    <Grid
                      container
                      direction="column"
                      className={classes.bottomSheetStyle}
                    >
                      <Grid
                        container
                        direction="row"
                        className={classes.drawerHeaderStyle}
                      >
                        <Grid>
                          <Typography className={classes.drawerHeaderTextStyle}>
                            {"Create Activity"}
                          </Typography>
                        </Grid>
                        <Grid>
                          <IconButton
                            style={{
                              padding: "0px",
                            }}
                            onClick={closeCreateActivity}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                      <Divider />
                      <Grid
                        className={classes.pdfDrawerBodyStyle}
                        style={{ backgroundColor: "white" }}
                      >
                        <>
                          <Grid container spacing={1}>
                            <Grid item xs={12}>
                              <SelectBox
                                label="Category"
                                placeholder="Select Category"
                                options={category}
                                value={initialCreateActivityData.category}
                                onChange={(value) => handlePurpose(value)}
                                isError={
                                  initialCreateActivityData.error.category
                                    .length > 0
                                }
                                errorMessage={
                                  initialCreateActivityData.error.category
                                }
                              />
                              <SelectBox
                                label="Purpose"
                                placeholder="Select Purpose"
                                options={subCategory}
                                value={initialCreateActivityData.purpose}
                                onChange={(value) =>
                                  updateInitialCreateActivityData(
                                    "purpose",
                                    value
                                  )
                                }
                                isError={
                                  initialCreateActivityData.error.purpose
                                    .length > 0
                                }
                                errorMessage={
                                  initialCreateActivityData.error.purpose
                                }
                              />
                              <div className={classes.field}>
                                <DateTime
                                  title="Date"
                                  placeholder=""
                                  value={initialCreateActivityData.date}
                                  onChange={(value) =>
                                    updateInitialCreateActivityData(
                                      "date",
                                      value
                                    )
                                  }
                                  isError={
                                    initialCreateActivityData.error.date
                                      .length > 0
                                  }
                                  errorMessage={
                                    initialCreateActivityData.error.date
                                  }
                                />
                                <Typography
                                  className={classes.label}
                                  style={{ color: "red" }}
                                >
                                  {initialCreateActivityData.error.date}
                                </Typography>
                              </div>
                              <div className={classes.field}>
                                <TextBox
                                  label="Location"
                                  placeholder="Enter Location"
                                  value={initialCreateActivityData.location}
                                  isError={
                                    initialCreateActivityData.error.location
                                      .length > 0
                                  }
                                  errorMessage={
                                    initialCreateActivityData.error.location
                                  }
                                  onChange={(e) =>
                                    updateInitialCreateActivityData(
                                      "location",
                                      e.target.value
                                    )
                                  }
                                />
                              </div>
                              <div className={classes.field}>
                                <TextBox
                                  label="Notes"
                                  multiline
                                  placeholder="Enter Notes"
                                  onChange={(e) =>
                                    updateInitialCreateActivityData(
                                      "notes",
                                      e.target.value
                                    )
                                  }
                                  value={initialCreateActivityData.notes}
                                  isError={
                                    initialCreateActivityData.error.notes
                                      .length > 0
                                  }
                                  errorMessage={
                                    initialCreateActivityData.error.notes
                                  }
                                />
                              </div>
                            </Grid>
                            {/* priority */}
                            <Grid item xs={12}>
                              <Typography className={classes.label}>
                                Priority
                              </Typography>
                            </Grid>
                            <Grid item xs={3}>
                              <Typography
                                onClick={() => {
                                  setPriority("Urgent");
                                  updateInitialCreateActivityData(
                                    "priority",
                                    "Urgent"
                                  );
                                }}
                                className={
                                  priority === "Urgent" ||
                                  initialCreateActivityData.priority ===
                                    "Urgent"
                                    ? classes.Requirements
                                    : classes.Requirementsqfts
                                }
                              >
                                Urgent
                              </Typography>
                            </Grid>
                            <Grid item xs={3}>
                              <Typography
                                onClick={() => {
                                  setPriority("High");
                                  updateInitialCreateActivityData(
                                    "priority",
                                    "High"
                                  );
                                }}
                                className={
                                  priority === "High" ||
                                  initialCreateActivityData.priority === "High"
                                    ? classes.Requirements
                                    : classes.Requirementsqfts
                                }
                              >
                                High
                              </Typography>
                            </Grid>
                            <Grid item xs={3}>
                              <Typography
                                onClick={() => {
                                  setPriority("Normal");
                                  updateInitialCreateActivityData(
                                    "priority",
                                    "Normal"
                                  );
                                }}
                                className={
                                  priority === "Normal" ||
                                  initialCreateActivityData.priority ===
                                    "Normal"
                                    ? classes.Requirements
                                    : classes.Requirementsqfts
                                }
                              >
                                Normal
                              </Typography>
                            </Grid>{" "}
                            <Grid item xs={3}>
                              <Typography
                                onClick={() => {
                                  setPriority("Low");
                                  updateInitialCreateActivityData(
                                    "priority",
                                    "Low"
                                  );
                                }}
                                className={
                                  priority === "Low" ||
                                  initialCreateActivityData.priority === "Low"
                                    ? classes.Requirements
                                    : classes.Requirementsqfts
                                }
                              >
                                Low
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography
                                className={classes.label}
                                style={{ color: "red" }}
                              >
                                {initialCreateActivityData?.error?.priority}
                              </Typography>
                            </Grid>
                          </Grid>
                        </>
                      </Grid>
                      <Grid className={classes.drawerFooterStyle}>
                        <Box height={"6px"} />
                        <Button
                          fullWidth
                          className={classes.drawerButtonStyle}
                          variant="contained"
                          onClick={createAcivity}
                        >
                          Create
                        </Button>
                      </Grid>
                    </Grid>
                  </Drawer>
                </Hidden>
              </Grid>
            ) : (
              ""
            )}
          </>
        )}
      </div>
    </>
  );
};
