import { Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Box } from "@mui/system";
import moment from "moment";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {},
  card: {
    cursor: "pointer",
    maxWidth: "300px",
    width: "-webkit-fill-available",
    borderRadius: "12px",
    backgroundColor: (props) =>
      props?.data?.lead_activitiy_master ===
      "67f34ccd-3f23-4f10-8334-80ed1d425781"
        ? "#EFFCFF"
        : props?.data?.lead_activitiy_master ===
          "fff87381-de52-455d-9f69-8400b9a2a0ff"
        ? "#EBFFF8"
        : props?.data?.lead_activitiy_master ===
          "cbc1480a-a7e5-4bab-9a07-9f08d949f179"
        ? "#F8F8FF"
        : props?.data?.lead_activitiy_master ===
          "50fae8f5-17d0-417a-ba01-568b35acfbc1"
        ? "#FFF3F7"
        : props?.data?.lead_activitiy_master ===
          "60f6e6f0-1328-47b4-8dc6-34035ee49c6e"
        ? "#FFF8EF"
        : props?.data?.lead_activitiy_master ===
          "ac53c561-9ab2-437b-b80c-e7a738bab8f2"
        ? "#EFFCFF"
        : props?.data?.lead_activitiy_master ===
          "1931346b-a32e-4834-b399-708f07448278"
        ? "#EFFCFF"
        : "#EFFCFF",

    alignItems: "center",
    justifyContent: "space-between",
    margin: "0px 8px 0px 0px",
  },
  iconContainer: {
    minHeight: "66px",
    borderRadius: "12px 0px 0px 12px",
    backgroundColor: (props) =>
      props?.data?.lead_activitiy_master ===
      "67f34ccd-3f23-4f10-8334-80ed1d425781"
        ? "#30AFCE"
        : props?.data?.lead_activitiy_master ===
          "fff87381-de52-455d-9f69-8400b9a2a0ff"
        ? "#35A27D"
        : props?.data?.lead_activitiy_master ===
          "cbc1480a-a7e5-4bab-9a07-9f08d949f179"
        ? "#8887D4"
        : props?.data?.lead_activitiy_master ===
          "50fae8f5-17d0-417a-ba01-568b35acfbc1"
        ? "#E53468"
        : props?.data?.lead_activitiy_master ===
          "60f6e6f0-1328-47b4-8dc6-34035ee49c6e"
        ? "#E29336"
        : props?.data?.lead_activitiy_master ===
          "ac53c561-9ab2-437b-b80c-e7a738bab8f2"
        ? "#78B1FE"
        : props?.data?.lead_activitiy_master ===
          "1931346b-a32e-4834-b399-708f07448278"
        ? "#5078E1"
        : "#30AFCE",

    backgroundImage: (props) =>
      props?.data?.lead_activitiy_master ===
      "67f34ccd-3f23-4f10-8334-80ed1d425781"
        ? "url('images/action_phonecall_icon_bg.svg')"
        : props?.data?.lead_activitiy_master ===
          "fff87381-de52-455d-9f69-8400b9a2a0ff"
        ? "url('images/action_followup_icon_bg.svg')"
        : props?.data?.lead_activitiy_master ===
          "cbc1480a-a7e5-4bab-9a07-9f08d949f179"
        ? "url('images/action_meeting_icon_bg.svg')"
        : props?.data?.lead_activitiy_master ===
          "50fae8f5-17d0-417a-ba01-568b35acfbc1"
        ? "url('images/action_appointment_icon_bg.svg')"
        : props?.data?.lead_activitiy_master ===
          "60f6e6f0-1328-47b4-8dc6-34035ee49c6e"
        ? "url('images/action_sitevisit_icon_bg.svg')"
        : props?.data?.lead_activitiy_master ===
          "ac53c561-9ab2-437b-b80c-e7a738bab8f2"
        ? "url('images/action_email_icon_bg.svg')"
        : props?.data?.lead_activitiy_master ===
          "1931346b-a32e-4834-b399-708f07448278"
        ? "url('images/action_sendquotation_icon_bg.svg')"
        : "url('images/action_phonecall_icon_bg.svg')",

    alignItems: "center",
    justifyContent: "center",
  },
  infoContainer: {
    minHeight: "66px",
    padding: "8px",
  },
  infoLineStyle: {
    alignItems: "center",
    justifyContent: "start",
  },
  rightChevronIconContainer: {
    minHeight: "66px",
    alignItems: "center",
    justifyContent: "center",
  },
  titleStyle: {
    fontSize: "16px",
    fontFamily: "tenant_bold",
    color: theme.typography.color.secondary,
    paddingRight: "6px",
  },
  priorityStyle: {
    fontSize: "10px",
    fontFamily: "tenant_bold",
    color: theme.typography.color.secondary,
    backgroundColor: "white",
    borderRadius: "4px",
    padding: "2px 8px 2px 8px",
    alignSelf: "center",
  },
  subTitleStyle: {
    fontSize: "12px",
    fontFamily: "tenant_regular",
    color: theme.typography.color.secondary,
  },
  periodStyle: {
    height: "6px",
    width: "6px",
    borderRadius: "50%",
    backgroundColor: "#CED3DD",
    margin: "6px",
  },
}));

export const TodayActionCard = (props) => {
  const classes = useStyles(props);
  return (
    <Grid
      onClick={() => props.onClick(props.data)}
      className={classes.card}
      container
      direction={"row"}
    >
      <Grid container xs={2} className={classes.iconContainer}>
        <img
          src={
            props?.data?.lead_activitiy_master ===
            "67f34ccd-3f23-4f10-8334-80ed1d425781"
              ? "images/activity_phonecall_icon_open.svg"
              : props?.data?.lead_activitiy_master ===
                "fff87381-de52-455d-9f69-8400b9a2a0ff"
              ? "images/activity_followup_icon_open.svg"
              : props?.data?.lead_activitiy_master ===
                "cbc1480a-a7e5-4bab-9a07-9f08d949f179"
              ? "images/activity_meeting_icon_open.svg"
              : props?.data?.lead_activitiy_master ===
                "50fae8f5-17d0-417a-ba01-568b35acfbc1"
              ? "images/activity_appointment_icon_open.svg"
              : props?.data?.lead_activitiy_master ===
                "60f6e6f0-1328-47b4-8dc6-34035ee49c6e"
              ? "images/activity_sitevisit_icon_open.svg"
              : props?.data?.lead_activitiy_master ===
                "ac53c561-9ab2-437b-b80c-e7a738bab8f2"
              ? "images/activity_email_icon_open.svg"
              : props?.data?.lead_activitiy_master ===
                "1931346b-a32e-4834-b399-708f07448278"
              ? "images/activity_sendquotation_icon_open.svg"
              : "images/activity_phonecall_icon_open.svg"
          }
          alt="card_icon"
        />
      </Grid>
      <Grid
        className={classes.infoContainer}
        container
        xs={8}
        direction={"column"}
      >
        <Grid className={classes.infoLineStyle} container direction={"row"}>
          <Typography className={classes.titleStyle}>
            {props?.data?.lead_activitiy_master ===
              "67f34ccd-3f23-4f10-8334-80ed1d425781" && "Phone Call"}
            {props?.data?.lead_activitiy_master ===
              "fff87381-de52-455d-9f69-8400b9a2a0ff" && "Follow up"}
            {props?.data?.lead_activitiy_master ===
              "cbc1480a-a7e5-4bab-9a07-9f08d949f179" && "Meeting"}
            {props?.data?.lead_activitiy_master ===
              "50fae8f5-17d0-417a-ba01-568b35acfbc1" && "Appointment"}
            {props?.data?.lead_activitiy_master ===
              "60f6e6f0-1328-47b4-8dc6-34035ee49c6e" && "Arrange site visit"}
            {props?.data?.lead_activitiy_master ===
              "1931346b-a32e-4834-b399-708f07448278" && "Send quotation"}
            {props?.data?.lead_activitiy_master ===
              "ac53c561-9ab2-437b-b80c-e7a738bab8f2" && "Send email"}
          </Typography>
          <Typography className={classes.priorityStyle}>
            {props?.data?.priority}
          </Typography>
        </Grid>
        <Grid className={classes.infoLineStyle} container direction={"row"}>
          <Typography className={classes.subTitleStyle}>
            {moment(props?.data?.date_time).format("DD MMM")}
          </Typography>
          <Box className={classes.periodStyle} />
          <Typography className={classes.subTitleStyle}>
            {props?.data?.phone_number && props?.data?.phone_number}
            {props?.data?.location && props?.data?.location}
            {props?.data?.email_id && props?.data?.email_id}
          </Typography>
        </Grid>
      </Grid>
      <Grid container xs={2} className={classes.rightChevronIconContainer}>
        <img
          src={
            props?.data?.lead_activitiy_master ===
            "67f34ccd-3f23-4f10-8334-80ed1d425781"
              ? "images/action_phonecall_right_chevron_icon.svg"
              : props?.data?.lead_activitiy_master ===
                "fff87381-de52-455d-9f69-8400b9a2a0ff"
              ? "images/action_followup_right_chevron_icon.svg"
              : props?.data?.lead_activitiy_master ===
                "cbc1480a-a7e5-4bab-9a07-9f08d949f179"
              ? "images/action_meeting_right_chevron_icon.svg"
              : props?.data?.lead_activitiy_master ===
                "50fae8f5-17d0-417a-ba01-568b35acfbc1"
              ? "images/action_appointment_right_chevron_icon.svg"
              : props?.data?.lead_activitiy_master ===
                "60f6e6f0-1328-47b4-8dc6-34035ee49c6e"
              ? "images/action_sitevisit_right_chevron_icon.svg"
              : props?.data?.lead_activitiy_master ===
                "ac53c561-9ab2-437b-b80c-e7a738bab8f2"
              ? "images/action_email_right_chevron_icon.svg"
              : props?.data?.lead_activitiy_master ===
                "1931346b-a32e-4834-b399-708f07448278"
              ? "images/action_sendquotation_right_chevron_icon.svg"
              : "images/action_phonecall_right_chevron_icon.svg"
          }
          alt="navigation_icon"
        />
      </Grid>
    </Grid>
  );
};
