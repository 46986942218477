import Grid from "@mui/material/Grid";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import makeStyles from "@mui/styles/makeStyles";
import * as React from "react";
import { Box } from "@mui/system";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.secondary,
    overflow: "hidden",
    padding:"0px"
  },
  tabItem: {
    textTransform: "capitalize",
    fontWeight: "bold",
    color: "#071741",
    padding: "12px 4px",
  },
  tabItemSelect: {
    textTransform: "capitalize",
    fontWeight: "bold",
    color: theme.typography.color.primary,
    padding: "12px 4px",
  },
  title: {
    border: "1px solid #E4E8EE",
    padding: "8px 14px",
    borderRadius: "20px",
    background: theme.palette.background.paper,
  },
  titleselect: {
    padding: "8px 14px",
    borderRadius: "20px",
    backgroundColor: theme.palette.primary.main,
    color: "white",
  },
  // more: {
  //   border: "1px solid #E4E8EE",
  //   borderRadius: "20px",
  //   background: theme.palette.background.paper,
  // },
}));
export const TabsComponent = ({
  selectedTab = 0,

  onTab1Clicked = () => false,
  onTab2Clicked = () => false,
  onTab3Clicked = () => false,
  onTab4Clicked = () => false,
  onTab5Clicked = () => false,
}) => {
  const classes = useStyles();
  return (
    <Grid
      container
      direction="row"
      className={classes.root}
      justifyContent="space-between"
      alignItems="center">
      {/* Tab Section */}
      <Grid item xs={12}>
        <Tabs
          value={selectedTab}
          indicatorColor="none"
          textColor="secondary"
          variant="scrollable"
          visibleScrollbar={false}
          scrollButtons={false}
        >
          <Box width={12} />
          <Tab
            className={
              selectedTab === 0 ? classes.tabItemSelect : classes.tabItem
            }
            style={{
              padding: "12px 4px 12px 16px",
            }}
            label={
              <span
                className={
                  selectedTab === 0 ? classes.titleselect : classes.title
                }
              >
                {selectedTab === 0 && (
                  <img src="/images/lead.svg" alt="img1" />
                )}
                &nbsp;&nbsp;Lead Info
              </span>
            }
            onClick={onTab1Clicked}
          />
          <Tab
            className={
              selectedTab === 1 ? classes.tabItemSelect : classes.tabItem
            }
            label={
              <span
                className={
                  selectedTab === 1 ? classes.titleselect : classes.title
                }
              >
                {selectedTab === 1 && (
                  <img src="/images/qutation.svg" alt="img2" />
                )}
                &nbsp;&nbsp;Quotation
              </span>
            }
            onClick={onTab2Clicked}
          />
          <Tab
            className={
              selectedTab === 2 ? classes.tabItemSelect : classes.tabItem
            }
            label={
              <span
                className={
                  selectedTab === 2 ? classes.titleselect : classes.title
                }
              >
                {selectedTab === 2 && (
                  <img src="/images/activity.svg" alt="img3" />
                )}
                &nbsp;&nbsp;Activity
              </span>
            }
            onClick={onTab3Clicked}
          />
          <Tab
            className={
              selectedTab === 3 ? classes.tabItemSelect : classes.tabItem
            }
            label={
              <span
                className={
                  selectedTab === 3 ? classes.titleselect : classes.title
                }
              >
                {selectedTab === 3 && (
                  <img src="/images/todo.svg" alt="img3" />
                )}
                &nbsp;&nbsp;Todo List
              </span>
            }
            onClick={onTab4Clicked}
          />
          <Tab
            className={
              selectedTab === 4 ? classes.tabItemSelect : classes.tabItem
            }
            label={
              <span
                className={
                  selectedTab === 4 ? classes.titleselect : classes.title
                }
              >
                {selectedTab === 4 && (
                  <img src="/images/track.svg" alt="img3" />
                )}
                &nbsp;&nbsp;Track
              </span>
            }
            onClick={onTab5Clicked}
          />
        </Tabs>
      </Grid>
    </Grid>
  );
};
