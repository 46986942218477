import React from "react";
import { withBottombar } from "../../HOCs";
import { CreateQuotation } from "./createquotation";
import ContextProvider from "./createquotationcontaxt";
const CreateQuotationParent = () => {
  return (
    <ContextProvider>
      <CreateQuotation />
    </ContextProvider>
  );
};

export default withBottombar(CreateQuotationParent);
