import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Dialog,
  Divider,
  Drawer,
  Grid,
  Hidden,
  IconButton,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Box } from "@mui/system";
import React from "react";
import { TextBox, ToDoListCard } from "../../../components";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: "16px",
    backgroundColor: "white",
  },
  titleTextStyle: {
    color: theme.typography.color.primary,
    fontFamily: "tenant_bold",
    fontSize: "14px",
  },
  createNewTextStyle: {
    cursor: "pointer",
    color: theme.palette.primary.main,
    fontFamily: "tenant_semiBold",
    fontSize: "14px",
  },
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopRightRadius: "16px",
      borderTopLeftRadius: "16px",
    },
    "& .MuiContainer-root": {
      padding: "0px 8px",
    },
  },
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: "16px",
      padding: "0px",
    },
  },
  bottomSheetStyle: {
    background: "white",
    borderRadius: "16px 16px 0px 0px",
    border: "1px solid #E4E8EE",
    justifyContent: "center",
  },
  dialogStyle: {
    background: "white",
    borderRadius: "16px",
    border: "1px solid #E4E8EE",
    justifyContent: "center",
  },
  drawerHeaderStyle: {
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px",
  },
  drawerHeaderTextStyle: {
    fontSize: "16px",
    fontWeight: "bolder",
    color: theme.typography.color.primary,
  },
  drawerBodyStyle: {
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px",
  },
  drawerFooterStyle: {
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px",
  },
  drawerButtonStyle: {
    "&:hover": "none",
    borderRadius: "8px",
    padding: "14px",
    background: theme.palette.primary.main,
    fontSize: "14px",
    fontWeight: "normal",
    color: "#FFF",
  },
}));
export const ToDoListSection = (props) => {
  const classes = useStyles();

  return (
    <Grid className={classes.root} container direction={"column"}>
      <Grid container direction={"row"} justifyContent={"space-between"}>
        <Typography className={classes.titleTextStyle}>
          {"To Do List"}
        </Typography>
        {props?.active === "true" && (
          <Typography
            onClick={props.openCreateToDo}
            className={classes.createNewTextStyle}
          >
            {"Create New"}
          </Typography>
        )}
      </Grid>
      <Box height={16} />
      {props?.todoListdata.map((val, index) => {
        return (
          <Grid container key={index}>
            <Grid item xs={12}>
              {!val.is_done && val.is_done !== null ? (
                <div>
                  <ToDoListCard
                    data={val}
                    checked={props?.checked}
                    onClick={props.onClick}
                  />
                  <Box height={"8px"} />
                </div>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
        );
      })}
      <Hidden smDown>
        <Dialog
          className={classes.dialog}
          open={props.isCreateToDo}
          fullWidth={true}
          maxWidth="sm"
          onClose={props.closeCreateToDo}
        >
          <Grid container direction="column">
            <Grid
              container
              direction="row"
              className={classes.drawerHeaderStyle}
            >
              <Grid>
                <Typography className={classes.drawerHeaderTextStyle}>
                  Create To Do
                </Typography>
              </Grid>
              <Grid>
                <IconButton
                  style={{
                    padding: "0px",
                  }}
                  onClick={props?.closeCreateToDo}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Divider />
            <Grid className={classes.drawerBodyStyle}>
              <TextBox
                label="Title"
                placeholder="Enter Todo Name"
                isError={props?.addto?.error?.name?.length > 0}
                value={props?.addto?.name}
                errorMessage={props?.addto?.error?.name}
                onChange={(e) => props?.updateStateadd("name", e.target.value)}
              />
              <Box height={"16px"} />
              <TextBox
                label="Description"
                placeholder="Enter Description"
                multiline
                value={props?.addto?.description}
                isError={props?.addto?.error?.description?.length > 0}
                errorMessage={props?.addto?.error?.description}
                onChange={(e) =>
                  props?.updateStateadd("description", e.target.value)
                }
              />
            </Grid>
            <Divider />
            <Grid className={classes.drawerFooterStyle}>
              <Button
                fullWidth
                className={classes.drawerButtonStyle}
                variant="contained"
                onClick={props?.submit}
              >
                Create
              </Button>
            </Grid>
          </Grid>
        </Dialog>
      </Hidden>
      <Hidden smUp>
        <Drawer
          className={classes.drawer}
          anchor="bottom"
          open={props.isCreateToDo}
          onClose={props.closeCreateToDo}
        >
          <Grid container direction="column">
            <Grid
              container
              direction="row"
              className={classes.drawerHeaderStyle}
            >
              <Grid>
                <Typography className={classes.drawerHeaderTextStyle}>
                  Create To Do
                </Typography>
              </Grid>
              <Grid>
                <IconButton
                  style={{
                    padding: "0px",
                  }}
                  onClick={props?.closeCreateToDo}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Divider />
            <Grid className={classes.drawerBodyStyle}>
              <TextBox
                label="Title"
                placeholder="Enter Todo Name"
                isError={props?.addto?.error?.name?.length > 0}
                value={props?.addto?.name}
                errorMessage={props?.addto?.error?.name}
                onChange={(e) => props?.updateStateadd("name", e.target.value)}
              />
              <Box height={"16px"} />
              <TextBox
                label="Description"
                placeholder="Enter Description"
                multiline
                value={props?.addto?.description}
                isError={props?.addto?.error?.description?.length > 0}
                errorMessage={props?.addto?.error?.description}
                onChange={(e) =>
                  props?.updateStateadd("description", e.target.value)
                }
              />
            </Grid>
            <Divider />
            <Grid className={classes.drawerFooterStyle}>
              <Button
                fullWidth
                className={classes.drawerButtonStyle}
                variant="contained"
                onClick={props?.submit}
              >
                Create
              </Button>
            </Grid>
          </Grid>
        </Drawer>
      </Hidden>
    </Grid>
  );
};
