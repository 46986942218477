import { Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Box } from "@mui/system";
import React from "react";
import Slider from "react-slick";
import {
  ActivityCard,
  ActivityListCard,
  ContactList,
  DashboardCard,
  LeadsCard,
  LeadsList,
  PropertyList,
  Quotation,
  QuotationHistory,
  QuotationList,
  SelectBox,
  SurveyCard,
  TabsComponent,
  TextBox,
  TodayActionCard,
  ToDoListCard,
  UnitList,
} from "../../components";
import MyGoogleMap from "../../components/interactiveMap/myGoogleMaps";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "10px",
  },
  actionCardContainer: {
    overflow: "overlay",
    display: "-webkit-box",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  dividerStyle: {
    backgroundColor: theme.palette.border.secondary,
    height: "1px",
    width: "100%",
    margin: "14px 0px 14px 0px",
  },
}));

export const ComponentScreen = (props) => {
  const classes = useStyles();
  const LeadsCardData = [
    {
      id: 0,
      count: 22,
      title: "leads",
      backgroundColor: "#DFF8FF",
      imgColor: "#46B9DA",
      src: "images/Leads.svg",
    },
    {
      id: 1,
      count: 22,
      title: "Leads to Quotations",
      backgroundColor: "#FFF1E0",
      imgColor: "#E29336",
      src: "images/Leads.svg",
    },
    {
      id: 2,
      count: 22,
      title: "Leads to Tenant",
      backgroundColor: "#EAECFB",
      imgColor: "#6248E2",
      src: "images/contactus.svg",
    },
  ];
  const survaydata = [
    {
      id: 1,
      type: "feedback",
      msg: "Swimming pool hygiene feedback",
    },
    {
      id: 2,
      type: "survey",
      msg: "Survey",
    },
  ];
  var settings = {
    dots: true,
    infinite: true,
    // slidesToShow: 3,
    // slidesToScroll: 1,
    //autoplay: true,
    speed: 0,
    // autoplaySpeed: 2000,
    cssEase: "linear",
    arrows: false,
  };
  const contactlist = [
    {
      id: 1,
      name: "Tom Cruise",
      place: "bangaldesh",
      level: "medium",
      img: "/images/pic1.png",
      status: "active",
      date: "opened on 22 nov",
    },
    {
      id: 2,
      name: "Tommy",
      place: "bangaldesh",
      level: "high",
      img: "/images/pic2.png",
      status: "active",
      date: "opened on 22 nov",
    },
    {
      id: 3,
      name: "Tom Peter",
      place: "bangaldesh",
      level: "low",
      img: "/images/pic3.png",
      status: "closed",
      date: "opened on 22 nov",
    },
  ];
  const [selected, setSelected] = React.useState(0);
  const onTab1Clicked = () => {
    setSelected(0);
  };
  const onTab2Clicked = () => {
    setSelected(1);
  };
  const onTab3Clicked = () => {
    setSelected(2);
  };
  const onTab4Clicked = () => {
    setSelected(3);
  };
  const onTab5Clicked = () => {
    setSelected(4);
  };
  const [change, onChange] = React.useState("");
  const onSChange = (value) => {
    onChange(value);
  };
  const tempOptions = [
    { value: "Reason_1", label: "Reason_1" },
    { value: "Reason_2", label: "Reason_2" },
    { value: "Reason_3", label: "Reason_3" },
    { value: "Reason_4", label: "Reason_4" },
  ];

  const InvoiceData = [
    {
      id: 0,
      type: "invoice",
      title: "2344",
      requestId: "K-F01-U25",
      dueDate: "Due 22 Nov",
      amount: "32.00",
      status: "Requested for visit",
    },
    {
      id: 1,
      type: "invoice",
      title: "2344",
      requestId: "K-F01-U25",
      dueDate: "Due 22 Nov",
      amount: "32.0000",
      status: "Requested for re-quote",
    },
    {
      id: 2,
      type: "invoice",
      title: "2344",
      requestId: "K-F01-U25",
      dueDate: "Due 22 Nov",
      amount: "32.00",
      status: "Quote Accepted",
    },
  ];
  const unitlist = [
    {
      id: 1,
      image: "/images/image1.png",
      title: "K-F01-394",
      address: "K Tower, 1 Floor",
      sqft: 1250,
      bed: 3,
      amount: "1,200.00",
    },
  ];

  const unitlisted = [
    {
      id: 1,
      image: "/images/image1.png",
      title: "K-F01-394",
      address: "K Tower, 1 Floor",
      sqft: 1250,
      bed: 3,
      amount: "1,200.00",
      shortlist: 12,
    },
  ];
  const dashboardData = [
    {
      id: 1,
      title: "14",
      subtitle: "Active Leads",
      img: "/images/Leads2.svg",
    },
    {
      id: 2,
      title: "10",
      subtitle: "Active Quotations",
      img: "/images/icons8-ticket (1).svg",
    },
    {
      id: 3,
      title: "04",
      subtitle: "Active Customers",
      img: "/images/Icon.svg",
    },
    {
      id: 4,
      title: "07",
      subtitle: "Requested Visits",
      img: "/images/icons8-leave-house (1).svg",
    },
    {
      id: 5,
      title: "16",
      subtitle: "Requested re-quotes",
      img: "/images/icons8-ticket.svg",
    },
    {
      id: 6,
      title: "17",
      subtitle: "Available units",
      img: "/images/My Units.svg",
    },
  ];
  const unitlisted1 = [
    {
      id: 0,
      image: "/images/image1.png",
      title: "K-F01-394",
      Reqid: "PID74432",
      loc: "t-nagar",
      units: 3,
      amount: "1,200.00",
      shortlist: 12,
    },
    {
      id: 1,
      image: "/images/image1.png",
      title: "K-F01-394",
      Reqid: "PID74432",
      loc: "t-nagar",
      units: 3,
      amount: "1,200.00",
      shortlist: 12,
    },
    {
      id: 2,
      image: "/images/image1.png",
      title: "K-F01-394",
      Reqid: "PID74432",
      loc: "t-nagar",
      units: 3,
      amount: "1,200.00",
      shortlist: 12,
    },
  ];
  const Activitycarddata = [
    {
      id: 1,
      image: "/images/phonecall.svg",
      title: "Phone call",
    },
    {
      id: 2,
      image: "/images/followup.svg",
      title: "Follow up",
    },
    {
      id: 3,
      image: "/images/meet.svg",
      title: "Meeting",
    },
    {
      id: 4,
      image: "/images/appoinment.svg",
      title: "Appointment",
    },
    {
      id: 5,
      image: "/images/sitevisit.svg",
      title: "Arrange Site Visit",
    },
    {
      id: 6,
      image: "/images/quotation.svg",
      title: "Send Quotation",
    },
    {
      id: 7,
      image: "/images/email.svg",
      title: "Send Email",
    },
  ];
  const contactlist1 = [
    {
      id: 1,
      name: "Tom Cruise",
      mail: "tomcruiser@mail.com",
      phone: "+91 9090808012",
      img: "/images/contact1.png",
    },
    {
      id: 2,
      name: "Tommy",
      mail: "Jamtm@mail.com",
      phone: "+91 9090808012",
      img: "/images/contact2.png",
    },
    {
      id: 3,
      name: "Tom Peter",
      mail: "Tommy@mail.com",
      phone: "+91 9090808012",
      img: "/images/contact3.png",
    },
  ];
  const quotationHistory = [
    {
      id: 0,
      quotationId: "Quotation #2345",
      time: "Received on 30-01-2021 9:00 AM",
      status: true,
    },
    {
      id: 1,
      quotationId: "Quotation #2345",
      time: "Received on 30-01-2021 9:00 AM",
    },
    {
      id: 2,
      quotationId: "Quotation #2345",
      time: "Received on 30-01-2021 9:00 AM",
    },
    {
      id: 3,
      quotationId: "Quotation #2345",
      time: "Received on 30-01-2021 9:00 AM",
    },
    {
      id: 4,
      quotationId: "Quotation #2345",
      time: "Received on 30-01-2021 9:00 AM",
    },
  ];
  const data = {
    id: 0,
    title: "Quotation",
    body: "Quotation by",
    body1: "Dennis Evans D (AG-001)",
    subBody: "Total amount :",
    subBody2: " $ 340,00,000",
  };
  const mapResult = (data) => {};
  const actionCardData = [
    {
      type: "Phone Call",
      priority: "Medium",
      date: "2021-12-09T00:00:00.000Z",
      mode: "+91 9988445544",
      onClick: () => {
        alert("Phone Call");
      },
    },
    {
      type: "Follow Up",
      priority: "Urgent",
      date: "2021-12-08T00:00:00.000Z",
      mode: "Inperson",
      onClick: () => {
        alert("Follow Up");
      },
    },
    {
      type: "Meeting",
      priority: "Urgent",
      date: "2021-12-10T00:00:00.000Z",
      mode: "Online",
      onClick: () => {
        alert("Meeting");
      },
    },
    {
      type: "Appointment",
      priority: "Urgent",
      date: "2021-12-03T00:00:00.000Z",
      mode: "Online",
      onClick: () => {
        alert("Appointment");
      },
    },
    {
      type: "Site Visit",
      priority: "Low",
      date: "2021-12-12T00:00:00.000Z",
      mode: "T,Nagar, Chennai",
      onClick: () => {
        alert("Site Visit");
      },
    },
    {
      type: "Email",
      priority: "Low",
      date: "2021-12-12T00:00:00.000Z",
      mode: "Tomcruz@gmail.com",
      onClick: () => {
        alert("Email");
      },
    },
    {
      type: "Send Quotation",
      priority: "Low",
      date: "2021-12-24T00:00:00.000Z",
      mode: "Tomcruz@gmail.com",
      onClick: () => {
        alert("Send Quotation");
      },
    },
  ];

  const actionCardData1 = [
    {
      type: "Phone Call",
      priority: "Medium",
      date: "2021-12-09T00:00:00.000Z",
      mode: "+91 9988445544",
      status: "open",
      onClick: () => {
        alert("Phone Call");
      },
    },
    {
      type: "Follow Up",
      priority: "Urgent",
      date: "2021-12-08T00:00:00.000Z",
      mode: "Inperson",
      status: "open",
      onClick: () => {
        alert("Follow Up");
      },
    },
    {
      type: "Meeting",
      priority: "Urgent",
      date: "2021-12-10T00:00:00.000Z",
      mode: "Online",
      status: "open",
      onClick: () => {
        alert("Meeting");
      },
    },
    {
      type: "Appointment",
      priority: "Urgent",
      date: "2021-12-03T00:00:00.000Z",
      mode: "Online",
      status: "open",
      onClick: () => {
        alert("Appointment");
      },
    },
    {
      type: "Site Visit",
      priority: "Low",
      date: "2021-12-12T00:00:00.000Z",
      mode: "T,Nagar, Chennai",
      status: "open",
      onClick: () => {
        alert("Site Visit");
      },
    },
    {
      type: "Email",
      priority: "Low",
      date: "2021-12-12T00:00:00.000Z",
      mode: "Tomcruz@gmail.com",
      status: "open",
      onClick: () => {
        alert("Email");
      },
    },
    {
      type: "Send Quotation",
      priority: "Low",
      date: "2021-12-24T00:00:00.000Z",
      mode: "Tomcruz@gmail.com",
      status: "open",
      onClick: () => {
        alert("Send Quotation");
      },
    },
    {
      type: "Phone Call",
      priority: "Medium",
      date: "2021-12-09T00:00:00.000Z",
      mode: "+91 9988445544",
      status: "closed",
      onClick: () => {
        alert("Phone Call");
      },
    },
    {
      type: "Follow Up",
      priority: "Urgent",
      date: "2021-12-08T00:00:00.000Z",
      mode: "Inperson",
      status: "closed",
      onClick: () => {
        alert("Follow Up");
      },
    },
    {
      type: "Meeting",
      priority: "Urgent",
      date: "2021-12-10T00:00:00.000Z",
      mode: "Online",
      status: "closed",
      onClick: () => {
        alert("Meeting");
      },
    },
    {
      type: "Appointment",
      priority: "Urgent",
      date: "2021-12-03T00:00:00.000Z",
      mode: "Online",
      status: "closed",
      onClick: () => {
        alert("Appointment");
      },
    },
    {
      type: "Site Visit",
      priority: "Low",
      date: "2021-12-12T00:00:00.000Z",
      mode: "T,Nagar, Chennai",
      status: "closed",
      onClick: () => {
        alert("Site Visit");
      },
    },
    {
      type: "Email",
      priority: "Low",
      date: "2021-12-12T00:00:00.000Z",
      mode: "Tomcruz@gmail.com",
      status: "closed",
      onClick: () => {
        alert("Email");
      },
    },
    {
      type: "Send Quotation",
      priority: "Low",
      date: "2021-12-24T00:00:00.000Z",
      mode: "Tomcruz@gmail.com",
      status: "closed",
      onClick: () => {
        alert("Send Quotation");
      },
    },
  ];

  const actionCardData2 = [
    {
      type: "Phone Call",
      contactName: "Tom Cruise",
      priority: "Medium",
      date: "2021-12-09T00:00:00.000Z",
      mode: "+91 9988445544",
      status: "open",
      selected: false,
      onClick: () => {
        alert("Phone Call");
      },
    },
    {
      type: "Follow Up",
      contactName: "Tom Cruise",
      priority: "Urgent",
      date: "2021-12-08T00:00:00.000Z",
      mode: "Inperson",
      status: "open",
      selected: false,
      onClick: () => {
        alert("Follow Up");
      },
    },
    {
      type: "Meeting",
      contactName: "Kyle Porter",
      priority: "Urgent",
      date: "2021-12-10T00:00:00.000Z",
      mode: "Online",
      status: "open",
      selected: false,
      onClick: () => {
        alert("Meeting");
      },
    },
    {
      type: "Appointment",
      contactName: "Mike Evans",
      priority: "Urgent",
      date: "2021-12-03T00:00:00.000Z",
      mode: "Online",
      status: "open",
      selected: false,
      onClick: () => {
        alert("Appointment");
      },
    },
    {
      type: "Site Visit",
      contactName: "Alex Grand",
      priority: "Low",
      date: "2021-12-12T00:00:00.000Z",
      mode: "T,Nagar, Chennai",
      status: "open",
      selected: false,
      onClick: () => {
        alert("Site Visit");
      },
    },
    {
      type: "Email",
      contactName: "Tyler Williamson",
      priority: "Low",
      date: "2021-12-12T00:00:00.000Z",
      mode: "Tomcruz@gmail.com",
      status: "open",
      selected: false,
      onClick: () => {
        alert("Email");
      },
    },
    {
      type: "Send Quotation",
      contactName: "Vincent Reid",
      priority: "Low",
      date: "2021-12-24T00:00:00.000Z",
      mode: "Tomcruz@gmail.com",
      status: "open",
      selected: false,
      onClick: () => {
        alert("Send Quotation");
      },
    },
  ];

  const toDoListData = [
    {
      completed: false,
      description: "Client Works",
    },
    {
      completed: false,
      description: "Client Works",
    },
    {
      completed: false,
      description: "Client Works",
    },
    {
      completed: false,
      description: "Client Works",
    },
    {
      completed: true,
      description: "Client Works",
    },
    {
      completed: true,
      description: "Client Works",
    },
  ];

  return (
    <div className={classes.root}>
      <Grid container justifyContent={"center"}>
        {LeadsCardData.map((item) => {
          return (
            <Grid item xs={4}>
              <LeadsCard data={item} />
            </Grid>
          );
        })}
      </Grid>
      <Box height="40px" />
      <br />
      {
        <Slider {...settings}>
          {survaydata.map((val) => {
            return <SurveyCard data={val} />;
          })}
        </Slider>
      }
      <br />
      <Box height="40px" />
      {contactlist.map((val) => {
        return <LeadsList data={val} />;
      })}
      <Box height="40px" />
      <TabsComponent
        selectedTab={selected}
        onTab1Clicked={() => onTab1Clicked()}
        onTab2Clicked={() => onTab2Clicked()}
        onTab3Clicked={() => onTab3Clicked()}
        onTab4Clicked={() => onTab4Clicked()}
        onTab5Clicked={() => onTab5Clicked()}
      />
      <Box height="40px" />
      <SelectBox
        label="Reason"
        value={change}
        placeholder="Select Reason"
        onChange={(value) => onSChange(value)}
        options={tempOptions}
        createSelect={true}
      />
      <Box height="40px" />
      <TextBox label="Field 1" />
      <Box height="40px" />
      <TextBox label="Field 2" multiline />
      <Box height="40px" />
      {InvoiceData.map((item) => {
        return (
          <React.Fragment key={item.id}>
            <Grid item xs={12}>
              <QuotationList invoiceData={item} />
            </Grid>
          </React.Fragment>
        );
      })}
      <Box height="40px" />
      {unitlisted.map((val) => {
        return <UnitList data={val} checked />;
      })}
      <br />
      <Box height="40px" />
      {unitlist.map((val) => {
        return <UnitList data={val} delete={true} />;
      })}
      <br />
      <Box height="40px" />
      {unitlist.map((val) => {
        return <UnitList data={val} breakup={true} />;
      })}
      <Box height="40px" />
      <Grid container spacing={2}>
        {dashboardData.map((val) => {
          return (
            <Grid item xs={4}>
              <DashboardCard data={val} />
            </Grid>
          );
        })}
      </Grid>
      <Box height="40px" />
      {unitlisted1.map((val) => {
        return <PropertyList data={val} />;
      })}
      <Box height="40px" />
      <Grid container spacing={2}>
        {Activitycarddata.map((val) => {
          return (
            <Grid item xs={6} md={4}>
              <ActivityCard data={val} />
            </Grid>
          );
        })}
      </Grid>

      <Box height="40px" />
      {contactlist1.map((val) => {
        return <ContactList data={val} />;
      })}
      <Box height="40px" />
      {quotationHistory.map((item, index, array) => {
        return (
          <Grid item>
            <QuotationHistory data={item} index={index} length={array.length} />
          </Grid>
        );
      })}
      <Box height="40px" />
      <Quotation data={data} />
      <Box height="40px" />
      <div className={classes.actionCardContainer}>
        {actionCardData.map((actionCard) => {
          return (
            <TodayActionCard
              onClick={actionCard.onClick}
              type={actionCard.type}
              priority={actionCard.priority}
              date={actionCard.date}
              mode={actionCard.mode}
            />
          );
        })}
      </div>
      <Box height="40px" />
      {actionCardData1.map((actionCard) => {
        return (
          <div>
            <ActivityListCard
              selectable={false}
              onClick={actionCard.onClick}
              type={actionCard.type}
              priority={actionCard.priority}
              date={actionCard.date}
              mode={actionCard.mode}
              status={actionCard.status}
            />

            <Box className={classes.dividerStyle} />
          </div>
        );
      })}
      <Box height="40px" />
      {actionCardData2.map((actionCard, index, array) => {
        return (
          <div>
            <ActivityListCard
              selectable={true}
              onClick={actionCard.onClick}
              type={actionCard.type}
              contactName={actionCard.contactName}
              priority={actionCard.priority}
              date={actionCard.date}
              mode={actionCard.mode}
              status={actionCard.status}
            />
            <Box className={classes.dividerStyle} />
          </div>
        );
      })}
      <Box height="40px" />
      {toDoListData.map((toDoList, index, array) => {
        return (
          <div>
            <ToDoListCard
              completed={toDoList.completed}
              description={toDoList.description}
            />
            <Box height={"8px"} />
          </div>
        );
      })}
      <Box height="40px" />
      {/* <RangeSlider
        title="Area"
        min={0}
        max={10000} />
      <Box height="40px" /> */}
      <Grid style={{ height: "315px", width: "100%" }}>
        <MyGoogleMap
          lat={13.0317229}
          lng={80.2351578}
          center={{ lat: 13.0317229, lng: 80.2351578 }}
          mapResult={mapResult}
        />
      </Grid>
      <Box height="40px" />
    </div>
  );
};
