import AddIcon from "@mui/icons-material/Add";
import {
  Badge,
  Box,
  Dialog,
  Drawer,
  Fab,
  Grid,
  Hidden,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useHistory } from "react-router-dom";
import {
  Filter,
  LeadsList,
  LoderSimmer,
  TitleBar,
  TopNavBars,
} from "../../components";
import { config } from "../../config";
import { NetworkCall } from "../../networkcall/index";
import { Routes } from "../../router/routes";
import { NetWorkCallMethods } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.secondary,
  },
  screenPadding: {
    overflow: "overlay",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  total: {
    fontSize: "16px",
    fontFamily: "tenant_bold",
  },
  filter: {
    borderRadius: "6px",
    border: "1px solid #E4E8EE",
    color: "black",
    backgroundColor: "white",
  },
  filterdata: {
    padding: "8px 12px",
    fontSize: "16px",
    fontFamily: "tenant_bold",
    cursor: "pointer",
    backgroundColor: theme.palette.background.paper,
    width: "100%",
  },
  filterdataChild: {
    paddingLeft: "12px",
    fontSize: "16px",
    fontFamily: "tenant_bold",
    width: "100%",
  },

  filterleft: {
    borderRight: "1px solid #c1c1c1",
    [theme.breakpoints.up("sm")]: {
      minHeight: "68vh",
    },
    [theme.breakpoints.down("sm")]: {
      minHeight: "75vh",
    },
  },
  active: {
    fontSize: "12px",
    padding: "2px 6px",
    color: "#5078E1",
    backgroundColor: "#E4E8EE",
    borderRadius: "50%",
    width: "100%",
  },
  btncontainer: {
    padding: "14px",
    backgroundColor: "#F5F7FA",
  },
  applybtn: {
    padding: "12px",
    borderRadius: "10px",
  },
  cancelbtn: {
    padding: "12px",
    border: "1px solid #E4E8EE",
    backgroundColor: "white",
    borderRadius: "10px",
  },
  filterdataActive: {
    padding: "8px 12px",
    fontSize: "16px",
    fontFamily: "tenant_bold",
    cursor: "pointer",
    backgroundColor: theme.palette.background.secondary,
    width: "100%",
  },
  listpad: {
    padding: "0px",
  },
  fab: {
    textTransform: "capitalize",
    "& .MuiButton-root": {
      color: theme.palette.background.paper,
    },

    [theme.breakpoints.down("sm")]: {
      position: "fixed",
      bottom: "60px",
      right: "10%",
    },
    [theme.breakpoints.up("sm")]: {
      position: "fixed",
      bottom: "60px",
      left: "65%",
    },
  },
  search: {
    borderBottom: "4px solid #F5F7FA",
    padding: "18px 0px",
  },
  searchmain: {
    padding: "18px 0px",
  },
  searchtext: {
    color: "#98A0AC",
    fontSize: "14px",
  },
  searchbox: {
    border: "1px solid #E4E8EE",
    borderRadius: "8px",
    backgroundColor: "#F5F7FA",
    padding: "8px 12px",
    cursor: "pointer",
  },
  textbox: {
    [`& fieldset`]: {
      borderRadius: "8px",
      height: 50,
      border: "1px solid #CED3DD",
      "& :hover": {
        border: "1px solid #5078E1",
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: "11px 14px",
    },
  },
}));
const filterInitialstate = {
  startDate: "",
  endDate: "",
};
export const Lead = (props) => {
  const classes = useStyles();
  const size = useWindowDimensions();

  const [drawer, setDrawer] = React.useState(null);
  const [search, setSearch] = React.useState(false);
  const [searchdata, setSearchdata] = React.useState("");
  const [leaddata, setLeaddata] = React.useState([]);
  const history = useHistory();
  const [loading, setLoading] = React.useState(true);
  const [hasMoreList, setHasMoreList] = React.useState(true);
  const [offSet, setOffSet] = React.useState(0);
  const [total, setTotal] = React.useState(0);
  //Filter Data Values
  const [filtername, setFiltername] = React.useState([]);
  const [filterlead, setFilterlead] = React.useState([]);
  const [filterdate, setFilterdate] = React.useState([]);
  const [filterdata, setFilterData] = React.useState({});
  const [filterStartDate, setfilterStartDate] = React.useState({
    ...filterInitialstate,
  });
  const updateState = (key, value) => {
    setfilterStartDate({ ...filterStartDate, [key]: value });
  };

  const user_profile_id = localStorage.getItem("userProfileId");
  const roles = JSON.parse(localStorage.getItem("selectedroles"));
  const filterData = [
    {
      id: "1",
      title: "Lead Name",
      key: "lead_name",
      showSearch: true,
      filterType: "CHECKBOX",
      values: filtername,
    },
    {
      id: "2",
      title: "Lead No",
      key: "lead_no",
      showSearch: true,
      filterType: "CHECKBOX",
      values: filterlead,
    },
    {
      id: "3",
      title: "Date",
      key: "date",
      filterType: "DATE",
      values: filterdate,
    },
    {
      id: "4",
      title: "Status",
      key: "status",
      filterType: "CHECKBOX",
      values: [
        {
          label: "Opened",
          value: 1,
        },
        {
          label: "Closed",
          value: 2,
        },
      ],
    },
  ];
  const getAlllead = () => {
    const data = {
      tenantId: `${config.tenantid}`,
      leadOwner: [user_profile_id],
      offset: offSet,
      recordsPerPage: 10,
      companyId: roles.company_id,
      // status: "active",
    };
    NetworkCall(
      `${config.api_url}/get-all-leads`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        setTotal(response?.data.totalLeads);
        let templist = leaddata.concat(response?.data.data);
        setLeaddata(templist);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getAllFilterData = () => {
    const data = {
      tenantId: `${config.tenantid}`,
      leadOwner: [user_profile_id],
      companyId: roles.company_id,
    };
    NetworkCall(
      `${config.api_url}/get-all-leads/props`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        const name = response.data.data?.map((val) => {
          let temp_name;
          try {
            temp_name = {
              label: val.name,
              value: val.contact_id,
            };
          } catch (err) {
            console.log(err);
          }
          return temp_name;
        });
        setFiltername(name);
        const leadid = response.data.data?.map((val) => {
          let lead_id;
          try {
            lead_id = {
              label: val.lead_no,
              value: val.id,
            };
          } catch (err) {
            console.log(err);
          }
          return lead_id;
        });
        setFilterlead(leadid);
        const date = response.data.data?.map((val) => {
          let temp_date;
          try {
            temp_date = {
              label: moment(val?.openedDate ? val?.openedDate : val?.closedData)
                .tz(moment.tz.guess())
                .format("DD MMM YYYY"),
              value: val?.openedDate ? val?.openedDate : val?.closedData,
            };
          } catch (err) {
            console.log(err);
          }
          return temp_date;
        });

        setFilterdate(date);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  React.useEffect(() => {
    getAlllead();
    getAllFilterData();
    // eslint-disable-next-line
  }, [offSet]);
  const searchHandle = (search) => {
    setSearchdata(search);
    const data = {
      tenantId: `${config.tenantid}`,
      leadOwner: [user_profile_id],
      offset: offSet,
      recordsPerPage: 10,
      query: search,
      companyId: roles.company_id,
      // status: "active",
    };
    NetworkCall(
      `${config.api_url}/get-all-leads`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        setLeaddata(response?.data.data);
      })

      .catch((error) => {
        console.log(error);
      });
  };
  //functions

  const fetchMoreData = () => {
    if (leaddata?.data?.length >= leaddata?.data?.length + 10) {
      setHasMoreList(false);
    } else {
      setOffSet(offSet + 10);
    }
  };
  const draweropen = () => {
    setDrawer(true);
  };
  const drawerclose = () => {
    setDrawer(false);
  };
  const handleClicksearch = () => {
    setSearch(!search);
  };
  const goBack = () => {
    setSearch(!search);
    getAlllead();
  };

  const handleToggle = () => {
    history.push(Routes.createlead);
  };
  const applyFilter = (datas) => {
    setFilterData(datas);
    const data = {
      tenantId: `${config.tenantid}`,
      leadOwner: user_profile_id,
      offset: offSet,
      recordsPerPage: 10,
      companyId: roles.company_id,
      leadStartDate: [filterStartDate.startDate],
      leadEndDate: [filterStartDate.endDate],
      contactId: datas?.lead_name,
      leadId: datas?.lead_no,
      status: datas?.status,
    };
    const data2 = {
      tenantId: `${config.tenantid}`,
      leadOwner: [user_profile_id],
      offset: offSet,
      recordsPerPage: 10,
      companyId: roles.company_id,

      contactId: datas?.lead_name,
      leadId: datas?.lead_no,
      status: datas?.status,
    };

    NetworkCall(
      `${config.api_url}/get-all-leads`,
      NetWorkCallMethods.post,
      filterStartDate.startDate && filterStartDate.endDate ? data : data2,
      null,
      true,
      false
    )
      .then((response) => {
        setTotal(response?.data.totalLeads);
        setLeaddata(response?.data.data);
        setDrawer(false);
      })
      .catch((err) => {
        console.log(err);
      });
    //drawerclose();
  };
  return (
    <>
      {loading ? (
        <>
          <TopNavBars />
          <LoderSimmer count={10} />
        </>
      ) : (
        <div className={classes.root}>
          <Grid container sx={{ padding: "0px" }}>
            <Grid
              className={classes.screen}
              style={{ height: size?.height }}
              item
              xs={12}
            >
              <Grid className={classes.topNavBarStyle}>
                <Grid item xs={12}>
                  {/* topnavbar */}
                  {search ? (
                    <>
                      <TitleBar text="search" goBack={goBack} />
                      <div style={{ padding: "12px" }}>
                        <TextField
                          size="small"
                          variant="outlined"
                          color="secondary"
                          style={{ backgroundColor: "white" }}
                          fullWidth
                          value={searchdata}
                          onChange={(event) => {
                            searchHandle(event.target.value);
                          }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="end">
                                <IconButton aria-label="Toggle password visibility">
                                  <img
                                    src="/images/icons8-search.svg"
                                    alt="note"
                                  />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <TopNavBars
                        search={true}
                        handleClicksearch={handleClicksearch}
                      />
                      <Grid
                        item
                        xs={12}
                        style={{ padding: "0px 12px", marginTop: "8px" }}
                      >
                        {/* filter btn and units length */}
                        <Box
                          display="flex"
                          alignItems="center"
                          marginBottom="10px"
                        >
                          <Box flexGrow={1}>
                            <Typography className={classes.total}>
                              Leads(
                              {total < 10 ? `0${total}` : total})
                            </Typography>
                          </Box>
                          <Box>
                            <IconButton
                              className={classes.filter}
                              onClick={draweropen}
                            >
                              {filterdata.lead_name ||
                              filterdata.lead_no ||
                              filterdata.lead_status ||
                              filterdate.startDate ? (
                                <Badge variant="dot" color="primary">
                                  <img
                                    src="/images/icons8-funnel.svg"
                                    alt="filter"
                                  />
                                </Badge>
                              ) : (
                                <img
                                  src="/images/icons8-funnel.svg"
                                  alt="filter"
                                />
                              )}
                            </IconButton>
                          </Box>
                        </Box>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
              <Grid className={classes.screenPadding}>
                <Grid item xs={12}>
                  <div className={classes.listpad}>
                    <InfiniteScroll
                      dataLength={leaddata.length}
                      next={fetchMoreData}
                      hasMore={hasMoreList}
                      height={size?.height - 115}
                    >
                      {leaddata?.map((val) => {
                        return <LeadsList data={val} />;
                      })}
                    </InfiniteScroll>
                  </div>
                </Grid>
                <Fab
                  color="primary"
                  className={classes.fab}
                  aria-label="add"
                  onClick={handleToggle}
                >
                  <AddIcon />
                </Fab>
              </Grid>
            </Grid>
          </Grid>

          {/* filter drawer */}
          <Hidden smDown>
            <Dialog
              fullWidth={true}
              maxWidth="sm"
              open={drawer}
              onClose={drawerclose}
            >
              <div>
                <Filter
                  filterData={filterData}
                  onGoBack={drawerclose}
                  selectedList={filterData[0]}
                  filters={{
                    lead_name: filterdata.lead_name,
                    lead_no: filterdata.lead_no,
                    status: filterdata.status,
                  }}
                  onApply={applyFilter}
                  updateState={updateState}
                  filterStartDate={filterStartDate}
                  onClose={drawerclose}
                />
              </div>
            </Dialog>
          </Hidden>
          <Hidden smUp>
            <Drawer
              open={drawer}
              fullWidth={true}
              onClose={drawerclose}
              style={{ width: "100%" }}
            >
              <Filter
                filterData={filterData}
                onGoBack={drawerclose}
                selectedList={filterData[0]}
                filters={{
                  lead_name: filterdata.lead_name,
                  lead_no: filterdata.lead_no,
                  status: filterdata.status,
                }}
                onClose={drawerclose}
                onApply={applyFilter}
              />
            </Drawer>
          </Hidden>
        </div>
      )}
    </>
  );
};
