import { Button, Container, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import jwt_decode from "jwt-decode";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { config } from "../../config";
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";
import { NetWorkCallMethods } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    backgroundImage: `url("/images/house.png")`,
    backgroundRepeat: "no-repeat",
    backgroundPositionY: "100%",
    backgroundSize: "100% 100vh",
    backgroundPosition: "bottom right",
    position: "relative",
  },
  welcomeText: {
    fontSize: "26px",
    fontFamily: "tenant_extraBold",
    color: "white",
  },
  btn: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.background.tertiary1,
    borderRadius: "12px",
    boxShadow: "opx 6px 10px #00000014",
    "&:hover": {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.background.tertiary1,
    },
  },
  img: {
    width: "auto",
    height: "36px",
  },
  btnRoot: {
    position: "fixed",
    bottom: 0,
    width: "100%",
    right: 0,
    left: 0,
    [theme.breakpoints.up("sm")]: {
      padding: "0px 45px",
    },
    // padding: " 0px 16px"
  },
  poweredby: {
    display: "flex",
    alignItems: "center",
    fontSize: "10px",
    color: "white",
  },
  pa: {
    color: "white",
    fontFamily: "tenant_semiBold",
    fontSize: "12px",
  },
}));

export const WelcomePage = () => {
  const classes = useStyles();
  const history = useHistory();
  const size = useWindowDimensions();
  const search = useLocation().search;
  const welcomePageAuthToken = new URLSearchParams(search).get("token");
  const [decoded, setDecoded] = React.useState(null);

  React.useEffect(() => {
    if (welcomePageAuthToken !== null && welcomePageAuthToken !== "") {
      setDecoded(jwt_decode(welcomePageAuthToken));
    }
    // eslint-disable-next-line
  }, []);
  const getStarted = () => {
    const payload = {
      tenantId: `${config.tenantid}`,
      userId: decoded.user_id,
    };
    NetworkCall(
      `${config.api_url}/users/verifyUser`,
      NetWorkCallMethods.post,
      payload,
      null,
      false,
      false
    )
      .then((response) => {
        if (response.status === 200) {
          if (response.data.is_verified) {
            history.push(Routes.login);
          } else {
            history.push(
              Routes.setupAccount + "?welcomeAuthToken=" + welcomePageAuthToken
            );
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Container maxWidth="sm" sx={{ padding: 0 }}>
      <div className={classes.root} style={{ height: size?.height }}>
        <Container maxWidth="sm">
          <Grid container>
            <Grid container paddingTop={2}>
              <Grid item xs={12}>
                <img
                  src="/images/DNTlogo2.png"
                  alt="casagrandLogo"
                  className={classes.img}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.welcomeText}>
                  {decoded && decoded.user_name ? decoded.user_name : ""}
                </Typography>
                <Typography className={classes.poweredby}>
                  Powered by&nbsp;
                  <img src="/images/logo.png" alt="logo" />
                  &nbsp;
                  <span className={classes.pa}>Property Automate</span>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Container>
        <Container maxWidth="sm" className={classes.btnRoot}>
          <Grid container>
            <Grid item xs={12}>
              <Button
                variant="contained"
                fullWidth
                className={classes.btn}
                onClick={getStarted}
              >
                Get Started
              </Button>
              <br />
              <br />
            </Grid>
          </Grid>
        </Container>
      </div>
    </Container>
  );
};
