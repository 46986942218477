import React from "react";
import { IconButton, Grid, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
const useStyles = makeStyles((theme) => ({
    gridContainer: {
        marginBottom: theme.spacing(2),
    },
    root: {
        width: "100%",
        height: "100%",
        "& input": {
            position: "absolute",
            width: "100%",
            height: "100%",
            opacity: 0,
            left: 0,
            cursor: "pointer",
            zIndex: 10000,
        },
        "& .MuiButton-root": {
            top: "80px",
        },
        "& .MuiTypography-colorPrimary": {
            marginTop: theme.spacing(11),
        }
    },
    drap: {
        position: "relative",
        height: "163px",
        borderRadius: 6,
        textAlign: "center",
        background: "#F5F7FA",
        border: "2px dashed #E4E8EE"
    },
    drap2: {
        position: "relative",
        height: "163px",
        borderRadius: 6,
        textAlign: "center",
        background: "#F5F7FA",
        border: "2px dashed #E4E8EE"
    },
    text: {
        color: "#404E61",
        fontSize: "12px",
        padding: 10,
        fontFamily: "tenant_semiBold"

    },
    text2: {
        color: "#404E61",
        fontSize: "12px",
        padding: 10,
        fontFamily: "tenant_semiBold"
    },
    upload: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        marginTop: '30px',
    }
}));

export const Upload = (props) => {
    const classes = useStyles(props);
    return (
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            className={classes.gridContainer}
        >
            <Grid item xs={12}>

                {
                    props.state.length > 0 ?
                        (
                            <div className={classes.drap}>
                                <div className={classes.root}>
                                    <div className={classes.upload}>
                                        {props?.single && <input
                                            type="file"
                                            onChange={(e) => props.onChange(e)}
                                            disabled={props.disabled}
                                        />}

                                        {!props?.single && <input
                                            type="file"
                                            multiple
                                            onChange={(e) => props.onChange(e)}
                                            disabled={props.disabled}
                                        />}
                                        <IconButton style={{ marginTop: "-1px", textTransform: "capitalize", color: "grey" }}
                                        >
                                            <img src="/images/icons/upload.svg" alt="upload_image" />
                                        </IconButton>
                                        <Typography className={classes.text}>Upload Images Here</Typography>
                                    </div>
                                </div>
                            </div>

                        )
                        :
                        (
                            <div className={classes.drap2}>
                                <div className={classes.root}>
                                    <div className={classes.upload}>
                                        {props?.single && <input
                                            type="file"
                                            onChange={(e) => props.onChange(e)}
                                            disabled={props.disabled}
                                        />}

                                        {!props?.single && <input
                                            type="file"
                                            multiple
                                            onChange={(e) => props.onChange(e)}
                                            disabled={props.disabled}
                                        />}
                                        <IconButton>
                                            <img src="/images/icons/upload.svg" alt="upload_image" />
                                        </IconButton>
                                        <Typography className={classes.text2}  >Upload Images Here</Typography>

                                    </div>
                                </div>
                            </div>


                        )

                }

            </Grid >
        </Grid >
    );
};
