import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Box, IconButton, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

const useStyles = makeStyles((theme) => ({
  arrowfeed: {
    marginLeft: "8px",
    backgroundColor: "black",
    "&:hover": {
      backgroundColor: "black",
    },
  },
  arrowiconfeed: {
    fontSize: "16px",
    color: "white",
  },
  feedcontainer: {
    backgroundImage: (props) =>
      props.data.type === "feedback"
        ? "url('/images/feedbg.svg')"
        : "url('images/surveyslider.svg')",
    padding: "20px",
    borderRadius: "10px",
    backgroundRepeat: "no-repeat",
    backgroundPositionX: "100%",
    backgroundColor: (props) =>
      props.data.type === "feedback" ? "#B9E5FF" : "#F8D5A5",
  },
  feed: {
    fontSize: "12px",
    color: (props) => (props.data.type === "feedback" ? "#0443A0" : "black"),
    fontFamily: "tenant_semiBold",
  },
  feedsub: {
    fontSize: "14px",
    fontWeight: "bold",
    fontStyle: "italic",
    marginTop: "10px",
    color: (props) => (props.data.type === "feedback" ? "black" : "#8D5C21"),
  },
}));
export const SurveyCard = (props) => {
  const classes = useStyles(props);
  return (
    <Box display="flex" className={classes.feedcontainer}>
      <Box flexGrow={1}>
        <Typography className={classes.feed}>
          Help us serve you better by providing
          <br />
          your valuable {props.data.type === "feedback" && "feedback"}
          {props.data.type === "survey" && "survey"}
        </Typography>

        <Typography className={classes.feedsub}>{props.data.msg}</Typography>
      </Box>
      <Box>
        <br />

        <IconButton className={classes.arrowfeed}>
          <ArrowForwardIosIcon size="small" className={classes.arrowiconfeed} />
        </IconButton>
      </Box>
    </Box>
  );
};
