import React, { createContext, useState } from "react";
const InitialState = {
  location: "",
  unitstatus: "",
  purpose: "",
  availabiltystart: "",
  availabiltyend: "",
  leaseperiod: "",
  furnishing: "",
  area: "",
  budget: "",
  aminities: [],
  floor: "",
  error: {
    location: "",
    unitstatus: "",
    purpose: "",
    availabiltystart: "",
    availabiltyend: "",
    leaseperiod: "",
    furnishing: "",
    area: "",
    budget: "",
    aminities: "",
    floor: "",
  },
};
export const SearchListContext = createContext();

const SearchContextProvider = (props) => {
  const [data, setdata] = useState({ ...InitialState });

  const updateState = (key, value) => {
    let error = data.error;
    error[key] = "";
    setdata({ ...data, [key]: value, error });
  };

  return (
    <SearchListContext.Provider
      value={{
        data,
        updateState,
      }}
    >
      {props.children}
    </SearchListContext.Provider>
  );
};

export default SearchContextProvider;
