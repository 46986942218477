import { Avatar, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Box } from "@mui/system";
import jwt_decode from "jwt-decode";
import moment from "moment-timezone";
import React from "react";
import { useHistory } from "react-router-dom";
import ShowMoreText from "react-show-more-text";
import { Routes } from "../../../router/routes";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: "16px",
    backgroundColor: "white",
  },
  profilePictureStyle: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    borderRadius: "10px",
    height: "100px",
    width: "100%",
  },
  priorityTagStyle: {
    backgroundColor: "#071741",
    borderRadius: "4px 4px 0px 0px",
    fontSize: "8px",
    fontFamily: "tenant_extraBold",
    color: "#FFFFFF",
    padding: "2px 6px 2px 6px",
  },
  detailSection1Style: {
    padding: "0px 16px 0px 16px",
  },
  detailSection2Style: {
    padding: "16px 0px 16px 0px",
  },
  detailSection2LabelTextStyle: {
    fontSize: "8px",
    fontFamily: "tenant_semiBold",
    color: theme.typography.color.Tertiary,
  },
  detailSection2ValueTextStyle: {
    fontSize: "12px",
    fontFamily: "tenant_bold",
    color: theme.typography.color.primary,
  },
  detailSection2SeparatorStyle: {
    margin: "0px 16px 0px 16px",
    height: "inherit",
    width: "1px",
    backgroundColor: theme.palette.border.secondary,
  },
  detailSectionHorizontalSeparatorStyle: {
    width: "inherit",
    height: "1px",
    backgroundColor: theme.palette.border.secondary,
  },
  detailSection3Style: {
    padding: "16px 0px 16px 0px",
  },
  detailSection4Style: {
    padding: "16px 0px 0px 0px",
  },
  nameTextStyle: {
    color: theme.typography.color.primary,
    fontSize: "16px",
    fontFamily: "tenant_extraBold",
    padding: "0px 8px 0px 0px",
    textTransform: "capitalize",
  },
  typeTextStyle: {
    color: theme.typography.color.primary,
    fontSize: "10px",
    fontFamily: "tenant_bold",
    borderRadius: "4px",
    backgroundColor: "#E1E8F8",
    padding: "2px 6px 2px 6px",
  },
  contactTextStyle: {
    color: theme.typography.color.secondary,
    fontSize: "10px",
    fontFamily: "tenant_bold",
    borderRadius: "4px",
    backgroundColor: theme.palette.background.tertiary,
    padding: "2px 6px 2px 6px",
    margin: "8px 8px 0px 0px",
  },
  createdByOnStyle1: {
    color: theme.typography.color.Tertiary,
    fontSize: "10px",
    fontFamily: "tenant_regular",
  },
  createdByOnStyle2: {
    color: theme.typography.color.Tertiary,
    fontSize: "10px",
    fontFamily: "tenant_semiBold",
  },
  periodTextStyle: {
    color: theme.typography.color.primary,
    fontSize: "12px",
    fontFamily: "tenant_bold",
  },
  noteTextStyle1: {
    fontSize: "12px",
    fontFamily: "tenant_semiBold",
    color: theme.typography.color.secondary,
    margin: "0px 5px 0px 0px",
  },
  noteTextStyle2: {
    fontSize: "12px",
    fontFamily: "tenant_regular",
    color: theme.typography.color.secondary,
    textOverflow: "clip",
  },
  seeMoreLessTextStyle: {
    fontSize: "12px",
    fontFamily: "tenant_semiBold",
    color: theme.palette.primary.main,
  },
}));
moment.defaultFormat = "DD MMM YYYY";
export const PrimaryDetailsSection = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const tempDecoded = jwt_decode(localStorage.getItem("authToken"));

  return (
    <Grid className={classes.root} container direction={"column"}>
      <Grid container direction={"row"} justifyContent={"space-between"}>
        <Grid container direction={"column"} xs={3}>
          <Avatar
            className={classes.profilePictureStyle}
            variant="rounded"
            style={{
              backgroundImage: "url(" + props?.leadImg + ")",
            }}
          >
            <Grid justifyContent={"center"} alignSelf={"end"}>
              <Typography className={classes.priorityTagStyle}>
                &nbsp;&nbsp;{props?.leadDetails?.urgent}&nbsp;&nbsp;
              </Typography>
            </Grid>
          </Avatar>
        </Grid>
        <Grid
          className={classes.detailSection1Style}
          container
          direction={"column"}
          xs={8}
        >
          <Grid container direction={"row"} alignItems={"center"}>
            <Typography className={classes.nameTextStyle}>
              {props?.leadDetails?.contact?.first_name}&nbsp;
              {props?.leadDetails?.contact?.last_name}
            </Typography>
            {props?.leadDetails?.lead_type && (
              <Typography className={classes.typeTextStyle}>
                {props?.leadDetails?.lead_type === 1 && "Commercial"}
                {props?.leadDetails?.lead_type === 2 && "Residential"}
              </Typography>
            )}
          </Grid>
          <Box height={8} />
          <Grid container direction={"row"} alignItems={"center"}>
            <Typography className={classes.contactTextStyle}>
              {props?.leadDetails?.contact?.mobile_no_country_code}&nbsp;
              {props?.leadDetails?.contact?.mobile_no}
            </Typography>
            <Typography className={classes.contactTextStyle}>
              {props?.leadDetails?.contact?.email_id}
            </Typography>
          </Grid>

          <Box height={8} />
          <Grid container direction={"row"} alignItems={"center"}>
            <Typography
              className={classes.createdByOnStyle1}
              style={{ margin: "0px 5px 0px 0px" }}
            >
              {"Created by"}
            </Typography>
            <Typography className={classes.createdByOnStyle2}>
              {tempDecoded.user_name}
            </Typography>
            <Typography
              className={classes.createdByOnStyle1}
              style={{ margin: "0px 5px 0px 5px" }}
            >
              {"on"}
            </Typography>
            <Typography className={classes.createdByOnStyle2}>
              {moment(props?.leadDetails?.created_at)
                .tz(moment.tz.guess())
                .format()}
            </Typography>
          </Grid>
        </Grid>
        <Grid container direction={"column"} xs={1} alignItems={"end"}>
          {props.active === "true" && (
            <Box
              onClick={() =>
                history.push(Routes.leadInfoEdit + "?leadId=" + props?.leadId)
              }
              style={{
                cursor: "pointer",
                height: "24px",
                width: "24px",
                padding: "5px",
              }}
            >
              <img src="images/lead_info_edit_icon.svg" alt="" />
            </Box>
          )}
        </Grid>
      </Grid>
      <Grid
        className={classes.detailSection2Style}
        container
        direction={"row"}
        justifyContent={"space-evenly"}
      >
        <Grid container direction={"column"} justifyContent={"center"} xs={3}>
          <Grid>
            <img src="/images/lead_info_requirement_icon.svg" alt="" />
          </Grid>
          <Grid>
            <Typography className={classes.detailSection2LabelTextStyle}>
              REQUIREMENT
            </Typography>
          </Grid>
          <Grid>
            <Typography className={classes.detailSection2ValueTextStyle}>
              {props?.leadDetails?.value}&nbsp;
              {props?.leadDetails?.value_type}
            </Typography>
          </Grid>
        </Grid>
        {props?.leadDetails?.budget_range && (
          <>
            <Box className={classes.detailSection2SeparatorStyle} />
            <Grid
              container
              direction={"column"}
              justifyContent={"center"}
              xs={3}
            >
              <Grid>
                <img src="/images/lead_info_budget_icon.svg" alt="" />
              </Grid>
              <Grid>
                <Typography className={classes.detailSection2LabelTextStyle}>
                  BUDGET
                </Typography>
              </Grid>
              <Grid>
                <Typography className={classes.detailSection2ValueTextStyle}>
                  {new Intl.NumberFormat("en-IN", {
                    style: "currency",
                    currency: "INR",
                    minimumFractionDigits: 0,
                  }).format(Math.round(props?.leadDetails?.budget_range?.[0]))}
                  &nbsp;-&nbsp;
                  {new Intl.NumberFormat("en-IN", {
                    style: "currency",
                    currency: "INR",
                    minimumFractionDigits: 0,
                  }).format(Math.round(props?.leadDetails?.budget_range?.[1]))}
                </Typography>
              </Grid>
            </Grid>
          </>
        )}

        <Box className={classes.detailSection2SeparatorStyle} />
        <Grid container direction={"column"} justifyContent={"center"} xs={3}>
          <Grid>
            <img src="/images/lead_info_source_type_icon.svg" alt="" />
          </Grid>
          <Grid>
            <Typography className={classes.detailSection2LabelTextStyle}>
              LEAD TYPE
            </Typography>
          </Grid>
          <Grid>
            <Typography className={classes.detailSection2ValueTextStyle}>
              {props?.leadDetails?.lead_type === 1 && "Commercial"}
              {props?.leadDetails?.lead_type === 2 && "Residential"}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Box className={classes.detailSectionHorizontalSeparatorStyle} />
      <Grid className={classes.detailSection3Style} container direction={"row"}>
        <img src="/images/lead_info_period_icon.svg" alt="" />
        <Box width={8} />
        <Typography className={classes.periodTextStyle}>
          {moment(props?.leadDetails?.lease_start_date)
            .tz(moment.tz.guess())
            .format()}
          &nbsp;-&nbsp;
          {moment(props?.leadDetails?.lease_end_date)
            .tz(moment.tz.guess())
            .format()}
        </Typography>
      </Grid>
      <Box className={classes.detailSectionHorizontalSeparatorStyle} />
      <Grid className={classes.detailSection4Style} container direction={"row"}>
        <Typography className={classes.noteTextStyle1}>{"Note:"}</Typography>
        <Grid xs={10}>
          <ShowMoreText
            lines={1}
            more="Show More"
            less="Show Less"
            className={classes.noteTextStyle2}
            anchorClass={classes.seeMoreLessTextStyle}
            expanded={false}
            truncatedEndingComponent={"... "}
          >
            <Typography className={classes.noteTextStyle2}>
              {props?.leadDetails?.notes}
            </Typography>
          </ShowMoreText>
        </Grid>
        {/* <Typography
                    className={classes.seeMoreLessTextStyle}
                    onClick={handleSeeMore}>
                    {isSeeMore ? "See More" : "See Less"}
                </Typography> */}
      </Grid>
    </Grid>
  );
};
